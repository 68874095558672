:root {
  box-sizing: border-box;

}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  /* background-color: #f4f5f8; */
  /* background-color: #f6f9fa; */
  background-color: #efefef;
  font-family: 'Roboto';
  height: 100%;
  /* padding-top: 6.4rem; */
}

/* UTILITY CLASSES */
.src_u-left-text__OeN4u {
  text-align: left;
}

.src_u-right-text__2dXuj {
  text-align: right;
}

.src_u-margin-bottom-big__1CWHm {
  margin-bottom: 7rem;
}

.src_u-margin-bottom-medium__2EKmt {
  margin-bottom: 4rem;
}

.src_u-margin-bottom-small__1Tx1Q {
  margin-bottom: 1.5rem;
}

.src_u-text-semi-bold__3mBQP {
  font-weight: 600;
}

.src_u-inline-block__3N2cL {
  display: inline-block;
}

.src_tab-list__1t9on {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.src_tab-list-item__1eCAK {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.src_tab-list-active__2Pe4Q {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}


.Layout_Wrapper__2MjJH {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.Layout_ToolBar__1976X {
    /* height: 6.4rem; */
}

.Layout_Content__2tP4q {
    width: 100% !important;
}

/* .MainContentWrapper {
    width: 100%;
} */
.Layout_FlexColumns__2Bc4m {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.Layout_shell-view__3mIoO .Layout_main__2rvbQ:not(.Layout_main-flex-container-true__MNSsm) {
    width: 100%;
    height: 100%;
    position: absolute;
}

.Layout_bAw__cwi7l {
    min-width: 56px;
    transition-duration: .15s;
    transition-property: min-width, width;
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    z-index: 2;
}
.Toolbar_Toolbar__xtg6y {
    display: flex;
    background-color: #FFF;
    border-bottom: 1px solid #ddd;
    color: black;
    top: 0;
    width: 100%;
    min-width: 320px;
    padding: 0.8rem 0;
    /* position: fixed; */
    z-index: 10000;
}

/* daeffa */
.Toolbar_ToolbarWrapper__2veu- {
    padding: 8px;
    background-color: #265973;
    color: white;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Toolbar_ToolbarLogoSection__1U70J {
    order: 1;
    /* padding-right: 4.8rem; */
    box-sizing: border-box;
    flex: 1 0 100%;
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    display: flex;
    max-width: 32rem;
}

.Toolbar_ToolbarHamburgerWrapper__220yS {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 2.4rem;
    width: 2.4rem;
    flex: 0 0 auto;
    fill: #FFF;
}

.Toolbar_AppLogo__OsimW,
.Toolbar_AppLogo__OsimW:hover {
    font-weight: 300;
    font-size: 1.8rem;
    background-color: #265973;
    color: #fff;
    /* border-right: 1px solid rgba(255, 255, 255, 0.36); */
    padding: 1.3rem 0;
    border-left: 1px solid transparent;
}

.Toolbar_CompanyLogo__2ck6P {
    /* height: 7.2rem; */
    line-height: 4.8rem;
    color: #fff;
    padding: 1.2rem 0;
    padding-left: 1.6rem;
}

.Toolbar_Logo__3n569 {
    background-color: transparent;
    padding: 0;
    height: 3.4rem;
    box-sizing: border-box;
}



.Toolbar_Bold__3aSSq {
    font-weight: 800;
}

.Toolbar_LogoWrapper__2_8wz {
    align-items: center;
    display: flex;
    position: relative;
}

.Toolbar_Logo__3n569 {
    display: inline-block;
    font-family: ''Roboto'', Arial, sans-serif;
    font-size: 24px;
    line-height: 24px;
    padding-left: 8px;
    position: relative;
    top: -1.5px;
    vertical-align: middle;
}

.Toolbar_bold__2HuS_ {
    font-weight: 600;
}

.Toolbar_gb_Ec__33PEy {
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    display: flex;
    -webkit-user-select: none;
}

.Toolbar_gb_dc__3EFVp {
    line-height: normal;
    position: relative;
    padding-left: 16px;
}

.Toolbar_gb_dc__3EFVp a {
    outline: none;
    color: inherit;
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
}

.Toolbar_gb_Ic__1pst0 {
    height: 24px;
    width: 74px;
    display: inline-block;
    vertical-align: middle;
}

.Toolbar_SelectionWrapper__1Nsw6 {
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    justify-content: flex-start;
}

.Toolbar_gb_Sd__1FkiD {
    padding-right: 16px;
    max-width: 100%;
    flex: 1 1 auto;
    width: 100%;
    height: 48px;
}

.Toolbar_SearchForm__3bxc0 {
    background: rgba(255, 255, 255, 0.36);
    color: white;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    margin-left: 0;
    margin-right: 0;
    max-width: 60rem;
    position: relative;
    transition: background 100ms ease-in, width 100ms ease-out;
}

.Toolbar_SearchFormInputWrapper__1nfCv {
    margin-left: 50px;
    margin-right: 50px;
}

.Toolbar_SearchFormInput__3nWUD {
    background: transparent;
    border: none;
    font: normal 16px 'Roboto', sans-serif;
    -webkit-font-feature-settings: none;
            font-feature-settings: none;
    -webkit-font-variant-ligatures: none;
            font-variant-ligatures: none;
    outline: none;
    padding: 1.4rem;
    padding-right: 0;
    padding-left: 2px;
    width: 51rem;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 1);
}

.Toolbar_SearchFormInput__3nWUD::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
}

.Toolbar_SearchFormInput__3nWUD::placeholder {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
}

.Toolbar_SearchFormIcon__3QydW {
    position: absolute;
    padding-left: 2px;
    padding-right: 2px;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0 4px;
    line-height: 0;
    float: left;
    top: 0;
    font: 400 13px 'Roboto', arial, sans-serif;
}

.Toolbar_SearchIconWrapper__24lVZ {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    line-height: 0;
    margin: 1.2rem;
    height: 24px;
    width: 24px;
}

.Toolbar_SearchIconWrapper__24lVZ svg {
    fill: rgba(0, 0, 0, 0.5);
    opacity: 1;
}

.Toolbar_UserControls__2Vq5U {
    order: 3;
    color: #fff;
    height: 48px;
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    display: flex;
    height: 48px;
    line-height: normal;
    padding: 0 4px;
    box-sizing: border-box;
    /* padding-left: 32px; */
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Toolbar_ToolbarHelpWrapper__20HAn {
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Toolbar_ToolbarHelpWrapper__20HAn span {
    padding-right: 12px;
}



.Toolbar_Notifications__24VkX {
    order: 2;
    flex: 1 1 100%;
    /* padding: 0 5rem; */
    position: relative;
    min-width: 242px;
}

.Toolbar_NotificationsWrapper__3RDS8 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.Toolbar_TimeContainer__hck1G {
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    cursor: pointer;
    display: inline-block;
    text-align: left;
    white-space: nowrap;
}



.Toolbar_Time__2DxhZ {
    display: inline-block;
    font-size: 38px;
    position: relative;
    vertical-align: middle;
    z-index: 5;
    color: #fff;
    font-weight: 300;
    letter-spacing: 1.5px;
    font-family: 'Open Sans';
}


.Toolbar_TimeHours__1bVHR {
    display: inline-block;
    vertical-align: top;
}

.Toolbar_TimeSemicolon__1yyPj {
    display: inline-block;
    vertical-align: top;
}

.Toolbar_TimeMinutes__3cGeK {
    display: inline-block;
    vertical-align: top;
}

.Toolbar_TimeAmPm__Zavi1 {
    display: inline-block;
    font-size: 12px;
    line-height: 24px;
    margin-left: 0;
    vertical-align: top;
}

.Toolbar_TimemanRightSide__31gf8 {
    color: #fff;
}

.Toolbar_TimemanRightSide__31gf8 {
    display: inline-block;
    font-size: 12px;
    font-family: "OpenSans-Regular", sans-serif;
    padding: 0 0 0 13px;
    position: relative;
    text-transform: uppercase;
    vertical-align: middle;
    z-index: 5;
}

.Toolbar_TimemanContainer__2JVKO .Toolbar_TimemanBackground__1ZVKz {
    width: auto;
    left: 0;
    right: 0;
}

.Toolbar_TimemanBackground__1ZVKz {
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.Toolbar_TimemanInfo__39azw {
    display: block;
    margin-bottom: 8px;
}

.Toolbar_TimemanTaskTime__1lzPh {
    padding-left: 17px;
}

.Toolbar_TimemanBeginningBut__3glw0,
.Toolbar_TimemanTaskTime__1lzPh {
    display: block;
    padding-left: 20px;
    position: relative;
}

.Toolbar_planner-wrap__3Zq4D .Toolbar_timeman-event__27FKg,
.Toolbar_planner-wrap__3Zq4D .Toolbar_timeman-tasks__1NP-Q {
    display: block;
    margin: 3px 11px 3px 0;
    padding-left: 15px;
}

.Toolbar_TimemanTasks__2Oj9Y {
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.Toolbar_TimemanTaskTime__1lzPh {
    padding-left: 17px;
}

.Toolbar_TimemanBeginningBut__3glw0,
.Toolbar_TimemanTaskTime__1lzPh {
    display: block;
    padding-left: 20px;
    position: relative;
}

.Toolbar_TimemanTasks__2Oj9Y:before {
    background: url('data:image/svg+xml;charset=US-ASCII,<svg%20xmlns%3D"http%3A//www.w3.org/2000/svg"%20width%3D"6"%20height%3D"9"%20viewBox%3D"0%200%206%209">%0A%20%20<polygon%20fill%3D"%23fff"%20points%3D"6%200%200%200%200%209%201%209%201%205%206%205"/>%0A</svg>%0A') center center no-repeat;
}

.Toolbar_TimemanTasks__2Oj9Y:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 6px;
    height: 9px;
    margin: 0 5px;
}
.SignedInMenu_Bold__17nif {
    font-weight: 800;
}

.SignedInMenu_bold__24zpS {
    font-weight: 600;
}


.SignedInMenu_LoginWrapper__2dsIG {
    border-right: solid 1px #E6E8EB;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    flex: 0 0 auto;
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    font-weight: 300;
    color: #002060;
}

.SignedInMenu_LoginWrapper__2dsIG .SignedInMenu_LoggedUser__1Bp9p {
    font-weight: 600;
}

.SignedInMenu_SettingsHelpIconWrapper__3n4Ml {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.SignedInMenu_SettingsIconWrapper__1z-f8 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}
.SignedOutMenu_Bold__4V-3k {
    font-weight: 800;
}

.SignedOutMenu_bold__3eZAA {
    font-weight: 600;
}


.SignedOutMenu_LoginWrapper__amk2a {
    border-right: solid 1px #E6E8EB;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    flex: 0 0 auto;
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    font-weight: 300;
    color: #002060;
}

.SignedOutMenu_LoginWrapper__amk2a .SignedOutMenu_LoggedUser__2zC_l {
    font-weight: 600;
    color: #fff;
}

.SignedOutMenu_SettingsHelpIconWrapper__28oaQ {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.SignedOutMenu_SettingsIconWrapper__32CSd {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}
.NavigationItems_NavigationItems__27JWH {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    /* height: 100%; */
    /* padding-left: 24px; */
    color: rgb(0, 0, 0);
    line-height: 24px;
}

@media (min-width: 500px) {
    .NavigationItems_NavigationItems__27JWH {
        flex-flow: column;
    }
}

.NavigationItems_NavLink__o6pNi {
    /* margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%; */
    display: flex;
    line-height: 24px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Roboto';
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}
/* 
 a {

    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 1.3rem 1.8rem;
    border-left: 6px solid transparent;
}


 a.hover {
    color: #292B2C;
    border-left: 6px solid #FFCC00;
}

 a:hover {
    background-color: #e8eaed;
    color: #292B2C;
}


a.Selected {
    color: #292B2C;
    border-left: 6px solid #FFCC00;
    background-color: #e8eaed;
    color: #292B2C;
} */
.NavigationItem_NavigationItem__3JE6M {
    display: flex;
    line-height: 18px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Roboto';
    font-size: 1.4rem;
    font-weight: 400;
}

.NavigationItem_NavigationItem__3JE6M a {
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 1.3rem 1.8rem;
    border-left: 6px solid transparent;
    -webkit-font-smoothing: antialiased;
    font-size: 1.4rem;
    letter-spacing: 1.2px;
    color: #202124;
    text-shadow: none;
}


.NavigationItem_NavigationItem__3JE6M a.NavigationItem_hover__2iBw5 {
    color: #d7effb
}

.NavigationItem_NavigationItem__3JE6M a:hover {
    font-weight: 700;
    color: #292B2C;
    border: 0;
    border-left: 6px solid #4680A0;
    background-color: #FFF;
    border-color: #4680A0;
}


.NavigationItem_NavigationItem__3JE6M a.NavigationItem_Selected__3fKJ3 {

    font-weight: 700;
    color: #002060;
    border: 0;
    border-left: 6px solid #4680A0;
    background-color: #FFF;
    border-color: #4680A0;
}
.Logo_Logo__3rslu {
    background-color: transparent;
    padding: 0;
    height: 4.8rem;
    box-sizing: border-box;
}

.Logo_Logo__3rslu img {
    height: 4.8rem;
}
.Sidebar_flex-children___nSUc {
    display: flex;
}

.Sidebar_left-nav-container__sFbTL {
    position: absolute;
    height: 100%;
    z-index: 2;
}

.Sidebar_flex-columns__cHyLn>.Sidebar_flex-fixed__39qVw {
    flex: 0 0 auto;
}

.Sidebar_SidebarContainer__2weQ7 {
    /* height: 100vh; */
    display: flex;
    flex: 0 0 auto;
    background-color: #f9f9f9;
    /* border-right: 1px solid #f1f3f4; */
    border-right: 1px solid #ddd;
    
}

.Sidebar_SidebarView__22nhI {
    flex: auto;
    overflow-y: auto;
    overflow-x: hidden;
    width: 21.6rem;
    margin-top: 9.6rem;
}

.Sidebar_CompanyLogo__1lLiu {
    /* height: 7.2rem; */
    line-height: 4.8rem;
    color: #fff;
    padding: 1.2rem 0;
    padding-left: 1.6rem;
}

.Sidebar_Bold__NyYkz {
    font-weight: 800;
}

.Sidebar_left-nav-view__1Z-RK .Sidebar_primaryNavItem__2KS5A {
    font-family: "Avenir Next forINTUIT", "Arial", -apple-system, "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    width: 165px;
}
.Modal_close__2FGSi {
    float: right;
    font-size: 1.5rem;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.Modal_close__2FGSi {
    font-weight: 700;
    line-height: 1;
}

 .Modal_close__2FGSi:not(:disabled):not(.Modal_disabled__2YAfZ) {
    cursor: pointer;
}

.Modal_pg-close__5IzSp:before {
    content: "\E60A";
}

button.Modal_close__2FGSi {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}
.RegisterForm_Logo__3SePp {
    background-color: transparent;
    padding: 0;
    height: 4rem;
    box-sizing: border-box;
}

.RegisterForm_Logo__3SePp {
    display: inline-block;
    font-size: 24px;
    line-height: 24px;
    vertical-align: middle;
}

.RegisterForm_btn__1bZej {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.RegisterForm_btn__1bZej:focus,
.RegisterForm_btn__1bZej:hover,
.RegisterForm_btn__1bZej:active,
.RegisterForm_btn__1bZej:disabled {
    background-color: #6d5eac;
}
.CreditForm_FilterReportBtn__8McGn {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.CreditForm_btn__yhraa {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.CreditForm_btn__yhraa:focus,
.CreditForm_btn__yhraa:hover,
.CreditForm_btn__yhraa:active,
.CreditForm_btn__yhraa:disabled {
    background-color: #6d5eac;
}
.CreateOutlet_btn__2mJfG {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.CreateOutlet_btn__2mJfG:focus,
.CreateOutlet_btn__2mJfG:hover,
.CreateOutlet_btn__2mJfG:active,
.CreateOutlet_btn__2mJfG:disabled {
    background-color: #6d5eac;
}
.AddSelfService_btn__3IVO3 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.AddSelfService_btn__3IVO3:focus,
.AddSelfService_btn__3IVO3:hover,
.AddSelfService_btn__3IVO3:active,
.AddSelfService_btn__3IVO3:disabled {
    background-color: #6d5eac;
}
.MerchantForm_Wizard__13xSb ul.MerchantForm_Progress__TQFkD {
    position: relative;
    list-style-type: none;
    margin: 20px 0px 0px;
    padding: 0px;
}

.MerchantForm_cf__2Sxzh {
    zoom: 1;
}



.MerchantForm_Wizard__13xSb ul.MerchantForm_Progress__TQFkD li {
    /* margin: 0px 2% 0px 0px;
    float: left;
    text-align: center;
    width: 32%;
    position: relative;
    z-index: 3; */

    margin: 0px 2% 0px 0px;
    float: left;
    text-align: center;
    width: 31.25%;
    position: relative;
    z-index: 3;
}

.MerchantForm_Active__3nOfH {
    color: rgb(96, 96, 96);
    font-weight:500;
}



.MerchantForm_Step__rretJ {
    display: block;
    padding: 15px 12px 10px;
    font-size: 1.4rem;
    line-height: 23px;
    text-decoration: none;
    color: rgb(106, 106, 106);
    float: none;
}

.MerchantForm_Wizard__13xSb ul.MerchantForm_Progress__TQFkD span.MerchantForm_Number__1PxsD {
    display: block;
    margin: 0px auto 1rem;
    border-radius:  3.2rem;
    height:  3.2rem;
    width:  3.2rem;
    text-align: center;
    color: rgb(153, 153, 153);
    font-size: 18px;
    background: rgb(225, 225, 225);
    line-height:  3.2rem;
}

.MerchantForm_Wizard__13xSb ul.MerchantForm_Progress__TQFkD li.MerchantForm_Future__FtScM span.MerchantForm_Number__1PxsD {
    color: rgb(181, 181, 181);
}

.MerchantForm_Wizard__13xSb ul.MerchantForm_Progress__TQFkD span.MerchantForm_Number__1PxsD {
    background: rgb(243, 243, 243);
}

ul.MerchantForm_Progress__TQFkD li.MerchantForm_Active__3nOfH span.MerchantForm_Number__1PxsD {
    background: rgb(102, 51, 153);
    color: rgb(255, 255, 255);
}

em {
    font-style: normal;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: block;
    color: rgb(153, 153, 153);
}

.MerchantForm_Active__3nOfH em {
    color: rgb(102, 102, 102);
}

.MerchantForm_Wizard__13xSb ul.MerchantForm_Progress__TQFkD li.MerchantForm_Split__3k8BP {
    margin: 0px;
    float: left;
    width: 24%;
    position: absolute;
    top: 30px;
    height: 1px;
    background: rgb(225, 225, 225);
}

ul.MerchantForm_Progress__TQFkD li.MerchantForm_split__3jg0V {
    background: rgb(225, 225, 225);
}

.MerchantForm_Wizard__13xSb ul.MerchantForm_Progress__TQFkD li.MerchantForm_Split__3k8BP.MerchantForm_One__4SNEE {
    left: 20%;
}

.MerchantForm_Wizard__13xSb ul.MerchantForm_Progress__TQFkD li.MerchantForm_Split__3k8BP.MerchantForm_Two___J7BZ {
    left: 54%;
}

.MerchantForm_TabContent__1FoSV {
    overflow: hidden;
    padding: 0.1rem;
}
.ReviewMerchant_btn__1C5wm {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
    float: right !important;
}


.ReviewMerchant_btn__1C5wm:focus,
.ReviewMerchant_btn__1C5wm:hover,
.ReviewMerchant_btn__1C5wm:active,
.ReviewMerchant_btn__1C5wm:disabled {
    background-color: #6d5eac;
}

.ReviewMerchant_btnDefault__1l9Zw, .ReviewMerchant_btnDefault__1l9Zw:focus {
    color: #5e5e5e;
    background-color: #fff;
    border-color: #e7e7e7;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .01em;
    margin-bottom: 0;
    border: 1px solid #e7e7e7;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    text-shadow: none;
    box-shadow: none;
    line-height: 21px;
    position: relative;
    transition: color .1s linear 0s, background-color .1s linear 0s, opacity .2s linear 0s !important;
    padding: 6px 17px;
}
.EnableProvider_Container__1MpKc {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    border: 1px solid #f1f3f4;
    flex-direction: column;
    font-size: 1.4rem;
}

.EnableProvider_Dropzone__ZC7MM {
    border: 1px solid rgba(100, 100, 100, 1);
    border-radius: 1px;
    background: linear-gradient(0deg,
            rgb(231, 231, 231) 68%,
            rgb(231, 231, 231) 68%);
    background-blend-mode: normal;
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.09);
}

.EnableProvider_CenteredDiv__hjooS {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
}

.EnableProvider_Tools__22KTr {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.EnableProvider_PageFilter__1s9iM {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.EnableProvider_ToolsWrapper__2armf {
    height: 6rem;
    width: 100%;
}

.EnableProvider_PrintToolsWrapper__4Nqs8 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}



.EnableProvider_AddNewMerchantBtn__3DZac {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.EnableProvider_AddNewMerchantBtn__3DZac:focus,
.EnableProvider_AddNewMerchantBtn__3DZac:hover,
.EnableProvider_AddNewMerchantBtn__3DZac:active {
    background-color: rgb(51, 137, 255);
}

.EnableProvider_ToolsDataExportIconWrapper__dKNQ1 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.EnableProvider_ToolsPrintIconWrapper__2EkVt {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.EnableProvider_FilterInput__1zOHz {
    margin-right: 2rem;
}

.EnableProvider_FormGroupDefault__3ubw-.EnableProvider_has-error__i_VZp {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.EnableProvider_FormGroupDefault__3ubw- {

    line-height: 2rem;
    position: relative;
    transition: all 0.2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.EnableProvider_FormGroupDefault__3ubw- label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.EnableProvider_FormGroup__21H-- label:not(.EnableProvider_error__ruQY_) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.EnableProvider_FormControl__22Wok {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.EnableProvider_MerchantTableWrapper__g-UXW {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.EnableProvider_MerchantTable__B_uds {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.EnableProvider_MerchantTable__B_uds th {
    text-align: left;
}

.EnableProvider_MerchantTable__B_uds th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.EnableProvider_MerchantTable__B_uds td,
.EnableProvider_MerchantTable__B_uds th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.EnableProvider_MerchantTable__B_uds td:last-child {
    border-right: 1px solid #e8e8e8;
}

.EnableProvider_MerchantTable__B_uds td:first-child {
    border-left: 1px solid #e8e8e8;
}

.EnableProvider_RevealMoreHeader__107ln {
    padding: 0;
    vertical-align: middle;
}

.EnableProvider_Row__1wKgG {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.EnableProvider_Row__1wKgG>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.EnableProvider_dataTables_paginate__3xkPr {
    float: right;
}


div.EnableProvider_dataTables_paginate__3xkPr {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.EnableProvider_dataTables_paginate__3xkPr ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}


.EnableProvider_dataTables_paginate__3xkPr ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: 0.35;
    transition: opacity 0.3s ease;
}

.EnableProvider_dataTables_paginate__3xkPr ul>li.EnableProvider_disabled__2MRav a {
    opacity: 0.5;
}

.EnableProvider_dataTables_paginate__3xkPr ul>li.EnableProvider_disabled__2MRav a {
    opacity: 0.35;
}

.EnableProvider_dataTables_paginate__3xkPr ul>li.EnableProvider_active__yJxDP>a {
    font-weight: 700;
    color: #575757;
}

.EnableProvider_dataTables_paginate__3xkPr ul>li.EnableProvider_next__3zNZY>a,
.EnableProvider_dataTables_paginate__3xkPr ul>li.EnableProvider_prev__Mzwh0>a {
    opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.EnableProvider_pg_arrow_right__1rWwU {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.EnableProvider_pg_arrow_left__3UTaR {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.EnableProvider_pg_arrow_right__1rWwU:before {
    content: "\E631";
}

.EnableProvider_pg_arrow_left__3UTaR:before {
    content: "\E629";
}

.EnableProvider_btn__3amKH {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
        opacity 0.2s linear 0s !important;
}

.EnableProvider_btn_primary__2SSR2,
.EnableProvider_btn_primary__2SSR2:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.EnableProvider_btn_cons__GcB1W {
    min-width: 120px;
}


.EnableProvider_MerchantRow__2s13b {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.EnableProvider_MerchantRow__2s13b:hover {
    background-color: #267788;
    color: #fff;
}

.EnableProvider_MerchantRow__2s13b:hover a {
    color: #fff;
}

.EnableProvider_MerchantRowExpanded__3YtCC {
    background: #267788;
    color: #fff;
}

.EnableProvider_table__jDYye.EnableProvider_table-condensed__3bTZ8.EnableProvider_table-detailed__132tK>tbody>tr.EnableProvider_shown__wfL4G>td {
    background: #fdf5df;
}

.EnableProvider_SelectedDevice__29vM8 {
    background-color: #267788;
    color: #fff;
}

.EnableProvider_DeviceDetail__1-PFZ {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
  }
.Providers_Container__kZKoP {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Providers_Tools__2aT0B {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Providers_PageFilter__2jOmo {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Providers_ToolsWrapper__3YvCi {
    height: 6rem;
    width: 100%;

}

.Providers_PrintToolsWrapper__2c6uF {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Providers_AddNewMerchant__3m4HW {
    /* // font-family: Cambay; */
}

.Providers_AddNewMerchantBtn__MSkGK {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Providers_AddNewMerchantBtn__MSkGK:focus,
.Providers_AddNewMerchantBtn__MSkGK:hover,
.Providers_AddNewMerchantBtn__MSkGK:active {
    background-color: rgb(51, 137, 255);
}


.Providers_ToolsDataExportIconWrapper__2IpEu {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Providers_ToolsPrintIconWrapper__3nA4e {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Providers_FilterInput__3QPTb {
    margin-right: 2rem;
}


.Providers_FormGroupDefault__1fo5B.Providers_has-error__2ALX9 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Providers_FormGroupDefault__1fo5B {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Providers_FormGroupDefault__1fo5B label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Providers_FormGroup__2-Ni8 label:not(.Providers_error__30cpV) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Providers_FormControl__eF4Qo {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}




.Providers_MerchantTableWrapper__1DE-S {
    margin-top: 2.5rem;
    white-space: nowrap;

}

.Providers_TableWrapper__1JAwM {
    display: flex;
    /* background-color: #f5f5f5;
    border: 1px solid #e0e0e0; */
}


.Providers_MerchantTable__3ZyfT {
    flex: 1 1 70%;
    border-collapse: collapse;
    /* background-color: #f5f5f5; */
}

.Providers_MerchantTable__3ZyfT th {
    text-align: left;
}



.Providers_MerchantTable__3ZyfT th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Providers_MerchantTable__3ZyfT td,
.Providers_MerchantTable__3ZyfT th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Providers_MerchantTable__3ZyfT td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Providers_MerchantTable__3ZyfT td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Providers_RevealMoreHeader__3LkCg {
    padding: 0;
    vertical-align: middle;
}


.Providers_EnableUser__3GEj- {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Providers_EnableUserHeader__3EWNS {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Providers_EnableUserBody__QrAhy {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}



.Providers_Row__1cEh2 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Providers_Row__1cEh2>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Providers_dataTables_paginate__Zo_yT {
    float: right;
}

.Providers_dataTables_paginate__Zo_yT {
    /* margin-top: 25px !important; */
}

div.Providers_dataTables_paginate__Zo_yT {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Providers_dataTables_paginate__Zo_yT ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Providers_dataTables_paginate__Zo_yT ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Providers_dataTables_paginate__Zo_yT ul>li.Providers_disabled__1QDab a {
    opacity: .5;
}

.Providers_dataTables_paginate__Zo_yT ul>li.Providers_disabled__1QDab a {
    opacity: .35;
}

.Providers_dataTables_paginate__Zo_yT ul>li.Providers_active__3mODM>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Providers_dataTables_paginate__Zo_yT ul>li.Providers_next__2i3rx>a,
.Providers_dataTables_paginate__Zo_yT ul>li.Providers_prev__LeBBr>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Providers_pg_arrow_right__2TY6v {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Providers_pg_arrow_left__2YhR6 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Providers_pg_arrow_right__2TY6v:before {
    content: "\E631";
}

.Providers_pg_arrow_left__2YhR6:before {
    content: "\E629";
}

.Providers_btn__26oVE {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Providers_btn_primary__bUo5J,
.Providers_btn_primary__bUo5J:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Providers_btn_cons__3Nc1E {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Providers_MerchantRow__3xDTX {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Providers_MerchantRow__3xDTX:hover {
    background-color: #267788;
    color: #fff;

}

.Providers_MerchantRow__3xDTX:hover a {
    color: #fff;
}

.Providers_MerchantRowExpanded__1O855 {
    background: #267788;
    color: #fff;
}

.Providers_table__31qIJ.Providers_table-condensed__zzxX0.Providers_table-detailed__pZFDt>tbody>tr.Providers_shown__2FYBQ>td {
    background: #fdf5df;
}

.Providers_SelectedTransaction__2R0Vu {
    background-color: #267788;
    color: #fff;
}

.Providers_MerchantRowExpanded__1O855 {
    background: #267788;
    color: #fff;
}

.Providers_table__31qIJ.Providers_table-condensed__zzxX0.Providers_table-detailed__pZFDt>tbody>tr.Providers_shown__2FYBQ>td {
    background: #fdf5df;
}
.RegisterWUCUserForm_Logo__3DkLA {
    background-color: transparent;
    padding: 0;
    height: 4rem;
    box-sizing: border-box;
}

.RegisterWUCUserForm_Logo__3DkLA {
    display: inline-block;
    font-size: 24px;
    line-height: 24px;
    vertical-align: middle;
}

.RegisterWUCUserForm_btn__LSe6x {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.RegisterWUCUserForm_btn__LSe6x:focus,
.RegisterWUCUserForm_btn__LSe6x:hover,
.RegisterWUCUserForm_btn__LSe6x:active,
.RegisterWUCUserForm_btn__LSe6x:disabled {
    background-color: #6d5eac;
}
.RegisterSytemUserForm_btn__1TORF {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.RegisterSytemUserForm_btn__1TORF:focus,
.RegisterSytemUserForm_btn__1TORF:hover,
.RegisterSytemUserForm_btn__1TORF:active,
.RegisterSytemUserForm_btn__1TORF:disabled {
    background-color: #6d5eac;
}
.AdhocDevicePaymentForm_btn__2W1we {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.AdhocDevicePaymentForm_btn__2W1we:focus,
.AdhocDevicePaymentForm_btn__2W1we:hover,
.AdhocDevicePaymentForm_btn__2W1we:active,
.AdhocDevicePaymentForm_btn__2W1we:disabled {
    background-color: #6d5eac;
}
.ManualDevicePaymentForm_btn__FeESp {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.ManualDevicePaymentForm_btn__FeESp:focus,
.ManualDevicePaymentForm_btn__FeESp:hover,
.ManualDevicePaymentForm_btn__FeESp:active,
.ManualDevicePaymentForm_btn__FeESp:disabled {
    background-color: #6d5eac;
}
.AmendDeviceContractForm_btn__2TwNy {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.AmendDeviceContractForm_btn__2TwNy:focus,
.AmendDeviceContractForm_btn__2TwNy:hover,
.AmendDeviceContractForm_btn__2TwNy:active,
.AmendDeviceContractForm_btn__2TwNy:disabled {
    background-color: #6d5eac;
}
.CreateSelfServiceOutlet_btn__3a2T2 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.CreateSelfServiceOutlet_btn__3a2T2:focus,
.CreateSelfServiceOutlet_btn__3a2T2:hover,
.CreateSelfServiceOutlet_btn__3a2T2:active,
.CreateSelfServiceOutlet_btn__3a2T2:disabled {
    background-color: #6d5eac;
}
.RegisterComputer_btn__1Anz- {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.RegisterComputer_btn__1Anz-:focus,
.RegisterComputer_btn__1Anz-:hover,
.RegisterComputer_btn__1Anz-:active,
.RegisterComputer_btn__1Anz-:disabled {
    background-color: #6d5eac;
}
.RegisterDeviceWithOutlet_btn__1ZJMu {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.RegisterDeviceWithOutlet_btn__1ZJMu:focus,
.RegisterDeviceWithOutlet_btn__1ZJMu:hover,
.RegisterDeviceWithOutlet_btn__1ZJMu:active,
.RegisterDeviceWithOutlet_btn__1ZJMu:disabled {
    background-color: #6d5eac;
}
.BalanceForm_btn__Ityla {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.BalanceForm_btn__Ityla:focus,
.BalanceForm_btn__Ityla:hover,
.BalanceForm_btn__Ityla:active,
.BalanceForm_btn__Ityla:disabled {
    background-color: #6d5eac;
}
.Merchants_Container__c-E_a {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    /* height: 100%; */
}

.Merchants_BreadcrumbWrapper__1OmSV {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Merchants_Content__3EhI5 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Merchants_CardBody__3VSKS {
    margin-top: 1rem;
}

.Merchants_Alert__3DbGw {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Merchants_Breadcrumb__yLdL1 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Merchants_Header__3Ahl- {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Merchants_ByLine__VLUZo {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Merchants_Activity__-8u3I {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Merchants_WelcomeContainer__jSpNr {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Merchants_LoggedUser__1ejv3 {
    display: flex;
    background: transparent;
    flex: 0 0 40rem;
}

@media (max-width: 1366px) {
    .Merchants_LoggedUser__1ejv3 {
        flex: 0 0 30rem;
    }
}

.Merchants_ProfilePic__OFdZI {
    flex: 0 0 10.4rem;
}

.Merchants_SummaryContainer__3pCoU {
    display: flex;
    flex: 1 1 auto;
    margin-left: 2.4rem;
}

.Merchants_MembershipSummary__wDx0c {
    flex: 1 1 25.6rem;
}

.Merchants_SubscriptionSummary__7Ct9U {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.Merchants_ClaimSummary__3d9xE {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.Merchants_ProfileImage__3k1-l {
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}


.Merchants_MerchantProfile__1XYfT {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}


.Merchants_Row__21xWg {

    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Merchants_Row__21xWg>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Merchants_Tools__2YypT {
    display: flex;
    /* margin: 0 2.5rem;
    padding-top: 1.4rem; */
}

.Merchants_PageFilter__3lFz5 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}


.Merchants_PrintToolsWrapper__vha7U {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Merchants_AddNewMerchant__3RUWV {
    /* // font-family: Cambay; */
}

.Merchants_AddNewMerchantBtn__2decv {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Merchants_AddNewMerchantBtn__2decv:focus,
.Merchants_AddNewMerchantBtn__2decv:hover,
.Merchants_AddNewMerchantBtn__2decv:active {
    background-color: rgb(51, 137, 255);
}


.Merchants_ToolsDataExportIconWrapper__Diup8 {
    margin-left: 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Merchants_ToolsPrintIconWrapper__7tlxd {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Merchants_FilterInput__lqq4a {
    margin-right: 2rem;
}

.Merchants_FormGroup__12TXU {
    margin-bottom: 10px;
}

.Merchants_FormGroupDefault__9QUUH.Merchants_has-error__3VR9l {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Merchants_FormGroupDefault__9QUUH {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Merchants_FormGroupDefault__9QUUH label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Merchants_FormGroup__12TXU label:not(.Merchants_error__1F7yC) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Merchants_FormControl__1budE {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Merchants_MerchantTableWrapper__I1dSu {
    margin-top: 3rem;
    white-space: nowrap;
}

.Merchants_TableWrapper__1UsmI {
    margin: 0 2.5rem;
}

.Merchants_MerchantTable__2Qgny {
    width: 100%;
    border-collapse: collapse;
}

.Merchants_MerchantTable__2Qgny th {
    text-align: left;
}

.Merchants_MerchantTable__2Qgny th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Merchants_MerchantTable__2Qgny td,
.Merchants_MerchantTable__2Qgny th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Merchants_MerchantTable__2Qgny td:last-child {

    border-right: 1px solid #e8e8e8;
}

.Merchants_RevealMoreHeader__1lvj6 {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Merchants_dataTables_paginate__2CMuB {
    float: right;
}

.Merchants_dataTables_paginate__2CMuB {
    /* margin-top: 25px !important; */
}

div.Merchants_dataTables_paginate__2CMuB {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Merchants_dataTables_paginate__2CMuB ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}


.Merchants_dataTables_paginate__2CMuB ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Merchants_dataTables_paginate__2CMuB ul>li.Merchants_disabled__bRUVK a {
    opacity: .5;
}

.Merchants_dataTables_paginate__2CMuB ul>li.Merchants_disabled__bRUVK a {
    opacity: .35;
}

.Merchants_dataTables_paginate__2CMuB ul>li.Merchants_active__nXdLL>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Merchants_dataTables_paginate__2CMuB ul>li.Merchants_next__1zMRo>a,
.Merchants_dataTables_paginate__2CMuB ul>li.Merchants_prev__3bsBL>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Merchants_pg_arrow_right__2Q0q3 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Merchants_pg_arrow_left__2Usyh {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Merchants_pg_arrow_right__2Q0q3:before {
    content: "\E631";
}

.Merchants_pg_arrow_left__2Usyh:before {
    content: "\E629";
}

.Merchants_btn__317Bj {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Merchants_btn_primary__3V54a,
.Merchants_btn_primary__3V54a:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Merchants_btn_cons__3ew73 {
    min-width: 120px;
}



.Merchants_TabHeader__3KMf0 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Merchants_PageHeader__3MfOh {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Merchants_AddUser__36tfH {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Merchants_AddUserBtn__1s8sK {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.Merchants_ExportIconWrapper__pPYy8 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Merchants_EmailIconWrapper__2uYsH {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}
.MerchantList_Container__2LnoC {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    border: 1px solid rgba(221, 221, 221, .7);
    flex-direction: column;
    font-size: 1.4rem;
}

.MerchantList_Tools___s4pF {
    display: flex;
    /* margin: 0 2.5rem;
    padding-top: 1.4rem; */
}

.MerchantList_PageFilter__16zVV {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

/* .ToolsWrapper {
    height: 6rem;
    width: 100%;

} */

.MerchantList_PrintToolsWrapper__31quE {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.MerchantList_AddNewMerchant__13AJR {
    /* // font-family: Cambay; */
}

.MerchantList_AddNewMerchantBtn__3ldSM {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.MerchantList_AddNewMerchantBtn__3ldSM:focus,
.MerchantList_AddNewMerchantBtn__3ldSM:hover,
.MerchantList_AddNewMerchantBtn__3ldSM:active {
    background-color: rgb(51, 137, 255);
}


.MerchantList_ToolsDataExportIconWrapper__1DrDO {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.MerchantList_ToolsPrintIconWrapper__1AD1f {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.MerchantList_FilterInput__1CRXI {
    margin-right: 2rem;
}

.MerchantList_FormGroup__3e9gy {
    /* margin-bottom: 10px; */
}

.MerchantList_FormGroupDefault__31TRi.MerchantList_has-error__2Kyhr {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.MerchantList_FormGroupDefault__31TRi {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.MerchantList_FormGroupDefault__31TRi label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.MerchantList_FormGroup__3e9gy label:not(.MerchantList_error__V4PWi) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.MerchantList_FormControl__1Y_8v {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.MerchantList_MerchantTableWrapper__1AQyt {
    margin-top: 3rem;
    white-space: nowrap;
}

.MerchantList_TableWrapper__1wFOf {
    margin: 0 2.5rem;
}

.MerchantList_MerchantTable__She8b {
    width: 100%;
    border-collapse: collapse;
}

.MerchantList_MerchantTable__She8b th {
    text-align: left;
}

.MerchantList_MerchantTable__She8b th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.MerchantList_MerchantTable__She8b td,
.MerchantList_MerchantTable__She8b th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.MerchantList_MerchantTable__She8b td:last-child {

    border-right: 1px solid #e8e8e8;
}

.MerchantList_MerchantTable__She8b td:first-child {

    border-left: 1px solid #e8e8e8;
}

.MerchantList_RevealMoreHeader__3XR-Z {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.MerchantList_Row__1Jdmq {

    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.MerchantList_Row__1Jdmq>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.MerchantList_dataTables_paginate__3ugi3 {
    float: right;
}

.MerchantList_dataTables_paginate__3ugi3 {
    /* margin-top: 25px !important; */
}

div.MerchantList_dataTables_paginate__3ugi3 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.MerchantList_dataTables_paginate__3ugi3 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.MerchantList_dataTables_paginate__3ugi3 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.MerchantList_dataTables_paginate__3ugi3 ul>li.MerchantList_disabled__3J1v1 a {
    opacity: .5;
}

.MerchantList_dataTables_paginate__3ugi3 ul>li.MerchantList_disabled__3J1v1 a {
    opacity: .35;
}

.MerchantList_dataTables_paginate__3ugi3 ul>li.MerchantList_active__19L83>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.MerchantList_dataTables_paginate__3ugi3 ul>li.MerchantList_next__3wufB>a,
.MerchantList_dataTables_paginate__3ugi3 ul>li.MerchantList_prev__29sCK>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MerchantList_pg_arrow_right__35kUa {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MerchantList_pg_arrow_left__mjdUN {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MerchantList_pg_arrow_right__35kUa:before {
    content: "\E631";
}

.MerchantList_pg_arrow_left__mjdUN:before {
    content: "\E629";
}

.MerchantList_btn__sf47K {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.MerchantList_btn_primary__FNH5-,
.MerchantList_btn_primary__FNH5-:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.MerchantList_btn_cons__w27Rj {
    min-width: 120px;
}



.MerchantList_TabHeader__ZDvFw {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.MerchantList_PageHeader__1muzz {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.MerchantList_AddUser__1Vf3G {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.MerchantList_AddUserBtn__3sjQs {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}
/* .Odd {
}

.Even {
} */

.MerchantListItem_MemberName__2MM-Q {
    font-weight: 500;
}

.MerchantListItem_Icn__18XJa {
    cursor: pointer;
    /* fill:#000; */
}

.MerchantListItem_Icn__18XJa:hover {
    cursor: pointer;

}



.MerchantListItem_RevealMore__2KvVV {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.MerchantListItem_RevealMore__2KvVV:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.MerchantListItem_RevealMore__2KvVV div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.MerchantListItem_RevealMoreExpanded__2XBUM {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .MerchantListItem_Expandabled__rxFwk {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.MerchantListItem_Expandabled__rxFwk td {
    padding: 2rem 2rem 2rem 5.6rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .MerchantListItem_MemberRow__3dqaZ {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.MerchantListItem_MemberRow__3dqaZ:hover {
    background-color: #267788;
    color:#fff;

    }

    .MerchantListItem_MemberRow__3dqaZ:hover a {
        color:#fff;
        }

    .MerchantListItem_MemberRowExpanded__3hejK {
        background: #267788;
        color:#fff;
    }

.MerchantListItem_table__1DZ4h.MerchantListItem_table-condensed__1REUZ.MerchantListItem_table-detailed__2g_sU > tbody > tr.MerchantListItem_shown__Ks3V- > td {
    background: #fdf5df;
}

.dashboard_Container__1jDdt {
  /* background-color: #fff; */
  flex-direction: column;
  height: 100%;
  font-size: 1.4rem;
}

.dashboard_BreadcrumbWrapper__28RTx {
  display: flex;
  background-color: transparent;
  width: 100%;
  padding-left: 2.4rem;
  flex: 0 0 7.2rem;
  height: 7.2rem;
}

.dashboard_Content__31_Hz {
  flex: 1 1 auto;
  display: flex;
  margin: 2.4rem;
  flex-direction: column;
}

.dashboard_CardBody__RKEqy {
  margin-top: 1rem;
}

.dashboard_Alert__1loVi {
  padding: 18px;
  margin-bottom: 15px;
  border: 0;
  border-radius: 0;
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.dashboard_Breadcrumb__3quYa {
  padding: 1.8rem 0;
  flex: 0 0 50%;
}

.dashboard_Header__3mK2V {
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 1.5px;
  /* text-transform: uppercase; */
}

.dashboard_ByLine__yx6xX {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.dashboard_Activity__2X6Bb {
  background: #fff;
  flex: 0 0 36rem;
  /* width: 36rem; */
}

.dashboard_WelcomeContainer__10E_2 {
  display: flex;
  /* margin: 0 2.4rem 0 0; */
  /* height: 17.6rem; */
}

.dashboard_MerchantProfile__TIr1w {
  display: flex;
  background: transparent;
  flex: 0 0 100%;
}

.dashboard_TabHeader__BbiGm {
  /* padding: 0px 2rem; */
  margin-bottom: 1rem;
  display: flex;
}

.dashboard_PageHeader__2cGut {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  width: auto;
}

.dashboard_Header__3mK2V {
  flex: 1 1 50%;
  justify-content: flex-start;
  width: auto;
}

.dashboard_ExportReport__2-aHr {
  flex: 1 1 50%;
  width: auto;
  margin-right: 1.4rem;
  align-items: center;
  display: flex;

  line-height: normal;
  flex: 1 1 auto;
  justify-content: flex-end;
}

.dashboard_ExportIconWrapper__1BUgC {
  margin: 0 1.6rem;
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
  flex: 0 0 auto;
}

.dashboard_EmailIconWrapper__29_Qu {
  margin: 0 1.6rem;
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
  flex: 0 0 auto;
}

.dashboard_AddUser__1te0n {
  display: flex;
  margin-right: 20px;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.dashboard_AddUserBtn__MNRKs {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

.dashboard_Tools__18d8L {
  /* display: flex; */
  margin: 1rem 0 3.5rem;
  /* padding-top: 1.4rem; */
}

.dashboard_PageFilter__1TQdJ {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-start;
  width: auto;
  /* margin-left: 15px; */
}

.dashboard_PrintToolsWrapper__9bhwn {
  display: flex;
  flex: 1 1 20%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
}

.dashboard_FilterReportBtn__33iJF {
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  height: 3.4rem;
  padding: 8px;
  text-decoration: none;
  border: none;
  display: block;
}

.dashboard_AddNewMerchantBtn__3To6A:focus,
.dashboard_AddNewMerchantBtn__3To6A:hover,
.dashboard_AddNewMerchantBtn__3To6A:active {
  background-color: rgb(51, 137, 255);
}

.dashboard_ToolsDataExportIconWrapper__1ubvn {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.dashboard_ToolsPrintIconWrapper__2ao2V {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.dashboard_FilterInput__3yd3f {
  margin-right: 2rem;
}

.dashboard_FormGroupDefault__2vKo9.dashboard_has-error__14GBq {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.dashboard_FormGroupDefault__2vKo9 {
  line-height: 2rem;
  position: relative;
  transition: all 0.2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.dashboard_FormGroupDefault__2vKo9 label {
  margin: 0;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.dashboard_FormGroup__2D6oL label:not(.dashboard_error__19FHu) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.dashboard_FormControl__394hN {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.dashboard_MerchantTableWrapper__1j-Vx {
  margin-top: 3rem;
  white-space: nowrap;
}

.dashboard_MerchantTable__1vutE {
  width: 100%;
  border-collapse: collapse;
}

.dashboard_MerchantTable__1vutE th {
  text-align: right;
}

.dashboard_MerchantTable__1vutE th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.dashboard_MerchantTable__1vutE td,
.dashboard_MerchantTable__1vutE th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.dashboard_RevealMoreHeader__1QSuP {
  padding: 0;
  vertical-align: middle;
}

.dashboard_Row__SjBIy {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem;
}

.dashboard_Row__SjBIy > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.dashboard_dataTables_paginate__34D3A {
  float: right;
}

div.dashboard_dataTables_paginate__34D3A {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.dashboard_dataTables_paginate__34D3A ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

.dashboard_dataTables_paginate__34D3A ul > li > a {
  padding: 5px 10px;
  color: #575757;
  opacity: 0.35;
  transition: opacity 0.3s ease;
}

.dashboard_dataTables_paginate__34D3A ul > li.dashboard_disabled__j7S4T a {
  opacity: 0.5;
}

.dashboard_dataTables_paginate__34D3A ul > li.dashboard_disabled__j7S4T a {
  opacity: 0.35;
}

.dashboard_dataTables_paginate__34D3A ul > li.dashboard_active__3pFUB > a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.dashboard_dataTables_paginate__34D3A ul > li.dashboard_next__2V0ul > a,
.dashboard_dataTables_paginate__34D3A ul > li.dashboard_prev__EU5kw > a {
  opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboard_pg_arrow_right__1Lcy6 {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboard_pg_arrow_left__gX_G1 {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboard_pg_arrow_right__1Lcy6:before {
  content: "\E631";
}

.dashboard_pg_arrow_left__gX_G1:before {
  content: "\E629";
}

.dashboard_btn__gDWe4 {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
}

.dashboard_btn_primary__15lCg,
.dashboard_btn_primary__15lCg:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.dashboard_btn_cons__MdmpP {
  min-width: 120px;
}

.dashboard_MerchantName__1wJ3j {
  font-weight: 500;
}

.dashboard_Icn__2OKPQ {
  cursor: pointer;
  /* fill:#000; */
}

.dashboard_Icn__2OKPQ:hover {
  cursor: pointer;
}

.dashboard_RevealMore__wD9J_ {
  background: #e1e1e1;
  cursor: pointer;
  width: 2.4rem;
}

.dashboard_RevealMore__wD9J_:hover {
  background: transparent;
  color: #707070;
  fill: #fff;
}

.dashboard_RevealMore__wD9J_ div {
  margin: 0 auto;
  width: 2.4rem;
  height: 2.4rem;
}

.dashboard_RevealMoreExpanded__27BUS {
  background: transparent;
  fill: #fff;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.dashboard_Expandabled__3l2Hx {
  text-align: left;
  background: #d7effb;
  border-bottom: 1px solid rgb(56, 0, 60);
  display: table-row;
  z-index: 500;
}

.dashboard_Expandabled__3l2Hx td {
  padding: 2rem 2rem 2rem 5.6rem;
  border-bottom: 1px solid #e8e8e8;
}

.dashboard_MerchantTable__1vutE td:last-child {
  border-right: 1px solid #e8e8e8;
}

.dashboard_MerchantTable__1vutE td:first-child {
  border-left: 1px solid #e8e8e8;
}

.dashboard_MerchantRow__1GOyn {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.dashboard_MerchantRow__1GOyn:hover {
  background-color: #267788;
  color: #fff;
}

.dashboard_MerchantRow__1GOyn:hover a {
  color: #fff;
}

.dashboard_MerchantRowExpanded__3CbQG {
  background: #267788;
  color: #fff;
}

.dashboard_table__2gXw_.dashboard_table-condensed__3SUo5.dashboard_table-detailed__1VI2L > tbody > tr.dashboard_shown__1lGyG > td {
  background: #fdf5df;
}

.dashboard_Totals__8UZU- td {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid #e8e8e8;
  font-weight: 700;
}

.dashboard_MoneyTotalLabel__JLoCV {
  float: right;
}

.dashboard_FilterWrapper__3ZuH4 {
  display: flex;
  justify-content: flex-start;
  /* border-right: solid #707070; */
}

.dashboard_FilterPeriods__3EjJv {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row;
  /* height: 100%; */
  padding-left: 2rem;
  color: rgb(0, 0, 0);
  justify-content: flex-start;
  /* margin-right: 3.2rem; */
}

.dashboard_FilterPeriods__3EjJv li {
  font-weight: 600;
  padding-right: 2rem;
  line-height: 38px;
}

.dashboard_FinancialsIntro__MJJab {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.MerchantList_Container__5x5xp {
  background-color: #fff;
  margin: 2.4rem;
  margin-bottom: 2.4rem;
  border: 1px solid rgba(221, 221, 221, 0.7);
  flex-direction: column;
  font-size: 1.4rem;
}

.MerchantList_Tools__Ak_hn {
  display: flex;
  /* margin: 0 2.5rem;
    padding-top: 1.4rem; */
}

.MerchantList_PageFilter__3IM_w {
  display: flex;
  flex: 1 1 50%;
  justify-content: flex-start;
  width: auto;
}

/* .ToolsWrapper {
    height: 6rem;
    width: 100%;

} */

.MerchantList_PrintToolsWrapper__1sfyH {
  display: flex;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.MerchantList_AddNewMerchant__3u6BN {
  /* // font-family: Cambay; */
}

.MerchantList_AddNewMerchantBtn__3BR4A {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  vertical-align: middle;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.MerchantList_AddNewMerchantBtn__3BR4A:focus,
.MerchantList_AddNewMerchantBtn__3BR4A:hover,
.MerchantList_AddNewMerchantBtn__3BR4A:active {
  background-color: rgb(51, 137, 255);
}

.MerchantList_ToolsDataExportIconWrapper__siOIv {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.MerchantList_ToolsPrintIconWrapper__2UVeO {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.MerchantList_FilterInput__1iTTg {
  margin-right: 2rem;
}

.MerchantList_FormGroup__13sDv {
  /* margin-bottom: 10px; */
}

.MerchantList_FormGroupDefault__mB3Ai.MerchantList_has-error__1y0j1 {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.MerchantList_FormGroupDefault__mB3Ai {
  /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
  /* padding: 1.4rem 2.5rem .2em .5rem; */
  line-height: 2rem;
  position: relative;
  transition: all 0.2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.MerchantList_FormGroupDefault__mB3Ai label {
  margin: 0;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.MerchantList_FormGroup__13sDv label:not(.MerchantList_error__3GPu9) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.MerchantList_FormControl__-YNpc {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.MerchantList_MerchantTableWrapper__31z9u {
  margin-top: 3rem;
  white-space: nowrap;
}

.MerchantList_TableWrapper__1UVpy {
  margin: 0 2.5rem;
}

.MerchantList_MerchantTable__1leU1 {
  width: 100%;
  border-collapse: collapse;
}

.MerchantList_MerchantTable__1leU1 th {
  text-align: right;
}

.MerchantList_MerchantTable__1leU1 th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.MerchantList_MerchantTable__1leU1 td,
.MerchantList_MerchantTable__1leU1 th {
  padding: 1rem 2rem 1rem 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.MerchantList_MerchantTable__1leU1 td:last-child {
  border-right: 1px solid #e8e8e8;
}

.MerchantList_MerchantTable__1leU1 td:first-child {
  border-left: 1px solid #e8e8e8;
}

.MerchantList_RevealMoreHeader__3NbI8 {
  padding: 0;
  vertical-align: middle;
}

/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */

.MerchantList_Row__UeY7x {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem 0;
}

.MerchantList_Row__UeY7x > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.MerchantList_dataTables_paginate__281X7 {
  float: right;
}

.MerchantList_dataTables_paginate__281X7 {
  /* margin-top: 25px !important; */
}

div.MerchantList_dataTables_paginate__281X7 {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.MerchantList_dataTables_paginate__281X7 ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.MerchantList_dataTables_paginate__281X7 ul > li > a {
  padding: 5px 10px;
  color: #575757;
  opacity: 0.35;
  transition: opacity 0.3s ease;
}

.MerchantList_dataTables_paginate__281X7 ul > li.MerchantList_disabled___MyWW a {
  opacity: 0.5;
}

.MerchantList_dataTables_paginate__281X7 ul > li.MerchantList_disabled___MyWW a {
  opacity: 0.35;
}

.MerchantList_dataTables_paginate__281X7 ul > li.MerchantList_active__385ra > a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.MerchantList_dataTables_paginate__281X7 ul > li.MerchantList_next__3Q1wR > a,
.MerchantList_dataTables_paginate__281X7 ul > li.MerchantList_prev__Ymx9G > a {
  opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MerchantList_pg_arrow_right__2faR1 {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MerchantList_pg_arrow_left__yVbO7 {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MerchantList_pg_arrow_right__2faR1:before {
  content: "\E631";
}

.MerchantList_pg_arrow_left__yVbO7:before {
  content: "\E629";
}

.MerchantList_btn__3ZE6N {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
}

.MerchantList_btn_primary__2_MVh,
.MerchantList_btn_primary__2_MVh:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.MerchantList_btn_cons__3CNUl {
  min-width: 120px;
}

.MerchantList_TabHeader__2sg6m {
  /* padding: 0px 2rem; */
  margin-bottom: 1rem;
  display: flex;
}

.MerchantList_PageHeader__3Y20E {
  flex: 1 1 50%;
  justify-content: flex-start;
  width: auto;
}

.MerchantList_AddUser__2ETKt {
  display: flex;
  margin-right: 20px;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.MerchantList_AddUserBtn__29FoH {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  vertical-align: middle;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

/* .Odd {
}

.Even {
} */

.MerchantListItem_MemberName__268Fb {
    font-weight: 500;
}

.MerchantListItem_Icn__2FSVO {
    cursor: pointer;
    /* fill:#000; */
}

.MerchantListItem_Icn__2FSVO:hover {
    cursor: pointer;

}

.MerchantListItem_RevealMore__3jz_7 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.MerchantListItem_RevealMore__3jz_7:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.MerchantListItem_RevealMore__3jz_7 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.MerchantListItem_RevealMoreExpanded__2lSLp {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .MerchantListItem_Expandabled__2q-MZ {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.MerchantListItem_Expandabled__2q-MZ td {
    padding: 2rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .MerchantListItem_MemberRow__8QFBs {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.MerchantListItem_MemberRow__8QFBs:hover {
    background-color: #267788;
    color:#fff;

    }

    .MerchantListItem_MemberRow__8QFBs:hover a {
        color:#fff;
        }

    .MerchantListItem_MemberRowExpanded__3S0Sj {
        background: #267788;
        color:#fff;
    }

.MerchantListItem_table__31Ty2.MerchantListItem_table-condensed__1uiYZ.MerchantListItem_table-detailed__1fH24 > tbody > tr.MerchantListItem_shown__208tW > td {
    background: #fdf5df;
}

.Transactions_ExpandableView__1DN8X {
    background-color: transparent;
    /* font-family: Cambay; */
}

.Transactions_Outlets__Sb0XF {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    /* margin-left: 0.6rem; */
}

.Transactions_Outlets__Sb0XF li {
    display: inline-block;
        list-style: none;
}

.Transactions_Outlet__3rPoV {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;}

.Transactions_Outlet__3rPoV:hover {
color: #FFF;
    background: #5BA6E6;

}

.Transactions_OutletName__2Kfwf {
    font-weight: 600;
}

.Transactions_OutletLocation__1lzh6 {
    font-weight: 400;

}

.Transactions_ExpandableMember__366nz {
    float: left;
    text-decoration: none;
}

.Transactions_ExpandableMemberName__Hm5qY {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.Transactions_OutletListArea__1xlS6 {
    width: 100%;
    margin-top: 1rem;
    background: transparent;
}



.Transactions_MemberContractDetailArea__2r-FQ {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem 0px;
    background: rgb(255, 255, 255);
}

.Transactions_MemberContractDetailArea__2r-FQ header {
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.Transactions_ExpandableMemberWrapper__ucTdD {
    background: transparent;
}

.Transactions_clearfix__3clcC::before,
.Transactions_clearfix__3clcC::after {
    display:table;
    content: " ";
}

.Transactions_clearfix__3clcC::after {
clear:both;
}


.Transactions_FuneralSchemeWrapper__1EJyn {
    margin: 0 2.5rem;
}

.Transactions_AddNewMember__218oa {
float:right;
margin-right: 8.8rem;
}

.Transactions_AddNewMemberBtn__1DpKt {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.Transactions_FuneralSchemeTable__3wLWv  {
    width: 100%;
    border-collapse: collapse;
}

.Transactions_FuneralSchemeTable__3wLWv th {
    text-align: left;
}

.Transactions_FuneralSchemeTable__3wLWv th {
    color: #666;
    vertical-align: top;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    vertical-align: middle;
}

.Transactions_FuneralSchemeTable__3wLWv td,
.Transactions_FuneralSchemeTable__3wLWv th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Transactions_FuneralSchemeTable__3wLWv .Transactions_Totals__23cgU td {   
     border-bottom: none;
     font-weight: 600;
}

.Transactions_FuneralSchemeTable__3wLWv .Transactions_MoneyTotalLabel__eEM0u {
    float: right;

}
.Transactions_FuneralSchemeTable__3wLWv .Transactions_MoneyTotal__2n-uc {

}
.Merchant_Container__2cZ-R {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Merchant_BreadcrumbWrapper__3riKC {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Merchant_Content__1Onf3 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Merchant_Alert__DZKNm {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Merchant_Breadcrumb__1Yemo {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Merchant_Header__33hYB {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Merchant_ByLine__3h_Zs {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Merchant_Activity__2luWf {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Merchant_WelcomeContainer__2Xjui {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Merchant_MerchantProfile__3BezI {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

@media (max-width: 1366px) {
    .Merchant_LoggedUser__1So4x {
        flex: 0 0 30rem;
    }
}

.Merchant_ProfilePic__Dhc3J {
    flex: 0 0 10.4rem;
}

.Merchant_SummaryContainer__3Nccx {
    display: flex;
    flex: 1 1 auto;
    margin-left: 2.4rem;
}

.Merchant_MembershipSummary__2qxh3 {
    flex: 1 1 25.6rem;
}

.Merchant_SubscriptionSummary__3-l3z {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.Merchant_ClaimSummary__iBPh3 {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.Merchant_ProfileImage__3ILsq {
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}
.Edit_btn__VLua2 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.Edit_btn__VLua2:focus,
.Edit_btn__VLua2:hover,
.Edit_btn__VLua2:active,
.Edit_btn__VLua2:disabled {
    background-color: #6d5eac;
}


.details_MemberName__WgkGs {
    font-weight: 500;
}

.details_Icn__qCgUU {
    cursor: pointer;
}

.details_Icn__qCgUU:hover {
    cursor: pointer;

}

.details_RevealMore__BFG4K {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__BFG4K:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.details_RevealMore__BFG4K div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__ReNYw {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .details_Expandabled__13tUa {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__13tUa td {
    padding: 2rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .details_MemberRow__URfBg {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MemberRow__URfBg:hover {
    background-color: #267788;
    color:#fff;

    }

    .details_MemberRow__URfBg:hover a {
        color:#fff;
        }

    .details_MemberRowExpanded__bdLHx {
        background: #267788;
        color:#fff;
    }

.details_table__1B2do.details_table-condensed__zsBUq.details_table-detailed__ISQ-0 > tbody > tr.details_shown__Gwnbu > td {
    background: #fdf5df;
}

.details_Container__1Zwmo {
  /* background-color: #fff; */
  flex-direction: column;
  height: 100%;
  font-size: 1.4rem;
}

.details_BreadcrumbWrapper__3Hr6_ {
  display: flex;
  background-color: transparent;
  width: 100%;
  padding-left: 2.4rem;
  flex: 0 0 7.2rem;
  height: 7.2rem;
}

.details_Content__2Q_Ac {
  flex: 1 1 auto;
  display: flex;
  margin: 2.4rem;
  flex-direction: column;
}

.details_CardBody__1kPTC {
  margin-top: 1rem;
}

.details_Alert__yHbW4 {
  padding: 18px;
  margin-bottom: 15px;
  border: 0;
  border-radius: 0;
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.details_Breadcrumb__Bh6FH {
  padding: 1.8rem 0;
  flex: 0 0 50%;
}

.details_Header__1m9Lu {
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 1.5px;
  /* text-transform: uppercase; */
}

.details_ByLine__2MhD_ {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.details_Activity__2fNH6 {
  background: #fff;
  flex: 0 0 36rem;
  /* width: 36rem; */
}

.details_WelcomeContainer__qxpwP {
  display: flex;
  /* margin: 0 2.4rem 0 0; */
  /* height: 17.6rem; */
}

.details_MerchantProfile__3yyBw {
  display: flex;
  background: transparent;
  flex: 0 0 100%;
}

.details_TabHeader__I5o8D {
  /* padding: 0px 2rem; */
  margin-bottom: 1rem;
  display: flex;
}

.details_PageHeader__1SxNj {
  flex: 1 1 auto;
  justify-content: flex-start;
  width: auto;
}

.details_Header__1m9Lu {
  flex: 1 1 50%;
  justify-content: flex-start;
  width: auto;
}

.details_ExportReport__1YR9r {
  flex: 1 1 50%;
  width: auto;
  margin-right: 1.4rem;
  align-items: center;
  display: flex;

  line-height: normal;
  flex: 1 1 auto;
  justify-content: flex-end;
}

.details_ExportIconWrapper__2sMzL {
  margin: 0 1.6rem;
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
  flex: 0 0 auto;
}

.details_EmailIconWrapper__3veNd {
  margin: 0 1.6rem;
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
  flex: 0 0 auto;
}

.details_AddUser__3i4DH {
  display: flex;
  margin-right: 20px;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.details_AddUserBtn__3FdOz {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

.details_Tools__3YDdN {
  /* display: flex; */
  margin: 1rem 0 3.5rem;
  /* padding-top: 1.4rem; */
}

.details_PageFilter__3axNZ {
  /* display: flex; */
  flex: 1 1 auto;
  justify-content: flex-start;
  width: auto;
  margin-left: 15px;
}

.details_PrintToolsWrapper__3ZCWd {
  display: flex;
  flex: 1 1 20%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
}

.details_FilterReportBtn__16GTV {
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  height: 3.4rem;
  padding: 8px;
  text-decoration: none;
  border: none;
  display: block;
}

.details_AddNewMerchantBtn__1U-jN:focus,
.details_AddNewMerchantBtn__1U-jN:hover,
.details_AddNewMerchantBtn__1U-jN:active {
  background-color: rgb(51, 137, 255);
}

.details_ToolsDataExportIconWrapper__31RxE {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.details_ToolsPrintIconWrapper__1fAnd {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.details_FilterInput__2xm2y {
  margin-right: 2rem;
}

.details_FormGroupDefault__16jgQ.details_has-error__hS7-H {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__16jgQ {
  line-height: 2rem;
  position: relative;
  transition: all 0.2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.details_FormGroupDefault__16jgQ label {
  margin: 0;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.details_FormGroup__2kn7r label:not(.details_error__3_7Ve) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.details_FormControl__3Vo_T {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.details_MerchantTableWrapper__3_RFF {
  margin-top: 3rem;
  white-space: nowrap;
}

.details_MerchantTable__1v-Z1 {
  width: 100%;
  border-collapse: collapse;
}

/* .MerchantTable th {
    text-align: left;
} */

.details_MerchantTable__1v-Z1 th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.details_MerchantTable__1v-Z1 td,
.details_MerchantTable__1v-Z1 th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__3g-Kn {
  padding: 0;
  vertical-align: middle;
}

.details_Row__yzEiO {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem;
}

.details_Row__yzEiO > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.details_dataTables_paginate__24Wy9 {
  float: right;
}

div.details_dataTables_paginate__24Wy9 {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.details_dataTables_paginate__24Wy9 ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

.details_dataTables_paginate__24Wy9 ul > li > a {
  padding: 5px 10px;
  color: #575757;
  opacity: 0.35;
  transition: opacity 0.3s ease;
}

.details_dataTables_paginate__24Wy9 ul > li.details_disabled___64wk a {
  opacity: 0.5;
}

.details_dataTables_paginate__24Wy9 ul > li.details_disabled___64wk a {
  opacity: 0.35;
}

.details_dataTables_paginate__24Wy9 ul > li.details_active__35jyR > a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.details_dataTables_paginate__24Wy9 ul > li.details_next__i8P9Q > a,
.details_dataTables_paginate__24Wy9 ul > li.details_prev__2ha06 > a {
  opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__2xGky {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__3OFkr {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__2xGky:before {
  content: "\E631";
}

.details_pg_arrow_left__3OFkr:before {
  content: "\E629";
}

.details_btn__2ByDQ {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
}

.details_btn_primary__3MFRb,
.details_btn_primary__3MFRb:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.details_btn_cons__2mjBp {
  min-width: 120px;
}

.details_MerchantName__1KUuX {
  font-weight: 500;
}

.details_Icn__3hv1R {
  cursor: pointer;
  /* fill:#000; */
}

.details_Icn__3hv1R:hover {
  cursor: pointer;
}

.details_RevealMore__2SQ-5 {
  background: #e1e1e1;
  cursor: pointer;
  width: 2.4rem;
}

.details_RevealMore__2SQ-5:hover {
  background: transparent;
  color: #707070;
  fill: #fff;
}

.details_RevealMore__2SQ-5 div {
  margin: 0 auto;
  width: 2.4rem;
  height: 2.4rem;
}

.details_RevealMoreExpanded__1qinC {
  background: transparent;
  fill: #fff;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.details_Expandabled__1vIIY {
  text-align: left;
  background: #d7effb;
  border-bottom: 1px solid rgb(56, 0, 60);
  display: table-row;
  z-index: 500;
}

.details_Expandabled__1vIIY td {
  padding: 2rem 2rem 2rem 5.6rem;
  border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__1v-Z1 td:last-child {
  border-right: 1px solid #e8e8e8;
}

.details_MerchantTable__1v-Z1 td:first-child {
  border-left: 1px solid #e8e8e8;
}

.details_MerchantRow__tnozL {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.details_MerchantRow__tnozL:hover {
  background-color: #267788;
  color: #fff;
}

.details_MerchantRow__tnozL:hover a {
  color: #fff;
}

.details_MerchantRowExpanded__3LJns {
  background: #267788;
  color: #fff;
}

.details_table__3-tqM.details_table-condensed__35Oo6.details_table-detailed__3ZfTB > tbody > tr.details_shown__3wS1W > td {
  background: #fdf5df;
}

.details_Totals__15_uG td {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid #e8e8e8;
  font-weight: 700;
}

.details_MoneyTotalLabel__26ZVr {
  float: right;
}

.details_FilterWrapper__2fD-6 {
  display: flex;
  justify-content: flex-end;
  border-right: solid #707070;
}

.details_FilterPeriods__2RbUr {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row;
  /* height: 100%; */
  padding-left: 24px;
  color: rgb(0, 0, 0);
  justify-content: flex-end;
  margin-right: 3.2rem;
}

.details_FilterPeriods__2RbUr li {
  font-weight: 600;

  padding-left: 2rem;
  line-height: 38px;
}

.details_FinancialsIntro__nassL {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.details_ExpandableView__1EMSN {
  background-color: transparent;
  /* font-family: Cambay; */
}

.details_Outlets__323wG {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* margin-left: 0.6rem; */
}

.details_Outlets__323wG li {
  display: inline-block;
  list-style: none;
}

.details_Outlet__3fDz6 {
  display: inline-block;
  width: 15rem;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  background: #fff;
  margin-right: 1rem;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

.details_Outlet__3fDz6:hover {
  color: #fff;
  background: #5ba6e6;
}

.details_OutletName__IQI28 {
  font-weight: 600;
}

.details_OutletLocation__bC8Ka {
  font-weight: 400;
}

.details_ExpandableMember__2Gz0q {
  float: left;
  text-decoration: none;
}

.details_ExpandableMemberName__3E0dk {
  font-weight: 600;
  font-size: 2.2rem;
  display: inline-block;
  vertical-align: middle;
}

.details_OutletListArea__1UI-G {
  width: 100%;
  margin-top: 1rem;
  background: transparent;
}

.details_MemberContractDetailArea__1nvxo {
  width: 100%;
  margin-top: 1rem;
  padding: 2rem 0px;
  background: rgb(255, 255, 255);
}

.details_MemberContractDetailArea__1nvxo header {
  padding: 0px 2rem;
  margin-bottom: 1rem;
}

.details_ExpandableMemberWrapper__3sCci {
  background: transparent;
}

.details_clearfix__qghjB::before,
.details_clearfix__qghjB::after {
  display: table;
  content: " ";
}

.details_clearfix__qghjB::after {
  clear: both;
}

.details_FuneralSchemeWrapper__3mi-s {
  margin: 0 2.5rem;
}

.details_AddNewMember__2yXyO {
  float: right;
  margin-right: 8.8rem;
}

.details_AddNewMemberBtn__2ZkzG {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  vertical-align: middle;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

.details_AmendContactBtn__Jrm2m {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  cursor: pointer;
  color: #2c2c2c;
  background-color: transparent;
  padding: 0.75rem;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
  border: 1px solid #e5e5e5;
  /* display: block; */
}

.details_FuneralSchemeTable__7gm5j {
  width: 100%;
  border-collapse: collapse;
}

.details_FuneralSchemeTable__7gm5j th {
  text-align: left;
}

.details_FuneralSchemeTable__7gm5j th {
  color: #666;
  vertical-align: top;
  font-size: 1.2rem;
  font-family: Raleway;
  font-weight: 600;
  vertical-align: middle;
}

.details_FuneralSchemeTable__7gm5j td,
.details_FuneralSchemeTable__7gm5j th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.details_FuneralSchemeTable__7gm5j .details_Totals__15_uG td {
  border-bottom: none;
  font-weight: 600;
}

.details_FuneralSchemeTable__7gm5j .details_MoneyTotalLabel__26ZVr {
  float: right;
}
.details_FuneralSchemeTable__7gm5j .details_MoneyTotal__1JYfb {
}

.Tabs_Tabs__2Ln80 {
  background: transparent;
  margin-bottom: 3rem;
}

.Tabs_TabList__23kgh {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.Tabs_TabListItem__342s7 {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  -webkit-user-select: none;
          user-select: none;
}

.Tabs_TabListActive__3fcYA {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.Tabs_TabContent__1xu4J {
  background: transparent;
  margin-right: 0;
  margin-left: 0;
}

.Tabs_FuneralSchemeMember__yF9KG {
  /* color: #666; */
  /* vertical-align: top; */
  font-size: 1.2rem;
  /* font-family: Raleway; */
  /* font-weight: 600; */
  /* vertical-align: middle; */
  padding: 1rem;
}
.Tab_TabList__11dlN {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.Tab_TabListItem__3il88 {
  /* display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem; */

  display: block;
  border-radius: 0;
  padding: 0.5rem 2rem;
  margin-right: 0;
  font-weight: 500;
  letter-spacing: .06em;
  color: #000;
  font-size: 85%;
  font-weight: 300;
  min-width: 14rem;
  text-transform: uppercase;
  text-align: center;
  border-color: transparent;
  position: relative;
  line-height: 1.8em;
  -webkit-user-select: none;
          user-select: none;
}



.Tab_TabListItem__3il88:after {
  position: absolute;
  transition: all .1s linear 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  display: block;
  background-color: #524f4f;
  height: 0;
  content: '';
  left: 0;
  z-index: 125;
  top: 100%;
}



.Tab_TabListActive__21Ze3 {
  background-color: white;
  font-weight: 700;
  /* border: solid #ccc;
    border-width: 1px 1px 0 1px; */
}

.Tab_TabListActive__21Ze3:after,
.Tab_TabListItem__3il88:hover:after {
  height: 3px;
  font-weight: 700;
}

/* .nav-tabs>li>a {
    display: block;
    border-radius: 0;
    padding: 13px 20px;
    margin-right: 0;
    font-weight: 500;
    letter-spacing: .06em;
    color: rgba(87,87,87,.7);
    font-size: 10.5px;
    min-width: 70px;
    text-transform: uppercase;
    border-color: transparent;
    position: relative;
    line-height: 1.7em;
} */
.DisableDevice_btn__1H7sS {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.DisableDevice_btn__1H7sS:focus,
.DisableDevice_btn__1H7sS:hover,
.DisableDevice_btn__1H7sS:active,
.DisableDevice_btn__1H7sS:disabled {
    background-color: #6d5eac;
}
.EnableDevice_btn__27qF8 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.EnableDevice_btn__27qF8:focus,
.EnableDevice_btn__27qF8:hover,
.EnableDevice_btn__27qF8:active,
.EnableDevice_btn__27qF8:disabled {
    background-color: #6d5eac;
}
.GeneratePairingCode_btn__3TLdh {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.GeneratePairingCode_btn__3TLdh:focus,
.GeneratePairingCode_btn__3TLdh:hover,
.GeneratePairingCode_btn__3TLdh:active,
.GeneratePairingCode_btn__3TLdh:disabled {
    background-color: #6d5eac;
}
.UnassignDevice_btn__WH9-g {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.UnassignDevice_btn__WH9-g:focus,
.UnassignDevice_btn__WH9-g:hover,
.UnassignDevice_btn__WH9-g:active,
.UnassignDevice_btn__WH9-g:disabled {
    background-color: #6d5eac;
}
.OutletInventory_Container__38Bg6 {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.OutletInventory_Tools__3GtOw {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.OutletInventory_PageFilter__2VGq_ {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.OutletInventory_ToolsWrapper__3SfQY {
    height: 6rem;
    width: 100%;

}

.OutletInventory_PrintToolsWrapper__KYvn2 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.OutletInventory_AddNewMerchant__3Wusn {
    /* // font-family: Cambay; */
}

.OutletInventory_AddNewMerchantBtnRed__2N1sd {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.OutletInventory_AddNewMerchantBtn__1r8Vs {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.OutletInventory_AddNewMerchantBtn__1r8Vs:focus,
.OutletInventory_AddNewMerchantBtn__1r8Vs:hover,
.OutletInventory_AddNewMerchantBtn__1r8Vs:active {
    background-color: rgb(51, 137, 255);
}


.OutletInventory_ToolsDataExportIconWrapper__2dXvA {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.OutletInventory_ToolsPrintIconWrapper__1vTMR {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.OutletInventory_FilterInput__WSaxo {
    margin-right: 2rem;
}

.OutletInventory_FormGroup__kClTX {
    /* margin-bottom: 10px; */
}

.OutletInventory_FormGroupDefault__2MIap.OutletInventory_has-error__FXOep {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.OutletInventory_FormGroupDefault__2MIap {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.OutletInventory_FormGroupDefault__2MIap label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.OutletInventory_FormGroup__kClTX label:not(.OutletInventory_error__3fSPH) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.OutletInventory_FormControl__t3xq3 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.OutletInventory_MerchantTableWrapper__1kW66 {
    margin-top: 2.5rem;
    white-space: nowrap;
}

/* 
.TableWrapper {
}

.MerchantTable {
    width: 100%;
    border-collapse: collapse;
} */


.OutletInventory_TableWrapper__V0irf {
    display: flex;
}

.OutletInventory_MerchantTable__2Ms66 {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.OutletInventory_MerchantTable__2Ms66 th {
    text-align: left;
}

.OutletInventory_MerchantTable__2Ms66 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.OutletInventory_MerchantTable__2Ms66 td,
.OutletInventory_MerchantTable__2Ms66 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.OutletInventory_MerchantTable__2Ms66 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.OutletInventory_MerchantTable__2Ms66 td:first-child {

    border-left: 1px solid #e8e8e8;
}

.OutletInventory_RevealMoreHeader__320Ez {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.OutletInventory_Row__3hkSL {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.OutletInventory_Row__3hkSL>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.OutletInventory_dataTables_paginate__3iwB_ {
    float: right;
}

.OutletInventory_dataTables_paginate__3iwB_ {
    /* margin-top: 25px !important; */
}

div.OutletInventory_dataTables_paginate__3iwB_ {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.OutletInventory_dataTables_paginate__3iwB_ ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.OutletInventory_dataTables_paginate__3iwB_ ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.OutletInventory_dataTables_paginate__3iwB_ ul>li.OutletInventory_disabled__3bz4N a {
    opacity: .5;
}

.OutletInventory_dataTables_paginate__3iwB_ ul>li.OutletInventory_disabled__3bz4N a {
    opacity: .35;
}

.OutletInventory_dataTables_paginate__3iwB_ ul>li.OutletInventory_active__mG5Kx>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.OutletInventory_dataTables_paginate__3iwB_ ul>li.OutletInventory_next__1XnIq>a,
.OutletInventory_dataTables_paginate__3iwB_ ul>li.OutletInventory_prev__3Q2Dw>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OutletInventory_pg_arrow_right__1DZpB {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OutletInventory_pg_arrow_left__1tEc6 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OutletInventory_pg_arrow_right__1DZpB:before {
    content: "\E631";
}

.OutletInventory_pg_arrow_left__1tEc6:before {
    content: "\E629";
}

.OutletInventory_btn__3iYlr {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.OutletInventory_btn_primary__aQS_C,
.OutletInventory_btn_primary__aQS_C:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.OutletInventory_btn_cons__3MIR2 {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.OutletInventory_MerchantRow__3qOgg {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.OutletInventory_MerchantRow__3qOgg:hover {
    background-color: #267788;
    color: #fff;

}

.OutletInventory_MerchantRow__3qOgg:hover a {
    color: #fff;
}

.OutletInventory_MerchantRowExpanded__3jQyo {
    background: #267788;
    color: #fff;
}

.OutletInventory_table__f6dAk.OutletInventory_table-condensed__3P_hH.OutletInventory_table-detailed__1dL68>tbody>tr.OutletInventory_shown__16RS6>td {
    background: #fdf5df;
}

.OutletInventory_EnableDevice__UMkCJ {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.OutletInventory_EnableDeviceHeader__ielvh {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.OutletInventory_EnableDeviceBody__3Eskz {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}
.OutletComputers_Container__K71v7 {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.OutletComputers_Tools__nwDr0 {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.OutletComputers_PageFilter__1IwuT {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.OutletComputers_ToolsWrapper__zJIHc {
    height: 6rem;
    width: 100%;

}

.OutletComputers_PrintToolsWrapper__3-RYa {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.OutletComputers_AddNewMerchant__AvARt {
    /* // font-family: Cambay; */
}

.OutletComputers_AddNewMerchantBtn__2KicW {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.OutletComputers_AddNewMerchantBtn__2KicW:focus,
.OutletComputers_AddNewMerchantBtn__2KicW:hover,
.OutletComputers_AddNewMerchantBtn__2KicW:active {
    background-color: rgb(51, 137, 255);
}


.OutletComputers_ToolsDataExportIconWrapper__kub0a {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.OutletComputers_ToolsPrintIconWrapper__1FOHM {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.OutletComputers_FilterInput__1pYBw {
    margin-right: 2rem;
}

.OutletComputers_FormGroup__31EHw {
    /* margin-bottom: 10px; */
}

.OutletComputers_FormGroupDefault__1k0wT.OutletComputers_has-error__fmIzg {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.OutletComputers_FormGroupDefault__1k0wT {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.OutletComputers_FormGroupDefault__1k0wT label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.OutletComputers_FormGroup__31EHw label:not(.OutletComputers_error__2yEYp) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.OutletComputers_FormControl__1_BRu {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.OutletComputers_MerchantTableWrapper__19Vbr {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.OutletComputers_TableWrapper__1RoNi {
    display: flex;
}

.OutletComputers_MerchantTable__1K5y3 {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.OutletComputers_MerchantTable__1K5y3 th {
    text-align: left;
}





.OutletComputers_MerchantTable__1K5y3 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.OutletComputers_MerchantTable__1K5y3 td,
.OutletComputers_MerchantTable__1K5y3 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.OutletComputers_MerchantTable__1K5y3 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.OutletComputers_MerchantTable__1K5y3 td:first-child {

    border-left: 1px solid #e8e8e8;
}

.OutletComputers_RevealMoreHeader__3tO53 {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.OutletComputers_Row__2O5cd {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.OutletComputers_Row__2O5cd>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.OutletComputers_dataTables_paginate__1FmlV {
    float: right;
}

.OutletComputers_dataTables_paginate__1FmlV {
    /* margin-top: 25px !important; */
}

div.OutletComputers_dataTables_paginate__1FmlV {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.OutletComputers_dataTables_paginate__1FmlV ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.OutletComputers_dataTables_paginate__1FmlV ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.OutletComputers_dataTables_paginate__1FmlV ul>li.OutletComputers_disabled__37YzP a {
    opacity: .5;
}

.OutletComputers_dataTables_paginate__1FmlV ul>li.OutletComputers_disabled__37YzP a {
    opacity: .35;
}

.OutletComputers_dataTables_paginate__1FmlV ul>li.OutletComputers_active__2c2C7>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.OutletComputers_dataTables_paginate__1FmlV ul>li.OutletComputers_next__1fAiS>a,
.OutletComputers_dataTables_paginate__1FmlV ul>li.OutletComputers_prev___uWdq>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OutletComputers_pg_arrow_right__3nb-V {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OutletComputers_pg_arrow_left__13_s1 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OutletComputers_pg_arrow_right__3nb-V:before {
    content: "\E631";
}

.OutletComputers_pg_arrow_left__13_s1:before {
    content: "\E629";
}

.OutletComputers_btn__3x_BK {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.OutletComputers_btn_primary__2oRYs,
.OutletComputers_btn_primary__2oRYs:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.OutletComputers_btn_cons__FPi7x {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.OutletComputers_MerchantRow__3Gq2U {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.OutletComputers_MerchantRow__3Gq2U:hover {
    background-color: #267788;
    color: #fff;

}

.OutletComputers_MerchantRow__3Gq2U:hover a {
    color: #fff;
}

.OutletComputers_MerchantRowExpanded__3EhME {
    background: #267788;
    color: #fff;
}

.OutletComputers_table__1Kh4F.OutletComputers_table-condensed__2HNMj.OutletComputers_table-detailed__3nvLB>tbody>tr.OutletComputers_shown__1CCnh>td {
    background: #fdf5df;
}

.OutletComputers_EnableComputer__13bEb {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.OutletComputers_EnableComputerHeader__12z5k {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.OutletComputers_EnableComputerBody__17peA {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}
.EnableComputer_btn__1wdDj {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.EnableComputer_btn__1wdDj:focus,
.EnableComputer_btn__1wdDj:hover,
.EnableComputer_btn__1wdDj:active,
.EnableComputer_btn__1wdDj:disabled {
    background-color: #6d5eac;
}
.DisableComputer_btn__1NWX1 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.DisableComputer_btn__1NWX1:focus,
.DisableComputer_btn__1NWX1:hover,
.DisableComputer_btn__1NWX1:active,
.DisableComputer_btn__1NWX1:disabled {
    background-color: #6d5eac;
}
.ChangeMACAddress_btn__1-Yrr {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.ChangeMACAddress_btn__1-Yrr:focus,
.ChangeMACAddress_btn__1-Yrr:hover,
.ChangeMACAddress_btn__1-Yrr:active,
.ChangeMACAddress_btn__1-Yrr:disabled {
    background-color: #6d5eac;
}
.GeneratePairingCode_btn__1riiK {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.GeneratePairingCode_btn__1riiK:focus,
.GeneratePairingCode_btn__1riiK:hover,
.GeneratePairingCode_btn__1riiK:active,
.GeneratePairingCode_btn__1riiK:disabled {
    background-color: #6d5eac;
}
.Edit_btn__i4rNd {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}

.Edit_btn__i4rNd:focus,
.Edit_btn__i4rNd:hover,
.Edit_btn__i4rNd:active,
.Edit_btn__i4rNd:disabled {
    background-color: #6d5eac;
}
.Users_Container__bP5lz {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Users_Tools__aKBEk {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Users_PageFilter__3FDVA {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Users_ToolsWrapper__1C9z4 {
    height: 6rem;
    width: 100%;

}

.Users_PrintToolsWrapper__1-v_r {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Users_AddNewMerchant__jWLVt {
    /* // font-family: Cambay; */
}

.Users_AddNewMerchantBtn__157E- {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.Users_AddNewMerchantBtn__157E-:focus,
.Users_AddNewMerchantBtn__157E-:hover,
.Users_AddNewMerchantBtn__157E-:active {
    background-color: rgb(51, 137, 255);
}

.Users_ToolsDataExportIconWrapper__3zSGe {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Users_ToolsPrintIconWrapper__1jweG {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Users_FilterInput__2k5wn {
    margin-right: 2rem;
}

.Users_FormGroup__2QKps {
    /* margin-bottom: 10px; */
}

.Users_FormGroupDefault__290po.Users_has-error__10sOB {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Users_FormGroupDefault__290po {
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Users_FormGroupDefault__290po label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Users_FormGroup__2QKps label:not(.Users_error__2hVA0) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Users_FormControl__sw_27 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Users_MerchantTableWrapper__ErBf_ {
    margin-top: 2.5rem;
    white-space: nowrap;

}

.Users_TableWrapper__1NT52 {
    display: flex;
    /* background-color: #f5f5f5;
    border: 1px solid #e0e0e0; */
}


.Users_MerchantTable__1Dk8g {
    flex: 1 1 70%;
    border-collapse: collapse;
    /* background-color: #f5f5f5; */
}

.Users_MerchantTable__1Dk8g th {
    text-align: left;
}

.Users_MerchantTable__1Dk8g th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
    height: 4rem;

}

.Users_MerchantTable__1Dk8g td,
.Users_MerchantTable__1Dk8g th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Users_MerchantTable__1Dk8g td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Users_MerchantTable__1Dk8g td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Users_EnableUser__2n01Q {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Users_EnableUserHeader__pf5V- {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Users_EnableUserBody__hRRL7 {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}

/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Users_Row__2SNYL {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Users_Row__2SNYL>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Users_dataTables_paginate__Ves5D {
    float: right;
}

.Users_dataTables_paginate__Ves5D {
    /* margin-top: 25px !important; */
}

div.Users_dataTables_paginate__Ves5D {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Users_dataTables_paginate__Ves5D ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Users_dataTables_paginate__Ves5D ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Users_dataTables_paginate__Ves5D ul>li.Users_disabled__1W2Ub a {
    opacity: .5;
}

.Users_dataTables_paginate__Ves5D ul>li.Users_disabled__1W2Ub a {
    opacity: .35;
}

.Users_dataTables_paginate__Ves5D ul>li.Users_active__DilAS>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Users_dataTables_paginate__Ves5D ul>li.Users_next__98RBZ>a,
.Users_dataTables_paginate__Ves5D ul>li.Users_prev__2419v>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Users_pg_arrow_right__1toXp {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Users_pg_arrow_left__sLQyi {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Users_pg_arrow_right__1toXp:before {
    content: "\E631";
}

.Users_pg_arrow_left__sLQyi:before {
    content: "\E629";
}

.Users_btn__XhC89 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Users_btn_primary__11UP1,
.Users_btn_primary__11UP1:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Users_btn_cons__3gGSx {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Users_MerchantRow__1pINY {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Users_MerchantRow__1pINY:hover {
    background-color: #267788;
    color: #fff;
    cursor: pointer;

}

.Users_MerchantRow__1pINY:hover a {
    color: #fff;
}


.Users_SelectedTransaction__2V-um {
    background-color: #267788;
    color: #fff;

}

.Users_MerchantRowExpanded__1C0Rs {
    background: #267788;
    color: #fff;
}

.Users_table__2hYpC.Users_table-condensed__2iWnR.Users_table-detailed__3NZfT>tbody>tr.Users_shown__Zwx4B>td {
    background: #fdf5df;
}
.EnableOutletUser_btn__cAOih {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}

.EnableOutletUser_btn__cAOih:focus,
.EnableOutletUser_btn__cAOih:hover,
.EnableOutletUser_btn__cAOih:active,
.EnableOutletUser_btn__cAOih:disabled {
    background-color: #6d5eac;
}
.DisableOutletUser_btn__2N3M3 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}

.DisableOutletUser_btn__2N3M3:focus,
.DisableOutletUser_btn__2N3M3:hover,
.DisableOutletUser_btn__2N3M3:active,
.DisableOutletUser_btn__2N3M3:disabled {
    background-color: #6d5eac;
}
.Outlet_Container__2JQrS {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Outlet_BreadcrumbWrapper__2y0nA {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Outlet_Content__t4apc {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Outlet_Alert__2Kd2y {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Outlet_Breadcrumb__2hYRc {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Outlet_Header__3GL39 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Outlet_ByLine__2s5kq {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Outlet_Activity__2sm3o {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Outlet_WelcomeContainer__3Uvfz {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Outlet_MerchantProfile__1-IQS {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

@media (max-width: 1366px) {
    .Outlet_LoggedUser__12qmI {
        flex: 0 0 30rem;
    }
}

.Outlet_ProfilePic__1h-yO {
    flex: 0 0 10.4rem;
}

.Outlet_SummaryContainer__1O2PM {
    display: flex;
    flex: 1 1 auto;
    margin-left: 2.4rem;
}

.Outlet_MembershipSummary__1_M7S {
    flex: 1 1 25.6rem;
}

.Outlet_SubscriptionSummary__2dDlv {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.Outlet_ClaimSummary__1C6Sr {
    flex: 1 1 25.6rem;
    margin-left: 2.4rem;
}

.Outlet_ProfileImage__8GQDK {
    border-radius: 50%;
    display: inline-block;
    margin: 0 4px;
    padding: 12px;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}


.Outlet_TabHeader__1k2N0 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Outlet_PageHeader__2U56_ {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Outlet_AddUser__3GJdx {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Outlet_AddUserBtn__1wdBr {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.Outlet_Details__YvnL- {
    display: flex;
    margin-bottom: 1rem;
}

.Outlet_DetailsContent__2FjLT {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Outlet_DetailsEdit__36Hw2 {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.025);
    /* border-left: 1px solid rgba(0, 0, 0, 0.05); */
    -webkit-animation: Outlet_slide__sWb3c 0.5s forwards;
            animation: Outlet_slide__sWb3c 0.5s forwards;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}

.Outlet_FieldWrapper__1e5Gy:hover {
    background-color: rgba(0, 0, 0, .12);
    padding: 0 1rem;
    cursor: pointer;

}
.Outlets_Container__2DejO {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Outlets_Tools__bCbuT {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Outlets_PageFilter__KdpUw {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Outlets_ToolsWrapper__117G0 {
    height: 6rem;
    width: 100%;

}

.Outlets_PrintToolsWrapper__1Dw1J {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Outlets_AddNewMerchant__1AVXF {
    /* // font-family: Cambay; */
}

.Outlets_AddNewMerchantBtn__1cy8Q {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Outlets_AddNewMerchantBtn__1cy8Q:focus,
.Outlets_AddNewMerchantBtn__1cy8Q:hover,
.Outlets_AddNewMerchantBtn__1cy8Q:active {
    background-color: rgb(51, 137, 255);
}


.Outlets_ToolsDataExportIconWrapper__1s5wo {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Outlets_ToolsPrintIconWrapper__aEyak {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Outlets_FilterInput__aWnYx {
    margin-right: 2rem;
}

.Outlets_FormGroup__CIHka {
    /* margin-bottom: 10px; */
}

.Outlets_FormGroupDefault__oWRlF.Outlets_has-error__19t-0 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Outlets_FormGroupDefault__oWRlF {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Outlets_FormGroupDefault__oWRlF label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Outlets_FormGroup__CIHka label:not(.Outlets_error__2BKdd) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Outlets_FormControl__25DvB {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Outlets_MerchantTableWrapper__RBLEn {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Outlets_TableWrapper__2xKK2 {
    /* margin: 0 2.5rem; */
}

.Outlets_MerchantTable__1hg3s {
    width: 100%;
    border-collapse: collapse;
}

.Outlets_MerchantTable__1hg3s th {
    text-align: left;
}

.Outlets_MerchantTable__1hg3s th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Outlets_MerchantTable__1hg3s td,
.Outlets_MerchantTable__1hg3s th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Outlets_MerchantTable__1hg3s td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Outlets_MerchantTable__1hg3s td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Outlets_RevealMoreHeader__2WVBr {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Outlets_Row__3sNtO {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Outlets_Row__3sNtO>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Outlets_dataTables_paginate__xG50z {
    float: right;
}

.Outlets_dataTables_paginate__xG50z {
    /* margin-top: 25px !important; */
}

div.Outlets_dataTables_paginate__xG50z {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Outlets_dataTables_paginate__xG50z ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Outlets_dataTables_paginate__xG50z ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Outlets_dataTables_paginate__xG50z ul>li.Outlets_disabled__22E4G a {
    opacity: .5;
}

.Outlets_dataTables_paginate__xG50z ul>li.Outlets_disabled__22E4G a {
    opacity: .35;
}

.Outlets_dataTables_paginate__xG50z ul>li.Outlets_active__3etHU>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Outlets_dataTables_paginate__xG50z ul>li.Outlets_next__W2D-E>a,
.Outlets_dataTables_paginate__xG50z ul>li.Outlets_prev__3X3Yp>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Outlets_pg_arrow_right__1z-_Y {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Outlets_pg_arrow_left__1oOmR {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Outlets_pg_arrow_right__1z-_Y:before {
    content: "\E631";
}

.Outlets_pg_arrow_left__1oOmR:before {
    content: "\E629";
}

.Outlets_btn__2r0MG {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Outlets_btn_primary__T0oCQ,
.Outlets_btn_primary__T0oCQ:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Outlets_btn_cons__guvpY {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Outlets_MerchantRow__2EY2k {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Outlets_MerchantRow__2EY2k:hover {
    background-color: #267788;
    color: #fff;

}

.Outlets_MerchantRow__2EY2k:hover a {
    color: #fff;
}

.Outlets_MerchantRowExpanded__3gDtb {
    background: #267788;
    color: #fff;
}

.Outlets_table__BbxFB.Outlets_table-condensed__YXSbk.Outlets_table-detailed__yYKpU>tbody>tr.Outlets_shown__wwFZE>td {
    background: #fdf5df;
}
.CreditLimit_Container__3vQdU {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.CreditLimit_Tools__2YJ6h {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.CreditLimit_PageFilter__2Nu8V {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.CreditLimit_ToolsWrapper__19G0N {
    height: 6rem;
    width: 100%;

}

.CreditLimit_PrintToolsWrapper__rYL6B {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.CreditLimit_AddNewMerchant__2xvqm {
    /* // font-family: Cambay; */
}

.CreditLimit_AddNewMerchantBtn__3e94v {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.CreditLimit_AddNewMerchantBtn__3e94v:focus,
.CreditLimit_AddNewMerchantBtn__3e94v:hover,
.CreditLimit_AddNewMerchantBtn__3e94v:active {
    background-color: rgb(51, 137, 255);
}


.CreditLimit_ToolsDataExportIconWrapper__3TRy- {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.CreditLimit_ToolsPrintIconWrapper__2kt4v {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.CreditLimit_FilterInput__1ey2N {
    margin-right: 2rem;
}

.CreditLimit_FormGroup__ZOeTm {
    /* margin-bottom: 10px; */
}

.CreditLimit_FormGroupDefault__3Bhq0.CreditLimit_has-error__3Umef {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.CreditLimit_FormGroupDefault__3Bhq0 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.CreditLimit_FormGroupDefault__3Bhq0 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.CreditLimit_FormGroup__ZOeTm label:not(.CreditLimit_error__2fo-M) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.CreditLimit_FormControl__n71r- {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.CreditLimit_MerchantTableWrapper__D8kkZ {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.CreditLimit_TableWrapper__1Y600 {
    display: flex;
}

.CreditLimit_MerchantTable__3iL5a {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.CreditLimit_MerchantTable__3iL5a th {
    text-align: left;
}

.CreditLimit_MerchantTable__3iL5a th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.CreditLimit_MerchantTable__3iL5a td,
.CreditLimit_MerchantTable__3iL5a th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.CreditLimit_MerchantTable__3iL5a td:last-child {

    border-right: 1px solid #e8e8e8;
}


.CreditLimit_MerchantTable__3iL5a td:first-child {

    border-left: 1px solid #e8e8e8;
}

.CreditLimit_RevealMoreHeader__2s0gc {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.CreditLimit_Row__36O57 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.CreditLimit_Row__36O57>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.CreditLimit_dataTables_paginate__gadGX {
    float: right;
}

.CreditLimit_dataTables_paginate__gadGX {
    /* margin-top: 25px !important; */
}

div.CreditLimit_dataTables_paginate__gadGX {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.CreditLimit_dataTables_paginate__gadGX ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.CreditLimit_dataTables_paginate__gadGX ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.CreditLimit_dataTables_paginate__gadGX ul>li.CreditLimit_disabled__14bqC a {
    opacity: .5;
}

.CreditLimit_dataTables_paginate__gadGX ul>li.CreditLimit_disabled__14bqC a {
    opacity: .35;
}

.CreditLimit_dataTables_paginate__gadGX ul>li.CreditLimit_active__jt8JG>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.CreditLimit_dataTables_paginate__gadGX ul>li.CreditLimit_next__2H4ck>a,
.CreditLimit_dataTables_paginate__gadGX ul>li.CreditLimit_prev__3cYuH>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.CreditLimit_pg_arrow_right__31qPF {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.CreditLimit_pg_arrow_left__1Ffdk {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.CreditLimit_pg_arrow_right__31qPF:before {
    content: "\E631";
}

.CreditLimit_pg_arrow_left__1Ffdk:before {
    content: "\E629";
}

.CreditLimit_btn__7C3KX {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.CreditLimit_btn_primary__nID_Q,
.CreditLimit_btn_primary__nID_Q:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.CreditLimit_btn_cons__12qv_ {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.CreditLimit_MerchantRow__1iv9l {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.CreditLimit_MerchantRow__1iv9l:hover {
    background-color: #267788;
    color: #fff;

}

.CreditLimit_MerchantRow__1iv9l:hover a {
    color: #fff;
}

.CreditLimit_MerchantRowExpanded__3GvTA {
    background: #267788;
    color: #fff;
}

.CreditLimit_table__2GK35.CreditLimit_table-condensed__1SxTH.CreditLimit_table-detailed__ZbICC>tbody>tr.CreditLimit_shown__3xw9I>td {
    background: #fdf5df;
}

.CreditLimit_ActivateDevice__38MNv {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.CreditLimit_ActivateDeviceHeader__1_Efl {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.CreditLimit_ActivateDeviceBody__f1u6r {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}

.CreditLimit_EnableDevice__3PwJI {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.CreditLimit_EnableDeviceHeader__1waRs {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.CreditLimit_EnableDeviceBody__5g1YW {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}

.EnableProvider_btn__3aJLl {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}

.EnableProvider_btn__3aJLl:focus,
.EnableProvider_btn__3aJLl:hover,
.EnableProvider_btn__3aJLl:active,
.EnableProvider_btn__3aJLl:disabled {
    background-color: #6d5eac;
}
.DisableProvider_btn__3XJ2R {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}

.DisableProvider_btn__3XJ2R:focus,
.DisableProvider_btn__3XJ2R:hover,
.DisableProvider_btn__3XJ2R:active,
.DisableProvider_btn__3XJ2R:disabled {
    background-color: #6d5eac;
}
.EnableUser_btn__24RrV {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}

.EnableUser_btn__24RrV:focus,
.EnableUser_btn__24RrV:hover,
.EnableUser_btn__24RrV:active,
.EnableUser_btn__24RrV:disabled {
    background-color: #6d5eac;
}
.DisableUser_btn__c74PH {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}

.DisableUser_btn__c74PH:focus,
.DisableUser_btn__c74PH:hover,
.DisableUser_btn__c74PH:active,
.DisableUser_btn__c74PH:disabled {
    background-color: #6d5eac;
}
.ChangeUserPassword_btn__2gNpV {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}

.ChangeUserPassword_btn__2gNpV:focus,
.ChangeUserPassword_btn__2gNpV:hover,
.ChangeUserPassword_btn__2gNpV:active,
.ChangeUserPassword_btn__2gNpV:disabled {
    background-color: #6d5eac;
}
.User_Container__16P7u {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.User_Tools__qB_um {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.User_PageFilter__3Nph_ {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.User_ToolsWrapper__2TTOI {
    height: 6rem;
    width: 100%;

}

.User_PrintToolsWrapper__2lTjM {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.User_AddNewMerchant__1wy6q {
    /* // font-family: Cambay; */
}

.User_AddNewMerchantBtn__3fa0F {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.User_AddNewMerchantBtn__3fa0F:focus,
.User_AddNewMerchantBtn__3fa0F:hover,
.User_AddNewMerchantBtn__3fa0F:active {
    background-color: rgb(51, 137, 255);
}

.User_ToolsDataExportIconWrapper__3OfFz {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.User_ToolsPrintIconWrapper__1H67s {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.User_FilterInput__2Qxwi {
    margin-right: 2rem;
}

.User_FormGroup__1PvbI {
    /* margin-bottom: 10px; */
}

.User_FormGroupDefault__2LVbW.User_has-error__361sE {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.User_FormGroupDefault__2LVbW {
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.User_FormGroupDefault__2LVbW label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.User_FormGroup__1PvbI label:not(.User_error__bEKzM) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.User_FormControl__1uWgj {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.User_MerchantTableWrapper__-upWu {
    margin-top: 2.5rem;
    white-space: nowrap;

}

.User_TableWrapper__286Rl {
    display: flex;
    /* background-color: #f5f5f5;
    border: 1px solid #e0e0e0; */
}


.User_MerchantTable__1NxFT {
    flex: 1 1 70%;
    border-collapse: collapse;
    /* background-color: #f5f5f5; */
}

.User_MerchantTable__1NxFT th {
    text-align: left;
}

.User_MerchantTable__1NxFT th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
    height: 4rem;

}

.User_MerchantTable__1NxFT td,
.User_MerchantTable__1NxFT th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.User_MerchantTable__1NxFT td:last-child {

    border-right: 1px solid #e8e8e8;
}


.User_MerchantTable__1NxFT td:first-child {

    border-left: 1px solid #e8e8e8;
}

.User_EnableUser__2G1rB {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.User_EnableUserHeader__i5XcT {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.User_EnableUserBody__298Ue {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}

/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.User_Row__1E47n {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.User_Row__1E47n>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.User_dataTables_paginate__1Vjsk {
    float: right;
}

.User_dataTables_paginate__1Vjsk {
    /* margin-top: 25px !important; */
}

div.User_dataTables_paginate__1Vjsk {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.User_dataTables_paginate__1Vjsk ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.User_dataTables_paginate__1Vjsk ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.User_dataTables_paginate__1Vjsk ul>li.User_disabled__2GAbo a {
    opacity: .5;
}

.User_dataTables_paginate__1Vjsk ul>li.User_disabled__2GAbo a {
    opacity: .35;
}

.User_dataTables_paginate__1Vjsk ul>li.User_active__5pBGo>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.User_dataTables_paginate__1Vjsk ul>li.User_next__2iUHZ>a,
.User_dataTables_paginate__1Vjsk ul>li.User_prev__223Uq>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.User_pg_arrow_right__1vGLw {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.User_pg_arrow_left__1mBdY {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.User_pg_arrow_right__1vGLw:before {
    content: "\E631";
}

.User_pg_arrow_left__1mBdY:before {
    content: "\E629";
}

.User_btn__s0Wbx {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.User_btn_primary__3RETY,
.User_btn_primary__3RETY:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.User_btn_cons__1r3td {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.User_MerchantRow__qtGHM {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.User_MerchantRow__qtGHM:hover {
    background-color: #267788;
    color: #fff;
    cursor: pointer;

}

.User_MerchantRow__qtGHM:hover a {
    color: #fff;
}


.User_SelectedTransaction__35ulh {
    background-color: #267788;
    color: #fff;

}

.User_MerchantRowExpanded__Xy0_Q {
    background: #267788;
    color: #fff;
}

.User_table__1PDQU.User_table-condensed__2rvpR.User_table-detailed__3B8w_>tbody>tr.User_shown__16fWW>td {
    background: #fdf5df;
}
.MerchantProfile_CardBody__250uu {
    margin-top: 1rem;
}

.MerchantProfile_LabelStyle__iJomW {
    color: #000;
    font-weight: 400;
}

.MerchantProfile_IconWrapper__3AiIy {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 36px;
    width: auto;
}

.MerchantProfile_ExpandableOutlet__1UefM {
    float: left;
    text-decoration: none;
}

.MerchantProfile_ExpandableOutletName__1pfb6 {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.MerchantProfile_AddNewOutlet__37neR {
    float: right;
    margin-right: 8.8rem;
}

.MerchantProfile_AddNewOutletBtn__2IoRT {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.MerchantProfile_Outlets__29uwB {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* margin-left: 0.6rem; */
}

.MerchantProfile_Outlets__29uwB li {
    display: inline-block;
    list-style: none;
}

.MerchantProfile_Outlet__dxNiJ {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
}

.MerchantProfile_Outlet__dxNiJ:hover {
    color: #FFF;
    background: #5BA6E6;

}

.MerchantProfile_OutletName__RNDaX {
    font-weight: 600;
}

.MerchantProfile_OutletLocation__3bhTz {
    font-weight: 400;

}

.MerchantProfile_TabHeader__3nzIb {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.MerchantProfile_CardBody__250uu {
    margin-top: 3rem;
}

.MerchantProfile_PageHeader__2oWep {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.MerchantProfile_Tools__3iJXS {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.MerchantProfile_ToolsExportIconWrapper__3P_7F {
    display: inline-block;
    margin-left: 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.MerchantProfile_AddUserBtn__ISzqp {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.MerchantProfile_Details__15Zkn {
    display: flex;
    margin-bottom: 1rem;
}

.MerchantProfile_DetailsContent__GjSnj {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.MerchantProfile_DetailsEdit__17LIU {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.025);
    /* border-left: 1px solid rgba(0, 0, 0, 0.05); */
    -webkit-animation: MerchantProfile_slide__g1EbD 0.5s forwards;
            animation: MerchantProfile_slide__g1EbD 0.5s forwards;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}

.MerchantProfile_FieldWrapper__3FDOa:hover {
    background-color: rgba(0, 0, 0, .12);
    padding: 0 1rem;
    cursor: pointer;

}

@-webkit-keyframes MerchantProfile_slide__g1EbD {
    100% {
        left: 0;
    }
}

@keyframes MerchantProfile_slide__g1EbD {
    100% {
        left: 0;
    }
}

.MerchantProfile_MerchantProfile__2amVQ {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}


.MerchantProfile_FilterInput__33cJ6 {
    margin-right: 2rem;
}

.MerchantProfile_FormGroup__B7sgx {
    margin-bottom: 10px;
}

.MerchantProfile_FormGroupDefault__2Qb-v.MerchantProfile_has-error__3RpF2 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.MerchantProfile_FormGroupDefault__2Qb-v {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

.MerchantProfile_PageFilter__1yKVj {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
    margin-top: 10px;
}

.MerchantProfile_PageTitle__2lrZM {
    border-right: 1px solid #707070;
    padding-right: 2rem;
    font-weight: 700;
    margin-top: 10px;
}

.MerchantProfile_FilterInput__33cJ6 {
    margin-right: 2rem;
}

.MerchantProfile_FormGroup__B7sgx {
    margin-bottom: 10px;
}

.MerchantProfile_FormGroupDefault__2Qb-v.MerchantProfile_has-error__3RpF2 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.MerchantProfile_FormGroupDefault__2Qb-v {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.MerchantProfile_FormGroupDefault__2Qb-v label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.MerchantProfile_FormGroup__B7sgx label:not(.MerchantProfile_error__PNqNv) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.MerchantProfile_FormControl__2d15Z {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}
.financials_Container__1T4Me {
  /* background-color: #fff; */
  flex-direction: column;
  height: 100%;
  font-size: 1.4rem;
}

.financials_BreadcrumbWrapper__16ba5 {
  display: flex;
  background-color: transparent;
  width: 100%;
  padding-left: 2.4rem;
  flex: 0 0 7.2rem;
  height: 7.2rem;
}

.financials_Content__jnf7L {
  flex: 1 1 auto;
  display: flex;
  margin: 2.4rem;
  flex-direction: column;
}

.financials_CardBody__3DyKd {
  margin-top: 1rem;
}

.financials_Alert__3QXHM {
  padding: 18px;
  margin-bottom: 15px;
  border: 0;
  border-radius: 0;
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.financials_Breadcrumb__IEE9A {
  padding: 1.8rem 0;
  flex: 0 0 50%;
}

.financials_Header__LudM1 {
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 1.5px;
  /* text-transform: uppercase; */
}

.financials_ByLine__3JeGr {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.financials_Activity__3JKLg {
  background: #fff;
  flex: 0 0 36rem;
  /* width: 36rem; */
}

.financials_WelcomeContainer__Im2nG {
  display: flex;
  /* margin: 0 2.4rem 0 0; */
  /* height: 17.6rem; */
}

.financials_MerchantProfile__1AQ_y {
  display: flex;
  background: transparent;
  flex: 0 0 100%;
}

.financials_TabHeader__20Kg_ {
  /* padding: 0px 2rem; */
  margin-bottom: 1rem;
  display: flex;
}

.financials_PageHeader__1xWwD {
  flex: 1 1 auto;
  justify-content: flex-start;
  width: auto;
}

.financials_Header__LudM1 {
  flex: 1 1 50%;
  justify-content: flex-start;
  width: auto;
}

.financials_ExportReport__7OVuo {
  flex: 1 1 50%;
  width: auto;
  margin-right: 1.4rem;
  align-items: center;
  display: flex;

  line-height: normal;
  flex: 1 1 auto;
  justify-content: flex-end;
}

.financials_ExportIconWrapper__1prDM {
  margin: 0 1.6rem;
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
  flex: 0 0 auto;
}

.financials_EmailIconWrapper__2Qwc7 {
  margin: 0 1.6rem;
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
  flex: 0 0 auto;
}

.financials_AddUser__2OZg- {
  display: flex;
  margin-right: 20px;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.financials_AddUserBtn__F_GMS {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  margin-left: 2rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

.financials_Tools__3AE5Q {
  /* display: flex; */
  margin: 1rem 0 3.5rem;
  /* padding-top: 1.4rem; */
}

.financials_PageFilter__1IoxM {
  /* display: flex; */
  flex: 1 1 auto;
  justify-content: flex-start;
  width: auto;
  margin-left: 15px;
}

.financials_PrintToolsWrapper__z3Ej7 {
  display: flex;
  flex: 1 1 20%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
}

.financials_FilterReportBtn__3AHsy {
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  height: 3.4rem;
  padding: 8px;
  text-decoration: none;
  border: none;
  display: block;
}

.financials_AddNewMerchantBtn__9ZFwD:focus,
.financials_AddNewMerchantBtn__9ZFwD:hover,
.financials_AddNewMerchantBtn__9ZFwD:active {
  background-color: rgb(51, 137, 255);
}

.financials_ToolsDataExportIconWrapper__O2sub {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.financials_ToolsPrintIconWrapper__3Amk8 {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.financials_FilterInput__2ELbG {
  margin-right: 2rem;
}

.financials_FormGroupDefault__3kTCF.financials_has-error__2M_U0 {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.financials_FormGroupDefault__3kTCF {
  line-height: 2rem;
  position: relative;
  transition: all 0.2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.financials_FormGroupDefault__3kTCF label {
  margin: 0;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.financials_FormGroup__bO9Gc label:not(.financials_error__QV4yV) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.financials_FormControl__3DRih {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.financials_MerchantTableWrapper__2sWCq {
  margin-top: 3rem;
  white-space: nowrap;
}

.financials_MerchantTable__3X6Pz {
  width: 100%;
  border-collapse: collapse;
}

.financials_MerchantTable__3X6Pz th {
  text-align: left;
}

.financials_MerchantTable__3X6Pz th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.financials_MerchantTable__3X6Pz td,
.financials_MerchantTable__3X6Pz th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.financials_RevealMoreHeader__3Kej2 {
  padding: 0;
  vertical-align: middle;
}

.financials_Row__3Vudg {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem;
}

.financials_Row__3Vudg > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.financials_dataTables_paginate__2YwOU {
  float: right;
}

div.financials_dataTables_paginate__2YwOU {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.financials_dataTables_paginate__2YwOU ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

.financials_dataTables_paginate__2YwOU ul > li > a {
  padding: 5px 10px;
  color: #575757;
  opacity: 0.35;
  transition: opacity 0.3s ease;
}

.financials_dataTables_paginate__2YwOU ul > li.financials_disabled__3da09 a {
  opacity: 0.5;
}

.financials_dataTables_paginate__2YwOU ul > li.financials_disabled__3da09 a {
  opacity: 0.35;
}

.financials_dataTables_paginate__2YwOU ul > li.financials_active__umDBS > a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.financials_dataTables_paginate__2YwOU ul > li.financials_next__3d785 > a,
.financials_dataTables_paginate__2YwOU ul > li.financials_prev__3HTp2 > a {
  opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.financials_pg_arrow_right__308ON {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.financials_pg_arrow_left__3EbRE {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.financials_pg_arrow_right__308ON:before {
  content: "\E631";
}

.financials_pg_arrow_left__3EbRE:before {
  content: "\E629";
}

.financials_btn__1RrFH {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
}

.financials_btn_primary__9Gtbh,
.financials_btn_primary__9Gtbh:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.financials_btn_cons__1LVSC {
  min-width: 120px;
}

.financials_MerchantName__2FT7e {
  font-weight: 500;
}

.financials_Icn__33Yd1 {
  cursor: pointer;
  /* fill:#000; */
}

.financials_Icn__33Yd1:hover {
  cursor: pointer;
}

.financials_RevealMore__2mNot {
  background: #e1e1e1;
  cursor: pointer;
  width: 2.4rem;
}

.financials_RevealMore__2mNot:hover {
  background: transparent;
  color: #707070;
  fill: #fff;
}

.financials_RevealMore__2mNot div {
  margin: 0 auto;
  width: 2.4rem;
  height: 2.4rem;
}

.financials_RevealMoreExpanded__1Acrb {
  background: transparent;
  fill: #fff;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.financials_Expandabled__1wBPb {
  text-align: left;
  background: #d7effb;
  border-bottom: 1px solid rgb(56, 0, 60);
  display: table-row;
  z-index: 500;
}

.financials_Expandabled__1wBPb td {
  padding: 2rem 2rem 2rem 5.6rem;
  border-bottom: 1px solid #e8e8e8;
}

.financials_MerchantTable__3X6Pz td:last-child {
  border-right: 1px solid #e8e8e8;
}

.financials_MerchantTable__3X6Pz td:first-child {
  border-left: 1px solid #e8e8e8;
}

.financials_MerchantRow__QlM-- {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.financials_MerchantRow__QlM--:hover {
  background-color: #267788;
  color: #fff;
}

.financials_MerchantRow__QlM--:hover a {
  color: #fff;
}

.financials_MerchantRowExpanded__2sWzv {
  background: #267788;
  color: #fff;
}

.financials_table__1fvlZ.financials_table-condensed__3cFFj.financials_table-detailed__26pzz > tbody > tr.financials_shown__UDHa0 > td {
  background: #fdf5df;
}

.financials_Totals__3wqUu td {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid #e8e8e8;
  font-weight: 700;
}

.financials_MoneyTotalLabel__1jmvZ {
  float: right;
}

.financials_FilterWrapper__3cGHY {
  display: flex;
  justify-content: flex-end;
  border-right: solid #707070;
}

.financials_FilterPeriods__1PhKU {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row;
  /* height: 100%; */
  padding-left: 24px;
  color: rgb(0, 0, 0);
  justify-content: flex-end;
  margin-right: 3.2rem;
}

.financials_FilterPeriods__1PhKU li {
  font-weight: 600;

  padding-left: 2rem;
  line-height: 38px;
}

.financials_FinancialsIntro__7vnwO {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.Deposits_Container__133Kj {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Deposits_Tools__2snoO {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Deposits_PageFilter__MbRdG {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Deposits_ToolsWrapper__1k57U {
    height: 6rem;
    width: 100%;

}

.Deposits_TabHeader__2Xzo5 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Deposits_Header__35utK {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Deposits_ExportReport__2MM3i {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Deposits_ExportIconWrapper__19Ztr {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Deposits_EmailIconWrapper__2ywPh {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Deposits_PrintToolsWrapper__374Yq {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Deposits_AddNewMerchant__1qfbx {
    /* // font-family: Cambay; */
}

.Deposits_AddNewMerchantBtn__2kjEh {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Deposits_AddNewMerchantBtn__2kjEh:focus,
.Deposits_AddNewMerchantBtn__2kjEh:hover,
.Deposits_AddNewMerchantBtn__2kjEh:active {
    background-color: rgb(51, 137, 255);
}


.Deposits_ToolsDataExportIconWrapper__2vuY8 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Deposits_ToolsPrintIconWrapper__1FJAQ {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Deposits_FilterInput__12R7Y {
    margin-right: 2rem;
}

.Deposits_FormGroup__cwJan {
    /* margin-bottom: 10px; */
}

.Deposits_FormGroupDefault__1WZn5.Deposits_has-error__3zfhf {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Deposits_FormGroupDefault__1WZn5 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Deposits_FormGroupDefault__1WZn5 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Deposits_FormGroup__cwJan label:not(.Deposits_error__2Q3bO) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Deposits_FormControl__2Yx0R {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Deposits_MerchantTableWrapper__2myGc {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Deposits_TableWrapper__1ciGz {
    display: flex;
}

.Deposits_MerchantTable__3otJY {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Deposits_MerchantTable__3otJY th {
    text-align: left;
}

.Deposits_MerchantTable__3otJY th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Deposits_MerchantTable__3otJY td,
.Deposits_MerchantTable__3otJY th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Deposits_MerchantTable__3otJY td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Deposits_MerchantTable__3otJY td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Deposits_RevealMoreHeader__RD3G2 {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Deposits_Row__2ugRc {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Deposits_Row__2ugRc>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Deposits_dataTables_paginate__26-bX {
    float: right;
}

.Deposits_dataTables_paginate__26-bX {
    /* margin-top: 25px !important; */
}

div.Deposits_dataTables_paginate__26-bX {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Deposits_dataTables_paginate__26-bX ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Deposits_dataTables_paginate__26-bX ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Deposits_dataTables_paginate__26-bX ul>li.Deposits_disabled__2EJac a {
    opacity: .5;
}

.Deposits_dataTables_paginate__26-bX ul>li.Deposits_disabled__2EJac a {
    opacity: .35;
}

.Deposits_dataTables_paginate__26-bX ul>li.Deposits_active__16mTX>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Deposits_dataTables_paginate__26-bX ul>li.Deposits_next__1neB7>a,
.Deposits_dataTables_paginate__26-bX ul>li.Deposits_prev__2n-lQ>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_right__3JXyE {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_left__eTbPT {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_right__3JXyE:before {
    content: "\E631";
}

.Deposits_pg_arrow_left__eTbPT:before {
    content: "\E629";
}

.Deposits_btn__TEFQa {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Deposits_btn_primary__3WbRR,
.Deposits_btn_primary__3WbRR:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Deposits_btn_cons__1EdRi {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Deposits_MerchantRow__135rB {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Deposits_MerchantRow__135rB:hover {
    background-color: #267788;
    color: #fff;

}

.Deposits_MerchantRow__135rB:hover a {
    color: #fff;
}

.Deposits_MerchantRowExpanded__1rHWE {
    background: #267788;
    color: #fff;
}

.Deposits_table__2izlN.Deposits_table-condensed__2hBGI.Deposits_table-detailed__2K01e>tbody>tr.Deposits_shown__2etTq>td {
    background: #fdf5df;
}

.Deposits_EnableMerchant__3DiSc {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Deposits_EnableMerchantHeader__1SHBF {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Deposits_EnableMerchantBody__2maJJ {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}


.Deposits_MoneyTotalLabel__2WNX8 {
    float: right;
    font-weight: 600;
}

.Deposits_MoneyTotal__IAXdD {
    font-weight: 600;
}
/* .Odd {
}

.Even {
} */

.details_MemberName__2apdQ {
    font-weight: 500;
}

.details_Icn__1tIpR {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__1tIpR:hover {
    cursor: pointer;

}

.details_RevealMore__2n-OV {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__2n-OV:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.details_RevealMore__2n-OV div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__1UAAQ {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .details_Expandabled__2pjww {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__2pjww td {
    padding: 2rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .details_MemberRow__3isgs {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MemberRow__3isgs:hover {
    background-color: #267788;
    color:#fff;

    }

    .details_MemberRow__3isgs:hover a {
        color:#fff;
        }

    .details_MemberRowExpanded__13FTY {
        background: #267788;
        color:#fff;
    }

.details_table__1hMD4.details_table-condensed__3uRfi.details_table-detailed__33tBg > tbody > tr.details_shown__24Lbm > td {
    background: #fdf5df;
}

.details_Container__3fSAQ {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.details_BreadcrumbWrapper__zHa-r {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.details_Content__1yfhk {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.details_CardBody__3BaKt {
    margin-top: 1rem;
}

.details_Alert__2CvEc {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.details_Breadcrumb__2odPd {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.details_Header__1PFKW {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.details_ByLine__39T1W {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_Activity__1-HEo {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.details_WelcomeContainer__5jD5c {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.details_MerchantProfile__3VGfr {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.details_TabHeader__3GX4s {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.details_PageHeader__1-6uj {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.details_Header__1PFKW {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.details_ExportReport__23ma- {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.details_ExportIconWrapper__3PTqv {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_EmailIconWrapper__3BzU_ {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_AddUser__z7Bur {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.details_AddUserBtn__38P2h {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_AmendDepositBtn__3o0EN {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border:  1px solid #2c2c2c;
    display: block;
}

.details_Tools__3lI7B {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.details_PageFilter__2rugL {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}

.details_PrintToolsWrapper__13mRE {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.details_FilterReportBtn__1lfX4 {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.details_AddNewMerchantBtn__LuPCi:focus,
.details_AddNewMerchantBtn__LuPCi:hover,
.details_AddNewMerchantBtn__LuPCi:active {
    background-color: rgb(51, 137, 255);
}

.details_ToolsDataExportIconWrapper__XstPq {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.details_ToolsPrintIconWrapper__3GePv {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.details_FilterInput__-w7qx {
    margin-right: 2rem;
}

.details_FormGroupDefault__2Veh9.details_has-error__Wpe55 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__2Veh9 {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.details_FormGroupDefault__2Veh9 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.details_FormGroup__1TqC8 label:not(.details_error__3zd8N) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.details_FormControl__cGVx- {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.details_MerchantTableWrapper__1cNja {
    margin-top: 3rem;
    white-space: nowrap;
}

.details_MerchantTable__UheWr {
    width: 100%;
    border-collapse: collapse;
}

.details_MerchantTable__UheWr th {
    text-align: left;
}

.details_MerchantTable__UheWr th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.details_MerchantTable__UheWr td,
.details_MerchantTable__UheWr th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__1rAsZ {
    padding: 0;
    vertical-align: middle;
}

.details_Row__3S59L {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.details_Row__3S59L>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.details_dataTables_paginate__1XhaT {
    float: right;
}

div.details_dataTables_paginate__1XhaT {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.details_dataTables_paginate__1XhaT ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

.details_dataTables_paginate__1XhaT ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.details_dataTables_paginate__1XhaT ul>li.details_disabled__2Nj43 a {
    opacity: .5;
}

.details_dataTables_paginate__1XhaT ul>li.details_disabled__2Nj43 a {
    opacity: .35;
}

.details_dataTables_paginate__1XhaT ul>li.details_active__nY5ZA>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.details_dataTables_paginate__1XhaT ul>li.details_next__MAFcX>a,
.details_dataTables_paginate__1XhaT ul>li.details_prev__3T4FD>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__3lXrA {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__n216_ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__3lXrA:before {
    content: "\E631";
}

.details_pg_arrow_left__n216_:before {
    content: "\E629";
}

.details_btn__JYFzO {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.details_btn_primary__3Jvva,
.details_btn_primary__3Jvva:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.details_btn_cons__1bm6v {
    min-width: 120px;
}

.details_MerchantName__1Dzzy {
    font-weight: 500;
}

.details_Icn__3F-jW {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__3F-jW:hover {
    cursor: pointer;

}



.details_RevealMore__3eZcV {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__3eZcV:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.details_RevealMore__3eZcV div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__3pL8M {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.details_Expandabled__1Svdu {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__1Svdu td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__UheWr td:last-child {

    border-right: 1px solid #e8e8e8;
}


.details_MerchantTable__UheWr td:first-child {

    border-left: 1px solid #e8e8e8;
}


.details_MerchantRow__36yFM {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MerchantRow__36yFM:hover {
    background-color: #267788;
    color: #fff;

}

.details_MerchantRow__36yFM:hover a {
    color: #fff;
}

.details_MerchantRowExpanded__dEjNi {
    background: #267788;
    color: #fff;
}

.details_table__1cYod.details_table-condensed__3ttmQ.details_table-detailed__htrav>tbody>tr.details_shown__3tfqW>td {
    background: #fdf5df;
}


.details_Totals__2zBRd td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.details_MoneyTotalLabel__3qmBg {
    float: right;

}

.details_FilterWrapper__2o2lZ {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.details_FilterPeriods__3e7yU {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.details_FilterPeriods__3e7yU li {
    font-weight: 600;

    padding-left: 2rem;
    line-height: 38px;
}

.details_FinancialsIntro__1Vtt7 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_ExpandableView__3QSea {
    background-color: transparent;
    /* font-family: Cambay; */
}

.details_Outlets__3U9LX {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    /* margin-left: 0.6rem; */
}

.details_Outlets__3U9LX li {
    display: inline-block;
        list-style: none;
}

.details_Outlet__2uLGv {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;}

.details_Outlet__2uLGv:hover {
color: #FFF;
    background: #5BA6E6;

}

.details_OutletName__3eO43 {
    font-weight: 600;
}

.details_OutletLocation__3pHhb {
    font-weight: 400;

}

.details_ExpandableMember__2ougH {
    float: left;
    text-decoration: none;
}

.details_ExpandableMemberName__ZRqpc {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.details_OutletListArea__1v6RJ {
    width: 100%;
    margin-top: 1rem;
    background: transparent;
}



.details_MemberContractDetailArea__1QXWu {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem 0px;
    background: rgb(255, 255, 255);
}

.details_MemberContractDetailArea__1QXWu header {
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.details_ExpandableMemberWrapper__1tEwc {
    background: transparent;
}

.details_clearfix__rIwNb::before,
.details_clearfix__rIwNb::after {
    display:table;
    content: " ";
}

.details_clearfix__rIwNb::after {
clear:both;
}


.details_FuneralSchemeWrapper__3XVzA {
    margin: 0 2.5rem;
}

.details_AddNewMember__2Wz2b {
float:right;
margin-right: 8.8rem;
}

.details_AddNewMemberBtn__S7LhG {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_FuneralSchemeTable__J57He  {
    width: 100%;
    border-collapse: collapse;
}

.details_FuneralSchemeTable__J57He th {
    text-align: left;
}

.details_FuneralSchemeTable__J57He th {
    color: #666;
    vertical-align: top;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    vertical-align: middle;
}

.details_FuneralSchemeTable__J57He td,
.details_FuneralSchemeTable__J57He th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_FuneralSchemeTable__J57He .details_Totals__2zBRd td {   
     border-bottom: none;
     font-weight: 600;
}

.details_FuneralSchemeTable__J57He .details_MoneyTotalLabel__3qmBg {
    float: right;

}
.details_FuneralSchemeTable__J57He .details_MoneyTotal__1Ja-d {

}
.Reversals_Container__1ODml {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Reversals_Tools__1j0gd {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Reversals_PageFilter__2PZhs {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Reversals_ToolsWrapper__1hKEP {
    height: 6rem;
    width: 100%;

}

.Reversals_TabHeader__1sRZ2 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Reversals_Header__NmoM6 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Reversals_ExportReport__1HGmt {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Reversals_ExportIconWrapper__2-Nxs {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Reversals_EmailIconWrapper__1hB7Y {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Reversals_PrintToolsWrapper__3JBBg {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Reversals_AddNewMerchant__jzC3i {
    /* // font-family: Cambay; */
}

.Reversals_AddNewMerchantBtn__1Ozkp {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Reversals_AddNewMerchantBtn__1Ozkp:focus,
.Reversals_AddNewMerchantBtn__1Ozkp:hover,
.Reversals_AddNewMerchantBtn__1Ozkp:active {
    background-color: rgb(51, 137, 255);
}


.Reversals_ToolsDataExportIconWrapper__3V1EL {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Reversals_ToolsPrintIconWrapper__2_H89 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Reversals_FilterInput__199DP {
    margin-right: 2rem;
}

.Reversals_FormGroup__16Wvd {
    /* margin-bottom: 10px; */
}

.Reversals_FormGroupDefault__1u6fa.Reversals_has-error__377vU {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Reversals_FormGroupDefault__1u6fa {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Reversals_FormGroupDefault__1u6fa label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Reversals_FormGroup__16Wvd label:not(.Reversals_error__2XIBH) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Reversals_FormControl__Ym9oU {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Reversals_MerchantTableWrapper__36mDA {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Reversals_TableWrapper__2dRlr {
    display: flex;
}

.Reversals_MerchantTable__1yRmq {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Reversals_MerchantTable__1yRmq th {
    text-align: left;
}

.Reversals_MerchantTable__1yRmq th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Reversals_MerchantTable__1yRmq td,
.Reversals_MerchantTable__1yRmq th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Reversals_MerchantTable__1yRmq td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Reversals_MerchantTable__1yRmq td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Reversals_RevealMoreHeader__2XfKK {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Reversals_Row__w60vC {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Reversals_Row__w60vC>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Reversals_dataTables_paginate__263EA {
    float: right;
}

.Reversals_dataTables_paginate__263EA {
    /* margin-top: 25px !important; */
}

div.Reversals_dataTables_paginate__263EA {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Reversals_dataTables_paginate__263EA ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Reversals_dataTables_paginate__263EA ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Reversals_dataTables_paginate__263EA ul>li.Reversals_disabled__17_m1 a {
    opacity: .5;
}

.Reversals_dataTables_paginate__263EA ul>li.Reversals_disabled__17_m1 a {
    opacity: .35;
}

.Reversals_dataTables_paginate__263EA ul>li.Reversals_active__3ACgT>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Reversals_dataTables_paginate__263EA ul>li.Reversals_next__1PaVO>a,
.Reversals_dataTables_paginate__263EA ul>li.Reversals_prev__3J9TC>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Reversals_pg_arrow_right__1igRR {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Reversals_pg_arrow_left__KETP3 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Reversals_pg_arrow_right__1igRR:before {
    content: "\E631";
}

.Reversals_pg_arrow_left__KETP3:before {
    content: "\E629";
}

.Reversals_btn__2OfM0 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Reversals_btn_primary__2V-z1,
.Reversals_btn_primary__2V-z1:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Reversals_btn_cons__3_sI- {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Reversals_MerchantRow__16Wyu {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Reversals_MerchantRow__16Wyu:hover {
    background-color: #267788;
    color: #fff;

}

.Reversals_MerchantRow__16Wyu:hover a {
    color: #fff;
}

.Reversals_MerchantRowExpanded__3nDd9 {
    background: #267788;
    color: #fff;
}

.Reversals_table__1Pm2O.Reversals_table-condensed__2-hBW.Reversals_table-detailed__83zKy>tbody>tr.Reversals_shown__2F19Z>td {
    background: #fdf5df;
}

.Reversals_EnableMerchant__3qrEc {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Reversals_EnableMerchantHeader__2-gUr {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Reversals_EnableMerchantBody__2jGWl {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}


.Reversals_MoneyTotalLabel__2aCXx {
    float: right;
    font-weight: 600;
}

.Reversals_MoneyTotal__3rpmb {
    font-weight: 600;
}
.Summary_Container__3gFzt {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Summary_Tools__1HoVY {
    display: flex;
    /* margin: 0 2.5rem; */
    /* padding-top: 1.4rem; */
}

.Summary_PageFilter__7B12A {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.Summary_ToolsWrapper__36lOA {
    height: 6rem;
    width: 100%;

}

.Summary_PrintToolsWrapper__31ucH {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Summary_AddNewMerchant__1QgIB {
    /* // font-family: Cambay; */
}

.Summary_FilterReportBtn__12V_b {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Summary_AddNewMerchantBtn__IxR07:focus,
.Summary_AddNewMerchantBtn__IxR07:hover,
.Summary_AddNewMerchantBtn__IxR07:active {
    background-color: rgb(51, 137, 255);
}


.Summary_ToolsDataExportIconWrapper__2qAVG {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Summary_ToolsPrintIconWrapper__1-OEV {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Summary_FilterInput__27B-o {
    margin-right: 2rem;
}

.Summary_FormGroup__1bfPu {
    /* margin-bottom: 10px; */
}

.Summary_FormGroupDefault__3knff.Summary_has-error__2T-C- {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Summary_FormGroupDefault__3knff {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Summary_FormGroupDefault__3knff label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Summary_FormGroup__1bfPu label:not(.Summary_error__2Kz1K) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Summary_FormControl__2gFu- {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Summary_MerchantTableWrapper__1amHk {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Summary_TableWrapper__1ilqP {
    /* margin: 0 2.5rem; */
}

.Summary_MerchantTable__C5bxn {
    width: 50%;
    border-collapse: collapse;
    border: 1px solid #e8e8e8;
}

.Summary_MerchantTable__C5bxn th {
    text-align: left;
}

.Summary_MerchantTable__C5bxn th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Summary_MerchantTable__C5bxn td,
.Summary_MerchantTable__C5bxn th {
    padding:0.35rem 1rem;
    /* border-bottom: 1px solid #e8e8e8; */
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Summary_RevealMoreHeader__2BYse {
    padding: 0;
    vertical-align: middle;
}





.Summary_Row__3exUL {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Summary_Row__3exUL>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Summary_dataTables_paginate__1Et3H {
    float: right;
}

.Summary_dataTables_paginate__1Et3H {
    /* margin-top: 25px !important; */
}

div.Summary_dataTables_paginate__1Et3H {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Summary_dataTables_paginate__1Et3H ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Summary_dataTables_paginate__1Et3H ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Summary_dataTables_paginate__1Et3H ul>li.Summary_disabled__3glg5 a {
    opacity: .5;
}

.Summary_dataTables_paginate__1Et3H ul>li.Summary_disabled__3glg5 a {
    opacity: .35;
}

.Summary_dataTables_paginate__1Et3H ul>li.Summary_active__I9lc2>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Summary_dataTables_paginate__1Et3H ul>li.Summary_next__3eKKr>a,
.Summary_dataTables_paginate__1Et3H ul>li.Summary_prev__3rlLV>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_right__3FWh7 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_left__1UYJJ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_right__3FWh7:before {
    content: "\E631";
}

.Summary_pg_arrow_left__1UYJJ:before {
    content: "\E629";
}

.Summary_btn__1j5Tx {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Summary_btn_primary__BvqUV,
.Summary_btn_primary__BvqUV:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Summary_btn_cons__1KVE1 {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Summary_MerchantName__2nt4u {
    font-weight: 500;
}

.Summary_Icn__2wg_V {
    cursor: pointer;
    /* fill:#000; */
}

.Summary_Icn__2wg_V:hover {
    cursor: pointer;

}



.Summary_RevealMore__aSpop {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Summary_RevealMore__aSpop:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Summary_RevealMore__aSpop div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Summary_RevealMoreExpanded__2zUuZ {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Summary_Expandabled__3zKSx {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Summary_Expandabled__3zKSx td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Summary_MerchantTable__C5bxn td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Summary_MerchantTable__C5bxn td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Summary_MerchantRow__2nhdi {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Summary_MerchantRow__2nhdi:hover {
    background-color: #267788;
    color: #fff;

}

.Summary_MerchantRow__2nhdi:hover a {
    color: #fff;
}

.Summary_MerchantRowExpanded__2IgIA {
    background: #267788;
    color: #fff;
}

.Summary_table__3vJtJ.Summary_table-condensed__2Fc0x.Summary_table-detailed__2hflW>tbody>tr.Summary_shown__3fezy>td {
    background: #fdf5df;
}


.Summary_Totals__2DgW8 td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Summary_MoneyTotalLabel__2lD1- {
    float: right;

}
.Commissions_Container__2QCoK {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Commissions_Tools__sjb_- {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Commissions_PageFilter__YxJUp {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Commissions_ToolsWrapper__369zz {
    height: 6rem;
    width: 100%;

}

.Commissions_TabHeader__3qEnv {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Commissions_Header__1rhkS {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Commissions_ExportReport__5jiHy {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Commissions_ExportIconWrapper__2eCxC {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Commissions_EmailIconWrapper__3YfaL {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Commissions_PrintToolsWrapper__3cFgW {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Commissions_AddNewMerchant__1Tz5o {
    /* // font-family: Cambay; */
}

.Commissions_AddNewMerchantBtn__2rmoo {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Commissions_AddNewMerchantBtn__2rmoo:focus,
.Commissions_AddNewMerchantBtn__2rmoo:hover,
.Commissions_AddNewMerchantBtn__2rmoo:active {
    background-color: rgb(51, 137, 255);
}


.Commissions_ToolsDataExportIconWrapper__2kAf7 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Commissions_ToolsPrintIconWrapper__3lAYA {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Commissions_FilterInput__2qQ-y {
    margin-right: 2rem;
}

.Commissions_FormGroup__138tK {
    /* margin-bottom: 10px; */
}

.Commissions_FormGroupDefault__34j08.Commissions_has-error__iwGax {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Commissions_FormGroupDefault__34j08 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Commissions_FormGroupDefault__34j08 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Commissions_FormGroup__138tK label:not(.Commissions_error__2HB_r) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Commissions_FormControl__2Y7UT {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Commissions_MerchantTableWrapper__2jIPS {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Commissions_TableWrapper__1ffS4 {
    display: flex;
}

.Commissions_MerchantTable__2qid0 {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Commissions_MerchantTable__2qid0 th {
    text-align: left;
}

.Commissions_MerchantTable__2qid0 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Commissions_MerchantTable__2qid0 td,
.Commissions_MerchantTable__2qid0 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Commissions_MerchantTable__2qid0 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Commissions_MerchantTable__2qid0 td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Commissions_RevealMoreHeader__2kcwB {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Commissions_Row__3FtvJ {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Commissions_Row__3FtvJ>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Commissions_dataTables_paginate__23soe {
    float: right;
}

.Commissions_dataTables_paginate__23soe {
    /* margin-top: 25px !important; */
}

div.Commissions_dataTables_paginate__23soe {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Commissions_dataTables_paginate__23soe ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Commissions_dataTables_paginate__23soe ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Commissions_dataTables_paginate__23soe ul>li.Commissions_disabled__11PMY a {
    opacity: .5;
}

.Commissions_dataTables_paginate__23soe ul>li.Commissions_disabled__11PMY a {
    opacity: .35;
}

.Commissions_dataTables_paginate__23soe ul>li.Commissions_active__1yFtN>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Commissions_dataTables_paginate__23soe ul>li.Commissions_next__3dQ5x>a,
.Commissions_dataTables_paginate__23soe ul>li.Commissions_prev__234yx>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commissions_pg_arrow_right__3iGrG {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commissions_pg_arrow_left__YR0jr {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commissions_pg_arrow_right__3iGrG:before {
    content: "\E631";
}

.Commissions_pg_arrow_left__YR0jr:before {
    content: "\E629";
}

.Commissions_btn__2-pgY {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Commissions_btn_primary__25a4V,
.Commissions_btn_primary__25a4V:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Commissions_btn_cons__W7ceN {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Commissions_MerchantRow__3lb3I {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Commissions_MerchantRow__3lb3I:hover {
    background-color: #267788;
    color: #fff;

}

.Commissions_MerchantRow__3lb3I:hover a {
    color: #fff;
}

.Commissions_MerchantRowExpanded__3wUOT {
    background: #267788;
    color: #fff;
}

.Commissions_table__2axUO.Commissions_table-condensed__22p0M.Commissions_table-detailed__3uAbV>tbody>tr.Commissions_shown__2tq0O>td {
    background: #fdf5df;
}

.Commissions_EnableMerchant__8M5TS {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Commissions_EnableMerchantHeader__3mEHz {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Commissions_EnableMerchantBody__KCgER {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}


.Commissions_MoneyTotalLabel__xVg8K {
    /* float: right; */
    font-weight: 600;
}

.Commissions_MoneyTotal__11uZj {
    font-weight: 600;
}
.Withdrawals_Container__1aA7s {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Withdrawals_Tools__SmpDv {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Withdrawals_PageFilter__3UARy {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Withdrawals_ToolsWrapper__sBKoB {
    height: 6rem;
    width: 100%;

}

.Withdrawals_TabHeader__12w8H {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Withdrawals_Header__2rU_F {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Withdrawals_ExportReport__3I6mh {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Withdrawals_ExportIconWrapper__2L9Ng {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Withdrawals_EmailIconWrapper__2PQGG {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Withdrawals_PrintToolsWrapper__2qFDs {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Withdrawals_AddNewMerchant__10eUZ {
    /* // font-family: Cambay; */
}

.Withdrawals_AddNewMerchantBtn__2CC7x {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Withdrawals_AddNewMerchantBtn__2CC7x:focus,
.Withdrawals_AddNewMerchantBtn__2CC7x:hover,
.Withdrawals_AddNewMerchantBtn__2CC7x:active {
    background-color: rgb(51, 137, 255);
}


.Withdrawals_ToolsDataExportIconWrapper__2E5oX {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Withdrawals_ToolsPrintIconWrapper__3T760 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Withdrawals_FilterInput__1uzph {
    margin-right: 2rem;
}

.Withdrawals_FormGroup__2dGbG {
    /* margin-bottom: 10px; */
}

.Withdrawals_FormGroupDefault__3qgjG.Withdrawals_has-error__3-hzw {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Withdrawals_FormGroupDefault__3qgjG {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Withdrawals_FormGroupDefault__3qgjG label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Withdrawals_FormGroup__2dGbG label:not(.Withdrawals_error__ui-aS) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Withdrawals_FormControl__2P0n6 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Withdrawals_MerchantTableWrapper__2d1KA {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Withdrawals_TableWrapper__1NkHy {
    display: flex;
}

.Withdrawals_MerchantTable__2o1ym {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Withdrawals_MerchantTable__2o1ym th {
    text-align: left;
}

.Withdrawals_MerchantTable__2o1ym th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Withdrawals_MerchantTable__2o1ym td,
.Withdrawals_MerchantTable__2o1ym th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Withdrawals_MerchantTable__2o1ym td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Withdrawals_MerchantTable__2o1ym td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Withdrawals_RevealMoreHeader__1zUfw {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Withdrawals_Row__2DVfp {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Withdrawals_Row__2DVfp>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Withdrawals_dataTables_paginate__2YXy7 {
    float: right;
}

.Withdrawals_dataTables_paginate__2YXy7 {
    /* margin-top: 25px !important; */
}

div.Withdrawals_dataTables_paginate__2YXy7 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Withdrawals_dataTables_paginate__2YXy7 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Withdrawals_dataTables_paginate__2YXy7 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Withdrawals_dataTables_paginate__2YXy7 ul>li.Withdrawals_disabled__1nO6D a {
    opacity: .5;
}

.Withdrawals_dataTables_paginate__2YXy7 ul>li.Withdrawals_disabled__1nO6D a {
    opacity: .35;
}

.Withdrawals_dataTables_paginate__2YXy7 ul>li.Withdrawals_active__20yKd>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Withdrawals_dataTables_paginate__2YXy7 ul>li.Withdrawals_next__3TCss>a,
.Withdrawals_dataTables_paginate__2YXy7 ul>li.Withdrawals_prev__34G-G>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Withdrawals_pg_arrow_right__2Hh4B {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Withdrawals_pg_arrow_left__3FkeS {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Withdrawals_pg_arrow_right__2Hh4B:before {
    content: "\E631";
}

.Withdrawals_pg_arrow_left__3FkeS:before {
    content: "\E629";
}

.Withdrawals_btn__1i1zZ {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Withdrawals_btn_primary__2R1hD,
.Withdrawals_btn_primary__2R1hD:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Withdrawals_btn_cons__3ftcv {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Withdrawals_MerchantRow__3F1gv {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Withdrawals_MerchantRow__3F1gv:hover {
    background-color: #267788;
    color: #fff;

}

.Withdrawals_MerchantRow__3F1gv:hover a {
    color: #fff;
}

.Withdrawals_MerchantRowExpanded__2GxE2 {
    background: #267788;
    color: #fff;
}

.Withdrawals_table__3pAbU.Withdrawals_table-condensed__2GSD0.Withdrawals_table-detailed__1cvMh>tbody>tr.Withdrawals_shown__2JUwY>td {
    background: #fdf5df;
}

.Withdrawals_EnableMerchant__RPcXX {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Withdrawals_EnableMerchantHeader__15W9v {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Withdrawals_EnableMerchantBody__3tKQ- {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Transfers_Container__3IG5i {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Transfers_Tools__1dZTc {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Transfers_PageFilter__iRUVW {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Transfers_ToolsWrapper__3wHuW {
    height: 6rem;
    width: 100%;

}

.Transfers_TabHeader__2vZkW {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Transfers_Header__2xn3l {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Transfers_ExportReport__usSy_ {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Transfers_ExportIconWrapper__2miCK {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Transfers_EmailIconWrapper__12HDV {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Transfers_PrintToolsWrapper__82vmO {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Transfers_AddNewMerchant__QBLKH {
    /* // font-family: Cambay; */
}

.Transfers_AddNewMerchantBtn__QjIu9 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Transfers_AddNewMerchantBtn__QjIu9:focus,
.Transfers_AddNewMerchantBtn__QjIu9:hover,
.Transfers_AddNewMerchantBtn__QjIu9:active {
    background-color: rgb(51, 137, 255);
}


.Transfers_ToolsDataExportIconWrapper__3qdTd {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Transfers_ToolsPrintIconWrapper__2jFZe {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Transfers_FilterInput__4AuTm {
    margin-right: 2rem;
}

.Transfers_FormGroup__Ora6L {
    /* margin-bottom: 10px; */
}

.Transfers_FormGroupDefault__Qk8hS.Transfers_has-error__1vCWp {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Transfers_FormGroupDefault__Qk8hS {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Transfers_FormGroupDefault__Qk8hS label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Transfers_FormGroup__Ora6L label:not(.Transfers_error__1Bo4v) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Transfers_FormControl__1K32e {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Transfers_MerchantTableWrapper__3Bp4D {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Transfers_TableWrapper__26YJM {
    display: flex;
}

.Transfers_MerchantTable__1Z7ST {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Transfers_MerchantTable__1Z7ST th {
    text-align: left;
}

.Transfers_MerchantTable__1Z7ST th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Transfers_MerchantTable__1Z7ST td,
.Transfers_MerchantTable__1Z7ST th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Transfers_MerchantTable__1Z7ST td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Transfers_MerchantTable__1Z7ST td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Transfers_RevealMoreHeader__3YXqI {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Transfers_Row__3ELvZ {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Transfers_Row__3ELvZ>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Transfers_dataTables_paginate__3gnUG {
    float: right;
}

.Transfers_dataTables_paginate__3gnUG {
    /* margin-top: 25px !important; */
}

div.Transfers_dataTables_paginate__3gnUG {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Transfers_dataTables_paginate__3gnUG ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Transfers_dataTables_paginate__3gnUG ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Transfers_dataTables_paginate__3gnUG ul>li.Transfers_disabled__1BvDA a {
    opacity: .5;
}

.Transfers_dataTables_paginate__3gnUG ul>li.Transfers_disabled__1BvDA a {
    opacity: .35;
}

.Transfers_dataTables_paginate__3gnUG ul>li.Transfers_active__3fQlV>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Transfers_dataTables_paginate__3gnUG ul>li.Transfers_next__LZafe>a,
.Transfers_dataTables_paginate__3gnUG ul>li.Transfers_prev__2l_LE>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transfers_pg_arrow_right__w4ob- {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transfers_pg_arrow_left__3WAjl {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transfers_pg_arrow_right__w4ob-:before {
    content: "\E631";
}

.Transfers_pg_arrow_left__3WAjl:before {
    content: "\E629";
}

.Transfers_btn__1-oT6 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Transfers_btn_primary__1T6fN,
.Transfers_btn_primary__1T6fN:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Transfers_btn_cons__2WMPA {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Transfers_MerchantRow__2gegE {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Transfers_MerchantRow__2gegE:hover {
    background-color: #267788;
    color: #fff;

}

.Transfers_MerchantRow__2gegE:hover a {
    color: #fff;
}

.Transfers_MerchantRowExpanded__1o1Al {
    background: #267788;
    color: #fff;
}

.Transfers_table__7tpnh.Transfers_table-condensed__1KDU5.Transfers_table-detailed__l8vMi>tbody>tr.Transfers_shown__2oXG1>td {
    background: #fdf5df;
}

.Transfers_EnableMerchant__15uWn {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Transfers_EnableMerchantHeader__1eYPP {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Transfers_EnableMerchantBody__5gW2v {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Sales_Container__1E-3r {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Sales_TabHeader__3vbLT {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Sales_Header__3JrJD {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Sales_ExportReport__2oeeK {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Sales_ExportIconWrapper__25D7u {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Sales_EmailIconWrapper__3ZbjU {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Sales_Tools__3LiCW {
    display: flex;
    /* margin: 0 2.5rem; */
    /* padding-top: 1.4rem; */
}

.Sales_PageFilter__nLwDd {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.Sales_ToolsWrapper__30BCY {
    height: 6rem;
    width: 100%;

}

.Sales_PrintToolsWrapper__55Bpr {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Sales_AddNewMerchant__1_V6C {
    /* // font-family: Cambay; */
}

.Sales_FilterReportBtn__28wpK {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Sales_AddNewMerchantBtn__2ysw3:focus,
.Sales_AddNewMerchantBtn__2ysw3:hover,
.Sales_AddNewMerchantBtn__2ysw3:active {
    background-color: rgb(51, 137, 255);
}


.Sales_ToolsDataExportIconWrapper__1AYoB {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Sales_ToolsPrintIconWrapper__2OdtQ {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Sales_FilterInput__2l4ls {
    margin-right: 2rem;
}

.Sales_FormGroup__2vbVx {
    /* margin-bottom: 10px; */
}

.Sales_FormGroupDefault__3Oi6T.Sales_has-error__2MMJA {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Sales_FormGroupDefault__3Oi6T {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Sales_FormGroupDefault__3Oi6T label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Sales_FormGroup__2vbVx label:not(.Sales_error__2P4Ow) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Sales_FormControl__3Movq {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Sales_MerchantTableWrapper__1l6Vp {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Sales_TableWrapper__6xfCm {
    display: flex;
}

.Sales_MerchantTable__1BWiN {
    width: 100%;
    border-collapse: collapse;
}

.Sales_MerchantTable__1BWiN th {
    text-align: left;
}

.Sales_MerchantTable__1BWiN th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Sales_MerchantTable__1BWiN td,
.Sales_MerchantTable__1BWiN th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Sales_RevealMoreHeader__32mgf {
    padding: 0;
    vertical-align: middle;
}





.Sales_Row__UzjKj {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Sales_Row__UzjKj>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Sales_dataTables_paginate__27BkF {
    float: right;
}

.Sales_dataTables_paginate__27BkF {
    /* margin-top: 25px !important; */
}

div.Sales_dataTables_paginate__27BkF {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Sales_dataTables_paginate__27BkF ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Sales_dataTables_paginate__27BkF ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Sales_dataTables_paginate__27BkF ul>li.Sales_disabled__KqOpD a {
    opacity: .5;
}

.Sales_dataTables_paginate__27BkF ul>li.Sales_disabled__KqOpD a {
    opacity: .35;
}

.Sales_dataTables_paginate__27BkF ul>li.Sales_active__DUjxk>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Sales_dataTables_paginate__27BkF ul>li.Sales_next__2XoQg>a,
.Sales_dataTables_paginate__27BkF ul>li.Sales_prev__288sl>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Sales_pg_arrow_right__2CDSA {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Sales_pg_arrow_left__3elSb {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Sales_pg_arrow_right__2CDSA:before {
    content: "\E631";
}

.Sales_pg_arrow_left__3elSb:before {
    content: "\E629";
}

.Sales_btn__17AcG {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Sales_btn_primary__3Y93v,
.Sales_btn_primary__3Y93v:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Sales_btn_cons__2437X {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Sales_MerchantName__2BZf0 {
    font-weight: 500;
}

.Sales_Icn___SRFx {
    cursor: pointer;
    /* fill:#000; */
}

.Sales_Icn___SRFx:hover {
    cursor: pointer;

}



.Sales_RevealMore__2fEQc {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Sales_RevealMore__2fEQc:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Sales_RevealMore__2fEQc div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Sales_RevealMoreExpanded__2b0_5 {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Sales_Expandabled__2x_rQ {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Sales_Expandabled__2x_rQ td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Sales_MerchantTable__1BWiN td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Sales_MerchantTable__1BWiN td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Sales_MerchantRow__qhH_U {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Sales_MerchantRow__qhH_U:hover {
    background-color: #267788;
    color: #fff;

}

.Sales_MerchantRow__qhH_U:hover a {
    color: #fff;
}

.Sales_MerchantRowExpanded__28LWN {
    background: #267788;
    color: #fff;
}

.Sales_table__3EqiR.Sales_table-condensed__204uM.Sales_table-detailed__1N2mf>tbody>tr.Sales_shown__2RYOt>td {
    background: #fdf5df;
}


.Sales_Totals__1kqOq td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Sales_MoneyTotalLabel__3gWgO {
    float: right;

}

.Sales_MoneyTotal__11ScG {
    font-weight: 600;
}
.UserList_Container__1WIEK {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.UserList_Tools__7o6XK {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.UserList_PageFilter__38SGH {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.UserList_ToolsWrapper__30ldk {
    height: 6rem;
    width: 100%;

}

.UserList_PrintToolsWrapper__sDTVU {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.UserList_AddNewMerchant__27xyn {
    /* // font-family: Cambay; */
}

.UserList_AddNewMerchantBtn__2BuVB {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.UserList_AddNewMerchantBtn__2BuVB:focus,
.UserList_AddNewMerchantBtn__2BuVB:hover,
.UserList_AddNewMerchantBtn__2BuVB:active {
    background-color: rgb(51, 137, 255);
}


.UserList_ToolsDataExportIconWrapper__WJT_V {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.UserList_ToolsPrintIconWrapper__3FCFd {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.UserList_FilterInput__1DagD {
    margin-right: 2rem;
}

.UserList_FormGroup___ygG_ {
    /* margin-bottom: 10px; */
}

.UserList_FormGroupDefault__346iG.UserList_has-error__1OVGh {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.UserList_FormGroupDefault__346iG {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.UserList_FormGroupDefault__346iG label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.UserList_FormGroup___ygG_ label:not(.UserList_error__jaVz0) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.UserList_FormControl__2Ejx0 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.UserList_MerchantTableWrapper__F8pb1 {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.UserList_TableWrapper__x5sd2 {
    /* margin: 0 2.5rem; */
}

.UserList_MerchantTable__uTsqw {
    width: 100%;
    border-collapse: collapse;
}

.UserList_MerchantTable__uTsqw th {
    text-align: left;
}

.UserList_MerchantTable__uTsqw th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.UserList_MerchantTable__uTsqw td,
.UserList_MerchantTable__uTsqw th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.UserList_MerchantTable__uTsqw td:last-child {

    border-right: 1px solid #e8e8e8;
}


.UserList_MerchantTable__uTsqw td:first-child {

    border-left: 1px solid #e8e8e8;
}

.UserList_RevealMoreHeader__1hD5W {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.UserList_Row__2T52H {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.UserList_Row__2T52H>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.UserList_dataTables_paginate__3rDYd {
    float: right;
}

.UserList_dataTables_paginate__3rDYd {
    /* margin-top: 25px !important; */
}

div.UserList_dataTables_paginate__3rDYd {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.UserList_dataTables_paginate__3rDYd ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.UserList_dataTables_paginate__3rDYd ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.UserList_dataTables_paginate__3rDYd ul>li.UserList_disabled__3H1cG a {
    opacity: .5;
}

.UserList_dataTables_paginate__3rDYd ul>li.UserList_disabled__3H1cG a {
    opacity: .35;
}

.UserList_dataTables_paginate__3rDYd ul>li.UserList_active__OAlcW>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.UserList_dataTables_paginate__3rDYd ul>li.UserList_next__3mtU0>a,
.UserList_dataTables_paginate__3rDYd ul>li.UserList_prev__nMERf>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.UserList_pg_arrow_right__1Znnp {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.UserList_pg_arrow_left__3WNiB {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.UserList_pg_arrow_right__1Znnp:before {
    content: "\E631";
}

.UserList_pg_arrow_left__3WNiB:before {
    content: "\E629";
}

.UserList_btn__1anEP {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.UserList_btn_primary__3VVrN,
.UserList_btn_primary__3VVrN:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.UserList_btn_cons__1dYqK {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.UserList_MerchantRow__T_wxo {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.UserList_MerchantRow__T_wxo:hover {
    background-color: #267788;
    color: #fff;

}

.UserList_MerchantRow__T_wxo:hover a {
    color: #fff;
}

.UserList_MerchantRowExpanded__Y91JC {
    background: #267788;
    color: #fff;
}

.UserList_table__2DEUh.UserList_table-condensed__2ZzmQ.UserList_table-detailed__2HF6X>tbody>tr.UserList_shown__SvePV>td {
    background: #fdf5df;
}
.Users_Container__3v4Ho {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Users_BreadcrumbWrapper__2kzxk {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Users_Content__Iu2Ul {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Users_Alert__GAlZn {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Users_Breadcrumb__QcklJ {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Users_Header__1rnh6 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Users_ByLine__2gTvh {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Users_Activity__PyOl8 {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Users_WelcomeContainer__3Daxu {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Users_MerchantProfile__juIn6 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}




.Users_TabHeader__3tSmy {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Users_PageHeader__T2D-f {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Users_AddUser__18LTA {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Users_AddUserBtn__20__z {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.Users_ToolsExportIconWrapper__3hzq5 {
    display: inline-block;
    margin-left: 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;
}
.UserList_Container__u0py5 {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.UserList_Tools__2TRKs {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.UserList_PageFilter__gFjeF {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.UserList_ToolsWrapper__niSp5 {
    height: 6rem;
    width: 100%;

}

.UserList_PrintToolsWrapper__1_-7N {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.UserList_AddNewMerchant__2auQx {
    /* // font-family: Cambay; */
}

.UserList_AddNewMerchantBtn__3a9ba {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.UserList_AddNewMerchantBtn__3a9ba:focus,
.UserList_AddNewMerchantBtn__3a9ba:hover,
.UserList_AddNewMerchantBtn__3a9ba:active {
    background-color: rgb(51, 137, 255);
}


.UserList_ToolsDataExportIconWrapper__1dXS8 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.UserList_ToolsPrintIconWrapper__10gDw {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.UserList_FilterInput__38kvI {
    margin-right: 2rem;
}

.UserList_FormGroup__SSDU5 {
    /* margin-bottom: 10px; */
}

.UserList_FormGroupDefault__2bHI0.UserList_has-error__3rOif {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.UserList_FormGroupDefault__2bHI0 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.UserList_FormGroupDefault__2bHI0 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.UserList_FormGroup__SSDU5 label:not(.UserList_error__fJVHt) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.UserList_FormControl__3yzDA {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.UserList_MerchantTableWrapper__2pmvR {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.UserList_TableWrapper__1isE7 {
    /* margin: 0 2.5rem; */
}

.UserList_MerchantTable__1E4kp {
    width: 100%;
    border-collapse: collapse;
}

.UserList_MerchantTable__1E4kp th {
    text-align: left;
}

.UserList_MerchantTable__1E4kp th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.UserList_MerchantTable__1E4kp td,
.UserList_MerchantTable__1E4kp th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.UserList_MerchantTable__1E4kp td:last-child {

    border-right: 1px solid #e8e8e8;
}


.UserList_MerchantTable__1E4kp td:first-child {

    border-left: 1px solid #e8e8e8;
}

.UserList_RevealMoreHeader__2Ad4s {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.UserList_Row__Lktkk {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.UserList_Row__Lktkk>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.UserList_dataTables_paginate__Oc9S5 {
    float: right;
}

.UserList_dataTables_paginate__Oc9S5 {
    /* margin-top: 25px !important; */
}

div.UserList_dataTables_paginate__Oc9S5 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.UserList_dataTables_paginate__Oc9S5 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.UserList_dataTables_paginate__Oc9S5 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.UserList_dataTables_paginate__Oc9S5 ul>li.UserList_disabled__1n4FR a {
    opacity: .5;
}

.UserList_dataTables_paginate__Oc9S5 ul>li.UserList_disabled__1n4FR a {
    opacity: .35;
}

.UserList_dataTables_paginate__Oc9S5 ul>li.UserList_active__2q9yT>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.UserList_dataTables_paginate__Oc9S5 ul>li.UserList_next__edrKa>a,
.UserList_dataTables_paginate__Oc9S5 ul>li.UserList_prev__3aZhw>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.UserList_pg_arrow_right__2CfgR {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.UserList_pg_arrow_left__Adqg1 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.UserList_pg_arrow_right__2CfgR:before {
    content: "\E631";
}

.UserList_pg_arrow_left__Adqg1:before {
    content: "\E629";
}

.UserList_btn__u6pWQ {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.UserList_btn_primary__3wHqY,
.UserList_btn_primary__3wHqY:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.UserList_btn_cons__29joU {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.UserList_MerchantRow__qOfKu {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.UserList_MerchantRow__qOfKu:hover {
    background-color: #267788;
    color: #fff;

}

.UserList_MerchantRow__qOfKu:hover a {
    color: #fff;
}

.UserList_MerchantRowExpanded__2bhyc {
    background: #267788;
    color: #fff;
}

.UserList_table__1KeZq.UserList_table-condensed__2GUk7.UserList_table-detailed__1Pn43>tbody>tr.UserList_shown__1ERZh>td {
    background: #fdf5df;
}
.Users_Container__1TmC8 {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Users_BreadcrumbWrapper__3y4r7 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Users_Content__btVU1 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Users_Alert__3Hkmq {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Users_Breadcrumb__3LXKz {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Users_Header__qtMhg {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Users_ByLine__2WM02 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Users_Activity__1KDck {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Users_WelcomeContainer__1LDqG {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Users_MerchantProfile__1Ts_0 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}




.Users_TabHeader__20TF0 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Users_PageHeader__1_qp_ {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Users_AddUser__2I1Av {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Users_AddUserBtn__1Ozl_ {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.Users_ToolsExportIconWrapper__GC5l1 {
    display: inline-block;
    margin-left: 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;
}
.LoginForm_FullHeight__iaVzD {
    height: 100% !important;
}

.LoginForm_LoginContainer__322Q3 {
    width: 40rem;;
    margin: auto;
    height: 100%;
    margin-top: 7.2rem;
}

.LoginForm_LoginWrapper__BAF0m {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    margin-top: 7.2rem;
}

.LoginForm_Logo__3boIQ {
    background-color: transparent;
    padding: 0;
    height: 4rem;
    box-sizing: border-box;
}

.LoginForm_Logo__3boIQ {
    display: inline-block;
    font-size: 24px;
    line-height: 24px;
    vertical-align: middle;
}

.LoginForm_PaddingTop40__3G7bz {
    padding-top: 4rem;
}

.LoginForm_PaddingTop15__2lgIw {
    padding-top: 1.5rem;
}

.LoginForm_FormGroup__2ukUt {
    margin-bottom: 1.5rem;
}

.LoginForm_FormGroupDefault__2vOjh.LoginForm_has-error__3xZ9- {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.LoginForm_FormGroupDefault__2vOjh {
    background-color: #fff;
    position: relative;
    border: 1px solid rgba(0, 0, 0, .07);
    border-radius: 2px;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;
    overflow: hidden;
    width: 100%;
    transition: background-color .2s ease;
}

label,
input,
button,
select,
textarea {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.LoginForm_FormGroupDefault__2vOjh label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity .2s ease;
}

.LoginForm_FormGroup__2ukUt label:not(.LoginForm_error__C3ulO) {
    font-size: 10.5px;
    letter-spacing: .06em;
    text-transform: uppercase;
    font-weight: 500;
}

.LoginForm_FormControl__36fGe {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.LoginForm_FormControl__36fGe {
    background-color: #fff;
    background-image: none;
    border: 1px solid rgba(0, 0, 0, .07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    padding: 8px 12px;
    line-height: normal;
    font-size: 14px;
    font-weight: 400;
    vertical-align: middle;
    min-height: 35px;
    transition: all .12s ease;
    box-shadow: none;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    transition: background .2s linear 0s;
}

.LoginForm_FormGroupDefault__2vOjh .LoginForm_FormControl__36fGe {
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: 0 0;
}

.LoginForm_Error__2jy77 {
    font-size: 12px;
    color: #f35958;
    display: block;
}

.LoginForm_HasError__3CrHz .LoginForm_FormControl__36fGe {
    border-color: #f35958;
    box-shadow: none;
}



.LoginForm_Row__1xQnb {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

form .LoginForm_Row__1xQnb {
    margin-left: 0;
    margin-right: 0;
}

.LoginForm_SignIn__1TOwm {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 !important;
}

.LoginForm_Help__19aE3 {
    display: flex;
    flex: 0 0 50%;
    max-width: 50%;
    justify-content: flex-end;
    align-items: center!important;
    padding-left: 7px;
    padding-right: 0;
}

.LoginForm_Radio__3soEb label:before, .LoginForm_Checkbox__2aG7r label:before {
    box-sizing: border-box;
    content: "";
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    background-color: #fff;
    border: 1px solid #cdcdcd;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.LoginForm_Checkbox__2aG7r label:before {
    top: 1.4px;
    border-radius: 3px;
    transition: border .2s linear 0s,color .2s linear 0s;
}

label, input, button, select, textarea {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.LoginForm_Radio__3soEb, .LoginForm_Checkbox__2aG7r {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 0;
}

.LoginForm_Checkbox__2aG7r label {
    transition: border .2s linear 0s,color .2s linear 0s;
}

.LoginForm_Radio__3soEb label, .LoginForm_Checkbox__2aG7r label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    min-width: 17px;
    min-height: 17px;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

label, input, button, select, textarea {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}


input[type=radio], input[type=checkbox] {
    margin-top: 1px 0 0;
    line-height: normal;
    cursor: pointer;
    box-sizing: border-box;
}



input[type=radio], input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
}

.LoginForm_Checkbox__2aG7r input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0;
}

input, input:focus {
    -webkit-transition: none!important;
}

.LoginForm_ButtonPrimary__qeWCt {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .01em;
    -webkit-font-smoothing: antialiased;
    margin-top: 10px;
    margin-bottom: 0;
    margin-right: 5px;
    min-width: 120px;
    border: 1px solid #e7e7e7;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-border-radius: 3px;
    background-image: none!important;
    color: #fff;
    background-color: #6d5eac;
    border-color: #6d5eac;
    text-shadow: none;
    box-shadow: none;
    line-height: 21px;
    position: relative;
    transition: color .1s linear 0s,background-color .1s linear 0s,opacity .2s linear 0s!important;
    padding: 6px 17px;
}

.LoginForm_LoginBtn__1uEaL {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.LoginForm_LoginBtn__1uEaL:focus,
.LoginForm_LoginBtn__1uEaL:hover,
.LoginForm_LoginBtn__1uEaL:active,
.LoginForm_LoginBtn__1uEaL:disabled {
    background-color: #6d5eac;
}

p {
    display: block;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .01em;
    line-height: 22px;
    margin: 0 0 10px;
    font-style: normal;
    white-space: normal;
}
.InventoryList_Container__16EBi {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.InventoryList_Tools__3g85e {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.InventoryList_PageFilter__2fkzS {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.InventoryList_ToolsWrapper__1CwJ8 {
    height: 6rem;
    width: 100%;

}

.InventoryList_PrintToolsWrapper__3u2vB {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.InventoryList_AddNewMerchant__2Nkn8 {
    /* // font-family: Cambay; */
}

.InventoryList_AddNewMerchantBtn__kw1Fi {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.InventoryList_AddNewMerchantBtn__kw1Fi:focus,
.InventoryList_AddNewMerchantBtn__kw1Fi:hover,
.InventoryList_AddNewMerchantBtn__kw1Fi:active {
    background-color: rgb(51, 137, 255);
}


.InventoryList_ToolsDataExportIconWrapper__3f7fD {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.InventoryList_ToolsPrintIconWrapper__2QaRN {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.InventoryList_FilterInput__1YoKA {
    margin-right: 2rem;
}

.InventoryList_FormGroup__1sYXN {
    /* margin-bottom: 10px; */
}

.InventoryList_FormGroupDefault__Sy0C2.InventoryList_has-error__1wWwg {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.InventoryList_FormGroupDefault__Sy0C2 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.InventoryList_FormGroupDefault__Sy0C2 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.InventoryList_FormGroup__1sYXN label:not(.InventoryList_error__2UF1T) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.InventoryList_FormControl__22sJ8 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.InventoryList_MerchantTableWrapper__2dOAR {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.InventoryList_TableWrapper__2mxPY {
    display: flex;
}

.InventoryList_MerchantTable__2bs9W {
    width: 100%;
    border-collapse: collapse;
}

.InventoryList_MerchantTable__2bs9W th {
    text-align: left;
}

.InventoryList_MerchantTable__2bs9W th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.InventoryList_MerchantTable__2bs9W td,
.InventoryList_MerchantTable__2bs9W th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.InventoryList_MerchantTable__2bs9W td:last-child {

    border-right: 1px solid #e8e8e8;
}


.InventoryList_MerchantTable__2bs9W td:first-child {

    border-left: 1px solid #e8e8e8;
}

.InventoryList_RevealMoreHeader__3lvlv {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.InventoryList_Row__1yWqW {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.InventoryList_Row__1yWqW>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.InventoryList_dataTables_paginate__cne-S {
    float: right;
}

.InventoryList_dataTables_paginate__cne-S {
    /* margin-top: 25px !important; */
}

div.InventoryList_dataTables_paginate__cne-S {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.InventoryList_dataTables_paginate__cne-S ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.InventoryList_dataTables_paginate__cne-S ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.InventoryList_dataTables_paginate__cne-S ul>li.InventoryList_disabled__4Oz5N a {
    opacity: .5;
}

.InventoryList_dataTables_paginate__cne-S ul>li.InventoryList_disabled__4Oz5N a {
    opacity: .35;
}

.InventoryList_dataTables_paginate__cne-S ul>li.InventoryList_active__3VpHa>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.InventoryList_dataTables_paginate__cne-S ul>li.InventoryList_next__FT9yj>a,
.InventoryList_dataTables_paginate__cne-S ul>li.InventoryList_prev__1KGz9>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.InventoryList_pg_arrow_right__2gQdv {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.InventoryList_pg_arrow_left__kXjZL {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.InventoryList_pg_arrow_right__2gQdv:before {
    content: "\E631";
}

.InventoryList_pg_arrow_left__kXjZL:before {
    content: "\E629";
}

.InventoryList_btn__3DXsJ {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.InventoryList_btn_primary__2Cscv,
.InventoryList_btn_primary__2Cscv:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.InventoryList_btn_cons__1mGf0 {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.InventoryList_MerchantRow__p6WcO {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.InventoryList_MerchantRow__p6WcO:hover {
    background-color: #267788;
    color: #fff;

}

.InventoryList_MerchantRow__p6WcO:hover a {
    color: #fff;
}

.InventoryList_MerchantRowExpanded__1SDUl {
    background: #267788;
    color: #fff;
}

.InventoryList_table__2aoiW.InventoryList_table-condensed__2ECrs.InventoryList_table-detailed__2frDn>tbody>tr.InventoryList_shown__3mIqp>td {
    background: #fdf5df;
}
.SIMCard_Container__mPpWX {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.SIMCard_Tools__1TDCH {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.SIMCard_PageFilter__py-_Q {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.SIMCard_ToolsWrapper__3JVjt {
    height: 6rem;
    width: 100%;

}

.SIMCard_PrintToolsWrapper__9U0d4 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.SIMCard_AddNewMerchant__2njY3 {
    /* // font-family: Cambay; */
}

.SIMCard_AddNewMerchantBtn__6xPe0 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.SIMCard_AddNewMerchantBtn__6xPe0:focus,
.SIMCard_AddNewMerchantBtn__6xPe0:hover,
.SIMCard_AddNewMerchantBtn__6xPe0:active {
    background-color: rgb(51, 137, 255);
}


.SIMCard_ToolsDataExportIconWrapper__1X0qj {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.SIMCard_ToolsPrintIconWrapper__1Zf3Y {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.SIMCard_FilterInput__20sYD {
    margin-right: 2rem;
}

.SIMCard_FormGroup__3DP99 {
    /* margin-bottom: 10px; */
}

.SIMCard_FormGroupDefault__2Tq2-.SIMCard_has-error__qDR-m {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.SIMCard_FormGroupDefault__2Tq2- {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.SIMCard_FormGroupDefault__2Tq2- label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.SIMCard_FormGroup__3DP99 label:not(.SIMCard_error__1qbfK) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.SIMCard_FormControl__WpTD2 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.SIMCard_MerchantTableWrapper__ZejBH {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.SIMCard_TableWrapper__zqpdJ {
    /* margin: 0 2.5rem; */
}

.SIMCard_MerchantTable__33Wan {
    width: 100%;
    border-collapse: collapse;
}

.SIMCard_MerchantTable__33Wan th {
    text-align: left;
}

.SIMCard_MerchantTable__33Wan th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.SIMCard_MerchantTable__33Wan td,
.SIMCard_MerchantTable__33Wan th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.SIMCard_MerchantTable__33Wan td:last-child {

    border-right: 1px solid #e8e8e8;
}


.SIMCard_MerchantTable__33Wan td:first-child {

    border-left: 1px solid #e8e8e8;
}

.SIMCard_RevealMoreHeader__1XO7p {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.SIMCard_Row__lTpXl {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.SIMCard_Row__lTpXl>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.SIMCard_dataTables_paginate__2SEbt {
    float: right;
}

.SIMCard_dataTables_paginate__2SEbt {
    /* margin-top: 25px !important; */
}

div.SIMCard_dataTables_paginate__2SEbt {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.SIMCard_dataTables_paginate__2SEbt ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.SIMCard_dataTables_paginate__2SEbt ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.SIMCard_dataTables_paginate__2SEbt ul>li.SIMCard_disabled__hNGgz a {
    opacity: .5;
}

.SIMCard_dataTables_paginate__2SEbt ul>li.SIMCard_disabled__hNGgz a {
    opacity: .35;
}

.SIMCard_dataTables_paginate__2SEbt ul>li.SIMCard_active__3ebg3>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.SIMCard_dataTables_paginate__2SEbt ul>li.SIMCard_next__1oYGs>a,
.SIMCard_dataTables_paginate__2SEbt ul>li.SIMCard_prev__39Dhd>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.SIMCard_pg_arrow_right__26jRG {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.SIMCard_pg_arrow_left__3N-1P {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.SIMCard_pg_arrow_right__26jRG:before {
    content: "\E631";
}

.SIMCard_pg_arrow_left__3N-1P:before {
    content: "\E629";
}

.SIMCard_btn__1YhuL {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.SIMCard_btn_primary__1TGzO,
.SIMCard_btn_primary__1TGzO:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.SIMCard_btn_cons__2AdFr {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.SIMCard_MerchantRow__Wb6kP {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.SIMCard_MerchantRow__Wb6kP:hover {
    background-color: #267788;
    color: #fff;

}

.SIMCard_MerchantRow__Wb6kP:hover a {
    color: #fff;
}

.SIMCard_MerchantRowExpanded__2paOH {
    background: #267788;
    color: #fff;
}

.SIMCard_table__2-yio.SIMCard_table-condensed__kkdCZ.SIMCard_table-detailed__1LorI>tbody>tr.SIMCard_shown__13vhn>td {
    background: #fdf5df;
}
.Devices_Container__XXXhu {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Devices_Tools__t5ZEL {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Devices_PageFilter__3BDQE {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Devices_ToolsWrapper__1R3Aq {
    height: 6rem;
    width: 100%;

}

.Devices_PrintToolsWrapper__3K432 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Devices_AddNewMerchant__DRx0- {
    /* // font-family: Cambay; */
}

.Devices_AddNewMerchantBtn__By8ut {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Devices_AddNewMerchantBtn__By8ut:focus,
.Devices_AddNewMerchantBtn__By8ut:hover,
.Devices_AddNewMerchantBtn__By8ut:active {
    background-color: rgb(51, 137, 255);
}


.Devices_ToolsDataExportIconWrapper__1_WOL {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Devices_ToolsPrintIconWrapper__BOktu {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Devices_FilterInput__2Duyj {
    margin-right: 2rem;
}

.Devices_FormGroup__1SwVL {
    /* margin-bottom: 10px; */
}

.Devices_FormGroupDefault__1pmcA.Devices_has-error__2bSyH {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Devices_FormGroupDefault__1pmcA {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Devices_FormGroupDefault__1pmcA label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Devices_FormGroup__1SwVL label:not(.Devices_error__3OmkW) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Devices_FormControl__3Osct {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Devices_MerchantTableWrapper__vXTY2 {
    margin-top: 2.5rem;
    white-space: nowrap;
}

/* .TableWrapper {
    display: flex;
} */

.Devices_MerchantTable__xaS4Z {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Devices_MerchantTable__xaS4Z th {
    text-align: left;
}

.Devices_MerchantTable__xaS4Z th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Devices_MerchantTable__xaS4Z td,
.Devices_MerchantTable__xaS4Z th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Devices_MerchantTable__xaS4Z td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Devices_MerchantTable__xaS4Z td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Devices_RevealMoreHeader__3ueWA {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Devices_Row__3fUIt {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Devices_Row__3fUIt>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Devices_dataTables_paginate__LYGvM {
    float: right;
}

.Devices_dataTables_paginate__LYGvM {
    /* margin-top: 25px !important; */
}

div.Devices_dataTables_paginate__LYGvM {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Devices_dataTables_paginate__LYGvM ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Devices_dataTables_paginate__LYGvM ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Devices_dataTables_paginate__LYGvM ul>li.Devices_disabled__2afPF a {
    opacity: .5;
}

.Devices_dataTables_paginate__LYGvM ul>li.Devices_disabled__2afPF a {
    opacity: .35;
}

.Devices_dataTables_paginate__LYGvM ul>li.Devices_active__2vlWv>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Devices_dataTables_paginate__LYGvM ul>li.Devices_next__-CknP>a,
.Devices_dataTables_paginate__LYGvM ul>li.Devices_prev__1U6ad>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_right__3gWCt {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_left__1I59M {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_right__3gWCt:before {
    content: "\E631";
}

.Devices_pg_arrow_left__1I59M:before {
    content: "\E629";
}

.Devices_btn__1z-XX {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Devices_btn_primary__3r-4L,
.Devices_btn_primary__3r-4L:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Devices_btn_cons__Ie8Jm {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Devices_MerchantRow__2vbJp {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Devices_MerchantRow__2vbJp:hover {
    background-color: #267788;
    color: #fff;

}

.Devices_MerchantRow__2vbJp:hover a {
    color: #fff;
}

.Devices_MerchantRowExpanded__gqmL8 {
    background: #267788;
    color: #fff;
}

.Devices_table__aOHCZ.Devices_table-condensed__2SBPx.Devices_table-detailed__2YX0L>tbody>tr.Devices_shown__1AUGD>td {
    background: #fdf5df;
}


.Devices_SelectedDevice__3I91y {
    background-color: #267788;
    color: #fff;

}

.Devices_DeviceDetail__KMZmG {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}
/* .Odd {
}

.Even {
} */

.details_MemberName__3BmMk {
    font-weight: 500;
}

.details_Icn__Gol6D {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__Gol6D:hover {
    cursor: pointer;

}

.details_RevealMore__RkHO6 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__RkHO6:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.details_RevealMore__RkHO6 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__1BJ-_ {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .details_Expandabled__19s1o {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__19s1o td {
    padding: 2rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .details_MemberRow__1qcWA {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MemberRow__1qcWA:hover {
    background-color: #267788;
    color:#fff;

    }

    .details_MemberRow__1qcWA:hover a {
        color:#fff;
        }

    .details_MemberRowExpanded__3zFq1 {
        background: #267788;
        color:#fff;
    }

.details_table__3jPcU.details_table-condensed__3Q23o.details_table-detailed__szOQZ > tbody > tr.details_shown__1tJ6o > td {
    background: #fdf5df;
}

.details_Container__3hb4h {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.details_BreadcrumbWrapper__33It5 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.details_Content__1wNra {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.details_CardBody__3DE33 {
    margin-top: 1rem;
}

.details_Alert__EiB-a {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.details_Breadcrumb__3C-Z6 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.details_Header__2YGaJ {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.details_ByLine__-WzzO {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_Activity__3UG9B {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.details_WelcomeContainer__3nSR3 {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.details_MerchantProfile__WcIAB {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.details_TabHeader__2aKhu {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.details_PageHeader__19WVD {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.details_Header__2YGaJ {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.details_ExportReport__3Mh8t {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.details_ExportIconWrapper__KUGs- {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_EmailIconWrapper__36dCr {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_AddUser__3obeq {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.details_AddUserBtn___q2Xz {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_Tools__3VZmh {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.details_PageFilter__2IioS {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}

.details_PrintToolsWrapper__3mhuO {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.details_FilterReportBtn__dAi4i {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.details_AddNewMerchantBtn__775AI:focus,
.details_AddNewMerchantBtn__775AI:hover,
.details_AddNewMerchantBtn__775AI:active {
    background-color: rgb(51, 137, 255);
}

.details_ToolsDataExportIconWrapper__1mo0R {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.details_ToolsPrintIconWrapper__2V7EI {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.details_FilterInput__39fln {
    margin-right: 2rem;
}

.details_FormGroupDefault__MXG7F.details_has-error__185ot {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__MXG7F {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.details_FormGroupDefault__MXG7F label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.details_FormGroup__lZoKC label:not(.details_error__2Xj7R) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.details_FormControl__2uo5g {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.details_MerchantTableWrapper__nJ3u6 {
    margin-top: 3rem;
    white-space: nowrap;
}

.details_MerchantTable__1ex1P {
    width: 100%;
    border-collapse: collapse;
}

.details_MerchantTable__1ex1P th {
    text-align: left;
}

.details_MerchantTable__1ex1P th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.details_MerchantTable__1ex1P td,
.details_MerchantTable__1ex1P th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__3cPRB {
    padding: 0;
    vertical-align: middle;
}

.details_Row__3CLMK {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.details_Row__3CLMK>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.details_dataTables_paginate___2mO- {
    float: right;
}

div.details_dataTables_paginate___2mO- {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.details_dataTables_paginate___2mO- ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

.details_dataTables_paginate___2mO- ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.details_dataTables_paginate___2mO- ul>li.details_disabled__2wL77 a {
    opacity: .5;
}

.details_dataTables_paginate___2mO- ul>li.details_disabled__2wL77 a {
    opacity: .35;
}

.details_dataTables_paginate___2mO- ul>li.details_active__1gTsv>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.details_dataTables_paginate___2mO- ul>li.details_next__1ov3R>a,
.details_dataTables_paginate___2mO- ul>li.details_prev__1jPxT>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__2mdYG {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__1ncPk {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__2mdYG:before {
    content: "\E631";
}

.details_pg_arrow_left__1ncPk:before {
    content: "\E629";
}

.details_btn__2XdSr {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.details_btn_primary__2Z5UN,
.details_btn_primary__2Z5UN:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.details_btn_cons__wUqIU {
    min-width: 120px;
}

.details_MerchantName__12PdM {
    font-weight: 500;
}

.details_Icn__1yQMD {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__1yQMD:hover {
    cursor: pointer;

}



.details_RevealMore__11Mya {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__11Mya:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.details_RevealMore__11Mya div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__27HMa {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.details_Expandabled__1tCt6 {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__1tCt6 td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__1ex1P td:last-child {

    border-right: 1px solid #e8e8e8;
}


.details_MerchantTable__1ex1P td:first-child {

    border-left: 1px solid #e8e8e8;
}


.details_MerchantRow__1EV1b {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MerchantRow__1EV1b:hover {
    background-color: #267788;
    color: #fff;

}

.details_MerchantRow__1EV1b:hover a {
    color: #fff;
}

.details_MerchantRowExpanded__2I4kn {
    background: #267788;
    color: #fff;
}

.details_table__323t-.details_table-condensed__32agy.details_table-detailed__2F-6B>tbody>tr.details_shown__3IELN>td {
    background: #fdf5df;
}


.details_Totals__3J-0k td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.details_MoneyTotalLabel__uU2Mx {
    float: right;

}

.details_FilterWrapper__3d1iS {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.details_FilterPeriods__1qDTy {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.details_FilterPeriods__1qDTy li {
    font-weight: 600;

    padding-left: 2rem;
    line-height: 38px;
}

.details_FinancialsIntro__1-d7z {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_ExpandableView__3ujs_ {
    background-color: transparent;
    /* font-family: Cambay; */
}

.details_Outlets__3f3Io {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    /* margin-left: 0.6rem; */
}

.details_Outlets__3f3Io li {
    display: inline-block;
        list-style: none;
}

.details_Outlet__8nujp {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;}

.details_Outlet__8nujp:hover {
color: #FFF;
    background: #5BA6E6;

}

.details_OutletName__18TlA {
    font-weight: 600;
}

.details_OutletLocation__3pkHo {
    font-weight: 400;

}

.details_ExpandableMember__29dMZ {
    float: left;
    text-decoration: none;
}

.details_ExpandableMemberName__1Qad- {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.details_OutletListArea__2_QP5 {
    width: 100%;
    margin-top: 1rem;
    background: transparent;
}



.details_MemberContractDetailArea__1fmVx {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem 0px;
    background: rgb(255, 255, 255);
}

.details_MemberContractDetailArea__1fmVx header {
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.details_ExpandableMemberWrapper__17RWe {
    background: transparent;
}

.details_clearfix__1I7mB::before,
.details_clearfix__1I7mB::after {
    display:table;
    content: " ";
}

.details_clearfix__1I7mB::after {
clear:both;
}


.details_FuneralSchemeWrapper__16XRm {
    margin: 0 2.5rem;
}

.details_AddNewMember__pdCB- {
float:right;
margin-right: 8.8rem;
}

.details_AddNewMemberBtn__g4RPO {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_FuneralSchemeTable__1NwAq  {
    width: 100%;
    border-collapse: collapse;
}

.details_FuneralSchemeTable__1NwAq th {
    text-align: left;
}

.details_FuneralSchemeTable__1NwAq th {
    color: #666;
    vertical-align: top;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    vertical-align: middle;
}

.details_FuneralSchemeTable__1NwAq td,
.details_FuneralSchemeTable__1NwAq th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_FuneralSchemeTable__1NwAq .details_Totals__3J-0k td {   
     border-bottom: none;
     font-weight: 600;
}

.details_FuneralSchemeTable__1NwAq .details_MoneyTotalLabel__uU2Mx {
    float: right;

}
.details_FuneralSchemeTable__1NwAq .details_MoneyTotal__s9SgY {

}
.Computers_Container__1Q4gE {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Computers_Tools__3zsIC {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Computers_PageFilter__b1CIE {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Computers_ToolsWrapper__1UuAe {
    height: 6rem;
    width: 100%;

}

.Computers_PrintToolsWrapper__3iEKY {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Computers_AddNewMerchant__3LCAO {
    /* // font-family: Cambay; */
}

.Computers_AddNewMerchantBtn__1m6GC {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Computers_AddNewMerchantBtn__1m6GC:focus,
.Computers_AddNewMerchantBtn__1m6GC:hover,
.Computers_AddNewMerchantBtn__1m6GC:active {
    background-color: rgb(51, 137, 255);
}


.Computers_ToolsDataExportIconWrapper__36rY4 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Computers_ToolsPrintIconWrapper__Va2YC {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Computers_FilterInput__2mv88 {
    margin-right: 2rem;
}

.Computers_FormGroup__zvJv1 {
    /* margin-bottom: 10px; */
}

.Computers_FormGroupDefault__1EFeR.Computers_has-error__6u7N8 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Computers_FormGroupDefault__1EFeR {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Computers_FormGroupDefault__1EFeR label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Computers_FormGroup__zvJv1 label:not(.Computers_error__1JCbX) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Computers_FormControl__1W7wO {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Computers_MerchantTableWrapper__20kLm {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Computers_TableWrapper__yehu9 {
    /* margin: 0 2.5rem; */
}

.Computers_MerchantTable__23FHV {
    width: 100%;
    border-collapse: collapse;
}

.Computers_MerchantTable__23FHV th {
    text-align: left;
}

.Computers_MerchantTable__23FHV th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Computers_MerchantTable__23FHV td,
.Computers_MerchantTable__23FHV th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Computers_MerchantTable__23FHV td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Computers_MerchantTable__23FHV td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Computers_RevealMoreHeader__Hg4Xh {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Computers_Row__1zJaG {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Computers_Row__1zJaG>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Computers_dataTables_paginate__1Tz40 {
    float: right;
}

.Computers_dataTables_paginate__1Tz40 {
    /* margin-top: 25px !important; */
}

div.Computers_dataTables_paginate__1Tz40 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Computers_dataTables_paginate__1Tz40 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Computers_dataTables_paginate__1Tz40 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Computers_dataTables_paginate__1Tz40 ul>li.Computers_disabled__2lQtS a {
    opacity: .5;
}

.Computers_dataTables_paginate__1Tz40 ul>li.Computers_disabled__2lQtS a {
    opacity: .35;
}

.Computers_dataTables_paginate__1Tz40 ul>li.Computers_active__2krUZ>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Computers_dataTables_paginate__1Tz40 ul>li.Computers_next__3i7yJ>a,
.Computers_dataTables_paginate__1Tz40 ul>li.Computers_prev__cVAdz>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Computers_pg_arrow_right__3_2Wx {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Computers_pg_arrow_left__1zQCZ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Computers_pg_arrow_right__3_2Wx:before {
    content: "\E631";
}

.Computers_pg_arrow_left__1zQCZ:before {
    content: "\E629";
}

.Computers_btn__3HTPV {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Computers_btn_primary__1mPYa,
.Computers_btn_primary__1mPYa:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Computers_btn_cons__35GSq {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Computers_MerchantRow__WEOP4 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Computers_MerchantRow__WEOP4:hover {
    background-color: #267788;
    color: #fff;

}

.Computers_MerchantRow__WEOP4:hover a {
    color: #fff;
}

.Computers_MerchantRowExpanded__3G2Lq {
    background: #267788;
    color: #fff;
}

.Computers_table__3NSZx.Computers_table-condensed__37wUg.Computers_table-detailed__36Kn4>tbody>tr.Computers_shown__6_fFR>td {
    background: #fdf5df;
}
.ProformaInvoices_Container__2PePM {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ProformaInvoices_Tools__3C8Ax {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.ProformaInvoices_PageFilter__3kvZl {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.ProformaInvoices_ToolsWrapper__3bJde {
    height: 6rem;
    width: 100%;

}

.ProformaInvoices_PrintToolsWrapper__3OwWG {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.ProformaInvoices_AddNewMerchant__2R0Fr {
    /* // font-family: Cambay; */
}

.ProformaInvoices_AddNewMerchantBtn__2Vn1P {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ProformaInvoices_AddNewMerchantBtn__2Vn1P:focus,
.ProformaInvoices_AddNewMerchantBtn__2Vn1P:hover,
.ProformaInvoices_AddNewMerchantBtn__2Vn1P:active {
    background-color: rgb(51, 137, 255);
}


.ProformaInvoices_ToolsDataExportIconWrapper__1EvJ9 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ProformaInvoices_ToolsPrintIconWrapper__32-gO {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ProformaInvoices_FilterInput__FUnfm {
    margin-right: 2rem;
}

.ProformaInvoices_FormGroup__F90Kj {
    /* margin-bottom: 10px; */
}

.ProformaInvoices_FormGroupDefault__3LpfK.ProformaInvoices_has-error__2qF9- {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ProformaInvoices_FormGroupDefault__3LpfK {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ProformaInvoices_FormGroupDefault__3LpfK label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.ProformaInvoices_FormGroup__F90Kj label:not(.ProformaInvoices_error__gj2vd) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ProformaInvoices_FormControl__3CK_F {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ProformaInvoices_MerchantTableWrapper__2OCGj {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.ProformaInvoices_TableWrapper__3Uepc {
    /* margin: 0 2.5rem; */
}

.ProformaInvoices_MerchantTable__3DWCQ {
    width: 100%;
    border-collapse: collapse;
}

.ProformaInvoices_MerchantTable__3DWCQ th {
    text-align: left;
}

.ProformaInvoices_MerchantTable__3DWCQ th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ProformaInvoices_MerchantTable__3DWCQ td,
.ProformaInvoices_MerchantTable__3DWCQ th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.ProformaInvoices_MerchantTable__3DWCQ td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ProformaInvoices_MerchantTable__3DWCQ td:first-child {

    border-left: 1px solid #e8e8e8;
}

.ProformaInvoices_RevealMoreHeader__28UmM {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.ProformaInvoices_Row__3k4OW {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.ProformaInvoices_Row__3k4OW>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ProformaInvoices_dataTables_paginate__2BUaj {
    float: right;
}

.ProformaInvoices_dataTables_paginate__2BUaj {
    /* margin-top: 25px !important; */
}

div.ProformaInvoices_dataTables_paginate__2BUaj {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ProformaInvoices_dataTables_paginate__2BUaj ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ProformaInvoices_dataTables_paginate__2BUaj ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.ProformaInvoices_dataTables_paginate__2BUaj ul>li.ProformaInvoices_disabled__2_DE- a {
    opacity: .5;
}

.ProformaInvoices_dataTables_paginate__2BUaj ul>li.ProformaInvoices_disabled__2_DE- a {
    opacity: .35;
}

.ProformaInvoices_dataTables_paginate__2BUaj ul>li.ProformaInvoices_active__3a1WE>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ProformaInvoices_dataTables_paginate__2BUaj ul>li.ProformaInvoices_next__MK_La>a,
.ProformaInvoices_dataTables_paginate__2BUaj ul>li.ProformaInvoices_prev__3B1mB>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ProformaInvoices_pg_arrow_right__2Ez0K {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ProformaInvoices_pg_arrow_left__1iCre {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ProformaInvoices_pg_arrow_right__2Ez0K:before {
    content: "\E631";
}

.ProformaInvoices_pg_arrow_left__1iCre:before {
    content: "\E629";
}

.ProformaInvoices_btn__1phjB {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ProformaInvoices_btn_primary__2_O8V,
.ProformaInvoices_btn_primary__2_O8V:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ProformaInvoices_btn_cons__10IDs {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.ProformaInvoices_MerchantRow__194-9 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ProformaInvoices_MerchantRow__194-9:hover {
    background-color: #267788;
    color: #fff;

}

.ProformaInvoices_MerchantRow__194-9:hover a {
    color: #fff;
}

.ProformaInvoices_MerchantRowExpanded__2eesq {
    background: #267788;
    color: #fff;
}

.ProformaInvoices_table__-xf1B.ProformaInvoices_table-condensed__3IFVb.ProformaInvoices_table-detailed__2TEOm>tbody>tr.ProformaInvoices_shown__mvg1M>td {
    background: #fdf5df;
}
.Devices_Container__3Oi_o {
  background-color: #fff;
  margin: 2.4rem;
  margin-bottom: 2.4rem;
  /* flex-grow: 1; */
  border: 1px solid #f1f3f4;
  /* display: flex; */
  flex-direction: column;
  font-size: 1.4rem;
}

.Devices_Tools__whE-W {
  display: flex;
  margin: 0 2.5rem;
  padding-top: 1.4rem;
}

.Devices_PageFilter__JtB5y {
  display: flex;
  flex: 1 1 50%;
  justify-content: flex-start;
  width: auto;
}

.Devices_ToolsWrapper__1IL5S {
  height: 6rem;
  width: 100%;
}

.Devices_PrintToolsWrapper__3ieDf {
  display: flex;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.Devices_AddNewMerchant__2PwnH {
  /* // font-family: Cambay; */
}

.Devices_AddNewMerchantBtn__2tC6O {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  vertical-align: middle;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Devices_AddNewMerchantBtn__2tC6O:focus,
.Devices_AddNewMerchantBtn__2tC6O:hover,
.Devices_AddNewMerchantBtn__2tC6O:active {
  background-color: rgb(51, 137, 255);
}

.Devices_ToolsDataExportIconWrapper__3-bqr {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.Devices_ToolsPrintIconWrapper__T0HjF {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.Devices_FilterInput__3R0by {
  margin-right: 2rem;
}

.Devices_FormGroup__x9bei {
  /* margin-bottom: 10px; */
}

.Devices_FormGroupDefault__gqgN8.Devices_has-error__IRfk6 {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}
.Devices_Dropzone__3UQgN {
  border: 1px solid rgba(100, 100, 100, 1);
  border-radius: 1px;
  background: linear-gradient(
    0deg,
    rgb(231, 231, 231) 68%,
    rgb(231, 231, 231) 68%
  );
  background-blend-mode: normal;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.09);
}

.Devices_CenteredDiv__3AbeF {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
}

.Devices_FormGroupDefault__gqgN8 {
  /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
  /* padding: 1.4rem 2.5rem .2em .5rem; */
  line-height: 2rem;
  position: relative;
  transition: all 0.2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.Devices_FormGroupDefault__gqgN8 label {
  margin: 0;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.Devices_FormGroup__x9bei label:not(.Devices_error__1E666) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.Devices_FormControl__1dItC {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.Devices_MerchantTableWrapper__2MT7n {
  margin-top: 2.5rem;
  white-space: nowrap;
}

/* .TableWrapper {
    display: flex;
} */

.Devices_MerchantTable__BAHTD {
  flex: 1 1 70%;
  width: 100%;
  border-collapse: collapse;
}

.Devices_MerchantTable__BAHTD th {
  text-align: left;
}

.Devices_MerchantTable__BAHTD th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.Devices_MerchantTable__BAHTD td,
.Devices_MerchantTable__BAHTD th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.Devices_MerchantTable__BAHTD td:last-child {
  border-right: 1px solid #e8e8e8;
}

.Devices_MerchantTable__BAHTD td:first-child {
  border-left: 1px solid #e8e8e8;
}

.Devices_RevealMoreHeader__hp45G {
  padding: 0;
  vertical-align: middle;
}

/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */

.Devices_Row__3qsx7 {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem 0;
}

.Devices_Row__3qsx7 > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.Devices_dataTables_paginate__3u4Gc {
  float: right;
}

.Devices_dataTables_paginate__3u4Gc {
  /* margin-top: 25px !important; */
}

div.Devices_dataTables_paginate__3u4Gc {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.Devices_dataTables_paginate__3u4Gc ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Devices_dataTables_paginate__3u4Gc ul > li > a {
  padding: 5px 10px;
  color: #575757;
  opacity: 0.35;
  transition: opacity 0.3s ease;
}

.Devices_dataTables_paginate__3u4Gc ul > li.Devices_disabled__2piKu a {
  opacity: 0.5;
}

.Devices_dataTables_paginate__3u4Gc ul > li.Devices_disabled__2piKu a {
  opacity: 0.35;
}

.Devices_dataTables_paginate__3u4Gc ul > li.Devices_active__1hilT > a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.Devices_dataTables_paginate__3u4Gc ul > li.Devices_next__qR8tk > a,
.Devices_dataTables_paginate__3u4Gc ul > li.Devices_prev__2VR0p > a {
  opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_right__1ozCy {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_left__Xky4b {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_right__1ozCy:before {
  content: "\E631";
}

.Devices_pg_arrow_left__Xky4b:before {
  content: "\E629";
}

.Devices_btn__3C1BS {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
}

.Devices_btn_primary__3r_g9,
.Devices_btn_primary__3r_g9:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.Devices_btn_cons__3yNog {
  min-width: 120px;
}

/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Devices_MerchantRow__1RlSf {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.Devices_MerchantRow__1RlSf:hover {
  background-color: #267788;
  color: #fff;
}

.Devices_MerchantRow__1RlSf:hover a {
  color: #fff;
}

.Devices_MerchantRowExpanded__ZkX3C {
  background: #267788;
  color: #fff;
}

.Devices_table__3DLCx.Devices_table-condensed__BFPcJ.Devices_table-detailed__1dlub > tbody > tr.Devices_shown__2zmYv > td {
  background: #fdf5df;
}

.Devices_SelectedDevice__Rck96 {
  background-color: #267788;
  color: #fff;
}

.Devices_DeviceDetail__2Rv7y {
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */
}

.Inventory_Container__3-8tj {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Inventory_BreadcrumbWrapper__3DEne {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Inventory_Content__1q3oi {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Inventory_Alert__2Qz6w {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Inventory_Breadcrumb__2GzDJ {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Inventory_Header__2K35h {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Inventory_ByLine__1FMLQ {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Inventory_Activity__XLpqf {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Inventory_WelcomeContainer__2OR1Q {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Inventory_MerchantProfile__1jpDN {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Inventory_TabHeader__2G8W1 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Inventory_PageHeader__XItZz {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Inventory_AddUser__3JqH0 {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Inventory_AddUserBtn__3He1W {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Inventory_FilterInput__3e_lj {
    margin-right: 2rem;
}

.Inventory_FormGroup__3tsZp {
    margin-bottom: 10px;
}

.Inventory_FormGroupDefault__n2PmL.Inventory_has-error__12_PK {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Inventory_FormGroupDefault__n2PmL {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

.Inventory_PageFilter__U4kkI {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
    margin-top: 10px;
}

.Inventory_PageTitle__-8XAV {
    border-right: 1px solid #707070;
    padding-right: 2rem;
    font-weight: 700;
    margin-top: 10px;
}

.Inventory_FilterInput__3e_lj {
    margin-right: 2rem;
}

.Inventory_FormGroup__3tsZp {
    margin-bottom: 10px;
}

.Inventory_FormGroupDefault__n2PmL.Inventory_has-error__12_PK {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Inventory_FormGroupDefault__n2PmL {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Inventory_FormGroupDefault__n2PmL label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Inventory_FormGroup__3tsZp label:not(.Inventory_error__ctQFF) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Inventory_FormControl__2ol4x {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Inventory_Tools__2w9VQ {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Inventory_ToolsExportIconWrapper__3HZXD {
    display: inline-block;
    margin-left: 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;
}
.SimCards_Container__XJa4k {
  background-color: #fff;
  margin: 2.4rem;
  margin-bottom: 2.4rem;
  /* flex-grow: 1; */
  border: 1px solid #f1f3f4;
  /* display: flex; */
  flex-direction: column;
  font-size: 1.4rem;
}
.SimCards_Dropzone__XCNck {
  border: 1px solid rgba(100, 100, 100, 1);
  border-radius: 1px;
  background: linear-gradient(
    0deg,
    rgb(231, 231, 231) 68%,
    rgb(231, 231, 231) 68%
  );
  background-blend-mode: normal;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.09);
}

.SimCards_CenteredDiv__1qTUc {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
}

.SimCards_Tools__2Wf0t {
  display: flex;
  margin: 0 2.5rem;
  padding-top: 1.4rem;
}

.SimCards_PageFilter__1hJID {
  display: flex;
  flex: 1 1 50%;
  justify-content: flex-start;
  width: auto;
}

.SimCards_ToolsWrapper__26oo9 {
  height: 6rem;
  width: 100%;
}

.SimCards_PrintToolsWrapper__xadLa {
  display: flex;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.SimCards_AddNewMerchant__hUfDb {
  /* // font-family: Cambay; */
}

.SimCards_AddNewMerchantBtn__xcEyk {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  vertical-align: middle;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.SimCards_AddNewMerchantBtn__xcEyk:focus,
.SimCards_AddNewMerchantBtn__xcEyk:hover,
.SimCards_AddNewMerchantBtn__xcEyk:active {
  background-color: rgb(51, 137, 255);
}

.SimCards_ToolsDataExportIconWrapper__20PRA {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.SimCards_ToolsPrintIconWrapper__bC5JU {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.SimCards_FilterInput__1hg2X {
  margin-right: 2rem;
}

.SimCards_FormGroup__1NliB {
  /* margin-bottom: 10px; */
}

.SimCards_FormGroupDefault__1k62j.SimCards_has-error__2MXXW {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.SimCards_FormGroupDefault__1k62j {
  /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
  /* padding: 1.4rem 2.5rem .2em .5rem; */
  line-height: 2rem;
  position: relative;
  transition: all 0.2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.SimCards_FormGroupDefault__1k62j label {
  margin: 0;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.SimCards_FormGroup__1NliB label:not(.SimCards_error__oDet_) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.SimCards_FormControl__1H_vf {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.SimCards_MerchantTableWrapper__1P2HX {
  margin-top: 2.5rem;
  white-space: nowrap;
}

/* .TableWrapper {
    display: flex;
} */

.SimCards_MerchantTable__2FnqJ {
  flex: 1 1 70%;
  width: 100%;
  border-collapse: collapse;
}

.SimCards_MerchantTable__2FnqJ th {
  text-align: left;
}

.SimCards_MerchantTable__2FnqJ th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.SimCards_MerchantTable__2FnqJ td,
.SimCards_MerchantTable__2FnqJ th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.SimCards_MerchantTable__2FnqJ td:last-child {
  border-right: 1px solid #e8e8e8;
}

.SimCards_MerchantTable__2FnqJ td:first-child {
  border-left: 1px solid #e8e8e8;
}

.SimCards_RevealMoreHeader__qG07j {
  padding: 0;
  vertical-align: middle;
}

/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */

.SimCards_Row__1J0s5 {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem 0;
}

.SimCards_Row__1J0s5 > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.SimCards_dataTables_paginate__20kfy {
  float: right;
}

.SimCards_dataTables_paginate__20kfy {
  /* margin-top: 25px !important; */
}

div.SimCards_dataTables_paginate__20kfy {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.SimCards_dataTables_paginate__20kfy ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.SimCards_dataTables_paginate__20kfy ul > li > a {
  padding: 5px 10px;
  color: #575757;
  opacity: 0.35;
  transition: opacity 0.3s ease;
}

.SimCards_dataTables_paginate__20kfy ul > li.SimCards_disabled__3_if- a {
  opacity: 0.5;
}

.SimCards_dataTables_paginate__20kfy ul > li.SimCards_disabled__3_if- a {
  opacity: 0.35;
}

.SimCards_dataTables_paginate__20kfy ul > li.SimCards_active__129-n > a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.SimCards_dataTables_paginate__20kfy ul > li.SimCards_next__2KOV1 > a,
.SimCards_dataTables_paginate__20kfy ul > li.SimCards_prev__1Exma > a {
  opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.SimCards_pg_arrow_right__Tt6n5 {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.SimCards_pg_arrow_left__3AYf- {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.SimCards_pg_arrow_right__Tt6n5:before {
  content: "\E631";
}

.SimCards_pg_arrow_left__3AYf-:before {
  content: "\E629";
}

.SimCards_btn__21DWu {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
}

.SimCards_btn_primary__1Tnh9,
.SimCards_btn_primary__1Tnh9:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.SimCards_btn_cons__1bB_I {
  min-width: 120px;
}

/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.SimCards_MerchantRow__3Fh0m {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.SimCards_MerchantRow__3Fh0m:hover {
  background-color: #267788;
  color: #fff;
}

.SimCards_MerchantRow__3Fh0m:hover a {
  color: #fff;
}

.SimCards_MerchantRowExpanded__1mqkx {
  background: #267788;
  color: #fff;
}

.SimCards_table__26yl5.SimCards_table-condensed__25Fk_.SimCards_table-detailed__2WSy2 > tbody > tr.SimCards_shown__3BXHM > td {
  background: #fdf5df;
}

.SimCards_SelectedDevice__m7vBc {
  background-color: #267788;
  color: #fff;
}

.SimCards_DeviceDetail__eNhFT {
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */
}

.authorisation_Container__23BAx {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.authorisation_BreadcrumbWrapper__2TZms {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.authorisation_Content__143rP {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.authorisation_Alert__2iLix {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.authorisation_Breadcrumb__3mDsn {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.authorisation_Header__z7bfP {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.authorisation_ByLine__1-Id2 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.authorisation_Activity__3VQbj {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.authorisation_WelcomeContainer__vMbeE {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.authorisation_MerchantProfile__1S036 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.authorisation_TabHeader__35uNf {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.authorisation_PageHeader__1uD_W {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.authorisation_AddUser__2j8wG {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.authorisation_AddUserBtn__1qtns {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}
.Merchant_Container__1cNtn {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Merchant_Tools__3kTEd {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Merchant_PageFilter__3lqdj {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Merchant_ToolsWrapper__htWws {
    height: 6rem;
    width: 100%;

}

.Merchant_PrintToolsWrapper__2Np4k {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}



.Merchant_AddNewMerchantBtn__2-wRD {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Merchant_AddNewMerchantBtn__2-wRD:focus,
.Merchant_AddNewMerchantBtn__2-wRD:hover,
.Merchant_AddNewMerchantBtn__2-wRD:active {
    background-color: rgb(51, 137, 255);
}


.Merchant_ToolsDataExportIconWrapper__2O9X- {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Merchant_ToolsPrintIconWrapper__2VYPK {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Merchant_FilterInput__2wy-L {
    margin-right: 2rem;
}

.Merchant_FormGroup__2u6Ps {
    /* margin-bottom: 10px; */
}

.Merchant_FormGroupDefault__2sGAe.Merchant_has-error__25Qoe {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Merchant_FormGroupDefault__2sGAe {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Merchant_FormGroupDefault__2sGAe label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Merchant_FormGroup__2u6Ps label:not(.Merchant_error__1eDVt) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Merchant_FormControl__3tLju {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Merchant_MerchantTableWrapper__3w0nG {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Merchant_TableWrapper__2PW9O {
    display: flex;
}

.Merchant_MerchantTable__22PHG {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Merchant_MerchantTable__22PHG th {
    text-align: left;
}

.Merchant_MerchantTable__22PHG th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Merchant_MerchantTable__22PHG td,
.Merchant_MerchantTable__22PHG th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Merchant_MerchantTable__22PHG td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Merchant_MerchantTable__22PHG td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Merchant_RevealMoreHeader__3ROrS {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Merchant_Row__1uRZD {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Merchant_Row__1uRZD>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Merchant_dataTables_paginate__2T63x {
    float: right;
}

.Merchant_dataTables_paginate__2T63x {
    /* margin-top: 25px !important; */
}

div.Merchant_dataTables_paginate__2T63x {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Merchant_dataTables_paginate__2T63x ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Merchant_dataTables_paginate__2T63x ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Merchant_dataTables_paginate__2T63x ul>li.Merchant_disabled__PRZ5f a {
    opacity: .5;
}

.Merchant_dataTables_paginate__2T63x ul>li.Merchant_disabled__PRZ5f a {
    opacity: .35;
}

.Merchant_dataTables_paginate__2T63x ul>li.Merchant_active__91iCf>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Merchant_dataTables_paginate__2T63x ul>li.Merchant_next__2QhwW>a,
.Merchant_dataTables_paginate__2T63x ul>li.Merchant_prev__1MQey>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Merchant_pg_arrow_right__2V4pD {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Merchant_pg_arrow_left__2vS7q {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Merchant_pg_arrow_right__2V4pD:before {
    content: "\E631";
}

.Merchant_pg_arrow_left__2vS7q:before {
    content: "\E629";
}

.Merchant_btn__RBvb- {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Merchant_btn_primary__3sWZD,
.Merchant_btn_primary__3sWZD:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Merchant_btn_cons__1o3s1 {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Merchant_MerchantRow__zm5K4 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Merchant_MerchantRow__zm5K4:hover {
    background-color: #267788;
    color: #fff;

}

.Merchant_MerchantRow__zm5K4:hover a {
    color: #fff;
}

.Merchant_MerchantRowExpanded__1PjaN {
    background: #267788;
    color: #fff;
}

.Merchant_table__CiZQh.Merchant_table-condensed__2xGGW.Merchant_table-detailed__309Pv>tbody>tr.Merchant_shown__3Jpm->td {
    background: #fdf5df;
}

.Merchant_EnableMerchant__1IRjF {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Merchant_EnableMerchantHeader__WAMTd {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Merchant_EnableMerchantBody__14L76 {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.EnableMerchant_btn__1RLav {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.EnableMerchant_btn__1RLav:focus,
.EnableMerchant_btn__1RLav:hover,
.EnableMerchant_btn__1RLav:active,
.EnableMerchant_btn__1RLav:disabled {
    background-color: #6d5eac;
}
.EnableDevice_btn__2LHO5 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.EnableDevice_btn__2LHO5:focus,
.EnableDevice_btn__2LHO5:hover,
.EnableDevice_btn__2LHO5:active,
.EnableDevice_btn__2LHO5:disabled {
    background-color: #6d5eac;
}
.Devices_Container__1oixF {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Devices_Tools__2XFBT {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Devices_PageFilter__1TOwb {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Devices_ToolsWrapper__zfLUa {
    height: 6rem;
    width: 100%;

}

.Devices_PrintToolsWrapper__1nHlz {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}



.Devices_AddNewMerchantBtn__1HgSH {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Devices_AddNewMerchantBtn__1HgSH:focus,
.Devices_AddNewMerchantBtn__1HgSH:hover,
.Devices_AddNewMerchantBtn__1HgSH:active {
    background-color: rgb(51, 137, 255);
}


.Devices_ToolsDataExportIconWrapper__2y7Dh {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Devices_ToolsPrintIconWrapper__l5n2c {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Devices_FilterInput__3xNHd {
    margin-right: 2rem;
}

.Devices_FormGroup__uN0p5 {
    /* margin-bottom: 10px; */
}

.Devices_FormGroupDefault__1BhDM.Devices_has-error__3d1ao {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Devices_FormGroupDefault__1BhDM {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Devices_FormGroupDefault__1BhDM label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Devices_FormGroup__uN0p5 label:not(.Devices_error__5-XoK) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Devices_FormControl__2TfLd {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Devices_MerchantTableWrapper__3Rscy {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Devices_TableWrapper__2UyX- {
    display: flex;
}

.Devices_MerchantTable__3J_g5 {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Devices_MerchantTable__3J_g5 th {
    text-align: left;
}

.Devices_MerchantTable__3J_g5 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Devices_MerchantTable__3J_g5 td,
.Devices_MerchantTable__3J_g5 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Devices_MerchantTable__3J_g5 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Devices_MerchantTable__3J_g5 td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Devices_RevealMoreHeader__3YhAC {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Devices_Row__2pjvZ {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Devices_Row__2pjvZ>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Devices_dataTables_paginate__2_O6S {
    float: right;
}

.Devices_dataTables_paginate__2_O6S {
    /* margin-top: 25px !important; */
}

div.Devices_dataTables_paginate__2_O6S {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Devices_dataTables_paginate__2_O6S ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Devices_dataTables_paginate__2_O6S ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Devices_dataTables_paginate__2_O6S ul>li.Devices_disabled__2KafM a {
    opacity: .5;
}

.Devices_dataTables_paginate__2_O6S ul>li.Devices_disabled__2KafM a {
    opacity: .35;
}

.Devices_dataTables_paginate__2_O6S ul>li.Devices_active__1DzrV>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Devices_dataTables_paginate__2_O6S ul>li.Devices_next__3oXx4>a,
.Devices_dataTables_paginate__2_O6S ul>li.Devices_prev__1_22r>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_right__kFfK0 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_left__1ZP16 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Devices_pg_arrow_right__kFfK0:before {
    content: "\E631";
}

.Devices_pg_arrow_left__1ZP16:before {
    content: "\E629";
}

.Devices_btn__1Yt8y {

    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Devices_btn_primary__39Msa,
.Devices_btn_primary__39Msa:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Devices_btn_cons__ZwfhU {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Devices_MerchantRow__2kpeN {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Devices_MerchantRow__2kpeN:hover {
    background-color: #267788;
    color: #fff;

}

.Devices_MerchantRow__2kpeN:hover a {
    color: #fff;
}

.Devices_MerchantRowExpanded__YBmdP {
    background: #267788;
    color: #fff;
}

.Devices_table__J9eE2.Devices_table-condensed__3W4FL.Devices_table-detailed__3JvHW>tbody>tr.Devices_shown__QzM1x>td {
    background: #fdf5df;
}

.Devices_ActivateDevice__l7Q71 {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Devices_ActivateDeviceHeader__1pf_a {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Devices_ActivateDeviceBody__MztQV {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}


.Devices_EnableDevice__14O4U {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Devices_EnableDeviceHeader__2pqy3 {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Devices_EnableDeviceBody__AMN2u {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.SetRateActive_btn__13byQ {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.SetRateActive_btn__13byQ:focus,
.SetRateActive_btn__13byQ:hover,
.SetRateActive_btn__13byQ:active,
.SetRateActive_btn__13byQ:disabled {
    background-color: #6d5eac;
}
.Commission_Container__1oUoc {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Commission_Tools__2jC0_ {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Commission_PageFilter__2-My2 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Commission_ToolsWrapper__-_71D {
    height: 6rem;
    width: 100%;

}

.Commission_PrintToolsWrapper__3rSLT {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}



.Commission_AddNewMerchantBtn__3DWm1 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Commission_AddNewMerchantBtn__3DWm1:focus,
.Commission_AddNewMerchantBtn__3DWm1:hover,
.Commission_AddNewMerchantBtn__3DWm1:active {
    background-color: rgb(51, 137, 255);
}


.Commission_ToolsDataExportIconWrapper__2qPYS {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Commission_ToolsPrintIconWrapper__3G1Gi {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Commission_FilterInput__6T4xE {
    margin-right: 2rem;
}



.Commission_FormGroupDefault__1uNBx.Commission_has-error__C-NCo {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Commission_FormGroupDefault__1uNBx {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Commission_FormGroupDefault__1uNBx label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Commission_FormGroup__12WW6 label:not(.Commission_error__2CwN9) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Commission_FormControl__2SblI {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Commission_MerchantTableWrapper__3zrwA {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Commission_TableWrapper__ahlbP {
    display: flex;
}

.Commission_MerchantTable__2oMv4 {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Commission_MerchantTable__2oMv4 th {
    text-align: left;
}

.Commission_MerchantTable__2oMv4 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Commission_MerchantTable__2oMv4 td,
.Commission_MerchantTable__2oMv4 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Commission_MerchantTable__2oMv4 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Commission_MerchantTable__2oMv4 td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Commission_RevealMoreHeader__3GmAW {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Commission_Row__oR6vz {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Commission_Row__oR6vz>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Commission_dataTables_paginate__2vRFD {
    float: right;
}



div.Commission_dataTables_paginate__2vRFD {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Commission_dataTables_paginate__2vRFD ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Commission_dataTables_paginate__2vRFD ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Commission_dataTables_paginate__2vRFD ul>li.Commission_disabled__1MLas a {
    opacity: .5;
}

.Commission_dataTables_paginate__2vRFD ul>li.Commission_disabled__1MLas a {
    opacity: .35;
}

.Commission_dataTables_paginate__2vRFD ul>li.Commission_active__uPaKp>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Commission_dataTables_paginate__2vRFD ul>li.Commission_next__bL5i7>a,
.Commission_dataTables_paginate__2vRFD ul>li.Commission_prev__1oZGv>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commission_pg_arrow_right__oIWv8 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commission_pg_arrow_left__2a5sa {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commission_pg_arrow_right__oIWv8:before {
    content: "\E631";
}

.Commission_pg_arrow_left__2a5sa:before {
    content: "\E629";
}

.Commission_btn__3r6OU {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Commission_btn_primary__15zPp,
.Commission_btn_primary__15zPp:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Commission_btn_cons__1LTpp {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Commission_MerchantRow__n7JWr {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Commission_MerchantRow__n7JWr:hover {
    background-color: #267788;
    color: #fff;

}

.Commission_MerchantRow__n7JWr:hover a {
    color: #fff;
}

.Commission_MerchantRowExpanded__1EALP {
    background: #267788;
    color: #fff;
}

.Commission_table__Iw1fL.Commission_table-condensed__OAO5f.Commission_table-detailed__1-EMH>tbody>tr.Commission_shown__1IogF>td {
    background: #fdf5df;
}

.Commission_ActivateDevice__3Tv6M {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Commission_ActivateDeviceHeader__suHt0 {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Commission_ActivateDeviceBody__CLrTS {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}


.Commission_EnableDevice__24Is0 {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Commission_EnableDeviceHeader__1YU9p {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Commission_EnableDeviceBody__15AW6 {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Credit_Container__2BWRM {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Credit_Tools__3EW0w {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Credit_PageFilter__2v0rM {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Credit_ToolsWrapper__KCjmm {
    height: 6rem;
    width: 100%;

}

.Credit_PrintToolsWrapper__3FIKR {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Credit_AddNewMerchant__2UCKz {
    /* // font-family: Cambay; */
}

.Credit_AddNewMerchantBtn__4ezSH {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Credit_AddNewMerchantBtn__4ezSH:focus,
.Credit_AddNewMerchantBtn__4ezSH:hover,
.Credit_AddNewMerchantBtn__4ezSH:active {
    background-color: rgb(51, 137, 255);
}


.Credit_ToolsDataExportIconWrapper__ITF8P {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Credit_ToolsPrintIconWrapper__3Wi8T {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Credit_FilterInput__3JrDx {
    margin-right: 2rem;
}

.Credit_FormGroup__Dz6-o {
    /* margin-bottom: 10px; */
}

.Credit_FormGroupDefault__12W-4.Credit_has-error__3aCoz {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Credit_FormGroupDefault__12W-4 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Credit_FormGroupDefault__12W-4 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Credit_FormGroup__Dz6-o label:not(.Credit_error__kMoap) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Credit_FormControl__2RRvS {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Credit_MerchantTableWrapper__xf_px {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Credit_TableWrapper__201sV {
    display: flex;
}

.Credit_MerchantTable__1FQ0I {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Credit_MerchantTable__1FQ0I th {
    text-align: left;
}

.Credit_MerchantTable__1FQ0I th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Credit_MerchantTable__1FQ0I td,
.Credit_MerchantTable__1FQ0I th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Credit_MerchantTable__1FQ0I td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Credit_MerchantTable__1FQ0I td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Credit_RevealMoreHeader__VXWY3 {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Credit_Row__JIoCF {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Credit_Row__JIoCF>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Credit_dataTables_paginate__3iqO8 {
    float: right;
}

.Credit_dataTables_paginate__3iqO8 {
    /* margin-top: 25px !important; */
}

div.Credit_dataTables_paginate__3iqO8 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Credit_dataTables_paginate__3iqO8 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Credit_dataTables_paginate__3iqO8 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Credit_dataTables_paginate__3iqO8 ul>li.Credit_disabled__3pGNd a {
    opacity: .5;
}

.Credit_dataTables_paginate__3iqO8 ul>li.Credit_disabled__3pGNd a {
    opacity: .35;
}

.Credit_dataTables_paginate__3iqO8 ul>li.Credit_active__6yajT>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Credit_dataTables_paginate__3iqO8 ul>li.Credit_next__1x6jQ>a,
.Credit_dataTables_paginate__3iqO8 ul>li.Credit_prev__2lFxC>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Credit_pg_arrow_right__1LUpl {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Credit_pg_arrow_left__e8Wup {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Credit_pg_arrow_right__1LUpl:before {
    content: "\E631";
}

.Credit_pg_arrow_left__e8Wup:before {
    content: "\E629";
}

.Credit_btn__VcoUD {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Credit_btn_primary__1MRo9,
.Credit_btn_primary__1MRo9:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Credit_btn_cons__3SYMC {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Credit_TableRow__1bKC7 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Credit_TableRow__1bKC7:hover {
    background-color: #267788;
    color: #fff;

}

.Credit_TableRow__1bKC7:hover a {
    color: #fff;
}

.Credit_TableRowExpanded__uC_vC {
    background: #267788;
    color: #fff;
}

.Credit_SelectedTableRow__pmnel {
    background-color: #267788;
    color: #fff;

}

.Credit_table__2D9RV.Credit_table-condensed__3azF2.Credit_table-detailed__aSS-X>tbody>tr.Credit_shown__2ExvG>td {
    background: #fdf5df;
}

.Credit_EnableMerchant__2I0N3 {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Credit_EnableMerchantHeader__38Wup {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Credit_EnableMerchantBody__2K-6N {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}

.Credit_btn__VcoUD {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.Credit_btn__VcoUD:focus,
.Credit_btn__VcoUD:hover,
.Credit_btn__VcoUD:active,
.Credit_btn__VcoUD:disabled {
    background-color: #6d5eac;
}
.ApproveCredit_btn__1XHuH {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.ApproveCredit_btn__1XHuH:focus,
.ApproveCredit_btn__1XHuH:hover,
.ApproveCredit_btn__1XHuH:active,
.ApproveCredit_btn__1XHuH:disabled {
    background-color: #6d5eac;
}
.details_Container__1GKny {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.details_BreadcrumbWrapper__3Dro4 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.details_Content__UzFlo {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.details_CardBody__2OApH {
    margin-top: 1rem;
}

.details_Alert__1I7Pa {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.details_Breadcrumb__fbOOu {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.details_Header__36AUf {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.details_ByLine__n4-R8 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_Activity__2uQYV {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.details_WelcomeContainer__-D-Gl {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.details_MerchantProfile__3yUMC {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.details_TabHeader__1z07C {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.details_PageHeader__13P70 {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.details_Header__36AUf {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.details_ExportReport__2DCqy {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.details_ExportIconWrapper__27cD2 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_EmailIconWrapper__GoJIA {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_AddUser__5GlHG {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.details_AddUserBtn__tq5Jz {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_Tools__135q_ {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.details_PageFilter__1652t {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}

.details_PrintToolsWrapper__1S6LY {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.details_FilterReportBtn__VJbtv {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.details_AddNewMerchantBtn__1ONK-:focus,
.details_AddNewMerchantBtn__1ONK-:hover,
.details_AddNewMerchantBtn__1ONK-:active {
    background-color: rgb(51, 137, 255);
}

.details_ToolsDataExportIconWrapper__Mob6V {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.details_ToolsPrintIconWrapper__LkNOJ {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.details_FilterInput__BdwoP {
    margin-right: 2rem;
}

.details_FormGroupDefault__E1P7S.details_has-error__w8JII {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__E1P7S {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.details_FormGroupDefault__E1P7S label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.details_FormGroup__3cTfl label:not(.details_error__2WpGy) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.details_FormControl__3xHh5 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.details_MerchantTableWrapper__Xxu11 {
    margin-top: 3rem;
    white-space: nowrap;
}

.details_MerchantTable__2eMz2 {
    width: 100%;
    border-collapse: collapse;
}

.details_MerchantTable__2eMz2 th {
    text-align: left;
}

.details_MerchantTable__2eMz2 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.details_MerchantTable__2eMz2 td,
.details_MerchantTable__2eMz2 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__3lH_- {
    padding: 0;
    vertical-align: middle;
}

.details_Row__3TFDB {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.details_Row__3TFDB>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.details_dataTables_paginate__1DX97 {
    float: right;
}

div.details_dataTables_paginate__1DX97 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.details_dataTables_paginate__1DX97 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

.details_dataTables_paginate__1DX97 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.details_dataTables_paginate__1DX97 ul>li.details_disabled__1W8EK a {
    opacity: .5;
}

.details_dataTables_paginate__1DX97 ul>li.details_disabled__1W8EK a {
    opacity: .35;
}

.details_dataTables_paginate__1DX97 ul>li.details_active__3JO35>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.details_dataTables_paginate__1DX97 ul>li.details_next__xUtG->a,
.details_dataTables_paginate__1DX97 ul>li.details_prev__2MTkg>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__3G7bO {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__1ADHU {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__3G7bO:before {
    content: "\E631";
}

.details_pg_arrow_left__1ADHU:before {
    content: "\E629";
}

.details_btn__3P1hr {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.details_btn_primary__BSdOQ,
.details_btn_primary__BSdOQ:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.details_btn_cons__Fpz0W {
    min-width: 120px;
}

.details_MerchantName__22A-s {
    font-weight: 500;
}

.details_Icn__2Et1b {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__2Et1b:hover {
    cursor: pointer;

}



.details_RevealMore__2Xcjd {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__2Xcjd:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.details_RevealMore__2Xcjd div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__3p1OZ {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.details_Expandabled__3M4sv {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__3M4sv td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__2eMz2 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.details_MerchantTable__2eMz2 td:first-child {

    border-left: 1px solid #e8e8e8;
}


.details_MerchantRow__1I3Oi {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MerchantRow__1I3Oi:hover {
    background-color: #267788;
    color: #fff;

}

.details_MerchantRow__1I3Oi:hover a {
    color: #fff;
}

.details_MerchantRowExpanded__13bAq {
    background: #267788;
    color: #fff;
}

.details_table__hhs-x.details_table-condensed__12lcW.details_table-detailed__18sUm>tbody>tr.details_shown__3p4tx>td {
    background: #fdf5df;
}


.details_Totals__3u3G5 td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.details_MoneyTotalLabel__32g9r {
    float: right;

}

.details_FilterWrapper__1eXbg {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.details_FilterPeriods__EGLMz {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.details_FilterPeriods__EGLMz li {
    font-weight: 600;

    padding-left: 2rem;
    line-height: 38px;
}

.details_FinancialsIntro__3drNp {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_ExpandableView__yoEjB {
    background-color: transparent;
    /* font-family: Cambay; */
}

.details_Outlets__KpwQS {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    /* margin-left: 0.6rem; */
}

.details_Outlets__KpwQS li {
    display: inline-block;
        list-style: none;
}

.details_Outlet__3i9xp {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;}

.details_Outlet__3i9xp:hover {
color: #FFF;
    background: #5BA6E6;

}

.details_OutletName__3shyV {
    font-weight: 600;
}

.details_OutletLocation__1SWVm {
    font-weight: 400;

}

.details_ExpandableMember__1oXxI {
    float: left;
    text-decoration: none;
}

.details_ExpandableMemberName__3UddW {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.details_OutletListArea__8dFoZ {
    width: 100%;
    margin-top: 1rem;
    background: transparent;
}



.details_MemberContractDetailArea__3vOCy {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem 0px;
    background: rgb(255, 255, 255);
}

.details_MemberContractDetailArea__3vOCy header {
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.details_ExpandableMemberWrapper__3SL9M {
    background: transparent;
}

.details_clearfix__t6VoY::before,
.details_clearfix__t6VoY::after {
    display:table;
    content: " ";
}

.details_clearfix__t6VoY::after {
clear:both;
}


.details_FuneralSchemeWrapper__2oSJN {
    margin: 0 2.5rem;
}

.details_AddNewMember__BMWwn {
float:right;
margin-right: 8.8rem;
}

.details_AddNewMemberBtn__3Zfpn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_FuneralSchemeTable__AOiUh  {
    width: 100%;
    border-collapse: collapse;
}

.details_FuneralSchemeTable__AOiUh th {
    text-align: left;
}

.details_FuneralSchemeTable__AOiUh th {
    color: #666;
    vertical-align: top;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    vertical-align: middle;
}

.details_FuneralSchemeTable__AOiUh td,
.details_FuneralSchemeTable__AOiUh th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_FuneralSchemeTable__AOiUh .details_Totals__3u3G5 td {   
     border-bottom: none;
     font-weight: 600;
}

.details_FuneralSchemeTable__AOiUh .details_MoneyTotalLabel__32g9r {
    float: right;

}
.details_FuneralSchemeTable__AOiUh .details_MoneyTotal__bi52J {

}
.details_Container__2EVSi {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.details_BreadcrumbWrapper__1Hy1A {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.details_Content__xzZIm {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.details_CardBody__3xiip {
    margin-top: 1rem;
}

.details_Alert__1v_Mi {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.details_Breadcrumb__uyJc_ {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.details_Header__nH-Lx {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.details_ByLine__3klBD {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_Activity__3BD0v {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.details_WelcomeContainer__2DITT {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.details_MerchantProfile__3sPbp {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.details_TabHeader__2m4RU {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.details_PageHeader__diCcY {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.details_Header__nH-Lx {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.details_ExportReport__ZwV48 {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.details_ExportIconWrapper__25Bii {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_EmailIconWrapper__2FH1h {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_AddUser__14HMP {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.details_AddUserBtn__1f-J6 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_Tools__YOV7H {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.details_PageFilter__3k44F {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}

.details_PrintToolsWrapper__zu5Om {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.details_FilterReportBtn__230fl {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.details_AddNewMerchantBtn__2G3aS:focus,
.details_AddNewMerchantBtn__2G3aS:hover,
.details_AddNewMerchantBtn__2G3aS:active {
    background-color: rgb(51, 137, 255);
}

.details_ToolsDataExportIconWrapper__9aQ7D {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.details_ToolsPrintIconWrapper__1RXDz {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.details_FilterInput__31gdm {
    margin-right: 2rem;
}

.details_FormGroupDefault__kTZiX.details_has-error__KX3gL {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__kTZiX {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.details_FormGroupDefault__kTZiX label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.details_FormGroup__2I1MO label:not(.details_error__1UGd8) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.details_FormControl__2dSd8 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.details_MerchantTableWrapper__2xrjy {
    margin-top: 3rem;
    white-space: nowrap;
}

.details_MerchantTable__1RJ-O {
    width: 100%;
    border-collapse: collapse;
}

.details_MerchantTable__1RJ-O th {
    text-align: left;
}

.details_MerchantTable__1RJ-O th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.details_MerchantTable__1RJ-O td,
.details_MerchantTable__1RJ-O th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__2DeUm {
    padding: 0;
    vertical-align: middle;
}

.details_Row__1jQcK {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.details_Row__1jQcK>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.details_dataTables_paginate__2l6m5 {
    float: right;
}

div.details_dataTables_paginate__2l6m5 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.details_dataTables_paginate__2l6m5 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

.details_dataTables_paginate__2l6m5 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.details_dataTables_paginate__2l6m5 ul>li.details_disabled__2v72E a {
    opacity: .5;
}

.details_dataTables_paginate__2l6m5 ul>li.details_disabled__2v72E a {
    opacity: .35;
}

.details_dataTables_paginate__2l6m5 ul>li.details_active__2KkoC>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.details_dataTables_paginate__2l6m5 ul>li.details_next__3p0Z7>a,
.details_dataTables_paginate__2l6m5 ul>li.details_prev__zM9tU>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__1rBZ5 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__3AFvf {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__1rBZ5:before {
    content: "\E631";
}

.details_pg_arrow_left__3AFvf:before {
    content: "\E629";
}

.details_btn__buqUi {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.details_btn_primary__3Ks9i,
.details_btn_primary__3Ks9i:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.details_btn_cons__34_nm {
    min-width: 120px;
}

.details_MerchantName__1xjMC {
    font-weight: 500;
}

.details_Icn__24q6l {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__24q6l:hover {
    cursor: pointer;

}



.details_RevealMore__3tZAh {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__3tZAh:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.details_RevealMore__3tZAh div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__2jtIw {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.details_Expandabled__morK5 {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__morK5 td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__1RJ-O td:last-child {

    border-right: 1px solid #e8e8e8;
}


.details_MerchantTable__1RJ-O td:first-child {

    border-left: 1px solid #e8e8e8;
}


.details_MerchantRow__2QmUV {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MerchantRow__2QmUV:hover {
    background-color: #267788;
    color: #fff;

}

.details_MerchantRow__2QmUV:hover a {
    color: #fff;
}

.details_MerchantRowExpanded__2tC0d {
    background: #267788;
    color: #fff;
}

.details_table__1ZlYc.details_table-condensed__3bt7I.details_table-detailed__3Ryzt>tbody>tr.details_shown__3EaNj>td {
    background: #fdf5df;
}


.details_Totals__Kak1T td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.details_MoneyTotalLabel__2cxKr {
    float: right;

}

.details_FilterWrapper__2DY5L {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.details_FilterPeriods__3LNys {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.details_FilterPeriods__3LNys li {
    font-weight: 600;

    padding-left: 2rem;
    line-height: 38px;
}

.details_FinancialsIntro__1M3MV {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_ExpandableView__lrRXI {
    background-color: transparent;
    /* font-family: Cambay; */
}

.details_Outlets__3BCTC {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    /* margin-left: 0.6rem; */
}

.details_Outlets__3BCTC li {
    display: inline-block;
        list-style: none;
}

.details_Outlet__Nhu70 {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;}

.details_Outlet__Nhu70:hover {
color: #FFF;
    background: #5BA6E6;

}

.details_OutletName__u02Xe {
    font-weight: 600;
}

.details_OutletLocation__2--N0 {
    font-weight: 400;

}

.details_ExpandableMember__31Zwz {
    float: left;
    text-decoration: none;
}

.details_ExpandableMemberName__2Uzyd {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.details_OutletListArea__1gXfo {
    width: 100%;
    margin-top: 1rem;
    background: transparent;
}



.details_MemberContractDetailArea__3EKgP {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem 0px;
    background: rgb(255, 255, 255);
}

.details_MemberContractDetailArea__3EKgP header {
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.details_ExpandableMemberWrapper__TUoc0 {
    background: transparent;
}

.details_clearfix__17OHG::before,
.details_clearfix__17OHG::after {
    display:table;
    content: " ";
}

.details_clearfix__17OHG::after {
clear:both;
}


.details_FuneralSchemeWrapper__3qSb1 {
    margin: 0 2.5rem;
}

.details_AddNewMember__3C0AC {
float:right;
margin-right: 8.8rem;
}

.details_AddNewMemberBtn__3XrzE {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_FuneralSchemeTable__cOwpj  {
    width: 100%;
    border-collapse: collapse;
}

.details_FuneralSchemeTable__cOwpj th {
    text-align: left;
}

.details_FuneralSchemeTable__cOwpj th {
    color: #666;
    vertical-align: top;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    vertical-align: middle;
}

.details_FuneralSchemeTable__cOwpj td,
.details_FuneralSchemeTable__cOwpj th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_FuneralSchemeTable__cOwpj .details_Totals__Kak1T td {   
     border-bottom: none;
     font-weight: 600;
}

.details_FuneralSchemeTable__cOwpj .details_MoneyTotalLabel__2cxKr {
    float: right;

}
.details_FuneralSchemeTable__cOwpj .details_MoneyTotal__1kmPo {

}
.PettyCash_Container__b-7EK {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.PettyCash_Tools__YmN1J {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.PettyCash_PageFilter__za0Zk {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.PettyCash_ToolsWrapper__30SS2 {
    height: 6rem;
    width: 100%;

}

.PettyCash_PrintToolsWrapper__13AYM {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.PettyCash_AddNewMerchant__2yoRq {
    /* // font-family: Cambay; */
}

.PettyCash_AddNewMerchantBtn__C0kHK {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.PettyCash_AddNewMerchantBtn__C0kHK:focus,
.PettyCash_AddNewMerchantBtn__C0kHK:hover,
.PettyCash_AddNewMerchantBtn__C0kHK:active {
    background-color: rgb(51, 137, 255);
}


.PettyCash_ToolsDataExportIconWrapper__15Hut {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.PettyCash_ToolsPrintIconWrapper__179oH {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.PettyCash_FilterInput___lAeB {
    margin-right: 2rem;
}

.PettyCash_FormGroup__1SVRf {
    /* margin-bottom: 10px; */
}

.PettyCash_FormGroupDefault__1_ZqC.PettyCash_has-error__2mHE4 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.PettyCash_FormGroupDefault__1_ZqC {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.PettyCash_FormGroupDefault__1_ZqC label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.PettyCash_FormGroup__1SVRf label:not(.PettyCash_error__2pAWI) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.PettyCash_FormControl__2qzcO {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.PettyCash_MerchantTableWrapper__3mOBL {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.PettyCash_TableWrapper__2Ap9B {
    display: flex;
}

.PettyCash_MerchantTable__15mKx {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.PettyCash_MerchantTable__15mKx th {
    text-align: left;
}

.PettyCash_MerchantTable__15mKx th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.PettyCash_MerchantTable__15mKx td,
.PettyCash_MerchantTable__15mKx th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.PettyCash_MerchantTable__15mKx td:last-child {

    border-right: 1px solid #e8e8e8;
}


.PettyCash_MerchantTable__15mKx td:first-child {

    border-left: 1px solid #e8e8e8;
}

.PettyCash_RevealMoreHeader__3Is5y {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.PettyCash_Row__nUpMP {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.PettyCash_Row__nUpMP>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.PettyCash_dataTables_paginate__3hrFr {
    float: right;
}

.PettyCash_dataTables_paginate__3hrFr {
    /* margin-top: 25px !important; */
}

div.PettyCash_dataTables_paginate__3hrFr {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.PettyCash_dataTables_paginate__3hrFr ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.PettyCash_dataTables_paginate__3hrFr ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.PettyCash_dataTables_paginate__3hrFr ul>li.PettyCash_disabled__epo-q a {
    opacity: .5;
}

.PettyCash_dataTables_paginate__3hrFr ul>li.PettyCash_disabled__epo-q a {
    opacity: .35;
}

.PettyCash_dataTables_paginate__3hrFr ul>li.PettyCash_active__2974f>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.PettyCash_dataTables_paginate__3hrFr ul>li.PettyCash_next__2YcOL>a,
.PettyCash_dataTables_paginate__3hrFr ul>li.PettyCash_prev__18eKn>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.PettyCash_pg_arrow_right__3Sv0D {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.PettyCash_pg_arrow_left__3E2Vk {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.PettyCash_pg_arrow_right__3Sv0D:before {
    content: "\E631";
}

.PettyCash_pg_arrow_left__3E2Vk:before {
    content: "\E629";
}

.PettyCash_btn__3stZv {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.PettyCash_btn_primary__ftMva,
.PettyCash_btn_primary__ftMva:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.PettyCash_btn_cons__6xU9Q {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.PettyCash_TableRow__8wp7x {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.PettyCash_TableRow__8wp7x:hover {
    background-color: #267788;
    color: #fff;

}

.PettyCash_TableRow__8wp7x:hover a {
    color: #fff;
}

.PettyCash_TableRowExpanded__1MJaB {
    background: #267788;
    color: #fff;
}

.PettyCash_SelectedTableRow__3wgP5 {
    background-color: #267788;
    color: #fff;

}

.PettyCash_table__3eOD1.PettyCash_table-condensed__UaD41.PettyCash_table-detailed__1PIbD>tbody>tr.PettyCash_shown__fCAZi>td {
    background: #fdf5df;
}

.PettyCash_EnableMerchant__3OTpx {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.PettyCash_EnableMerchantHeader__2RLUb {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.PettyCash_EnableMerchantBody__3ul8o {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}

.PettyCash_btn__3stZv {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.PettyCash_btn__3stZv:focus,
.PettyCash_btn__3stZv:hover,
.PettyCash_btn__3stZv:active,
.PettyCash_btn__3stZv:disabled {
    background-color: #6d5eac;
}
.Deposits_Container__3AVnk {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Deposits_Tools__1I6Pk {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Deposits_PageFilter__5LI2B {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Deposits_ToolsWrapper__1ufhe {
    height: 6rem;
    width: 100%;

}

.Deposits_PrintToolsWrapper__WxU7O {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}



.Deposits_AddNewMerchantBtn__3BIsb {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Deposits_AddNewMerchantBtn__3BIsb:focus,
.Deposits_AddNewMerchantBtn__3BIsb:hover,
.Deposits_AddNewMerchantBtn__3BIsb:active {
    background-color: rgb(51, 137, 255);
}


.Deposits_ToolsDataExportIconWrapper__2X0_K {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Deposits_ToolsPrintIconWrapper__3FwMy {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Deposits_FilterInput__1zhXv {
    margin-right: 2rem;
}

.Deposits_FormGroup__3ShVX {
    /* margin-bottom: 10px; */
}

.Deposits_FormGroupDefault__62qtb.Deposits_has-error__2j6Bj {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Deposits_FormGroupDefault__62qtb {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Deposits_FormGroupDefault__62qtb label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Deposits_FormGroup__3ShVX label:not(.Deposits_error__2aVig) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Deposits_FormControl__Du2gO {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Deposits_MerchantTableWrapper__2PY-F {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Deposits_TableWrapper__z98IW {
    display: flex;
}

.Deposits_MerchantTable__31-N- {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Deposits_MerchantTable__31-N- th {
    text-align: left;
}

.Deposits_MerchantTable__31-N- th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Deposits_MerchantTable__31-N- td,
.Deposits_MerchantTable__31-N- th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Deposits_MerchantTable__31-N- td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Deposits_MerchantTable__31-N- td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Deposits_RevealMoreHeader__1MUHB {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Deposits_Row__3aXdM {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Deposits_Row__3aXdM>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Deposits_dataTables_paginate__1q8wo {
    float: right;
}

.Deposits_dataTables_paginate__1q8wo {
    /* margin-top: 25px !important; */
}

div.Deposits_dataTables_paginate__1q8wo {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Deposits_dataTables_paginate__1q8wo ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Deposits_dataTables_paginate__1q8wo ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Deposits_dataTables_paginate__1q8wo ul>li.Deposits_disabled__ca-zr a {
    opacity: .5;
}

.Deposits_dataTables_paginate__1q8wo ul>li.Deposits_disabled__ca-zr a {
    opacity: .35;
}

.Deposits_dataTables_paginate__1q8wo ul>li.Deposits_active__12X_A>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Deposits_dataTables_paginate__1q8wo ul>li.Deposits_next__1OqHM>a,
.Deposits_dataTables_paginate__1q8wo ul>li.Deposits_prev__33E3R>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_right__svFuW {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_left__1M5za {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_right__svFuW:before {
    content: "\E631";
}

.Deposits_pg_arrow_left__1M5za:before {
    content: "\E629";
}

.Deposits_btn__1eHP3 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Deposits_btn_primary__R26I5,
.Deposits_btn_primary__R26I5:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Deposits_btn_cons__1XKUH {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Deposits_MerchantRow__1UNdW {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Deposits_MerchantRow__1UNdW:hover {
    background-color: #267788;
    color: #fff;

}

.Deposits_MerchantRow__1UNdW:hover a {
    color: #fff;
}

.Deposits_MerchantRowExpanded__Bzb8e {
    background: #267788;
    color: #fff;
}

.Deposits_table__3PLDn.Deposits_table-condensed__3gm89.Deposits_table-detailed__1bZPA>tbody>tr.Deposits_shown__3u5mI>td {
    background: #fdf5df;
}

.Deposits_EnableMerchant__f9WXt {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Deposits_EnableMerchantHeader__QIzkr {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Deposits_EnableMerchantBody__1jkNG {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.EnableMerchant_btn__NXceq {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.EnableMerchant_btn__NXceq:focus,
.EnableMerchant_btn__NXceq:hover,
.EnableMerchant_btn__NXceq:active,
.EnableMerchant_btn__NXceq:disabled {
    background-color: #6d5eac;
}
.Courier_Container__vMKf9 {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Courier_Tools__19Hfr {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Courier_PageFilter__3BYEc {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Courier_ToolsWrapper__pC54p {
    height: 6rem;
    width: 100%;

}

.Courier_PrintToolsWrapper__3VZ2j {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}



.Courier_AddNewMerchantBtn__3CfBU {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Courier_AddNewMerchantBtn__3CfBU:focus,
.Courier_AddNewMerchantBtn__3CfBU:hover,
.Courier_AddNewMerchantBtn__3CfBU:active {
    background-color: rgb(51, 137, 255);
}


.Courier_ToolsDataExportIconWrapper__1omLn {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Courier_ToolsPrintIconWrapper__1zL2N {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Courier_FilterInput__1S0l2 {
    margin-right: 2rem;
}

.Courier_FormGroup__15mEn {
    /* margin-bottom: 10px; */
}

.Courier_FormGroupDefault__12Xxu.Courier_has-error__iNYA9 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Courier_FormGroupDefault__12Xxu {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Courier_FormGroupDefault__12Xxu label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Courier_FormGroup__15mEn label:not(.Courier_error__3FEkm) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Courier_FormControl__29T1j {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Courier_MerchantTableWrapper__k9Wwa {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Courier_TableWrapper__3Evx9 {
    display: flex;
}

.Courier_MerchantTable__ryGk7 {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Courier_MerchantTable__ryGk7 th {
    text-align: left;
}

.Courier_MerchantTable__ryGk7 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Courier_MerchantTable__ryGk7 td,
.Courier_MerchantTable__ryGk7 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Courier_MerchantTable__ryGk7 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Courier_MerchantTable__ryGk7 td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Courier_RevealMoreHeader__3OekE {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Courier_Row__fmxQ1 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Courier_Row__fmxQ1>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Courier_dataTables_paginate__1QcVF {
    float: right;
}

.Courier_dataTables_paginate__1QcVF {
    /* margin-top: 25px !important; */
}

div.Courier_dataTables_paginate__1QcVF {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Courier_dataTables_paginate__1QcVF ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Courier_dataTables_paginate__1QcVF ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Courier_dataTables_paginate__1QcVF ul>li.Courier_disabled__3oBz2 a {
    opacity: .5;
}

.Courier_dataTables_paginate__1QcVF ul>li.Courier_disabled__3oBz2 a {
    opacity: .35;
}

.Courier_dataTables_paginate__1QcVF ul>li.Courier_active__2--DO>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Courier_dataTables_paginate__1QcVF ul>li.Courier_next__1e0BI>a,
.Courier_dataTables_paginate__1QcVF ul>li.Courier_prev__2a-5C>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Courier_pg_arrow_right__2jKXr {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Courier_pg_arrow_left__2-J_t {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Courier_pg_arrow_right__2jKXr:before {
    content: "\E631";
}

.Courier_pg_arrow_left__2-J_t:before {
    content: "\E629";
}

.Courier_btn__2s7Rq {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Courier_btn_primary__1Qsan,
.Courier_btn_primary__1Qsan:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Courier_btn_cons__3ZHrI {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Courier_MerchantRow__nMcD1 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Courier_MerchantRow__nMcD1:hover {
    background-color: #267788;
    color: #fff;

}

.Courier_MerchantRow__nMcD1:hover a {
    color: #fff;
}

.Courier_MerchantRowExpanded__2h8-8 {
    background: #267788;
    color: #fff;
}

.Courier_table__3nEKr.Courier_table-condensed__1z9BT.Courier_table-detailed__1HUBd>tbody>tr.Courier_shown__pjZeI>td {
    background: #fdf5df;
}

.Courier_EnableMerchant__2Aamp {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Courier_EnableMerchantHeader__3YhIa {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Courier_EnableMerchantBody__H0Ozf {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.EnableMerchant_btn__xC4zX {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.EnableMerchant_btn__xC4zX:focus,
.EnableMerchant_btn__xC4zX:hover,
.EnableMerchant_btn__xC4zX:active,
.EnableMerchant_btn__xC4zX:disabled {
    background-color: #6d5eac;
}
.airtime_Container__2GH65 {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.airtime_BreadcrumbWrapper__2LIkn {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.airtime_Content__2qkwd {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.airtime_Alert__2XZ-v {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.airtime_Breadcrumb__2gR2V {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.airtime_Header__2UAAs {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.airtime_ByLine__1qkV3 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.airtime_Activity__1PbKw {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.airtime_WelcomeContainer__VZdmi {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.airtime_MerchantProfile__1CICM {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.airtime_TabHeader__2vdin {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.airtime_PageHeader__3BdqZ {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.airtime_AddUser__6xw2a {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.airtime_AddUserBtn__FQ8lr {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}
.Summary_Container__2pM1s {
  background-color: #fff;
  /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
  /* flex-grow: 1; */
  /* border-top: 1px solid #707070; */
  /* display: flex; */
  flex-direction: column;
  font-size: 1.4rem;
}

.Summary_Tools__slZOR {
  display: flex;
  /* margin: 0 2.5rem; */
  /* padding-top: 1.4rem; */
}

.Summary_PageFilter__svViG {
  /* display: flex; */
  flex: 1 1 80%;
  justify-content: flex-start;
  width: auto;
}

.Summary_ToolsWrapper__351JA {
  height: 6rem;
  width: 100%;
}

.Summary_PrintToolsWrapper__3wunq {
  display: flex;
  flex: 1 1 20%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
}

.Summary_AddNewMerchant__52jpu {
  /* // font-family: Cambay; */
}

.Summary_FilterReportBtn__29rJe {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  vertical-align: middle;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 0.9rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
  width: 100%;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Summary_AddNewMerchantBtn__35Lu9:focus,
.Summary_AddNewMerchantBtn__35Lu9:hover,
.Summary_AddNewMerchantBtn__35Lu9:active {
  background-color: rgb(51, 137, 255);
}

.Summary_ToolsDataExportIconWrapper__25eyY {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.Summary_ToolsPrintIconWrapper__2u-8S {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.Summary_FilterInput__3dOLN {
  margin-right: 2rem;
}

.Summary_FormGroup__rDyRu {
  /* margin-bottom: 10px; */
}

.Summary_FormGroupDefault__1YXxf.Summary_has-error__1hByL {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.Summary_FormGroupDefault__1YXxf {
  /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
  /* padding: 1.4rem 2.5rem .2em .5rem; */
  line-height: 2rem;
  position: relative;
  transition: all 0.2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.Summary_FormGroupDefault__1YXxf label {
  margin: 0;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.Summary_FormGroup__rDyRu label:not(.Summary_error__1yyEo) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.Summary_FormControl__3VsuM {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.Summary_MerchantTableWrapper__3dAya {
  /* margin-top: 2.5rem; */
  white-space: nowrap;
}

.Summary_MerchantTable__GPmNT {
  width: 80%;
  border-collapse: collapse;
  border: 1px solid #e8e8e8;
}

.Summary_MerchantTable__GPmNT th {
  /* text-align: left; */
}

.Summary_MerchantTable__GPmNT th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.Summary_MerchantTable__GPmNT td,
.Summary_MerchantTable__GPmNT th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Summary_RevealMoreHeader__1p6Ff {
  padding: 0;
  vertical-align: middle;
}

.Summary_Row__2evtv {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem;
}

.Summary_Row__2evtv > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.Summary_dataTables_paginate__11ts8 {
  float: right;
}

.Summary_dataTables_paginate__11ts8 {
  /* margin-top: 25px !important; */
}

div.Summary_dataTables_paginate__11ts8 {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.Summary_dataTables_paginate__11ts8 ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Summary_dataTables_paginate__11ts8 ul > li > a {
  padding: 5px 10px;
  color: #575757;
  opacity: 0.35;
  transition: opacity 0.3s ease;
}

.Summary_dataTables_paginate__11ts8 ul > li.Summary_disabled__2NWLF a {
  opacity: 0.5;
}

.Summary_dataTables_paginate__11ts8 ul > li.Summary_disabled__2NWLF a {
  opacity: 0.35;
}

.Summary_dataTables_paginate__11ts8 ul > li.Summary_active__2j6Zt > a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.Summary_dataTables_paginate__11ts8 ul > li.Summary_next__3kvTi > a,
.Summary_dataTables_paginate__11ts8 ul > li.Summary_prev__1UAV_ > a {
  opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_right__132Np {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_left__3WA1T {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_right__132Np:before {
  content: "\E631";
}

.Summary_pg_arrow_left__3WA1T:before {
  content: "\E629";
}

.Summary_btn__Vru9Q {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
}

.Summary_btn_primary__14YAY,
.Summary_btn_primary__14YAY:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.Summary_btn_cons__2smHS {
  min-width: 120px;
}

/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

/* .Odd {
}

.Even {
} */

.Summary_MerchantName__BGkZ0 {
  font-weight: 500;
}

.Summary_Icn__26BVS {
  cursor: pointer;
  /* fill:#000; */
}

.Summary_Icn__26BVS:hover {
  cursor: pointer;
}

.Summary_RevealMore__1jk4E {
  background: #e1e1e1;
  cursor: pointer;
  width: 2.4rem;
}

.Summary_RevealMore__1jk4E:hover {
  background: transparent;
  color: #707070;
  fill: #fff;
}

.Summary_RevealMore__1jk4E div {
  margin: 0 auto;
  width: 2.4rem;
  height: 2.4rem;
}

.Summary_RevealMoreExpanded__3l9Oy {
  background: transparent;
  fill: #fff;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.Summary_Expandabled__1eKS5 {
  text-align: left;
  background: #d7effb;
  border-bottom: 1px solid rgb(56, 0, 60);
  display: table-row;
  z-index: 500;
}

.Summary_Expandabled__1eKS5 td {
  padding: 2rem 2rem 2rem 5.6rem;
  border-bottom: 1px solid #e8e8e8;
}

.Summary_MerchantTable__GPmNT td:last-child {
  border-right: 1px solid #e8e8e8;
}

.Summary_MerchantTable__GPmNT td:first-child {
  border-left: 1px solid #e8e8e8;
}

.Summary_MerchantRow__I3X0C {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.Summary_MerchantRow__I3X0C:hover {
  background-color: #267788;
  color: #fff;
}

.Summary_MerchantRow__I3X0C:hover a {
  color: #fff;
}

.Summary_MerchantRowExpanded__3GhRA {
  background: #267788;
  color: #fff;
}

.Summary_table__tfw0o.Summary_table-condensed__1mr9g.Summary_table-detailed__3eVnE > tbody > tr.Summary_shown__2iZ-7 > td {
  background: #fdf5df;
}

.Summary_Totals__2Pr-g td {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid #e8e8e8;
  font-weight: 700;
}

.Summary_MoneyTotalLabel__1XLno {
  float: right;
}

.History_Container__2lcJT {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.History_Tools__1wILA {
    display: flex;
    /* margin: 0 2.5rem; */
    /* padding-top: 1.4rem; */
}

.History_PageFilter__1EYcX {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.History_ToolsWrapper__1qHLb {
    height: 6rem;
    width: 100%;

}

.History_PrintToolsWrapper__5_3oW {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}



.History_FilterReportBtn__1uuGb {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.History_AddNewMerchantBtn__1ocvi:focus,
.History_AddNewMerchantBtn__1ocvi:hover,
.History_AddNewMerchantBtn__1ocvi:active {
    background-color: rgb(51, 137, 255);
}


.History_ToolsDataExportIconWrapper__C4Ksi {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.History_ToolsPrintIconWrapper__2bLL2 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.History_FilterInput__CFISf {
    margin-right: 2rem;
}


.History_FormGroupDefault__2l9G6.History_has-error__3Pf3y {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.History_FormGroupDefault__2l9G6 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.History_FormGroupDefault__2l9G6 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.History_FormGroup__3JnLf label:not(.History_error__2AGHs) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.History_FormControl__2m5T0 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.History_MerchantTableWrapper__3pYlj {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.History_MerchantTable__242qx {
    width: 100%;
    border-collapse: collapse;
    /* border-bottom: 1px solid #e8e8e8; */
}

.History_MerchantTable__242qx th {
    text-align: left;
}

.History_MerchantTable__242qx th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.History_MerchantTable__242qx td,
.History_MerchantTable__242qx th {
    padding:1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.History_RevealMoreHeader__3Zn4j {
    padding: 0;
    vertical-align: middle;
}





.History_Row__1VCRv {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.History_Row__1VCRv>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.History_dataTables_paginate__BqU1r {
    float: right;
}



div.History_dataTables_paginate__BqU1r {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.History_dataTables_paginate__BqU1r ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.History_dataTables_paginate__BqU1r ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.History_dataTables_paginate__BqU1r ul>li.History_disabled__2AFn3 a {
    opacity: .5;
}

.History_dataTables_paginate__BqU1r ul>li.History_disabled__2AFn3 a {
    opacity: .35;
}

.History_dataTables_paginate__BqU1r ul>li.History_active__3LX3j>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.History_dataTables_paginate__BqU1r ul>li.History_next__2-HDp>a,
.History_dataTables_paginate__BqU1r ul>li.History_prev__JXmnE>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.History_pg_arrow_right__2VFx0 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.History_pg_arrow_left__1Fa9S {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.History_pg_arrow_right__2VFx0:before {
    content: "\E631";
}

.History_pg_arrow_left__1Fa9S:before {
    content: "\E629";
}

.History_btn__2thp6 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.History_btn_primary__3yVwF,
.History_btn_primary__3yVwF:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.History_btn_cons__g8jIi {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.History_MerchantName__4rFb3 {
    font-weight: 500;
}

.History_Icn__3r9Jq {
    cursor: pointer;
    /* fill:#000; */
}

.History_Icn__3r9Jq:hover {
    cursor: pointer;

}



.History_RevealMore__vngo0 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.History_RevealMore__vngo0:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.History_RevealMore__vngo0 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.History_RevealMoreExpanded__1LsGB {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.History_Expandabled__3mcaa {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.History_Expandabled__3mcaa td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.History_MerchantTable__242qx td:last-child {

    border-right: 1px solid #e8e8e8;
}


.History_MerchantTable__242qx td:first-child {

    border-left: 1px solid #e8e8e8;
}


.History_MerchantRow__3Kwvv {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.History_MerchantRow__3Kwvv:hover {
    background-color: #267788;
    color: #fff;

}

.History_MerchantRow__3Kwvv:hover a {
    color: #fff;
}

.History_MerchantRowExpanded__myQNG {
    background: #267788;
    color: #fff;
}

.History_table__3JNji.History_table-condensed__U_kWU.History_table-detailed__36pOM>tbody>tr.History_shown__3Xb9z>td {
    background: #fdf5df;
}


.History_Totals__3EJbU td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.History_MoneyTotalLabel__3EX1u {
    float: right;

}
.Upload_Container__3139b {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Upload_Tools__2qHFZ {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Upload_PageFilter__3PmSb {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Upload_ToolsWrapper__2qObu {
    height: 6rem;
    width: 100%;

}

.Upload_PrintToolsWrapper__2X_En {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}



.Upload_AddNewMerchantBtn__2AbD9 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Upload_AddNewMerchantBtn__2AbD9:focus,
.Upload_AddNewMerchantBtn__2AbD9:hover,
.Upload_AddNewMerchantBtn__2AbD9:active {
    background-color: rgb(51, 137, 255);
}


.Upload_ToolsDataExportIconWrapper__jeMSc {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Upload_ToolsPrintIconWrapper__3a7lJ {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Upload_FilterInput__36oTU {
    margin-right: 2rem;
}



.Upload_FormGroupDefault__3QQCQ.Upload_has-error__P3dAn {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Upload_FormGroupDefault__3QQCQ {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Upload_FormGroupDefault__3QQCQ label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Upload_FormGroup__2gMXZ label:not(.Upload_error___kd8C) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Upload_FormControl__1zYsd {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Upload_MerchantTableWrapper__9D11F {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Upload_TableWrapper__2WvCz {
    display: flex;
}

.Upload_MerchantTable__3Q8cH {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Upload_MerchantTable__3Q8cH th {
    text-align: left;
}

.Upload_MerchantTable__3Q8cH th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Upload_MerchantTable__3Q8cH td,
.Upload_MerchantTable__3Q8cH th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Upload_MerchantTable__3Q8cH td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Upload_MerchantTable__3Q8cH td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Upload_RevealMoreHeader__3mfFh {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Upload_Row__3xdjw {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Upload_Row__3xdjw>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Upload_dataTables_paginate__3cEwm {
    float: right;
}



div.Upload_dataTables_paginate__3cEwm {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Upload_dataTables_paginate__3cEwm ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Upload_dataTables_paginate__3cEwm ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Upload_dataTables_paginate__3cEwm ul>li.Upload_disabled__5m4Jz a {
    opacity: .5;
}

.Upload_dataTables_paginate__3cEwm ul>li.Upload_disabled__5m4Jz a {
    opacity: .35;
}

.Upload_dataTables_paginate__3cEwm ul>li.Upload_active__25wbJ>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Upload_dataTables_paginate__3cEwm ul>li.Upload_next__fDnzo>a,
.Upload_dataTables_paginate__3cEwm ul>li.Upload_prev__1yWws>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Upload_pg_arrow_right__iW6EW {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Upload_pg_arrow_left__1-wqW {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Upload_pg_arrow_right__iW6EW:before {
    content: "\E631";
}

.Upload_pg_arrow_left__1-wqW:before {
    content: "\E629";
}

.Upload_btn__27oLD {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Upload_btn_primary__2AJ8B,
.Upload_btn_primary__2AJ8B:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Upload_btn_cons__3lhG6 {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Upload_MerchantRow__v7CPy {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Upload_MerchantRow__v7CPy:hover {
    background-color: #267788;
    color: #fff;

}

.Upload_MerchantRow__v7CPy:hover a {
    color: #fff;
}

.Upload_MerchantRowExpanded__PPBOW {
    background: #267788;
    color: #fff;
}

.Upload_table__1TtFO.Upload_table-condensed__13Puf.Upload_table-detailed__ufreN>tbody>tr.Upload_shown__3RF3W>td {
    background: #fdf5df;
}

.Upload_ActivateDevice__2WNVL {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Upload_ActivateDeviceHeader__94XFo {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Upload_ActivateDeviceBody__38cOf {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
    border-bottom: none;
}


.Upload_EnableDevice__2NYMD {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Upload_EnableDeviceHeader__3F5s2 {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Upload_EnableDeviceBody__1jmlk {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}

.Upload_btn__27oLD {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: #584c8b;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 10px;
    margin-right: 5px;
    min-width: 120px;
    text-decoration: none;
    border: none;
    display: block;
}


.Upload_btn__27oLD:focus,
.Upload_btn__27oLD:hover,
.Upload_btn__27oLD:active,
.Upload_btn__27oLD:disabled {
    background-color: #6d5eac;
}

.Upload_csvInput__3KMF4 {
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, .03);
    /* min-height: 10rem; */
    width:100%;
    background: rgba(0, 0, 0, .03);
    padding: 23px;
}
.settings_Container__27Yjt {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.settings_BreadcrumbWrapper__Y4ow_ {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.settings_Content__15JnV {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.settings_Alert__3k9yB {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.settings_Breadcrumb__qsBzq {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.settings_Header__2M0Ly {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.settings_ByLine__1MDuc {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.settings_Activity__bg93h {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.settings_WelcomeContainer__1LCQI {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.settings_MerchantProfile__290hG {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.settings_TabHeader__3c9FT {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.settings_PageHeader__o10RK {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.settings_AddUser__30-_J {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.settings_AddUserBtn__39obH {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}
.Merchant_Container__1HE7S {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Merchant_BreadcrumbWrapper__2ak54 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Merchant_Content__1-Gvx {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Merchant_Alert__25tam {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Merchant_Breadcrumb__2cvF1 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Merchant_Header__SN_KH {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Merchant_ByLine__3J8A6 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Merchant_Activity__187Va {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Merchant_WelcomeContainer__3MP8z {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Merchant_MerchantProfile__2UKua {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Merchant_TabHeader__2QyiL {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Merchant_PageHeader__phsx3 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Merchant_AddUser__2BUwF {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Merchant_AddUserBtn__1r7HU {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Merchant_ReportContainer__2Ei7C {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.Merchant_ReportTiles__3RA_s {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.Merchant_TileContainer__3izGm {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    /* width: 11.2rem; */
}

.Merchant_Tile__1wVnS {
    cursor: pointer;
    padding: 4px;
    display: flex;
    width: 20rem;
    background-color: #f2f2f2;
    justify-content: center;
    flex-direction: column;
    height: 100%;

}


.Merchant_Tile__1wVnS:hover {
    background-color: #e6e6e6;
}

.Merchant_TileInner__1XsN4 {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
}

.Merchant_TileIcon__2Ajp3 {
    margin-bottom: 5px;
}

.Merchant_TileText__3pOTV {
    font-size: 15px;
    color: #262626;
}
.Cachet_Container__2DuDe {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Cachet_BreadcrumbWrapper__FkNLd {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Cachet_Content__1VBNc {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Cachet_Alert__34hor {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Cachet_Breadcrumb__3ewxc {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Cachet_Header__2WEn0 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Cachet_ByLine__3ATJl {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Cachet_Activity__1v18E {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Cachet_WelcomeContainer__3JZ9N {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Cachet_MerchantProfile__1LB3Z {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Cachet_TabHeader__12xQL {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Cachet_PageHeader__d-7Ox {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Cachet_AddUser__1X903 {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Cachet_AddUserBtn__2q1Hx {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Cachet_ReportContainer__3l1ww {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.Cachet_ReportTiles__2mbIH {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.Cachet_TileContainer___6TiS {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}


.Cachet_Tile__168Yt {
    cursor: pointer;
    padding: 4px;
    display: flex;
    width: 20rem;
    background-color: #f2f2f2;
    justify-content: center;
    flex-direction: column;
    height: 100%;

}


.Cachet_Tile__168Yt:hover {
    background-color: #e6e6e6;
}

.Cachet_TileInner__2U6HB {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
}

.Cachet_TileIcon__xbsCu {
    margin-bottom: 5px;
}

.Cachet_TileText__pDVKN {
    font-size: 15px;
    color: #262626;
}
.Mail_Container__2yt5I {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Mail_Tools__3hq71 {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.Mail_PageFilter__2QJ_d {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.Mail_ToolsWrapper__22hnb {
    height: 6rem;
    width: 100%;

}

.Mail_PrintToolsWrapper__1sJuw {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Mail_AddNewMerchant__2Nx-l {
    /* // font-family: Cambay; */
}

/* .FilterReportBtn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
} */



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Mail_AddNewMerchantBtn__qaqrx:focus,
.Mail_AddNewMerchantBtn__qaqrx:hover,
.Mail_AddNewMerchantBtn__qaqrx:active {
    background-color: rgb(51, 137, 255);
}


.Mail_ToolsDataExportIconWrapper__h1jT5 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Mail_ToolsPrintIconWrapper__2Ynl_ {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Mail_FilterInput__2f03A {
    margin-right: 2rem;
}

.Mail_FormGroup__S4g_8 {
    /* margin-bottom: 10px; */
}

.Mail_FormGroupDefault__3xNfe.Mail_has-error__3SAvH {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Mail_FormGroupDefault__3xNfe {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Mail_FormGroupDefault__3xNfe label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Mail_FormGroup__S4g_8 label:not(.Mail_error__CDHrg) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Mail_FormControl__10hVH {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Mail_MerchantTableWrapper__3dXr4 {
    margin-top: 3rem;
    white-space: nowrap;
}

.Mail_TableWrapper__2G_Xu {
    /* margin: 0 2.5rem; */
}

.Mail_MerchantTable__ttuxZ {
    width: 100%;
    border-collapse: collapse;
}

.Mail_MerchantTable__ttuxZ th {
    text-align: left;
}

.Mail_MerchantTable__ttuxZ th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Mail_MerchantTable__ttuxZ td,
.Mail_MerchantTable__ttuxZ th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Mail_RevealMoreHeader__3TgLx {
    padding: 0;
    vertical-align: middle;
}





.Mail_Row__3I4gG {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Mail_Row__3I4gG>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Mail_dataTables_paginate__2UlCx {
    float: right;
}

.Mail_dataTables_paginate__2UlCx {
    /* margin-top: 25px !important; */
}

div.Mail_dataTables_paginate__2UlCx {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Mail_dataTables_paginate__2UlCx ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Mail_dataTables_paginate__2UlCx ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Mail_dataTables_paginate__2UlCx ul>li.Mail_disabled__1LsjN a {
    opacity: .5;
}

.Mail_dataTables_paginate__2UlCx ul>li.Mail_disabled__1LsjN a {
    opacity: .35;
}

.Mail_dataTables_paginate__2UlCx ul>li.Mail_active__3PVrp>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Mail_dataTables_paginate__2UlCx ul>li.Mail_next__2BMuj>a,
.Mail_dataTables_paginate__2UlCx ul>li.Mail_prev__2TFwv>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Mail_pg_arrow_right__1XeXC {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Mail_pg_arrow_left__1ZO_6 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Mail_pg_arrow_right__1XeXC:before {
    content: "\E631";
}

.Mail_pg_arrow_left__1ZO_6:before {
    content: "\E629";
}

.Mail_btn__WLy59 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Mail_btn_primary__1Zv5o,
.Mail_btn_primary__1Zv5o:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Mail_btn_cons__1VEen {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Mail_MerchantName__1eu3p {
    font-weight: 500;
}

.Mail_Icn__2uk6v {
    cursor: pointer;
    /* fill:#000; */
}

.Mail_Icn__2uk6v:hover {
    cursor: pointer;

}



.Mail_RevealMore__3C061 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Mail_RevealMore__3C061:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Mail_RevealMore__3C061 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Mail_RevealMoreExpanded__K21r8 {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Mail_Expandabled__2vnVo {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Mail_Expandabled__2vnVo td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Mail_MerchantTable__ttuxZ td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Mail_MerchantTable__ttuxZ td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Mail_MerchantRow__zIkN_ {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Mail_MerchantRow__zIkN_:hover {
    background-color: #267788;
    color: #fff;

}

.Mail_MerchantRow__zIkN_:hover a {
    color: #fff;
}

.Mail_MerchantRowExpanded__UeMKM {
    background: #267788;
    color: #fff;
}

.Mail_table__2syj0.Mail_table-condensed__2vDc7.Mail_table-detailed__1hXzr>tbody>tr.Mail_shown__1oCci>td {
    background: #fdf5df;
}


.Mail_Totals__nFG8v td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Mail_MoneyTotalLabel__2oqKE {
    float: right;

}

.Mail_FilterReportBtn__eHGts {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}
.Group_Container__2XA8L {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Group_BreadcrumbWrapper__38KLz {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Group_Content__1_Cmq {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Group_Alert__1uKwp {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Group_Breadcrumb__1ICyz {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Group_Header__22vPS {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Group_ByLine__3LGZh {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Group_Activity__130uu {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Group_WelcomeContainer__3uogs {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Group_MerchantProfile__1sBSD {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Group_TabHeader__n0v3j {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Group_PageHeader__2qul5 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Group_AddUser__2xR3E {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Group_AddUserBtn__1KQ2C {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Group_ReportContainer__WFmZI {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.Group_ReportTiles__SQbRr {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.Group_TileContainer__3zrCy {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}




.Group_Tile__1Stcv {
    cursor: pointer;
    padding: 4px;
    display: flex;
    width: 20rem;
    background-color: #f2f2f2;
    justify-content: center;
    flex-direction: column;
    height: 100%;

}


.Group_Tile__1Stcv:hover {
    background-color: #e6e6e6;
}

.Group_TileInner__Y-dtJ {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
}

.Group_TileIcon__2myJX {
    margin-bottom: 5px;
}

.Group_TileText__14K2q {
    font-size: 15px;
    color: #262626;
}
.ItemisedReport_Container__26_bU {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.ItemisedReport_BreadcrumbWrapper__2p-6p {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.ItemisedReport_Content__2-FMI {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.ItemisedReport_Alert__35pIm {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.ItemisedReport_Breadcrumb__125zC {
    padding: 1.8rem 0;
    /* flex: 0 0 50%; */
}

.ItemisedReport_Header__1BLyL {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.ItemisedReport_ByLine__2WZxd {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.ItemisedReport_Activity__lNcqB {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.ItemisedReport_WelcomeContainer__2HhpA {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.ItemisedReport_MerchantProfile__DPOLz {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.ItemisedReport_TabHeader__idRb2 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.ItemisedReport_PageHeader__2EI07 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.ItemisedReport_AddUser__DRTRP {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.ItemisedReport_AddUserBtn__9u9uK {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.ItemisedReport_ReportContainer__3M5-V {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.ItemisedReport_ReportTiles__3lsMP {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.ItemisedReport_TileContainer__qWU7C {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.ItemisedReport_Tile__1o7he {
    cursor: pointer;
    padding: 4px;
}

.ItemisedReport_TileInner__1Ev74 {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.Itemised_Container__1shFW {
  background-color: #fff;
  /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
  /* flex-grow: 1; */
  /* border-top: 1px solid #707070; */
  /* display: flex; */
  flex-direction: column;
  font-size: 1.4rem;
}

.Itemised_Tools__33p3D {
  display: flex;
  /* margin: 0 2.5rem; */
  padding-top: 1.4rem;
}

.Itemised_PageFilterTools__17MpV {
  display: flex;
  justify-content: flex-start;
  width: auto;
  margin-left: 15px;
  align-items: center;
}

.Itemised_PageFilter__s5OVy {
  /* display: flex; */
  flex: 1 1 80%;
  justify-content: flex-start;
  width: auto;
}

.Itemised_ToolsWrapper__2P97H {
  height: 6rem;
  width: 100%;
}

.Itemised_PrintToolsWrapper__3EB_R {
  display: flex;
  flex: 1 1 20%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
}

.Itemised_AddNewMerchant__39ISC {
  /* // font-family: Cambay; */
}

/* .FilterReportBtn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
} */

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Itemised_AddNewMerchantBtn__3v5C1:focus,
.Itemised_AddNewMerchantBtn__3v5C1:hover,
.Itemised_AddNewMerchantBtn__3v5C1:active {
  background-color: rgb(51, 137, 255);
}

.Itemised_ToolsDataExportIconWrapper__1BiHk {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.Itemised_ToolsPrintIconWrapper__12WvP {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.Itemised_FilterInput__stJiy {
  margin-right: 2rem;
  width: 170px;
}

.Itemised_FormGroup__3rtpN {
  /* margin-bottom: 10px; */
}

.Itemised_FormGroupDefault__20B8w.Itemised_has-error__10_v0 {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.Itemised_FormGroupDefault__20B8w {
  /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
  /* padding: 1.4rem 2.5rem .2em .5rem; */
  line-height: 2rem;
  position: relative;
  transition: all 0.2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.Itemised_FormGroupDefault__20B8w label {
  margin: 0;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.Itemised_FormGroup__3rtpN label:not(.Itemised_error__3Jnht) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.Itemised_FormControl__vR7In {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.Itemised_MerchantTableWrapper__21IQk {
  margin-top: 3rem;
  white-space: nowrap;
}

.Itemised_TableWrapper__3OUFW {
  /* margin: 0 2.5rem; */
}

.Itemised_MerchantTable__2YFyz {
  width: 100%;
  border-collapse: collapse;
}

.Itemised_MerchantTable__2YFyz th {
  text-align: left;
}

.Itemised_MerchantTable__2YFyz th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.Itemised_MerchantTable__2YFyz td,
.Itemised_MerchantTable__2YFyz th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Itemised_RevealMoreHeader__BRBed {
  padding: 0;
  vertical-align: middle;
}

.Itemised_Row__2e8RX {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem;
}

.Itemised_Row__2e8RX > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.Itemised_dataTables_paginate__13Wwf {
  float: right;
}

.Itemised_dataTables_paginate__13Wwf {
  /* margin-top: 25px !important; */
}

div.Itemised_dataTables_paginate__13Wwf {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.Itemised_dataTables_paginate__13Wwf ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Itemised_dataTables_paginate__13Wwf ul > li > a {
  padding: 5px 10px;
  color: #575757;
  opacity: 0.35;
  transition: opacity 0.3s ease;
}

.Itemised_dataTables_paginate__13Wwf ul > li.Itemised_disabled__2jVzm a {
  opacity: 0.5;
}

.Itemised_dataTables_paginate__13Wwf ul > li.Itemised_disabled__2jVzm a {
  opacity: 0.35;
}

.Itemised_dataTables_paginate__13Wwf ul > li.Itemised_active__RsNAs > a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.Itemised_dataTables_paginate__13Wwf ul > li.Itemised_next__2vJ2q > a,
.Itemised_dataTables_paginate__13Wwf ul > li.Itemised_prev__2EuFA > a {
  opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__3cVvV {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_left__3Bfcw {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__3cVvV:before {
  content: "\E631";
}

.Itemised_pg_arrow_left__3Bfcw:before {
  content: "\E629";
}

.Itemised_btn__34oga {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
}

.Itemised_btn_primary__a2Bjm,
.Itemised_btn_primary__a2Bjm:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.Itemised_btn_cons__j8rS6 {
  min-width: 120px;
}

/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

/* .Odd {
}

.Even {
} */

.Itemised_MerchantName__2vACb {
  font-weight: 500;
}

.Itemised_Icn__2ksyp {
  cursor: pointer;
  /* fill:#000; */
}

.Itemised_Icn__2ksyp:hover {
  cursor: pointer;
}

.Itemised_RevealMore__3QBk0 {
  background: #e1e1e1;
  cursor: pointer;
  width: 2.4rem;
}

.Itemised_RevealMore__3QBk0:hover {
  background: transparent;
  color: #707070;
  fill: #fff;
}

.Itemised_RevealMore__3QBk0 div {
  margin: 0 auto;
  width: 2.4rem;
  height: 2.4rem;
}

.Itemised_RevealMoreExpanded__1nUEU {
  background: transparent;
  fill: #fff;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.Itemised_Expandabled__Sb-iU {
  text-align: left;
  background: #d7effb;
  border-bottom: 1px solid rgb(56, 0, 60);
  display: table-row;
  z-index: 500;
}

.Itemised_Expandabled__Sb-iU td {
  padding: 2rem 2rem 2rem 5.6rem;
  border-bottom: 1px solid #e8e8e8;
}

.Itemised_MerchantTable__2YFyz td:last-child {
  border-right: 1px solid #e8e8e8;
}

.Itemised_MerchantTable__2YFyz td:first-child {
  border-left: 1px solid #e8e8e8;
}

.Itemised_MerchantRow__codee {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.Itemised_MerchantRow__codee:hover {
  background-color: #267788;
  color: #fff;
}

.Itemised_MerchantRow__codee:hover a {
  color: #fff;
}

.Itemised_MerchantRowExpanded__3frmj {
  background: #267788;
  color: #fff;
}

.Itemised_table__2oDih.Itemised_table-condensed__1Aswv.Itemised_table-detailed__1_RAz > tbody > tr.Itemised_shown__1hA13 > td {
  background: #fdf5df;
}

.Itemised_Totals__1OtnA td {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid #e8e8e8;
  font-weight: 700;
}

.Itemised_MoneyTotalLabel__27JYj {
  float: right;
}

.Itemised_FilterReportBtn__1nI7s {
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  height: 3.8rem;
  padding: 10px;
  text-decoration: none;
  border: none;
  display: block;
}

.details_Container__2I5nV {
  /* background-color: #fff; */
  flex-direction: column;
  height: 100%;
  font-size: 1.4rem;
}

.details_BreadcrumbWrapper__1y95Q {
  display: flex;
  background-color: transparent;
  width: 100%;
  padding-left: 2.4rem;
  flex: 0 0 7.2rem;
  height: 7.2rem;
}

.details_Content__33AOb {
  flex: 1 1 auto;
  display: flex;
  margin: 2.4rem;
  flex-direction: column;
}

.details_CardBody__3fdOo {
  margin-top: 1rem;
}

.details_Alert__2lUbR {
  padding: 18px;
  margin-bottom: 15px;
  border: 0;
  border-radius: 0;
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.details_Breadcrumb__7UfkN {
  padding: 1.8rem 0;
  flex: 0 0 50%;
}

.details_Header__WZZba {
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 1.5px;
  /* text-transform: uppercase; */
}

.details_ByLine__bl5mf {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.details_Activity__3ZmKZ {
  background: #fff;
  flex: 0 0 36rem;
  /* width: 36rem; */
}

.details_WelcomeContainer__1yU9n {
  display: flex;
  /* margin: 0 2.4rem 0 0; */
  /* height: 17.6rem; */
}

.details_MerchantProfile__90no9 {
  display: flex;
  background: transparent;
  flex: 0 0 100%;
}

.details_TabHeader__2Xod7 {
  /* padding: 0px 2rem; */
  margin-bottom: 1rem;
  display: flex;
}

.details_PageHeader__gQnCn {
  flex: 1 1 auto;
  justify-content: flex-start;
  width: auto;
}

.details_Header__WZZba {
  flex: 1 1 50%;
  justify-content: flex-start;
  width: auto;
}

.details_ExportReport__3uBxw {
  flex: 1 1 50%;
  width: auto;
  margin-right: 1.4rem;
  align-items: center;
  display: flex;

  line-height: normal;
  flex: 1 1 auto;
  justify-content: flex-end;
}

.details_ExportIconWrapper__1J4x6 {
  margin: 0 1.6rem;
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
  flex: 0 0 auto;
}

.details_EmailIconWrapper__2kBEe {
  margin: 0 1.6rem;
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
  flex: 0 0 auto;
}

.details_AddUser__14SiA {
  display: flex;
  margin-right: 20px;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.details_AddUserBtn__2SZX7 {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

.details_Tools__w5duO {
  /* display: flex; */
  margin: 1rem 0 3.5rem;
  /* padding-top: 1.4rem; */
}

.details_PageFilter__2QZ1b {
  /* display: flex; */
  flex: 1 1 auto;
  justify-content: flex-start;
  width: auto;
  margin-left: 15px;
}

.details_PrintToolsWrapper__poGEy {
  display: flex;
  flex: 1 1 20%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
}

.details_FilterReportBtn__1Wlq0 {
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  height: 3.4rem;
  padding: 8px;
  text-decoration: none;
  border: none;
  display: block;
}

.details_AddNewMerchantBtn__3OTta:focus,
.details_AddNewMerchantBtn__3OTta:hover,
.details_AddNewMerchantBtn__3OTta:active {
  background-color: rgb(51, 137, 255);
}

.details_ToolsDataExportIconWrapper__2XQCw {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.details_ToolsPrintIconWrapper__1FvB7 {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.details_FilterInput__2AfR3 {
  margin-right: 2rem;
}

.details_FormGroupDefault__xHUeC.details_has-error__3oQPB {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__xHUeC {
  line-height: 2rem;
  position: relative;
  transition: all 0.2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.details_FormGroupDefault__xHUeC label {
  margin: 0;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.details_FormGroup__3SfAS label:not(.details_error__34qJk) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.details_FormControl__Bzrig {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.details_MerchantTableWrapper__v5_oJ {
  margin-top: 3rem;
  white-space: nowrap;
}

.details_MerchantTable__3O4ra {
  width: 100%;
  border-collapse: collapse;
}

.details_MerchantTable__3O4ra th {
  text-align: left;
}

.details_MerchantTable__3O4ra th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.details_MerchantTable__3O4ra td,
.details_MerchantTable__3O4ra th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__3cmWu {
  padding: 0;
  vertical-align: middle;
}

.details_Row__8HzkC {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem;
}

.details_Row__8HzkC > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.details_dataTables_paginate__35jN7 {
  float: right;
}

div.details_dataTables_paginate__35jN7 {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.details_dataTables_paginate__35jN7 ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

.details_dataTables_paginate__35jN7 ul > li > a {
  padding: 5px 10px;
  color: #575757;
  opacity: 0.35;
  transition: opacity 0.3s ease;
}

.details_dataTables_paginate__35jN7 ul > li.details_disabled__26Gvv a {
  opacity: 0.5;
}

.details_dataTables_paginate__35jN7 ul > li.details_disabled__26Gvv a {
  opacity: 0.35;
}

.details_dataTables_paginate__35jN7 ul > li.details_active__3Kr5U > a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.details_dataTables_paginate__35jN7 ul > li.details_next__3mmCD > a,
.details_dataTables_paginate__35jN7 ul > li.details_prev__10grk > a {
  opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__2avau {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__19puw {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__2avau:before {
  content: "\E631";
}

.details_pg_arrow_left__19puw:before {
  content: "\E629";
}

.details_btn__3lKx3 {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
}

.details_btn_primary__2RNEi,
.details_btn_primary__2RNEi:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.details_btn_cons__3_xt0 {
  min-width: 120px;
}

.details_MerchantName__2Jixm {
  font-weight: 500;
}

.details_Icn__3PZvO {
  cursor: pointer;
  /* fill:#000; */
}

.details_Icn__3PZvO:hover {
  cursor: pointer;
}

.details_RevealMore__1S9Nj {
  background: #e1e1e1;
  cursor: pointer;
  width: 2.4rem;
}

.details_RevealMore__1S9Nj:hover {
  background: transparent;
  color: #707070;
  fill: #fff;
}

.details_RevealMore__1S9Nj div {
  margin: 0 auto;
  width: 2.4rem;
  height: 2.4rem;
}

.details_RevealMoreExpanded__PGdCx {
  background: transparent;
  fill: #fff;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.details_Expandabled__2_cGx {
  text-align: left;
  background: #d7effb;
  border-bottom: 1px solid rgb(56, 0, 60);
  display: table-row;
  z-index: 500;
}

.details_Expandabled__2_cGx td {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__3O4ra td:last-child {
  border-right: 1px solid #e8e8e8;
}

.details_MerchantTable__3O4ra td:first-child {
  border-left: 1px solid #e8e8e8;
}

.details_MerchantRow__3Fkqa {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.details_MerchantRow__3Fkqa:hover {
  background-color: #267788;
  color: #fff;
}

.details_MerchantRow__3Fkqa:hover a {
  color: #fff;
}

.details_MerchantRowExpanded__1QAl0 {
  background: #267788;
  color: #fff;
}

.details_table__KnYJl.details_table-condensed__1VZFc.details_table-detailed__3zp7V > tbody > tr.details_shown__KGAtB > td {
  background: #fdf5df;
}

.details_Totals__m1hTk td {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid #e8e8e8;
  font-weight: 700;
}

.details_MoneyTotalLabel__3pn1U {
  float: right;
}

.details_FilterWrapper__35sAO {
  display: flex;
  justify-content: flex-end;
  border-right: solid #707070;
}

.details_FilterPeriods__2l3AF {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row;
  /* height: 100%; */
  padding-left: 24px;
  color: rgb(0, 0, 0);
  justify-content: flex-end;
  margin-right: 3.2rem;
}

.details_FilterPeriods__2l3AF li {
  font-weight: 600;

  padding-left: 2rem;
  line-height: 38px;
}

.details_FinancialsIntro__2cIVs {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.details_ExpandableView__1U1cV {
  background-color: transparent;
  /* font-family: Cambay; */
}

.details_Outlets__8elW1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* margin-left: 0.6rem; */
}

.details_Outlets__8elW1 li {
  display: inline-block;
  list-style: none;
}

.details_Outlet__3l1j8 {
  display: inline-block;
  width: 15rem;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  background: #fff;
  margin-right: 1rem;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

.details_Outlet__3l1j8:hover {
  color: #fff;
  background: #5ba6e6;
}

.details_OutletName__2CuEu {
  font-weight: 600;
}

.details_OutletLocation__1qqON {
  font-weight: 400;
}

.details_ExpandableMember__1Nos3 {
  float: left;
  text-decoration: none;
}

.details_ExpandableMemberName__2H_m1 {
  font-weight: 600;
  font-size: 2.2rem;
  display: inline-block;
  vertical-align: middle;
}

.details_OutletListArea__1IcJl {
  width: 100%;
  margin-top: 1rem;
  background: transparent;
}

.details_MemberContractDetailArea__I0J8y {
  width: 100%;
  margin-top: 1rem;
  padding: 2rem 0px;
  background: rgb(255, 255, 255);
}

.details_MemberContractDetailArea__I0J8y header {
  padding: 0px 2rem;
  margin-bottom: 1rem;
}

.details_ExpandableMemberWrapper__1QLKQ {
  background: transparent;
}

.details_clearfix__1aPgN::before,
.details_clearfix__1aPgN::after {
  display: table;
  content: " ";
}

.details_clearfix__1aPgN::after {
  clear: both;
}

.details_FuneralSchemeWrapper__210r6 {
  margin: 0 2.5rem;
}

.details_AddNewMember__29hUH {
  float: right;
  margin-right: 8.8rem;
}

.details_AddNewMemberBtn__3RHVm {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  vertical-align: middle;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

.details_FuneralSchemeTable__3HhuN {
  width: 100%;
  border-collapse: collapse;
}

.details_FuneralSchemeTable__3HhuN th {
  text-align: left;
}

.details_FuneralSchemeTable__3HhuN th {
  color: #666;
  vertical-align: top;
  font-size: 1.2rem;
  font-family: Raleway;
  font-weight: 600;
  vertical-align: middle;
}

.details_FuneralSchemeTable__3HhuN td,
.details_FuneralSchemeTable__3HhuN th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.details_FuneralSchemeTable__3HhuN .details_Totals__m1hTk td {
  border-bottom: none;
  font-weight: 600;
}

.details_FuneralSchemeTable__3HhuN .details_MoneyTotalLabel__3pn1U {
  float: right;
}
.details_FuneralSchemeTable__3HhuN .details_MoneyTotal__1Jvl4 {
}

/* .Odd {
}

.Even {
} */

.details_MemberName__1LhN3 {
    font-weight: 500;
}

.details_Icn__7H-h6 {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__7H-h6:hover {
    cursor: pointer;

}

.details_RevealMore__jYw2E {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__jYw2E:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.details_RevealMore__jYw2E div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__qsvTO {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .details_Expandabled__zBXYa {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__zBXYa td {
    padding: 2rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .details_MemberRow__294Qf {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MemberRow__294Qf:hover {
    background-color: #267788;
    color:#fff;

    }

    .details_MemberRow__294Qf:hover a {
        color:#fff;
        }

    .details_MemberRowExpanded__2Lt2_ {
        background: #267788;
        color:#fff;
    }

.details_table__2bFlu.details_table-condensed__2kgzj.details_table-detailed__d82ot > tbody > tr.details_shown__3vb4X > td {
    background: #fdf5df;
}

.Category_Container__34bIJ {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Category_BreadcrumbWrapper__3MR3v {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Category_Content__YQYcH {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Category_Alert__O3MbW {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Category_Breadcrumb__1uxFQ {
    padding: 1.8rem 0;
    /* flex: 0 0 50%; */
}

.Category_Header__2QY53 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Category_ByLine__3oBtq {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Category_Activity__2giGX {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Category_WelcomeContainer__3-QBL {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Category_MerchantProfile__pT-mm {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Category_TabHeader__23iyz {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Category_PageHeader__H28Of {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Category_AddUser__246al {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Category_AddUserBtn__3p3ga {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Category_ReportContainer__2gmOC {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.Category_ReportTiles__2zkGl {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.Category_TileContainer__1HtrD {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Category_Tile__1phqm {
    cursor: pointer;
    padding: 4px;
}

.Category_TileInner__2b30F {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}

.Category_FilterReportBtn__NX7W0 {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}
.ReportBody_Container__2qyx6 {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__8kgi5 {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.ReportBody_PageFilter__sOGFQ {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.ReportBody_ToolsWrapper__1h1c- {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__1UjnA {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__1gzy4 {
    /* // font-family: Cambay; */
}


.ReportBody_FilterReportBtn__nkt9M {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}


/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__1ub7O:focus,
.ReportBody_AddNewMerchantBtn__1ub7O:hover,
.ReportBody_AddNewMerchantBtn__1ub7O:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__36jlN {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__2YcfJ {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__2SNri {
    margin-right: 2rem;
}

.ReportBody_FormGroup__X1B23 {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__1b2uT.ReportBody_has-error__2vceA {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__1b2uT {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__1b2uT label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__X1B23 label:not(.ReportBody_error__3Walq) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__1eSQH {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__2Kdlw {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__kdr5O {
    /* margin: 0 2.5rem; */
}

.ReportBody_MerchantTable__2_hG9 {
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__2_hG9 th {
    text-align: left;
}

.ReportBody_MerchantTable__2_hG9 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__2_hG9 td,
.ReportBody_MerchantTable__2_hG9 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__1H7Dh {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__3q8vE {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__3q8vE>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__3bdDE {
    float: right;
}

.ReportBody_dataTables_paginate__3bdDE {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__3bdDE {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__3bdDE ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__3bdDE ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__3bdDE ul>li.ReportBody_disabled__KWvsb a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__3bdDE ul>li.ReportBody_disabled__KWvsb a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__3bdDE ul>li.ReportBody_active__2oxLo>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__3bdDE ul>li.ReportBody_next__39ig2>a,
.ReportBody_dataTables_paginate__3bdDE ul>li.ReportBody_prev__1J8wF>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__3707S {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__ng9wR {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__3707S:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__ng9wR:before {
    content: "\E629";
}

.ReportBody_btn__sM23c {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__h66B3,
.ReportBody_btn_primary__h66B3:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__3oakX {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__2ZIAh {
    font-weight: 500;
}

.ReportBody_Icn__lyZNg {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__lyZNg:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__AnOOr {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__AnOOr:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__AnOOr div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__25LiO {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__3LOEM {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__3LOEM td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__2_hG9 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__2_hG9 td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__2DuCV {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__2DuCV:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__2DuCV:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__2-CRg {
    background: #267788;
    color: #fff;
}

.ReportBody_table__12wR7.ReportBody_table-condensed__137ik.ReportBody_table-detailed__VP025>tbody>tr.ReportBody_shown__1NBuf>td {
    background: #fdf5df;
}


.ReportBody_Totals__1RhvL td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__lWrw0 {
    float: right;

}
.User_Container__20bak {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.User_BreadcrumbWrapper__1P0fC {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.User_Content__3Kd_G {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.User_Alert__10poo {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.User_Breadcrumb__vg-PD {
    padding: 1.8rem 0;
    /* flex: 0 0 50%; */
}

.User_Header__3z_CH {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.User_ByLine__1cY-t {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.User_Activity__2fljs {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.User_WelcomeContainer__37Hn6 {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.User_MerchantProfile__hS2q_ {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.User_TabHeader__2lFBQ {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.User_PageHeader__ioC-H {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.User_AddUser__3Wkqu {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.User_AddUserBtn__qr26T {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.User_ReportContainer__2GRCR {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.User_ReportTiles__1M0M9 {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.User_TileContainer__3_Ig8 {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.User_Tile__1ibDz {
    cursor: pointer;
    padding: 4px;
}

.User_TileInner__320tG {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__1oMbO {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__10_ye {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.ReportBody_PageFilter__1BptL {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.ReportBody_ToolsWrapper__cWmpU {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__3dkqY {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__h1U4p {
    /* // font-family: Cambay; */
}

.ReportBody_FilterReportBtn__3c1YQ {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__3IFfy:focus,
.ReportBody_AddNewMerchantBtn__3IFfy:hover,
.ReportBody_AddNewMerchantBtn__3IFfy:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__34rRi {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__1LJ7w {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__2zAVI {
    margin-right: 2rem;
}

.ReportBody_FormGroup__1hp_8 {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__8wkvF.ReportBody_has-error__1GrxI {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__8wkvF {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__8wkvF label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__1hp_8 label:not(.ReportBody_error__3hnBq) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__1tSU7 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__2cDyK {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__zTdc_ {
    /* margin: 0 2.5rem; */
}

.ReportBody_MerchantTable__1FYOt {
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__1FYOt th {
    text-align: left;
}

.ReportBody_MerchantTable__1FYOt th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__1FYOt td,
.ReportBody_MerchantTable__1FYOt th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__HL5d2 {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__2Ulnq {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__2Ulnq>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__30G_D {
    float: right;
}

.ReportBody_dataTables_paginate__30G_D {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__30G_D {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__30G_D ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__30G_D ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__30G_D ul>li.ReportBody_disabled__1jqZD a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__30G_D ul>li.ReportBody_disabled__1jqZD a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__30G_D ul>li.ReportBody_active__iDZhE>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__30G_D ul>li.ReportBody_next__3lgNB>a,
.ReportBody_dataTables_paginate__30G_D ul>li.ReportBody_prev__l6sut>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__2NA9S {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__1t43y {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__2NA9S:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__1t43y:before {
    content: "\E629";
}

.ReportBody_btn__1kNq_ {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__2S-7V,
.ReportBody_btn_primary__2S-7V:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__1uMUP {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__2lcAi {
    font-weight: 500;
}

.ReportBody_Icn__2olcm {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__2olcm:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__3iq2f {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__3iq2f:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__3iq2f div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__3dtwy {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__37Kcj {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__37Kcj td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__1FYOt td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__1FYOt td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__g2Bpi {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__g2Bpi:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__g2Bpi:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__2TO6L {
    background: #267788;
    color: #fff;
}

.ReportBody_table__2onom.ReportBody_table-condensed__1eDjG.ReportBody_table-detailed__1j6io>tbody>tr.ReportBody_shown__Rz1Jo>td {
    background: #fdf5df;
}


.ReportBody_Totals__vYmMJ td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__2RUvJ {
    float: right;

}
.Outlet_Container__1xNLv {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Outlet_BreadcrumbWrapper__311nz {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Outlet_Content__3MAO2 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Outlet_Alert__klQK9 {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Outlet_Breadcrumb__1EF0G {
    padding: 1.8rem 0;
    /* flex: 0 0 50%; */
}

.Outlet_Header__3s258 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Outlet_ByLine__3glE5 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Outlet_Activity__GU5xm {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Outlet_WelcomeContainer__284DA {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Outlet_MerchantProfile__2CTL8 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Outlet_TabHeader__2GF0Q {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Outlet_PageHeader__3ZRcR {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Outlet_AddUser__2MdAp {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Outlet_AddUserBtn__DtfUq {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Outlet_ReportContainer__1ruVP {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.Outlet_ReportTiles__18oEa {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.Outlet_TileContainer__tqtUL {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Outlet_Tile__3ARN9 {
    cursor: pointer;
    padding: 4px;
}

.Outlet_TileInner__2zg-T {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__Tn7rt {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__qwYoA {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.ReportBody_PageFilter__1ncjN {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.ReportBody_ToolsWrapper__1mciR {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__3zLM8 {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__1xfu4 {
    /* // font-family: Cambay; */
}

.ReportBody_FilterReportBtn__2nUbT {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__1ZqxX:focus,
.ReportBody_AddNewMerchantBtn__1ZqxX:hover,
.ReportBody_AddNewMerchantBtn__1ZqxX:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__g3U2v {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__1pqtt {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__3Ntsn {
    margin-right: 2rem;
}

.ReportBody_FormGroup__2mVsX {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault___DKh2.ReportBody_has-error__uqUsa {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault___DKh2 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault___DKh2 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__2mVsX label:not(.ReportBody_error__2oHnw) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__3K2wZ {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__1B73m {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__3XmW0 {
    /* margin: 0 2.5rem; */
}

.ReportBody_MerchantTable__x5k1b {
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__x5k1b th {
    text-align: left;
}

.ReportBody_MerchantTable__x5k1b th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__x5k1b td,
.ReportBody_MerchantTable__x5k1b th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__1BEnY {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__33whD {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__33whD>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__2jH3O {
    float: right;
}

.ReportBody_dataTables_paginate__2jH3O {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__2jH3O {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__2jH3O ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__2jH3O ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__2jH3O ul>li.ReportBody_disabled__uFF1Y a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__2jH3O ul>li.ReportBody_disabled__uFF1Y a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__2jH3O ul>li.ReportBody_active__2Bib1>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__2jH3O ul>li.ReportBody_next__L8Ipa>a,
.ReportBody_dataTables_paginate__2jH3O ul>li.ReportBody_prev__3OZA_>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__3Mbcg {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__-8GLy {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__3Mbcg:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__-8GLy:before {
    content: "\E629";
}

.ReportBody_btn__3LMaS {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__2EeO3,
.ReportBody_btn_primary__2EeO3:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__1ywvc {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__elFPd {
    font-weight: 500;
}

.ReportBody_Icn__2wRaK {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__2wRaK:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__3lLFG {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__3lLFG:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__3lLFG div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__1GOWK {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__2LlB8 {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__2LlB8 td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__x5k1b td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__x5k1b td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__2yNfF {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__2yNfF:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__2yNfF:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__2xUAD {
    background: #267788;
    color: #fff;
}

.ReportBody_table__UbLil.ReportBody_table-condensed__2v2_n.ReportBody_table-detailed__3zDaJ>tbody>tr.ReportBody_shown__3PNfU>td {
    background: #fdf5df;
}


.ReportBody_Totals__3a9fq td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__3vnbs {
    float: right;

}
.Date_Container__jYDAw {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Date_BreadcrumbWrapper__3gsuf {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Date_Content__SKwvp {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Date_Alert__R7sXa {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Date_Breadcrumb__1yLa1 {
    padding: 1.8rem 0;
    /* flex: 0 0 50%; */
}

.Date_Header__16tgi {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Date_ByLine__2XLSd {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Date_Activity__WgW-d {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Date_WelcomeContainer__zw-Ak {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Date_MerchantProfile__3dYto {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Date_TabHeader__32Ql1 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Date_PageHeader__3eZVd {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Date_AddUser__2mri- {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Date_AddUserBtn__1cKJE {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Date_ReportContainer__3O4lw {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.Date_ReportTiles__2t22j {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.Date_TileContainer__17B_Q {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Date_Tile__1Qvha {
    cursor: pointer;
    padding: 4px;
}

.Date_TileInner__IbjKM {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__20AOX {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__18EfL {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.ReportBody_PageFilter__1LlsQ {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.ReportBody_ToolsWrapper__3T9zJ {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__2ieX3 {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__1GFwz {
    /* // font-family: Cambay; */
}

.ReportBody_FilterReportBtn__3Gwmg {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__3zeDC:focus,
.ReportBody_AddNewMerchantBtn__3zeDC:hover,
.ReportBody_AddNewMerchantBtn__3zeDC:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__mb5Qv {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__1_6kx {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__3CGKU {
    margin-right: 2rem;
}

.ReportBody_FormGroup__19r2_ {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__10GQM.ReportBody_has-error__1h3bZ {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__10GQM {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__10GQM label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__19r2_ label:not(.ReportBody_error__2Meol) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__3Vc3B {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__Ll8l2 {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__1d4Nz {
    /* margin: 0 2.5rem; */
}

.ReportBody_MerchantTable__1V5CV {
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__1V5CV th {
    text-align: left;
}

.ReportBody_MerchantTable__1V5CV th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__1V5CV td,
.ReportBody_MerchantTable__1V5CV th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__ZO7iv {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__s2kh8 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__s2kh8>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__1XlZw {
    float: right;
}

.ReportBody_dataTables_paginate__1XlZw {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__1XlZw {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__1XlZw ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__1XlZw ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__1XlZw ul>li.ReportBody_disabled__2DIAQ a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__1XlZw ul>li.ReportBody_disabled__2DIAQ a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__1XlZw ul>li.ReportBody_active__3GkiY>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__1XlZw ul>li.ReportBody_next__1-Lx7>a,
.ReportBody_dataTables_paginate__1XlZw ul>li.ReportBody_prev__WUurr>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__36z8x {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__kEfOL {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__36z8x:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__kEfOL:before {
    content: "\E629";
}

.ReportBody_btn__M98_l {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__8PJbe,
.ReportBody_btn_primary__8PJbe:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__eNYCo {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__2TG62 {
    font-weight: 500;
}

.ReportBody_Icn__1BhFH {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__1BhFH:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__3xHNZ {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__3xHNZ:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__3xHNZ div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__2-KDv {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__2Jdwh {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__2Jdwh td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__1V5CV td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__1V5CV td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__1n35m {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__1n35m:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__1n35m:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__1LTaN {
    background: #267788;
    color: #fff;
}

.ReportBody_table__qSinm.ReportBody_table-condensed__c0FZW.ReportBody_table-detailed__1n9PX>tbody>tr.ReportBody_shown__Nl8Wv>td {
    background: #fdf5df;
}


.ReportBody_Totals__1wCeA td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__2koMq {
    float: right;

}
.ItemisedReport_Container__18w0d {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.ItemisedReport_BreadcrumbWrapper__3yeit {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.ItemisedReport_Content__2cmWb {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.ItemisedReport_Alert__2SnzL {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.ItemisedReport_Breadcrumb__2Mk-D {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.ItemisedReport_Header__1f-_d {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.ItemisedReport_ByLine__1I0EZ {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.ItemisedReport_Activity__w7WfZ {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.ItemisedReport_WelcomeContainer__19u2X {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.ItemisedReport_MerchantProfile__1K9lC {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.ItemisedReport_TabHeader__31a0z {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.ItemisedReport_PageHeader__1AMWI {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.ItemisedReport_AddUser__2bHTW {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.ItemisedReport_AddUserBtn__bHhcb {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.ItemisedReport_ReportContainer__2YkxW {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.ItemisedReport_ReportTiles__LIIHr {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.ItemisedReport_TileContainer__3ZtV2 {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.ItemisedReport_Tile__1kN9Y {
    cursor: pointer;
    padding: 4px;
}

.ItemisedReport_TileInner__dA8F3 {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.Itemised_Container__35JBi {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Itemised_Tools__zczUF {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.Itemised_PageFilter__3yJU6 {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.Itemised_ToolsWrapper__3sIFm {
    height: 6rem;
    width: 100%;

}

.Itemised_PrintToolsWrapper__fc6_H {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Itemised_AddNewMerchant__1lsSw {
    /* // font-family: Cambay; */
}

/* .FilterReportBtn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
} */



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Itemised_AddNewMerchantBtn__171ik:focus,
.Itemised_AddNewMerchantBtn__171ik:hover,
.Itemised_AddNewMerchantBtn__171ik:active {
    background-color: rgb(51, 137, 255);
}


.Itemised_ToolsDataExportIconWrapper__2EV9U {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Itemised_ToolsPrintIconWrapper__2VnP0 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Itemised_FilterInput__2yIq9 {
    margin-right: 2rem;
}

.Itemised_FormGroup__3sNlA {
    /* margin-bottom: 10px; */
}

.Itemised_FormGroupDefault__2iZPr.Itemised_has-error__iNHpY {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Itemised_FormGroupDefault__2iZPr {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Itemised_FormGroupDefault__2iZPr label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Itemised_FormGroup__3sNlA label:not(.Itemised_error__1Ti7D) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Itemised_FormControl__38m9r {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Itemised_MerchantTableWrapper__gRpVM {
    margin-top: 3rem;
    white-space: nowrap;
}

.Itemised_TableWrapper__jtd5Z {
    /* margin: 0 2.5rem; */
}

.Itemised_MerchantTable__1WRiv {
    width: 100%;
    border-collapse: collapse;
}

.Itemised_MerchantTable__1WRiv th {
    text-align: left;
}

.Itemised_MerchantTable__1WRiv th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Itemised_MerchantTable__1WRiv td,
.Itemised_MerchantTable__1WRiv th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Itemised_RevealMoreHeader__2RQvv {
    padding: 0;
    vertical-align: middle;
}





.Itemised_Row__3lDx8 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Itemised_Row__3lDx8>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Itemised_dataTables_paginate__oVHtV {
    float: right;
}

.Itemised_dataTables_paginate__oVHtV {
    /* margin-top: 25px !important; */
}

div.Itemised_dataTables_paginate__oVHtV {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Itemised_dataTables_paginate__oVHtV ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Itemised_dataTables_paginate__oVHtV ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Itemised_dataTables_paginate__oVHtV ul>li.Itemised_disabled__3WVLg a {
    opacity: .5;
}

.Itemised_dataTables_paginate__oVHtV ul>li.Itemised_disabled__3WVLg a {
    opacity: .35;
}

.Itemised_dataTables_paginate__oVHtV ul>li.Itemised_active__1qT1i>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Itemised_dataTables_paginate__oVHtV ul>li.Itemised_next__357gj>a,
.Itemised_dataTables_paginate__oVHtV ul>li.Itemised_prev__3YhQ7>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__IMROM {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_left__1XXwB {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__IMROM:before {
    content: "\E631";
}

.Itemised_pg_arrow_left__1XXwB:before {
    content: "\E629";
}

.Itemised_btn__3UP4F {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Itemised_btn_primary__2USJz,
.Itemised_btn_primary__2USJz:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Itemised_btn_cons__3-yPH {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Itemised_MerchantName__3jDxZ {
    font-weight: 500;
}

.Itemised_Icn__2h-tm {
    cursor: pointer;
    /* fill:#000; */
}

.Itemised_Icn__2h-tm:hover {
    cursor: pointer;

}



.Itemised_RevealMore__1BG5A {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Itemised_RevealMore__1BG5A:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Itemised_RevealMore__1BG5A div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Itemised_RevealMoreExpanded__3u_TK {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Itemised_Expandabled__DhaBl {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Itemised_Expandabled__DhaBl td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Itemised_MerchantTable__1WRiv td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Itemised_MerchantTable__1WRiv td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Itemised_MerchantRow__3lYy4 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Itemised_MerchantRow__3lYy4:hover {
    background-color: #267788;
    color: #fff;

}

.Itemised_MerchantRow__3lYy4:hover a {
    color: #fff;
}

.Itemised_MerchantRowExpanded__3_ZZ6 {
    background: #267788;
    color: #fff;
}

.Itemised_table__MTp5h.Itemised_table-condensed__36thk.Itemised_table-detailed__27b4C>tbody>tr.Itemised_shown__3EcSn>td {
    background: #fdf5df;
}


.Itemised_Totals__Px9sO td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Itemised_MoneyTotalLabel__1Thu_ {
    float: right;

}

.Itemised_FilterReportBtn__1SyCQ {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}
.Category_Container__1gTJ_ {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Category_BreadcrumbWrapper__1WVTQ {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Category_Content__3KAmg {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Category_Alert__2nmqT {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Category_Breadcrumb__Xi3vq {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Category_Header__22OUS {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Category_ByLine__3g-V1 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Category_Activity__3_wo- {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Category_WelcomeContainer__oE6x_ {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Category_MerchantProfile__2yPDq {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Category_TabHeader__1s6Ly {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Category_PageHeader__2Anui {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Category_AddUser__2-Kyn {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Category_AddUserBtn__1l7v4 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Category_ReportContainer__2nhmo {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.Category_ReportTiles__obkuP {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.Category_TileContainer__ahc2F {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Category_Tile__2vcQI {
    cursor: pointer;
    padding: 4px;
}

.Category_TileInner__3Upii {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__X9X10 {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__3S2fO {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.ReportBody_PageFilter__3EaOf {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.ReportBody_ToolsWrapper__3z_Qp {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__2iMP4 {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__37YcJ {
    /* // font-family: Cambay; */
}

/* .FilterReportBtn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
} */



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__DGoqB:focus,
.ReportBody_AddNewMerchantBtn__DGoqB:hover,
.ReportBody_AddNewMerchantBtn__DGoqB:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__1kHCu {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__NNKVp {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__2GNuI {
    margin-right: 2rem;
}

.ReportBody_FormGroup__Tfj_n {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__dbd9c.ReportBody_has-error__3T2Vq {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__dbd9c {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__dbd9c label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__Tfj_n label:not(.ReportBody_error__3fui6) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__1xHXQ {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__3NQkI {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__1EH0b {
    /* margin: 0 2.5rem; */
}

.ReportBody_MerchantTable__1U4_9 {
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__1U4_9 th {
    text-align: left;
}

.ReportBody_MerchantTable__1U4_9 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__1U4_9 td,
.ReportBody_MerchantTable__1U4_9 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__1Cmbm {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__2VDqo {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__2VDqo>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__2wumB {
    float: right;
}

.ReportBody_dataTables_paginate__2wumB {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__2wumB {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__2wumB ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__2wumB ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__2wumB ul>li.ReportBody_disabled__1MZGL a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__2wumB ul>li.ReportBody_disabled__1MZGL a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__2wumB ul>li.ReportBody_active__kLxJu>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__2wumB ul>li.ReportBody_next__2iA_J>a,
.ReportBody_dataTables_paginate__2wumB ul>li.ReportBody_prev__2z55U>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__2QQUS {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__1TnXh {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__2QQUS:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__1TnXh:before {
    content: "\E629";
}

.ReportBody_btn__1-cM2 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__34EvX,
.ReportBody_btn_primary__34EvX:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__1IGg_ {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__30cB1 {
    font-weight: 500;
}

.ReportBody_Icn__2JQ4Y {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__2JQ4Y:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__3Vcdc {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__3Vcdc:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__3Vcdc div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__3oc2i {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__2t2xi {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__2t2xi td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__1U4_9 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__1U4_9 td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__2qrzy {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__2qrzy:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__2qrzy:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__2BD-M {
    background: #267788;
    color: #fff;
}

.ReportBody_table__3KW4b.ReportBody_table-condensed__1MyJF.ReportBody_table-detailed__1GTXi>tbody>tr.ReportBody_shown__IiZh3>td {
    background: #fdf5df;
}


.ReportBody_Totals__1unk2 td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__7Mcmu {
    float: right;

}

.ReportBody_FilterReportBtn__3v5iY {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}
.Date_Container__1utW9 {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Date_BreadcrumbWrapper__15hl- {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Date_Content__31QbU {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Date_Alert__2t-9f {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Date_Breadcrumb__12WgQ {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Date_Header__3-HPC {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Date_ByLine__2jyBo {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Date_Activity__1McC7 {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Date_WelcomeContainer__3v5qb {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Date_MerchantProfile__3GsX3 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Date_TabHeader__mghVM {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Date_PageHeader__3IDXQ {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Date_AddUser__IBH2u {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Date_AddUserBtn__8gF81 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Date_ReportContainer__1JZAP {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.Date_ReportTiles__2hG7O {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.Date_TileContainer__249bj {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Date_Tile__y1XGh {
    cursor: pointer;
    padding: 4px;
}

.Date_TileInner__2FATp {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__2jVNh {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__3ZDdf {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}

.ReportBody_PageFilter__1UAbH {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.ReportBody_ToolsWrapper__QhN9T {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__1EfMa {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__Baw15 {
    /* // font-family: Cambay; */
}

/* .FilterReportBtn {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
} */



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__1dL9o:focus,
.ReportBody_AddNewMerchantBtn__1dL9o:hover,
.ReportBody_AddNewMerchantBtn__1dL9o:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__2FC8N {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__2NtPy {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__22Amh {
    margin-right: 2rem;
}

.ReportBody_FormGroup__kdyvi {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__hEJH4.ReportBody_has-error__30jpJ {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__hEJH4 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__hEJH4 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__kdyvi label:not(.ReportBody_error__3gTVP) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__2c14o {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__3w9DE {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__2kfJb {
    /* margin: 0 2.5rem; */
}

.ReportBody_MerchantTable__7PYuV {
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__7PYuV th {
    text-align: left;
}

.ReportBody_MerchantTable__7PYuV th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__7PYuV td,
.ReportBody_MerchantTable__7PYuV th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__29AvC {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__1F4hj {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__1F4hj>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__2rYQf {
    float: right;
}

.ReportBody_dataTables_paginate__2rYQf {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__2rYQf {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__2rYQf ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__2rYQf ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__2rYQf ul>li.ReportBody_disabled__3fPyu a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__2rYQf ul>li.ReportBody_disabled__3fPyu a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__2rYQf ul>li.ReportBody_active__1f8_a>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__2rYQf ul>li.ReportBody_next__utSJg>a,
.ReportBody_dataTables_paginate__2rYQf ul>li.ReportBody_prev__xoetI>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__3YRT8 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__3GIUH {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__3YRT8:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__3GIUH:before {
    content: "\E629";
}

.ReportBody_btn__K9gTt {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__2sCHZ,
.ReportBody_btn_primary__2sCHZ:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__IZlX6 {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__3noYP {
    font-weight: 500;
}

.ReportBody_Icn__3newQ {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__3newQ:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__3Nugr {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__3Nugr:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__3Nugr div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__3V9Yu {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__3EkHZ {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__3EkHZ td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__7PYuV td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__7PYuV td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__1301K {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__1301K:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__1301K:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__2P6lE {
    background: #267788;
    color: #fff;
}

.ReportBody_table__2ucRl.ReportBody_table-condensed__2lM29.ReportBody_table-detailed__3qao9>tbody>tr.ReportBody_shown__1DrYT>td {
    background: #fdf5df;
}


.ReportBody_Totals__l84Xt td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__3jHeD {
    float: right;

}

.ReportBody_FilterReportBtn__VW32z {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}
.ItemisedReport_Container__3agaA {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.ItemisedReport_BreadcrumbWrapper__1TmDL {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.ItemisedReport_Content__20fRH {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.ItemisedReport_Alert__2lN8N {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.ItemisedReport_Breadcrumb__2SlVn {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.ItemisedReport_Header__jQpR2 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.ItemisedReport_ByLine__1S1Ru {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.ItemisedReport_Activity__323Lp {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.ItemisedReport_WelcomeContainer__2fp6L {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.ItemisedReport_MerchantProfile__jBLQy {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.ItemisedReport_TabHeader__2sNUv {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.ItemisedReport_PageHeader__2a3DO {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.ItemisedReport_AddUser__L6uoq {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.ItemisedReport_AddUserBtn__1vFLa {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.ItemisedReport_ReportContainer__1N9x2 {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.ItemisedReport_ReportTiles__1lRyU {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.ItemisedReport_TileContainer__34xd5 {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.ItemisedReport_Tile__1ZLZY {
    cursor: pointer;
    padding: 4px;
}

.ItemisedReport_TileInner__1QtBg {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.PresetDateRangePicker_panel {
    padding: 0 22px 11px
}

.PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer
}

.PresetDateRangePicker_button:active {
    outline: 0
}

.PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
}

.SingleDatePickerInput {
    display: inline-block;
    background-color: #fff
}

.SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
}

.SingleDatePickerInput__rtl {
    direction: rtl
}

.SingleDatePickerInput__disabled {
    background-color: #f2f2f2
}

.SingleDatePickerInput__block {
    display: block
}

.SingleDatePickerInput__showClearDate {
    padding-right: 30px
}

.SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.SingleDatePickerInput_clearDate__small {
    padding: 6px
}

.SingleDatePickerInput_clearDate__hide {
    visibility: hidden
}

.SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.SingleDatePickerInput_clearDate_svg__small {
    height: 9px
}

.SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.SingleDatePicker {
    position: relative;
    display: inline-block
}

.SingleDatePicker__block {
    display: block
}

.SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.SingleDatePicker_picker__rtl {
    direction: rtl
}

.SingleDatePicker_picker__directionLeft {
    left: 0
}

.SingleDatePicker_picker__directionRight {
    right: 0
}

.SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
}

.SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_show {
    width: 33px;
    height: 26px;
    position: absolute;
    z-index: 2
}

.DayPickerKeyboardShortcuts_show::before {
    content: "";
    display: block;
    position: absolute
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topRight {
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight::before {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topLeft {
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid #008489
}

.DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: 5px
}

.DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
    text-align: left
}

.DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
}

.DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
}

.DayPickerKeyboardShortcuts_close:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
}

.CalendarDay {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center
}

.CalendarDay:active {
    outline: 0
}

.CalendarDay__defaultCursor {
    cursor: default
}

.CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff
}

.CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px solid #e4e7e7;
    color: inherit
}

.CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
}

.CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848
}

.CalendarDay__outside:hover {
    border: 0
}

.CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
}

.CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
}

.CalendarDay__selected_span {
    background: #66e2da;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #00a699;
    border: 1px double #00a699;
    color: #fff
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd
}

.CalendarDay__hovered_start_first_possible_end {
    background: #eceeee;
    border: 1px double #eceeee
}

.CalendarDay__hovered_start_blocked_min_nights {
    background: #eceeee;
    border: 1px double #e4e7e7
}

.CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    user-select: none
}

.CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
}

.CalendarMonth_verticalSpacing {
    border-collapse: separate
}

.CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial
}

.CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
}

.CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0
}

.CalendarMonthGrid__animating {
    z-index: 1
}

.CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
}

.CalendarMonthGrid__vertical {
    margin: 0 auto
}

.CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll
}

.CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
}

.CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
}

.CalendarMonthGrid_month__hidden {
    visibility: hidden
}

.DayPickerNavigation {
    position: relative;
    z-index: 2
}

.DayPickerNavigation__horizontal {
    height: 0
}

.DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
}

.DayPickerNavigation__verticalScrollableDefault {
    position: relative
}

.DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
}

.DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
}

.DayPickerNavigation_button__default:active {
    background: #f2f2f2
}

.DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:active {
    background: 0 0
}

.DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
}

.DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
}

.DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
}

.DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
}

.DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
}

.DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%
}

.DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
}

.DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848
}

.DayPickerNavigation_svg__disabled {
    fill: #f2f2f2
}

.DayPicker {
    background: #fff;
    position: relative;
    text-align: left
}

.DayPicker__horizontal {
    background: #fff
}

.DayPicker__verticalScrollable {
    height: 100%
}

.DayPicker__hidden {
    visibility: hidden
}

.DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
    border-radius: 3px
}

.DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
}

.DayPicker_portal__vertical {
    position: initial
}

.DayPicker_focusRegion {
    outline: 0
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
}

.DayPicker_weekHeaders {
    position: relative
}

.DayPicker_weekHeaders__horizontal {
    margin-left: 9px
}

.DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
}

.DayPicker_weekHeader__vertical {
    left: 50%
}

.DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
}

.DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
}

.DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
}

.DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px
}

.DayPicker_transitionContainer__horizontal {
    transition: height .2s ease-in-out
}

.DayPicker_transitionContainer__vertical {
    width: 100%
}

.DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll
}

.DateRangePickerInput {
    background-color: #fff;
    display: inline-block
}

.DateRangePickerInput__disabled {
    background: #f2f2f2
}

.DateRangePickerInput__withBorder {
    border-radius: 0;
    border: 1px solid #dbdbdb
}

.DateRangePickerInput__rtl {
    direction: rtl
}

.DateRangePickerInput__block {
    display: block
}

.DateRangePickerInput__showClearDates {
    padding-right: 30px
}

.DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
    padding: 5px;
}

.DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
}

.DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.DateRangePickerInput_clearDates__small {
    padding: 6px
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.DateRangePickerInput_clearDates__hide {
    visibility: hidden
}

.DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.DateRangePickerInput_clearDates_svg__small {
    height: 9px
}

.DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.DateRangePicker {
    position: relative;
    display: inline-block
}

.DateRangePicker__block {
    display: block
}

.DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.DateRangePicker_picker__rtl {
    direction: rtl
}

.DateRangePicker_picker__directionLeft {
    left: 0
}

.DateRangePicker_picker__directionRight {
    right: 0
}

.DateRangePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.DateRangePicker_picker__fullScreenPortal {
    background-color: #fff
}

.DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}
.Itemised_Container__2K4qb {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Itemised_Tools__FgD-H {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.Itemised_PageFilter__3Xp_t {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.Itemised_FilterReportBtn__3Zrz6 {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.Itemised_ToolsWrapper__2x0hN {
    height: 6rem;
    width: 100%;

}

.Itemised_PrintToolsWrapper__VqQRu {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Itemised_AddNewMerchant__2yt_y {
    /* // font-family: Cambay; */
}




/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Itemised_AddNewMerchantBtn__35v4l:focus,
.Itemised_AddNewMerchantBtn__35v4l:hover,
.Itemised_AddNewMerchantBtn__35v4l:active {
    background-color: rgb(51, 137, 255);
}


.Itemised_ToolsDataExportIconWrapper__1qGMO {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Itemised_ToolsPrintIconWrapper__27iDN {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Itemised_FilterInput__1LdP0 {
    margin-right: 2rem;
}

.Itemised_FormGroup__1U2oh {
    /* margin-bottom: 10px; */
}

.Itemised_FormGroupDefault__2SwJS.Itemised_has-error__Ed19w {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Itemised_FormGroupDefault__2SwJS {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Itemised_FormGroupDefault__2SwJS label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Itemised_FormGroup__1U2oh label:not(.Itemised_error__1fi8A) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Itemised_FormControl__1GuGm {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Itemised_MerchantTableWrapper__3rbAZ {
    margin-top: 3rem;
    white-space: nowrap;
}

.Itemised_TableWrapper__GSw8x {
    display: flex;
}

.Itemised_MerchantTable__297y9 {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Itemised_MerchantTable__297y9 th {
    text-align: left;
}

.Itemised_MerchantTable__297y9 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Itemised_MerchantTable__297y9 td,
.Itemised_MerchantTable__297y9 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Itemised_RevealMoreHeader__28xY2 {
    padding: 0;
    vertical-align: middle;
}





.Itemised_Row__VnK1K {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Itemised_Row__VnK1K>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Itemised_dataTables_paginate__61Cg7 {
    float: right;
}

.Itemised_dataTables_paginate__61Cg7 {
    /* margin-top: 25px !important; */
}

div.Itemised_dataTables_paginate__61Cg7 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Itemised_dataTables_paginate__61Cg7 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Itemised_dataTables_paginate__61Cg7 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Itemised_dataTables_paginate__61Cg7 ul>li.Itemised_disabled__3C1wg a {
    opacity: .5;
}

.Itemised_dataTables_paginate__61Cg7 ul>li.Itemised_disabled__3C1wg a {
    opacity: .35;
}

.Itemised_dataTables_paginate__61Cg7 ul>li.Itemised_active__2FbG8>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Itemised_dataTables_paginate__61Cg7 ul>li.Itemised_next__2S9Kz>a,
.Itemised_dataTables_paginate__61Cg7 ul>li.Itemised_prev__1bT00>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__2gYT2 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_left__2dbWN {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__2gYT2:before {
    content: "\E631";
}

.Itemised_pg_arrow_left__2dbWN:before {
    content: "\E629";
}

.Itemised_btn__1cZwe {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Itemised_btn_primary__F0sQj,
.Itemised_btn_primary__F0sQj:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Itemised_btn_cons__3mqQo {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Itemised_MerchantName__3SEfs {
    font-weight: 500;
}

.Itemised_Icn__2D6Es {
    cursor: pointer;
    /* fill:#000; */
}

.Itemised_Icn__2D6Es:hover {
    cursor: pointer;

}



.Itemised_RevealMore__1lN2h {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Itemised_RevealMore__1lN2h:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Itemised_RevealMore__1lN2h div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Itemised_RevealMoreExpanded__1nQSY {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Itemised_Expandabled__2GTrp {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Itemised_Expandabled__2GTrp td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Itemised_MerchantTable__297y9 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Itemised_MerchantTable__297y9 td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Itemised_MerchantRow__27JMq {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Itemised_MerchantRow__27JMq:hover {
    background-color: #267788;
    color: #fff;

}

.Itemised_SelectedTransaction__2-rk5 {
    background-color: #267788;
    color: #fff;

}

.Itemised_MerchantRow__27JMq:hover a {
    color: #fff;
}

.Itemised_MerchantRowExpanded__3TuhS {
    background: #267788;
    color: #fff;
}

.Itemised_table__anHWA.Itemised_table-condensed__110jy.Itemised_table-detailed__ly-UI>tbody>tr.Itemised_shown__164U1>td {
    background: #fdf5df;
}


.Itemised_Totals__1FrkN td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Itemised_MoneyTotalLabel__2gQnU {
    float: right;

}

.Itemised_TransactionDetail__j4hnU {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Itemised_TransactionDetailHeader__2WPg0 {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Itemised_TransactionDetailBody__2j1du {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Category_Container__26Cuq {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Category_BreadcrumbWrapper__ggvS5 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Category_Content__EWn1S {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Category_Alert__3cZb1 {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Category_Breadcrumb__3SnMu {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Category_Header__2U4D_ {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Category_ByLine__2CgAX {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Category_Activity__3mwOg {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Category_WelcomeContainer__1b1LC {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Category_MerchantProfile__17118 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Category_TabHeader__3AKgD {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Category_PageHeader__2lsnf {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Category_AddUser__qfX-R {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Category_AddUserBtn__36KeI {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Category_ReportContainer__2kX2X {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.Category_ReportTiles__3Jdqx {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.Category_TileContainer__QBd0U {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Category_Tile__350Zb {
    cursor: pointer;
    padding: 4px;
}

.Category_TileInner__tRJVb {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__23KDe {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__1Tvn3 {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.ReportBody_PageFilter__2ulbL {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.ReportBody_FilterReportBtn__3h18r {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.ReportBody_ToolsWrapper__13RMH {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__RLcSm {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__3Sen5 {
    /* // font-family: Cambay; */
}




/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__1xhDA:focus,
.ReportBody_AddNewMerchantBtn__1xhDA:hover,
.ReportBody_AddNewMerchantBtn__1xhDA:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__UtiJP {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__a6M4k {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__2E5ma {
    margin-right: 2rem;
}

.ReportBody_FormGroup__4i5d1 {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__2YuyQ.ReportBody_has-error__1u06r {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__2YuyQ {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__2YuyQ label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__4i5d1 label:not(.ReportBody_error__7Hvwt) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__1Rdik {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__1ga2Z {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__1byuH {
    display: flex;
}

.ReportBody_MerchantTable__2BH14 {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__2BH14 th {
    text-align: left;
}

.ReportBody_MerchantTable__2BH14 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__2BH14 td,
.ReportBody_MerchantTable__2BH14 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__eree4 {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__2xg8t {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__2xg8t>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__2krTF {
    float: right;
}

.ReportBody_dataTables_paginate__2krTF {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__2krTF {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__2krTF ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__2krTF ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__2krTF ul>li.ReportBody_disabled__2vwR_ a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__2krTF ul>li.ReportBody_disabled__2vwR_ a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__2krTF ul>li.ReportBody_active__r4C78>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__2krTF ul>li.ReportBody_next__P2I8y>a,
.ReportBody_dataTables_paginate__2krTF ul>li.ReportBody_prev__2xG06>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__PD71E {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__2e_NM {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__PD71E:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__2e_NM:before {
    content: "\E629";
}

.ReportBody_btn__HaxDk {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary___VV_g,
.ReportBody_btn_primary___VV_g:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__2jSxB {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__1oWmh {
    font-weight: 500;
}

.ReportBody_Icn__1oruD {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__1oruD:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__1LiBn {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__1LiBn:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__1LiBn div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__3wXMi {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__31cQR {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__31cQR td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__2BH14 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__2BH14 td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__2CwBt {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__2CwBt:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_SelectedTransaction__3_TRn {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__2CwBt:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__22T0i {
    background: #267788;
    color: #fff;
}

.ReportBody_table__35UEJ.ReportBody_table-condensed__3kKyQ.ReportBody_table-detailed__1_3AI>tbody>tr.ReportBody_shown__X4s50>td {
    background: #fdf5df;
}


.ReportBody_Totals__2BpNt td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__2IT4L {
    float: right;

}

.ReportBody_TransactionDetail__ArHA6 {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.ReportBody_TransactionDetailHeader__ADH_K {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.ReportBody_TransactionDetailBody__2EyYL {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Date_Container__3QykX {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Date_BreadcrumbWrapper__oBD94 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Date_Content__bq29B {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Date_Alert__230PH {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Date_Breadcrumb__Hjf9J {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Date_Header__2TvWW {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Date_ByLine__3RQcm {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Date_Activity__1ejXo {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Date_WelcomeContainer__Kq4NT {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Date_MerchantProfile__nFdt_ {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Date_TabHeader__3C8Y- {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Date_PageHeader__sigPs {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Date_AddUser__2YOd9 {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Date_AddUserBtn__1QSfz {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Date_ReportContainer__rFhEh {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.Date_ReportTiles__2oELL {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.Date_TileContainer__2fOn_ {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Date_Tile__3wwNC {
    cursor: pointer;
    padding: 4px;
}

.Date_TileInner__1zHC4 {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.ReportBody_Container__95g7f {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.ReportBody_Tools__3Sw82 {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.ReportBody_PageFilter__vFW9l {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.ReportBody_FilterReportBtn__1Q0jR {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.ReportBody_ToolsWrapper__1S-a1 {
    height: 6rem;
    width: 100%;

}

.ReportBody_PrintToolsWrapper__2YL0b {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.ReportBody_AddNewMerchant__XWW1O {
    /* // font-family: Cambay; */
}




/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__1OBTw:focus,
.ReportBody_AddNewMerchantBtn__1OBTw:hover,
.ReportBody_AddNewMerchantBtn__1OBTw:active {
    background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__3mosc {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__3SHzS {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReportBody_FilterInput__3A-Ie {
    margin-right: 2rem;
}

.ReportBody_FormGroup__2YdY1 {
    /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__8UwqW.ReportBody_has-error__2wOtt {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__8UwqW {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReportBody_FormGroupDefault__8UwqW label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__2YdY1 label:not(.ReportBody_error__PpTFA) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReportBody_FormControl__1--3z {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReportBody_MerchantTableWrapper__3dgMR {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReportBody_TableWrapper__QchF- {
    display: flex;
}

.ReportBody_MerchantTable__yG2un {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.ReportBody_MerchantTable__yG2un th {
    text-align: left;
}

.ReportBody_MerchantTable__yG2un th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReportBody_MerchantTable__yG2un td,
.ReportBody_MerchantTable__yG2un th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__18MUi {
    padding: 0;
    vertical-align: middle;
}





.ReportBody_Row__gjHrY {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReportBody_Row__gjHrY>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__3r-4_ {
    float: right;
}

.ReportBody_dataTables_paginate__3r-4_ {
    /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__3r-4_ {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReportBody_dataTables_paginate__3r-4_ ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.ReportBody_dataTables_paginate__3r-4_ ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__3r-4_ ul>li.ReportBody_disabled__2aIRI a {
    opacity: .5;
}

.ReportBody_dataTables_paginate__3r-4_ ul>li.ReportBody_disabled__2aIRI a {
    opacity: .35;
}

.ReportBody_dataTables_paginate__3r-4_ ul>li.ReportBody_active__3a-xN>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReportBody_dataTables_paginate__3r-4_ ul>li.ReportBody_next__1EpiM>a,
.ReportBody_dataTables_paginate__3r-4_ ul>li.ReportBody_prev__3hZFy>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__36-cW {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__XG8Gv {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__36-cW:before {
    content: "\E631";
}

.ReportBody_pg_arrow_left__XG8Gv:before {
    content: "\E629";
}

.ReportBody_btn__3zEo1 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__3PCoT,
.ReportBody_btn_primary__3PCoT:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReportBody_btn_cons__2TGcy {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__2BVHG {
    font-weight: 500;
}

.ReportBody_Icn__1ZDCL {
    cursor: pointer;
    /* fill:#000; */
}

.ReportBody_Icn__1ZDCL:hover {
    cursor: pointer;

}



.ReportBody_RevealMore__8ol8J {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReportBody_RevealMore__8ol8J:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReportBody_RevealMore__8ol8J div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__pNTvt {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReportBody_Expandabled__3Enwm {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReportBody_Expandabled__3Enwm td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__yG2un td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__yG2un td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__27eIK {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__27eIK:hover {
    background-color: #267788;
    color: #fff;

}

.ReportBody_SelectedTransaction__1yc2u {
    background-color: #267788;
    color: #fff;

}

.ReportBody_MerchantRow__27eIK:hover a {
    color: #fff;
}

.ReportBody_MerchantRowExpanded__3o_pN {
    background: #267788;
    color: #fff;
}

.ReportBody_table__skaBX.ReportBody_table-condensed__3zT9W.ReportBody_table-detailed__1GmIJ>tbody>tr.ReportBody_shown__2m0QT>td {
    background: #fdf5df;
}


.ReportBody_Totals__6F5_N td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReportBody_MoneyTotalLabel__YJsbn {
    float: right;

}

.ReportBody_TransactionDetail__1iGuQ {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.ReportBody_TransactionDetailHeader__1fXvb {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.ReportBody_TransactionDetailBody__2umbv {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Levy_Container__3njjS {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.Levy_BreadcrumbWrapper__37u81 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Levy_Content__1uMls {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Levy_Alert__dGUAB {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Levy_Breadcrumb__1Nf_S {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Levy_Header__2BDOz {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.Levy_ByLine__uU0mm {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Levy_Activity__1OJQ1 {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Levy_WelcomeContainer__1LGLF {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Levy_MerchantProfile__dy5hf {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.Levy_TabHeader__31ghP {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.Levy_PageHeader__2Ygbg {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Levy_AddUser__3rEwZ {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Levy_AddUserBtn__34KXG {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.Levy_ReportContainer__2dKpu {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.Levy_ReportTiles__3SizS {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.Levy_TileContainer__1vSbM {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.Levy_Tile__3bsll {
    cursor: pointer;
    padding: 4px;
}

.Levy_TileInner__34ADD {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.Itemised_Container__3-gSk {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Itemised_Tools__2AnOT {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.Itemised_PageFilter__3-8_c {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.Itemised_FilterReportBtn__14RrH {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.Itemised_ToolsWrapper__3YjzL {
    height: 6rem;
    width: 100%;

}

.Itemised_PrintToolsWrapper__iGkG9 {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Itemised_AddNewMerchant__3H0Cv {
    /* // font-family: Cambay; */
}




/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Itemised_AddNewMerchantBtn__2IzOW:focus,
.Itemised_AddNewMerchantBtn__2IzOW:hover,
.Itemised_AddNewMerchantBtn__2IzOW:active {
    background-color: rgb(51, 137, 255);
}


.Itemised_ToolsDataExportIconWrapper__HPMmp {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Itemised_ToolsPrintIconWrapper__39QqL {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Itemised_FilterInput__1A2QM {
    margin-right: 2rem;
}

.Itemised_FormGroup__axNvi {
    /* margin-bottom: 10px; */
}

.Itemised_FormGroupDefault__2RDE6.Itemised_has-error__Irizy {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Itemised_FormGroupDefault__2RDE6 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Itemised_FormGroupDefault__2RDE6 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Itemised_FormGroup__axNvi label:not(.Itemised_error__3tOSw) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Itemised_FormControl__S3Z63 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Itemised_MerchantTableWrapper__3pwAD {
    margin-top: 3rem;
    white-space: nowrap;
}

.Itemised_TableWrapper__3Ocjp {
    display: flex;
}

.Itemised_MerchantTable__2-x9r {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Itemised_MerchantTable__2-x9r th {
    text-align: left;
}

.Itemised_MerchantTable__2-x9r th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Itemised_MerchantTable__2-x9r td,
.Itemised_MerchantTable__2-x9r th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Itemised_RevealMoreHeader__234kN {
    padding: 0;
    vertical-align: middle;
}





.Itemised_Row__3kGXs {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Itemised_Row__3kGXs>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Itemised_dataTables_paginate__3jmjt {
    float: right;
}

.Itemised_dataTables_paginate__3jmjt {
    /* margin-top: 25px !important; */
}

div.Itemised_dataTables_paginate__3jmjt {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Itemised_dataTables_paginate__3jmjt ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Itemised_dataTables_paginate__3jmjt ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Itemised_dataTables_paginate__3jmjt ul>li.Itemised_disabled__3caZn a {
    opacity: .5;
}

.Itemised_dataTables_paginate__3jmjt ul>li.Itemised_disabled__3caZn a {
    opacity: .35;
}

.Itemised_dataTables_paginate__3jmjt ul>li.Itemised_active__1nsSs>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Itemised_dataTables_paginate__3jmjt ul>li.Itemised_next__NS6Rl>a,
.Itemised_dataTables_paginate__3jmjt ul>li.Itemised_prev__1V7Os>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__ir30Q {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_left__3KP04 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Itemised_pg_arrow_right__ir30Q:before {
    content: "\E631";
}

.Itemised_pg_arrow_left__3KP04:before {
    content: "\E629";
}

.Itemised_btn__3wzRc {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Itemised_btn_primary__NJ9pl,
.Itemised_btn_primary__NJ9pl:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Itemised_btn_cons__2tuzU {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Itemised_MerchantName__2R7vr {
    font-weight: 500;
}

.Itemised_Icn__2JQEY {
    cursor: pointer;
    /* fill:#000; */
}

.Itemised_Icn__2JQEY:hover {
    cursor: pointer;

}



.Itemised_RevealMore__1wj7O {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Itemised_RevealMore__1wj7O:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Itemised_RevealMore__1wj7O div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Itemised_RevealMoreExpanded__2bxki {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Itemised_Expandabled__114zQ {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Itemised_Expandabled__114zQ td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Itemised_MerchantTable__2-x9r td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Itemised_MerchantTable__2-x9r td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Itemised_MerchantRow__3q0Ou {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Itemised_MerchantRow__3q0Ou:hover {
    background-color: #267788;
    color: #fff;

}

.Itemised_SelectedTransaction__ec50S {
    background-color: #267788;
    color: #fff;

}

.Itemised_MerchantRow__3q0Ou:hover a {
    color: #fff;
}

.Itemised_MerchantRowExpanded__2Cisc {
    background: #267788;
    color: #fff;
}

.Itemised_table__1a_XM.Itemised_table-condensed__-32Zu.Itemised_table-detailed__1Gps1>tbody>tr.Itemised_shown__1Y9RS>td {
    background: #fdf5df;
}


.Itemised_Totals__2JubM td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Itemised_MoneyTotalLabel__28kg5 {
    float: right;

}

.Itemised_TransactionDetail__3THby {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Itemised_TransactionDetailHeader__1ZoK2 {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Itemised_TransactionDetailBody__13ykj {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.VendorRecon_Container__3tacj {
  /* display: flex; */
  flex-direction: column;
  /* justify-content: flex-start; */
  height: 100%;
}

.VendorRecon_BreadcrumbWrapper__3zaOU {
  display: flex;
  background-color: transparent;
  width: 100%;
  padding-left: 2.4rem;
  flex: 0 0 7.2rem;
  height: 7.2rem;
}

.VendorRecon_Content__TC3AQ {
  flex: 1 1 auto;
  display: flex;
  margin: 2.4rem;
  flex-direction: column;
}

.VendorRecon_Alert__3tK2B {
  padding: 18px;
  margin-bottom: 15px;
  border: 0;
  border-radius: 0;
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.VendorRecon_Breadcrumb__11OhO {
  padding: 1.8rem 0;
  flex: 0 0 50%;
}

.VendorRecon_Header__3nlnR {
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.VendorRecon_ByLine__3PQIc {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.VendorRecon_Activity__2vebI {
  background: #FFF;
  flex: 0 0 36rem;
  /* width: 36rem; */
}

.VendorRecon_WelcomeContainer__3l0WO {
  display: flex;
  /* margin: 0 2.4rem 0 0; */
  /* height: 17.6rem; */
}

.VendorRecon_MerchantProfile__23NmQ {
  display: flex;
  background: transparent;
  flex: 0 0 100%;
}



.VendorRecon_TabHeader__9dyb7 {
  /* padding: 0px 2rem; */
  margin-bottom: 1rem;
  display: flex;
}


.VendorRecon_PageHeader__3lyTu {
  flex: 1 1 50%;
  justify-content: flex-start;
  width: auto;
}

.VendorRecon_AddUser__25K6W {
  display: flex;
  margin-right: 20px;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.VendorRecon_AddUserBtn__1uSNB {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  vertical-align: middle;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}


.VendorRecon_ReportContainer__1sTRe {
  margin: 10px 0;
  text-align: -webkit-center;
  -webkit-user-select: none;
          user-select: none;
  width: 100%;
}

.VendorRecon_ReportTiles__bjFJT {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  /* justify-content: center; */
  max-width: 100%;
  opacity: 1;
  position: static;
  text-align: -webkit-auto;
  transition: opacity 300ms;
  -webkit-user-select: none;
          user-select: none;
}

.VendorRecon_TileContainer__7ue1F {
  border-radius: 4px;
  box-sizing: border-box;
  height: 11.2rem;
  margin-bottom: var(--md-tile-margin);
  opacity: 1;
  position: relative;
  transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
  transition-property: background, background-color, border-color, box-shadow, opacity, filter;
  transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
  width: 11.2rem;
}

.VendorRecon_Tile__FldcS {
  cursor: pointer;
  padding: 4px;
}

.VendorRecon_TileInner__1_5L0 {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  position: relative;
  width: 100%;
  /* z-index: -1; */
}
.ReportBody_Container__2ZrqM {
  background-color: #fff;
  /* margin: 2.4rem;
  margin-bottom: 2.4rem; */
  /* flex-grow: 1; */
  /* border-top: 1px solid #707070; */
  /* display: flex; */
  flex-direction: column;
  font-size: 1.4rem;
}

.ReportBody_Tools__3aHsc {
  display: flex;
  /* margin: 0 2.5rem; */
  padding-top: 1.4rem;
}



.ReportBody_PageFilter__3uCcC {
  /* display: flex; */
  flex: 1 1 auto;
  justify-content: flex-start;
  width: auto;
  margin-left: 15px;
}


.ReportBody_FilterReportBtn__26vXw {

  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  height: 3.8rem;
  padding: 10px;
  text-decoration: none;
  border: none;
  display: block;

}

.ReportBody_ToolsWrapper__1Rfnj {
  height: 6rem;
  width: 100%;

}

.ReportBody_PrintToolsWrapper__ATi6k {
  display: flex;
  flex: 1 1 20%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
}

.ReportBody_AddNewMerchant__9GXr_ {
  /* // font-family: Cambay; */
}




/* 
text-align: center;
  padding: 0 3rem;

  background: #E8E8E8;
  color: #333;

  transition: all .2s;
  position: relative;
  font-size: 1.2rem;
  cursor: pointer;
  line-height: 4rem;
  border-radius: 2px; */

.ReportBody_AddNewMerchantBtn__1erBK:focus,
.ReportBody_AddNewMerchantBtn__1erBK:hover,
.ReportBody_AddNewMerchantBtn__1erBK:active {
  background-color: rgb(51, 137, 255);
}


.ReportBody_ToolsDataExportIconWrapper__2qvi_ {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.ReportBody_ToolsPrintIconWrapper__CbqVI {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;

}

.ReportBody_FilterInput__1gl1- {
  margin-right: 2rem;
}

.ReportBody_FormGroup__1BgDr {
  /* margin-bottom: 10px; */
}

.ReportBody_FormGroupDefault__2GfYX.ReportBody_has-error__3TORn {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReportBody_FormGroupDefault__2GfYX {
  /* background-color: #fff;
  position: relative;
  padding-top: 7px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;

  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease; */
  /* padding: 1.4rem 2.5rem .2em .5rem; */
  line-height: 2rem;
  position: relative;
  transition: all .2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.ReportBody_FormGroupDefault__2GfYX label {
  margin: 0;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.ReportBody_FormGroup__1BgDr label:not(.ReportBody_error__7kg-b) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.ReportBody_FormControl__2Wvmy {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.ReportBody_MerchantTableWrapper__Vp952 {
  margin-top: 3rem;
  white-space: nowrap;
}

.ReportBody_TableWrapper__JQNzV {
  display: flex;
}

.ReportBody_MerchantTable__1HNSg {
  flex: 1 1 70%;
  width: 100%;
  border-collapse: collapse;
}

.ReportBody_MerchantTable__1HNSg th {
  text-align: left;
}

.ReportBody_MerchantTable__1HNSg th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.ReportBody_MerchantTable__1HNSg td,
.ReportBody_MerchantTable__1HNSg th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

  border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

  border-right: 1px solid #e8e8e8;
} */

.ReportBody_RevealMoreHeader__H6EyY {
  padding: 0;
  vertical-align: middle;
}





.ReportBody_Row__4qQCp {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem;
}

.ReportBody_Row__4qQCp>div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.ReportBody_dataTables_paginate__1c7BK {
  float: right;
}

.ReportBody_dataTables_paginate__1c7BK {
  /* margin-top: 25px !important; */
}

div.ReportBody_dataTables_paginate__1c7BK {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.ReportBody_dataTables_paginate__1c7BK ul>li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
  outline: 0 !important;
  text-decoration: none;
  background-color: transparent;
} */

.ReportBody_dataTables_paginate__1c7BK ul>li>a {
  padding: 5px 10px;
  color: #575757;
  opacity: .35;
  transition: opacity .3s ease;
}

.ReportBody_dataTables_paginate__1c7BK ul>li.ReportBody_disabled__24vKR a {
  opacity: .5;
}

.ReportBody_dataTables_paginate__1c7BK ul>li.ReportBody_disabled__24vKR a {
  opacity: .35;
}

.ReportBody_dataTables_paginate__1c7BK ul>li.ReportBody_active__1p61y>a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.ReportBody_dataTables_paginate__1c7BK ul>li.ReportBody_next__2UIl9>a,
.ReportBody_dataTables_paginate__1c7BK ul>li.ReportBody_prev___97Gx>a {
  opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__1lh9h {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_left__1I_En {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ReportBody_pg_arrow_right__1lh9h:before {
  content: "\E631";
}

.ReportBody_pg_arrow_left__1I_En:before {
  content: "\E629";
}

.ReportBody_btn__1Mlpk {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern"1;
  -moz-font-feature-settings: "kern"1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  /* border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px; */
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReportBody_btn_primary__mTKKA,
.ReportBody_btn_primary__mTKKA:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.ReportBody_btn_cons__SaXEI {
  min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
  display: table-row;
} */


/* .Odd {
}

.Even {
} */

.ReportBody_MerchantName__RaxSC {
  font-weight: 500;
}

.ReportBody_Icn__3_isd {
  cursor: pointer;
  /* fill:#000; */
}

.ReportBody_Icn__3_isd:hover {
  cursor: pointer;

}



.ReportBody_RevealMore__1Ja8G {
  background: #e1e1e1;
  cursor: pointer;
  width: 2.4rem;
}

.ReportBody_RevealMore__1Ja8G:hover {
  background: transparent;
  color: #707070;
  fill: #fff;
}

.ReportBody_RevealMore__1Ja8G div {
  margin: 0 auto;
  width: 2.4rem;
  height: 2.4rem;
}

.ReportBody_RevealMoreExpanded__3VhVq {
  background: transparent;
  fill: #fff;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.ReportBody_Expandabled__pBd5s {
  text-align: left;
  background: #d7effb;
  border-bottom: 1px solid rgb(56, 0, 60);
  display: table-row;
  z-index: 500;
}

.ReportBody_Expandabled__pBd5s td {
  padding: 2rem 2rem 2rem 5.6rem;
  border-bottom: 1px solid #e8e8e8;
}

.ReportBody_MerchantTable__1HNSg td:last-child {

  border-right: 1px solid #e8e8e8;
}


.ReportBody_MerchantTable__1HNSg td:first-child {

  border-left: 1px solid #e8e8e8;
}


.ReportBody_MerchantRow__Agi-7 {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.ReportBody_MerchantRow__Agi-7:hover {
  background-color: #267788;
  color: #fff;

}

.ReportBody_SelectedTransaction__fyF8V {
  background-color: #267788;
  color: #fff;

}

.ReportBody_MerchantRow__Agi-7:hover a {
  color: #fff;
}

.ReportBody_MerchantRowExpanded__1mSYu {
  background: #267788;
  color: #fff;
}

.ReportBody_table__1sxBA.ReportBody_table-condensed__1kVIp.ReportBody_table-detailed__3pajH>tbody>tr.ReportBody_shown__1kihj>td {
  background: #fdf5df;
}


.ReportBody_Totals__1LZAw td {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid #e8e8e8;
  font-weight: 700;
}

.ReportBody_MoneyTotalLabel__tSOYi {
  float: right;

}

.ReportBody_TransactionDetail__2o57g {
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  /* background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0; */

}

.ReportBody_TransactionDetailHeader__20wID {

  padding: 0;
  vertical-align: middle;
  height: 4rem;
  background-color: #fff;
  border: none;

}

.ReportBody_TransactionDetailBody__1b8pG {
  flex: 1 1 auto;
  padding: 0;
  vertical-align: middle;
  overflow: hidden;
  padding: 2rem;
  background-color: transparent;
  border: 1px solid #e0e0e0;
}
.BPCDailySales_Container__323Rr {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.BPCDailySales_BreadcrumbWrapper__2Od_4 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.BPCDailySales_Content__Zh-WZ {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.BPCDailySales_CardBody__30Uk7 {
    margin-top: 1rem;
}

.BPCDailySales_Alert__146gY {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.BPCDailySales_Breadcrumb__jYFR_ {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.BPCDailySales_Header__1MNea {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.BPCDailySales_ByLine__3Insk {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.BPCDailySales_Activity__2MH7y {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.BPCDailySales_WelcomeContainer__1ppha {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.BPCDailySales_MerchantProfile__2hFdj {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.BPCDailySales_TabHeader__2oULs {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.BPCDailySales_PageHeader__1eIPr {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.BPCDailySales_Header__1MNea {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.BPCDailySales_ExportReport__14X4t {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.BPCDailySales_ExportIconWrapper__f3w93 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.BPCDailySales_EmailIconWrapper__3uetS {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.BPCDailySales_AddUser__1-SLe {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.BPCDailySales_AddUserBtn__2CH1O {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.BPCDailySales_Tools__3bz_B {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.BPCDailySales_PageFilter__1pBEh {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.BPCDailySales_PrintToolsWrapper__1bkjb {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.BPCDailySales_FilterReportBtn__lOzSL {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.BPCDailySales_AddNewMerchantBtn__1nHIt:focus,
.BPCDailySales_AddNewMerchantBtn__1nHIt:hover,
.BPCDailySales_AddNewMerchantBtn__1nHIt:active {
    background-color: rgb(51, 137, 255);
}


.BPCDailySales_ToolsDataExportIconWrapper__COICV {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.BPCDailySales_ToolsPrintIconWrapper__1ObEQ {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.BPCDailySales_FilterInput__PDM-w {
    margin-right: 2rem;
}

.BPCDailySales_FormGroupDefault__hcm7t.BPCDailySales_has-error__Ru0l5 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.BPCDailySales_FormGroupDefault__hcm7t {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.BPCDailySales_FormGroupDefault__hcm7t label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.BPCDailySales_FormGroup__kR-K5 label:not(.BPCDailySales_error__nLIKI) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.BPCDailySales_FormControl__1gLJt {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.BPCDailySales_MerchantTableWrapper__2kpRW {
    margin-top: 3rem;
    white-space: nowrap;
}

.BPCDailySales_MerchantTable__1xmvI {
    width: 100%;
    border-collapse: collapse;
}

.BPCDailySales_MerchantTable__1xmvI th {
    text-align: left;
}

.BPCDailySales_MerchantTable__1xmvI th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.BPCDailySales_MerchantTable__1xmvI td,
.BPCDailySales_MerchantTable__1xmvI th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.BPCDailySales_RevealMoreHeader__1kApk {
    padding: 0;
    vertical-align: middle;
}

.BPCDailySales_Row__UmR-H {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.BPCDailySales_Row__UmR-H>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.BPCDailySales_dataTables_paginate__3Qobc {
    float: right;
}

div.BPCDailySales_dataTables_paginate__3Qobc {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.BPCDailySales_dataTables_paginate__3Qobc ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.BPCDailySales_dataTables_paginate__3Qobc ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.BPCDailySales_dataTables_paginate__3Qobc ul>li.BPCDailySales_disabled__1-vrI a {
    opacity: .5;
}

.BPCDailySales_dataTables_paginate__3Qobc ul>li.BPCDailySales_disabled__1-vrI a {
    opacity: .35;
}

.BPCDailySales_dataTables_paginate__3Qobc ul>li.BPCDailySales_active__hFB2y>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.BPCDailySales_dataTables_paginate__3Qobc ul>li.BPCDailySales_next__3volT>a,
.BPCDailySales_dataTables_paginate__3Qobc ul>li.BPCDailySales_prev__2W6yx>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.BPCDailySales_pg_arrow_right__3_Jly {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.BPCDailySales_pg_arrow_left__2z2o3 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.BPCDailySales_pg_arrow_right__3_Jly:before {
    content: "\E631";
}

.BPCDailySales_pg_arrow_left__2z2o3:before {
    content: "\E629";
}

.BPCDailySales_btn__1jwvg {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.BPCDailySales_btn_primary__3LexL,
.BPCDailySales_btn_primary__3LexL:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.BPCDailySales_btn_cons__Oxtqi {
    min-width: 120px;
}




.BPCDailySales_MerchantName__3aZ7W {
    font-weight: 500;
}

.BPCDailySales_Icn__2Yq_Q {
    cursor: pointer;
    /* fill:#000; */
}

.BPCDailySales_Icn__2Yq_Q:hover {
    cursor: pointer;

}



.BPCDailySales_RevealMore__3hj-0 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.BPCDailySales_RevealMore__3hj-0:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.BPCDailySales_RevealMore__3hj-0 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.BPCDailySales_RevealMoreExpanded__3Dl6b {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.BPCDailySales_Expandabled__3tIvz {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.BPCDailySales_Expandabled__3tIvz td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.BPCDailySales_MerchantTable__1xmvI td:last-child {

    border-right: 1px solid #e8e8e8;
}


.BPCDailySales_MerchantTable__1xmvI td:first-child {

    border-left: 1px solid #e8e8e8;
}


.BPCDailySales_MerchantRow__1EayN {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.BPCDailySales_MerchantRow__1EayN:hover {
    background-color: #267788;
    color: #fff;

}

.BPCDailySales_MerchantRow__1EayN:hover a {
    color: #fff;
}

.BPCDailySales_MerchantRowExpanded__2q_T5 {
    background: #267788;
    color: #fff;
}

.BPCDailySales_table__f87Z8.BPCDailySales_table-condensed__3JeSQ.BPCDailySales_table-detailed__CWwqu>tbody>tr.BPCDailySales_shown__luO4w>td {
    background: #fdf5df;
}


.BPCDailySales_Totals__HVf9U td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.BPCDailySales_MoneyTotalLabel__j-ABj {
    float: right;

}

.BPCDailySales_FilterWrapper__20Goh {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.BPCDailySales_FilterPeriods__4Vo6p {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.BPCDailySales_FilterPeriods__4Vo6p li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.BPCDailySales_FinancialsIntro__3ljdO {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.BPCDailySales_SalesBtn__2xPQ0 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.KazangDailySales_Container__k-mWm {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.KazangDailySales_BreadcrumbWrapper__2d-P9 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.KazangDailySales_Content__3pU4G {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.KazangDailySales_CardBody__2Mhxe {
    margin-top: 1rem;
}

.KazangDailySales_Alert__2refS {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.KazangDailySales_Breadcrumb__3hJrh {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.KazangDailySales_Header__2NsWA {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.KazangDailySales_ByLine__3HGM7 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.KazangDailySales_Activity__dutqA {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.KazangDailySales_WelcomeContainer__3ZiaN {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.KazangDailySales_MerchantProfile__2gvPo {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.KazangDailySales_TabHeader__ytYAg {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.KazangDailySales_PageHeader__Sqm_h {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.KazangDailySales_Header__2NsWA {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.KazangDailySales_ExportReport__2BSxK {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.KazangDailySales_ExportIconWrapper__1TCmu {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.KazangDailySales_EmailIconWrapper__1TwD7 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.KazangDailySales_AddUser__dgGw7 {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.KazangDailySales_AddUserBtn__3zoUW {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.KazangDailySales_Tools__2KCov {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.KazangDailySales_PageFilter__KVIzH {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.KazangDailySales_PrintToolsWrapper__jy0UC {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.KazangDailySales_FilterReportBtn__lQtX5 {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.KazangDailySales_AddNewMerchantBtn__24t5G:focus,
.KazangDailySales_AddNewMerchantBtn__24t5G:hover,
.KazangDailySales_AddNewMerchantBtn__24t5G:active {
    background-color: rgb(51, 137, 255);
}


.KazangDailySales_ToolsDataExportIconWrapper__2yhW7 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.KazangDailySales_ToolsPrintIconWrapper__RDvzv {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.KazangDailySales_FilterInput__iScJy {
    margin-right: 2rem;
}

.KazangDailySales_FormGroupDefault__iuwqZ.KazangDailySales_has-error__2H2O6 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.KazangDailySales_FormGroupDefault__iuwqZ {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.KazangDailySales_FormGroupDefault__iuwqZ label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.KazangDailySales_FormGroup__23CSg label:not(.KazangDailySales_error__1t9cL) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.KazangDailySales_FormControl__3UfCx {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.KazangDailySales_MerchantTableWrapper__2Il4q {
    margin-top: 3rem;
    white-space: nowrap;
}

.KazangDailySales_MerchantTable__1tvh0 {
    width: 100%;
    border-collapse: collapse;
}

.KazangDailySales_MerchantTable__1tvh0 th {
    text-align: left;
}

.KazangDailySales_MerchantTable__1tvh0 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.KazangDailySales_MerchantTable__1tvh0 td,
.KazangDailySales_MerchantTable__1tvh0 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.KazangDailySales_RevealMoreHeader__tUWpn {
    padding: 0;
    vertical-align: middle;
}

.KazangDailySales_Row__1uE6U {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.KazangDailySales_Row__1uE6U>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.KazangDailySales_dataTables_paginate__1GYuK {
    float: right;
}

div.KazangDailySales_dataTables_paginate__1GYuK {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.KazangDailySales_dataTables_paginate__1GYuK ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.KazangDailySales_dataTables_paginate__1GYuK ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.KazangDailySales_dataTables_paginate__1GYuK ul>li.KazangDailySales_disabled__2YGvs a {
    opacity: .5;
}

.KazangDailySales_dataTables_paginate__1GYuK ul>li.KazangDailySales_disabled__2YGvs a {
    opacity: .35;
}

.KazangDailySales_dataTables_paginate__1GYuK ul>li.KazangDailySales_active__d0BDE>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.KazangDailySales_dataTables_paginate__1GYuK ul>li.KazangDailySales_next__2HP5k>a,
.KazangDailySales_dataTables_paginate__1GYuK ul>li.KazangDailySales_prev__M8HYv>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.KazangDailySales_pg_arrow_right__VLjuc {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.KazangDailySales_pg_arrow_left__1cGMX {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.KazangDailySales_pg_arrow_right__VLjuc:before {
    content: "\E631";
}

.KazangDailySales_pg_arrow_left__1cGMX:before {
    content: "\E629";
}

.KazangDailySales_btn__2c12v {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.KazangDailySales_btn_primary__3uo1j,
.KazangDailySales_btn_primary__3uo1j:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.KazangDailySales_btn_cons__A_bLl {
    min-width: 120px;
}




.KazangDailySales_MerchantName__3Lqka {
    font-weight: 500;
}

.KazangDailySales_Icn__11Q6H {
    cursor: pointer;
    /* fill:#000; */
}

.KazangDailySales_Icn__11Q6H:hover {
    cursor: pointer;

}



.KazangDailySales_RevealMore__1Rtz9 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.KazangDailySales_RevealMore__1Rtz9:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.KazangDailySales_RevealMore__1Rtz9 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.KazangDailySales_RevealMoreExpanded__2qBRI {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.KazangDailySales_Expandabled__26I3C {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.KazangDailySales_Expandabled__26I3C td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.KazangDailySales_MerchantTable__1tvh0 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.KazangDailySales_MerchantTable__1tvh0 td:first-child {

    border-left: 1px solid #e8e8e8;
}


.KazangDailySales_MerchantRow__1D6jh {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.KazangDailySales_MerchantRow__1D6jh:hover {
    background-color: #267788;
    color: #fff;

}

.KazangDailySales_MerchantRow__1D6jh:hover a {
    color: #fff;
}

.KazangDailySales_MerchantRowExpanded__2gAiR {
    background: #267788;
    color: #fff;
}

.KazangDailySales_table__3K7Ke.KazangDailySales_table-condensed__8yg7t.KazangDailySales_table-detailed__3PG4h>tbody>tr.KazangDailySales_shown__3HXCT>td {
    background: #fdf5df;
}


.KazangDailySales_Totals__1T9wY td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.KazangDailySales_MoneyTotalLabel__3QcN_ {
    float: right;

}

.KazangDailySales_FilterWrapper__2GWX9 {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.KazangDailySales_FilterPeriods__zH9IN {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.KazangDailySales_FilterPeriods__zH9IN li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.KazangDailySales_FinancialsIntro__WGqci {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.KazangDailySales_SalesBtn__2tFJX {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.OozeDailySales_Container__2SETP {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.OozeDailySales_BreadcrumbWrapper__rv1_X {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.OozeDailySales_Content__24jNX {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.OozeDailySales_CardBody__35TYA {
    margin-top: 1rem;
}

.OozeDailySales_Alert__2EwPh {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.OozeDailySales_Breadcrumb__33c8Q {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.OozeDailySales_Header__1w1gh {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.OozeDailySales_ByLine__30lKL {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.OozeDailySales_Activity__1AFNZ {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.OozeDailySales_WelcomeContainer__3xANP {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.OozeDailySales_MerchantProfile__3f_VA {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.OozeDailySales_TabHeader__1cY-_ {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.OozeDailySales_PageHeader__2hFsU {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.OozeDailySales_Header__1w1gh {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.OozeDailySales_ExportReport__1LlCj {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.OozeDailySales_ExportIconWrapper__31Ttm {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.OozeDailySales_EmailIconWrapper__35K-z {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.OozeDailySales_AddUser__94oOn {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.OozeDailySales_AddUserBtn__1neto {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.OozeDailySales_Tools__1LvU4 {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.OozeDailySales_PageFilter__36wst {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.OozeDailySales_PrintToolsWrapper__Xrfa3 {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.OozeDailySales_FilterReportBtn__2JeaS {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.OozeDailySales_AddNewMerchantBtn__18uFK:focus,
.OozeDailySales_AddNewMerchantBtn__18uFK:hover,
.OozeDailySales_AddNewMerchantBtn__18uFK:active {
    background-color: rgb(51, 137, 255);
}


.OozeDailySales_ToolsDataExportIconWrapper__1j_ME {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.OozeDailySales_ToolsPrintIconWrapper__QXmJU {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.OozeDailySales_FilterInput__17xxS {
    margin-right: 2rem;
}

.OozeDailySales_FormGroupDefault__3DF7K.OozeDailySales_has-error__1XsGL {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.OozeDailySales_FormGroupDefault__3DF7K {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.OozeDailySales_FormGroupDefault__3DF7K label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.OozeDailySales_FormGroup__2A-KL label:not(.OozeDailySales_error__1ZX-1) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.OozeDailySales_FormControl__1VMSy {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.OozeDailySales_MerchantTableWrapper__mN9h5 {
    margin-top: 3rem;
    white-space: nowrap;
}

.OozeDailySales_MerchantTable__SB_g_ {
    width: 100%;
    border-collapse: collapse;
}

.OozeDailySales_MerchantTable__SB_g_ th {
    text-align: left;
}

.OozeDailySales_MerchantTable__SB_g_ th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.OozeDailySales_MerchantTable__SB_g_ td,
.OozeDailySales_MerchantTable__SB_g_ th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.OozeDailySales_RevealMoreHeader__1Upyd {
    padding: 0;
    vertical-align: middle;
}

.OozeDailySales_Row__2bZse {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.OozeDailySales_Row__2bZse>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.OozeDailySales_dataTables_paginate__3JX0k {
    float: right;
}

div.OozeDailySales_dataTables_paginate__3JX0k {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.OozeDailySales_dataTables_paginate__3JX0k ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.OozeDailySales_dataTables_paginate__3JX0k ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.OozeDailySales_dataTables_paginate__3JX0k ul>li.OozeDailySales_disabled__1NZt7 a {
    opacity: .5;
}

.OozeDailySales_dataTables_paginate__3JX0k ul>li.OozeDailySales_disabled__1NZt7 a {
    opacity: .35;
}

.OozeDailySales_dataTables_paginate__3JX0k ul>li.OozeDailySales_active__3l3vP>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.OozeDailySales_dataTables_paginate__3JX0k ul>li.OozeDailySales_next__2AkNX>a,
.OozeDailySales_dataTables_paginate__3JX0k ul>li.OozeDailySales_prev__32PcW>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OozeDailySales_pg_arrow_right__PEwzH {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OozeDailySales_pg_arrow_left__23OLU {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.OozeDailySales_pg_arrow_right__PEwzH:before {
    content: "\E631";
}

.OozeDailySales_pg_arrow_left__23OLU:before {
    content: "\E629";
}

.OozeDailySales_btn__2fu5P {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.OozeDailySales_btn_primary__3XRRh,
.OozeDailySales_btn_primary__3XRRh:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.OozeDailySales_btn_cons__69Q65 {
    min-width: 120px;
}




.OozeDailySales_MerchantName__1TZ3B {
    font-weight: 500;
}

.OozeDailySales_Icn__1XffJ {
    cursor: pointer;
    /* fill:#000; */
}

.OozeDailySales_Icn__1XffJ:hover {
    cursor: pointer;

}



.OozeDailySales_RevealMore__3LgMt {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.OozeDailySales_RevealMore__3LgMt:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.OozeDailySales_RevealMore__3LgMt div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.OozeDailySales_RevealMoreExpanded__2Z2XR {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.OozeDailySales_Expandabled__2Pv1p {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.OozeDailySales_Expandabled__2Pv1p td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.OozeDailySales_MerchantTable__SB_g_ td:last-child {

    border-right: 1px solid #e8e8e8;
}


.OozeDailySales_MerchantTable__SB_g_ td:first-child {

    border-left: 1px solid #e8e8e8;
}


.OozeDailySales_MerchantRow__Cb4Rh {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.OozeDailySales_MerchantRow__Cb4Rh:hover {
    background-color: #267788;
    color: #fff;

}

.OozeDailySales_MerchantRow__Cb4Rh:hover a {
    color: #fff;
}

.OozeDailySales_MerchantRowExpanded__3tCYn {
    background: #267788;
    color: #fff;
}

.OozeDailySales_table__eEqgN.OozeDailySales_table-condensed__1bFhp.OozeDailySales_table-detailed__1RhM4>tbody>tr.OozeDailySales_shown__JoiKf>td {
    background: #fdf5df;
}


.OozeDailySales_Totals__38gNx td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.OozeDailySales_MoneyTotalLabel__2k7tC {
    float: right;

}

.OozeDailySales_FilterWrapper__1GNbM {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.OozeDailySales_FilterPeriods__2vNsm {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.OozeDailySales_FilterPeriods__2vNsm li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.OozeDailySales_FinancialsIntro__FZBV8 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.OozeDailySales_SalesBtn__34akD {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.BPCReversals_Container__1ikBO {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.BPCReversals_BreadcrumbWrapper__2N7Ze {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.BPCReversals_Content__sLIob {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.BPCReversals_CardBody__U48fe {
    margin-top: 1rem;
}

.BPCReversals_Alert__3TkUQ {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.BPCReversals_Breadcrumb__3fBSB {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.BPCReversals_Header__2cBhD {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.BPCReversals_ByLine__1wY8p {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.BPCReversals_Activity__2F6lZ {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.BPCReversals_WelcomeContainer__yJzMP {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.BPCReversals_MerchantProfile__K6-wz {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.BPCReversals_TabHeader__2PS-9 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.BPCReversals_PageHeader__O2PLt {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.BPCReversals_Header__2cBhD {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.BPCReversals_ExportReport__1BK2W {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.BPCReversals_ExportIconWrapper__3kV7Z {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.BPCReversals_EmailIconWrapper__3qnfN {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.BPCReversals_AddUser__1fSAJ {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.BPCReversals_AddUserBtn__3qlyP {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.BPCReversals_Tools__1EV2e {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.BPCReversals_PageFilter__Qxd4C {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.BPCReversals_PrintToolsWrapper__10U5s {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.BPCReversals_FilterReportBtn__3TUMC {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.BPCReversals_AddNewMerchantBtn__2TsX0:focus,
.BPCReversals_AddNewMerchantBtn__2TsX0:hover,
.BPCReversals_AddNewMerchantBtn__2TsX0:active {
    background-color: rgb(51, 137, 255);
}


.BPCReversals_ToolsDataExportIconWrapper__3xErE {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.BPCReversals_ToolsPrintIconWrapper__1ZhSZ {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.BPCReversals_FilterInput__2ajz2 {
    margin-right: 2rem;
}

.BPCReversals_FormGroupDefault__1rfH7.BPCReversals_has-error__wRWvk {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.BPCReversals_FormGroupDefault__1rfH7 {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.BPCReversals_FormGroupDefault__1rfH7 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.BPCReversals_FormGroup__387A9 label:not(.BPCReversals_error__1xUfH) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.BPCReversals_FormControl__3aMAk {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.BPCReversals_MerchantTableWrapper__2-nTo {
    margin-top: 3rem;
    white-space: nowrap;
}

.BPCReversals_MerchantTable__2zCjx {
    width: 100%;
    border-collapse: collapse;
}

.BPCReversals_MerchantTable__2zCjx th {
    text-align: left;
}

.BPCReversals_MerchantTable__2zCjx th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.BPCReversals_MerchantTable__2zCjx td,
.BPCReversals_MerchantTable__2zCjx th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.BPCReversals_RevealMoreHeader__1rgRC {
    padding: 0;
    vertical-align: middle;
}

.BPCReversals_Row__2yhNi {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.BPCReversals_Row__2yhNi>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.BPCReversals_dataTables_paginate__1IZlo {
    float: right;
}

div.BPCReversals_dataTables_paginate__1IZlo {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.BPCReversals_dataTables_paginate__1IZlo ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.BPCReversals_dataTables_paginate__1IZlo ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.BPCReversals_dataTables_paginate__1IZlo ul>li.BPCReversals_disabled__3K4Gw a {
    opacity: .5;
}

.BPCReversals_dataTables_paginate__1IZlo ul>li.BPCReversals_disabled__3K4Gw a {
    opacity: .35;
}

.BPCReversals_dataTables_paginate__1IZlo ul>li.BPCReversals_active__18POj>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.BPCReversals_dataTables_paginate__1IZlo ul>li.BPCReversals_next__2x_tQ>a,
.BPCReversals_dataTables_paginate__1IZlo ul>li.BPCReversals_prev__3e8aS>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.BPCReversals_pg_arrow_right__1UjAI {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.BPCReversals_pg_arrow_left__2D3NY {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.BPCReversals_pg_arrow_right__1UjAI:before {
    content: "\E631";
}

.BPCReversals_pg_arrow_left__2D3NY:before {
    content: "\E629";
}

.BPCReversals_btn__L3Km6 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.BPCReversals_btn_primary__iSNIG,
.BPCReversals_btn_primary__iSNIG:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.BPCReversals_btn_cons__3Y7BH {
    min-width: 120px;
}




.BPCReversals_MerchantName__1x7fB {
    font-weight: 500;
}

.BPCReversals_Icn__g6Cz0 {
    cursor: pointer;
    /* fill:#000; */
}

.BPCReversals_Icn__g6Cz0:hover {
    cursor: pointer;

}



.BPCReversals_RevealMore__202i7 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.BPCReversals_RevealMore__202i7:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.BPCReversals_RevealMore__202i7 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.BPCReversals_RevealMoreExpanded__3dgs8 {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.BPCReversals_Expandabled__1RL9y {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.BPCReversals_Expandabled__1RL9y td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.BPCReversals_MerchantTable__2zCjx td:last-child {

    border-right: 1px solid #e8e8e8;
}


.BPCReversals_MerchantTable__2zCjx td:first-child {

    border-left: 1px solid #e8e8e8;
}


.BPCReversals_MerchantRow__2KCpY {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.BPCReversals_MerchantRow__2KCpY:hover {
    background-color: #267788;
    color: #fff;

}

.BPCReversals_MerchantRow__2KCpY:hover a {
    color: #fff;
}

.BPCReversals_MerchantRowExpanded__nT-g5 {
    background: #267788;
    color: #fff;
}

.BPCReversals_table__digaL.BPCReversals_table-condensed__o8X5J.BPCReversals_table-detailed__1SDj1>tbody>tr.BPCReversals_shown__3mQrK>td {
    background: #fdf5df;
}


.BPCReversals_Totals__1vNi5 td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.BPCReversals_MoneyTotalLabel__DHOJS {
    float: right;

}

.BPCReversals_FilterWrapper__434-T {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.BPCReversals_FilterPeriods__24-ZS {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.BPCReversals_FilterPeriods__24-ZS li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.BPCReversals_FinancialsIntro__JcgNz {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.BPCReversals_SalesBtn__20cjr {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.ReversalsReport_Container__1fuz0 {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.ReversalsReport_BreadcrumbWrapper__1IFOB {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.ReversalsReport_Content__1inVz {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.ReversalsReport_CardBody__3kMwA {
    margin-top: 1rem;
}

.ReversalsReport_Alert__2OU1- {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.ReversalsReport_Breadcrumb__1_10k {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.ReversalsReport_Header__b6lpF {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.ReversalsReport_ByLine__3r3n3 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.ReversalsReport_Activity__Ket5S {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.ReversalsReport_WelcomeContainer__2oxfg {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.ReversalsReport_MerchantProfile__BSXYf {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.ReversalsReport_TabHeader__1trn6 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.ReversalsReport_PageHeader__3XCh_ {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.ReversalsReport_Header__b6lpF {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.ReversalsReport_ExportReport__2k7cV {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.ReversalsReport_ExportIconWrapper__3wH2h {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.ReversalsReport_EmailIconWrapper__2PjsT {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.ReversalsReport_AddUser__3l_bF {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.ReversalsReport_AddUserBtn__3mseU {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.ReversalsReport_Tools__Tg1Y5 {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.ReversalsReport_PageFilter__2KZkW {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.ReversalsReport_PrintToolsWrapper__YIG-r {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.ReversalsReport_FilterReportBtn__3Cn6N {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.ReversalsReport_AddNewMerchantBtn__36ghQ:focus,
.ReversalsReport_AddNewMerchantBtn__36ghQ:hover,
.ReversalsReport_AddNewMerchantBtn__36ghQ:active {
    background-color: rgb(51, 137, 255);
}


.ReversalsReport_ToolsDataExportIconWrapper__EgvqM {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ReversalsReport_ToolsPrintIconWrapper__heYNe {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ReversalsReport_FilterInput__1X8ro {
    margin-right: 2rem;
}

.ReversalsReport_FormGroupDefault__3sR4s.ReversalsReport_has-error__baDAO {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ReversalsReport_FormGroupDefault__3sR4s {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ReversalsReport_FormGroupDefault__3sR4s label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.ReversalsReport_FormGroup__OZdCT label:not(.ReversalsReport_error__3UvjI) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ReversalsReport_FormControl__2D1xg {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ReversalsReport_MerchantTableWrapper__1_UXJ {
    margin-top: 3rem;
    white-space: nowrap;
}

.ReversalsReport_MerchantTable__3DAZe {
    width: 100%;
    border-collapse: collapse;
}

.ReversalsReport_MerchantTable__3DAZe th {
    text-align: left;
}

.ReversalsReport_MerchantTable__3DAZe th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ReversalsReport_MerchantTable__3DAZe td,
.ReversalsReport_MerchantTable__3DAZe th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReversalsReport_RevealMoreHeader__3hJcZ {
    padding: 0;
    vertical-align: middle;
}

.ReversalsReport_Row__IBfj2 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ReversalsReport_Row__IBfj2>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ReversalsReport_dataTables_paginate__26iXc {
    float: right;
}

div.ReversalsReport_dataTables_paginate__26iXc {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ReversalsReport_dataTables_paginate__26iXc ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.ReversalsReport_dataTables_paginate__26iXc ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.ReversalsReport_dataTables_paginate__26iXc ul>li.ReversalsReport_disabled__k0fEJ a {
    opacity: .5;
}

.ReversalsReport_dataTables_paginate__26iXc ul>li.ReversalsReport_disabled__k0fEJ a {
    opacity: .35;
}

.ReversalsReport_dataTables_paginate__26iXc ul>li.ReversalsReport_active__1kFyb>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ReversalsReport_dataTables_paginate__26iXc ul>li.ReversalsReport_next__XQxLL>a,
.ReversalsReport_dataTables_paginate__26iXc ul>li.ReversalsReport_prev__2n4Wf>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReversalsReport_pg_arrow_right__3cWar {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReversalsReport_pg_arrow_left__2PrNP {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ReversalsReport_pg_arrow_right__3cWar:before {
    content: "\E631";
}

.ReversalsReport_pg_arrow_left__2PrNP:before {
    content: "\E629";
}

.ReversalsReport_btn__20-yU {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ReversalsReport_btn_primary__i8gt7,
.ReversalsReport_btn_primary__i8gt7:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ReversalsReport_btn_cons__1TY7t {
    min-width: 120px;
}




.ReversalsReport_MerchantName__1fnAj {
    font-weight: 500;
}

.ReversalsReport_Icn__3kypJ {
    cursor: pointer;
    /* fill:#000; */
}

.ReversalsReport_Icn__3kypJ:hover {
    cursor: pointer;

}



.ReversalsReport_RevealMore__npSfp {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ReversalsReport_RevealMore__npSfp:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ReversalsReport_RevealMore__npSfp div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ReversalsReport_RevealMoreExpanded__2CHtg {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ReversalsReport_Expandabled__2ESPR {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ReversalsReport_Expandabled__2ESPR td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ReversalsReport_MerchantTable__3DAZe td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ReversalsReport_MerchantTable__3DAZe td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ReversalsReport_MerchantRow__gLGDm {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ReversalsReport_MerchantRow__gLGDm:hover {
    background-color: #267788;
    color: #fff;

}

.ReversalsReport_MerchantRow__gLGDm:hover a {
    color: #fff;
}

.ReversalsReport_MerchantRowExpanded__1KDne {
    background: #267788;
    color: #fff;
}

.ReversalsReport_table__2YpXb.ReversalsReport_table-condensed__3iaCF.ReversalsReport_table-detailed__1m5Ax>tbody>tr.ReversalsReport_shown__2rEHx>td {
    background: #fdf5df;
}


.ReversalsReport_Totals__1TlLF td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ReversalsReport_MoneyTotalLabel__magA8 {
    float: right;

}

.ReversalsReport_FilterWrapper__18t0h {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.ReversalsReport_FilterPeriods__2jNhi {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.ReversalsReport_FilterPeriods__2jNhi li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.ReversalsReport_FinancialsIntro__30Umy {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.ReversalsReport_SalesBtn__3_0aE {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.ChoppiesMonthlyReport_Container__2-DlV {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.ChoppiesMonthlyReport_BreadcrumbWrapper__3MFQC {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.ChoppiesMonthlyReport_Content__22-CX {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.ChoppiesMonthlyReport_CardBody__2Yoys {
    margin-top: 1rem;
}

.ChoppiesMonthlyReport_Alert__2WMfo {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.ChoppiesMonthlyReport_Breadcrumb__P3b8A {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.ChoppiesMonthlyReport_Header__fq0Do {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.ChoppiesMonthlyReport_ByLine__u0mc0 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.ChoppiesMonthlyReport_Activity__jt17B {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.ChoppiesMonthlyReport_WelcomeContainer__3kZpI {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.ChoppiesMonthlyReport_MerchantProfile__1Kexs {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.ChoppiesMonthlyReport_TabHeader__16wWu {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.ChoppiesMonthlyReport_PageHeader__282Zw {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.ChoppiesMonthlyReport_Header__fq0Do {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.ChoppiesMonthlyReport_ExportReport__p_a0f {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.ChoppiesMonthlyReport_ExportIconWrapper__kTlQh {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.ChoppiesMonthlyReport_EmailIconWrapper__dYqI6 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.ChoppiesMonthlyReport_AddUser__gIeZ- {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.ChoppiesMonthlyReport_AddUserBtn__3lGA4 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.ChoppiesMonthlyReport_Tools__prHDt {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.ChoppiesMonthlyReport_PageFilter__3IiL- {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.ChoppiesMonthlyReport_PrintToolsWrapper__EdHy- {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.ChoppiesMonthlyReport_FilterReportBtn__3HyWs {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.ChoppiesMonthlyReport_AddNewMerchantBtn__WAiY_:focus,
.ChoppiesMonthlyReport_AddNewMerchantBtn__WAiY_:hover,
.ChoppiesMonthlyReport_AddNewMerchantBtn__WAiY_:active {
    background-color: rgb(51, 137, 255);
}


.ChoppiesMonthlyReport_ToolsDataExportIconWrapper__2SUQy {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ChoppiesMonthlyReport_ToolsPrintIconWrapper__3k1lT {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ChoppiesMonthlyReport_FilterInput__3kOdt {
    margin-right: 2rem;
}

.ChoppiesMonthlyReport_FormGroupDefault__Y0qO8.ChoppiesMonthlyReport_has-error__3PEn7 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ChoppiesMonthlyReport_FormGroupDefault__Y0qO8 {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ChoppiesMonthlyReport_FormGroupDefault__Y0qO8 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.ChoppiesMonthlyReport_FormGroup__3VN7r label:not(.ChoppiesMonthlyReport_error__GZIFL) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ChoppiesMonthlyReport_FormControl__3z6tN {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ChoppiesMonthlyReport_MerchantTableWrapper__3lh_M {
    margin-top: 3rem;
    white-space: nowrap;
}

.ChoppiesMonthlyReport_MerchantTable__2DoIo {
    width: 100%;
    border-collapse: collapse;
}

.ChoppiesMonthlyReport_MerchantTable__2DoIo th {
    text-align: left;
}

.ChoppiesMonthlyReport_MerchantTable__2DoIo th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ChoppiesMonthlyReport_MerchantTable__2DoIo td,
.ChoppiesMonthlyReport_MerchantTable__2DoIo th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.ChoppiesMonthlyReport_RevealMoreHeader__3X475 {
    padding: 0;
    vertical-align: middle;
}

.ChoppiesMonthlyReport_Row__1_-G6 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ChoppiesMonthlyReport_Row__1_-G6>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ChoppiesMonthlyReport_dataTables_paginate__2MnA1 {
    float: right;
}

div.ChoppiesMonthlyReport_dataTables_paginate__2MnA1 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ChoppiesMonthlyReport_dataTables_paginate__2MnA1 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.ChoppiesMonthlyReport_dataTables_paginate__2MnA1 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.ChoppiesMonthlyReport_dataTables_paginate__2MnA1 ul>li.ChoppiesMonthlyReport_disabled__1xlUS a {
    opacity: .5;
}

.ChoppiesMonthlyReport_dataTables_paginate__2MnA1 ul>li.ChoppiesMonthlyReport_disabled__1xlUS a {
    opacity: .35;
}

.ChoppiesMonthlyReport_dataTables_paginate__2MnA1 ul>li.ChoppiesMonthlyReport_active__294Gw>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ChoppiesMonthlyReport_dataTables_paginate__2MnA1 ul>li.ChoppiesMonthlyReport_next__2ssZ9>a,
.ChoppiesMonthlyReport_dataTables_paginate__2MnA1 ul>li.ChoppiesMonthlyReport_prev__1JsY8>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ChoppiesMonthlyReport_pg_arrow_right__1ileC {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ChoppiesMonthlyReport_pg_arrow_left__WIx85 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ChoppiesMonthlyReport_pg_arrow_right__1ileC:before {
    content: "\E631";
}

.ChoppiesMonthlyReport_pg_arrow_left__WIx85:before {
    content: "\E629";
}

.ChoppiesMonthlyReport_btn__1C2Xj {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ChoppiesMonthlyReport_btn_primary__2Saet,
.ChoppiesMonthlyReport_btn_primary__2Saet:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ChoppiesMonthlyReport_btn_cons__275De {
    min-width: 120px;
}




.ChoppiesMonthlyReport_MerchantName__2tsQP {
    font-weight: 500;
}

.ChoppiesMonthlyReport_Icn__2hZxg {
    cursor: pointer;
    /* fill:#000; */
}

.ChoppiesMonthlyReport_Icn__2hZxg:hover {
    cursor: pointer;

}



.ChoppiesMonthlyReport_RevealMore__2TmDB {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ChoppiesMonthlyReport_RevealMore__2TmDB:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ChoppiesMonthlyReport_RevealMore__2TmDB div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ChoppiesMonthlyReport_RevealMoreExpanded__3jIUh {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ChoppiesMonthlyReport_Expandabled__2YjZp {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ChoppiesMonthlyReport_Expandabled__2YjZp td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ChoppiesMonthlyReport_MerchantTable__2DoIo td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ChoppiesMonthlyReport_MerchantTable__2DoIo td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ChoppiesMonthlyReport_MerchantRow__TUCDV {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ChoppiesMonthlyReport_MerchantRow__TUCDV:hover {
    background-color: #267788;
    color: #fff;

}

.ChoppiesMonthlyReport_MerchantRow__TUCDV:hover a {
    color: #fff;
}

.ChoppiesMonthlyReport_MerchantRowExpanded__3ODJM {
    background: #267788;
    color: #fff;
}

.ChoppiesMonthlyReport_table__TaVaI.ChoppiesMonthlyReport_table-condensed__1zO4v.ChoppiesMonthlyReport_table-detailed__8hLIK>tbody>tr.ChoppiesMonthlyReport_shown__3Rz-9>td {
    background: #fdf5df;
}


.ChoppiesMonthlyReport_Totals__N_Ixb td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ChoppiesMonthlyReport_MoneyTotalLabel__UlFjz {
    float: right;

}

.ChoppiesMonthlyReport_FilterWrapper__zi5EZ {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.ChoppiesMonthlyReport_FilterPeriods__1YiGL {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.ChoppiesMonthlyReport_FilterPeriods__1YiGL li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.ChoppiesMonthlyReport_FinancialsIntro__2UDqr {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.ChoppiesMonthlyReport_SalesBtn__2ZSFC {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.SalesAndCommissionReport_Container__1OGW3 {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.SalesAndCommissionReport_BreadcrumbWrapper__26A_W {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.SalesAndCommissionReport_Content__2PthG {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.SalesAndCommissionReport_CardBody__3loRf {
    margin-top: 1rem;
}

.SalesAndCommissionReport_Alert__1Fl3f {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.SalesAndCommissionReport_Breadcrumb__1aXJr {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.SalesAndCommissionReport_Header__1knfI {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.SalesAndCommissionReport_ByLine__3Ks_I {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.SalesAndCommissionReport_Activity__joPRa {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.SalesAndCommissionReport_WelcomeContainer__1ckNC {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.SalesAndCommissionReport_MerchantProfile__2xcKq {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.SalesAndCommissionReport_TabHeader__3E3jG {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.SalesAndCommissionReport_PageHeader__1-HFF {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.SalesAndCommissionReport_Header__1knfI {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.SalesAndCommissionReport_ExportReport__1_2YQ {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.SalesAndCommissionReport_ExportIconWrapper__3D0w0 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.SalesAndCommissionReport_EmailIconWrapper__2QVSd {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.SalesAndCommissionReport_AddUser__2BYZf {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.SalesAndCommissionReport_AddUserBtn__11fY4 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.SalesAndCommissionReport_Tools__23lPf {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.SalesAndCommissionReport_PageFilter__2gtTt {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.SalesAndCommissionReport_PrintToolsWrapper__podTG {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.SalesAndCommissionReport_FilterReportBtn__zIAoV {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.SalesAndCommissionReport_AddNewMerchantBtn__1CIfY:focus,
.SalesAndCommissionReport_AddNewMerchantBtn__1CIfY:hover,
.SalesAndCommissionReport_AddNewMerchantBtn__1CIfY:active {
    background-color: rgb(51, 137, 255);
}


.SalesAndCommissionReport_ToolsDataExportIconWrapper__2PSx4 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.SalesAndCommissionReport_ToolsPrintIconWrapper__JthV4 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.SalesAndCommissionReport_FilterInput__34RpA {
    margin-right: 2rem;
}

.SalesAndCommissionReport_FormGroupDefault__3V-ab.SalesAndCommissionReport_has-error__TD4Gs {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.SalesAndCommissionReport_FormGroupDefault__3V-ab {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.SalesAndCommissionReport_FormGroupDefault__3V-ab label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.SalesAndCommissionReport_FormGroup__cuVPF label:not(.SalesAndCommissionReport_error__2JFwU) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.SalesAndCommissionReport_FormControl__3aaqD {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.SalesAndCommissionReport_MerchantTableWrapper__3iJRG {
    margin-top: 3rem;
    white-space: nowrap;
}

.SalesAndCommissionReport_MerchantTable__3z5bt {
    width: 100%;
    border-collapse: collapse;
}

.SalesAndCommissionReport_MerchantTable__3z5bt th {
    text-align: left;
}

.SalesAndCommissionReport_MerchantTable__3z5bt th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.SalesAndCommissionReport_MerchantTable__3z5bt td,
.SalesAndCommissionReport_MerchantTable__3z5bt th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.SalesAndCommissionReport_RevealMoreHeader__cWSBo {
    padding: 0;
    vertical-align: middle;
}

.SalesAndCommissionReport_Row__nQy6W {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.SalesAndCommissionReport_Row__nQy6W>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.SalesAndCommissionReport_dataTables_paginate__14N-O {
    float: right;
}

div.SalesAndCommissionReport_dataTables_paginate__14N-O {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.SalesAndCommissionReport_dataTables_paginate__14N-O ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.SalesAndCommissionReport_dataTables_paginate__14N-O ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.SalesAndCommissionReport_dataTables_paginate__14N-O ul>li.SalesAndCommissionReport_disabled__1oIdW a {
    opacity: .5;
}

.SalesAndCommissionReport_dataTables_paginate__14N-O ul>li.SalesAndCommissionReport_disabled__1oIdW a {
    opacity: .35;
}

.SalesAndCommissionReport_dataTables_paginate__14N-O ul>li.SalesAndCommissionReport_active__34FlV>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.SalesAndCommissionReport_dataTables_paginate__14N-O ul>li.SalesAndCommissionReport_next__3bwQL>a,
.SalesAndCommissionReport_dataTables_paginate__14N-O ul>li.SalesAndCommissionReport_prev__30ppc>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.SalesAndCommissionReport_pg_arrow_right__3NzFM {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.SalesAndCommissionReport_pg_arrow_left__3pztg {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.SalesAndCommissionReport_pg_arrow_right__3NzFM:before {
    content: "\E631";
}

.SalesAndCommissionReport_pg_arrow_left__3pztg:before {
    content: "\E629";
}

.SalesAndCommissionReport_btn__3H7q8 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.SalesAndCommissionReport_btn_primary__WqkMp,
.SalesAndCommissionReport_btn_primary__WqkMp:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.SalesAndCommissionReport_btn_cons__UhMQO {
    min-width: 120px;
}




.SalesAndCommissionReport_MerchantName__3V4su {
    font-weight: 500;
}

.SalesAndCommissionReport_Icn__2CC75 {
    cursor: pointer;
    /* fill:#000; */
}

.SalesAndCommissionReport_Icn__2CC75:hover {
    cursor: pointer;

}



.SalesAndCommissionReport_RevealMore__1-wNB {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.SalesAndCommissionReport_RevealMore__1-wNB:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.SalesAndCommissionReport_RevealMore__1-wNB div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.SalesAndCommissionReport_RevealMoreExpanded__AagHO {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.SalesAndCommissionReport_Expandabled__2FgsK {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.SalesAndCommissionReport_Expandabled__2FgsK td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.SalesAndCommissionReport_MerchantTable__3z5bt td:last-child {

    border-right: 1px solid #e8e8e8;
}


.SalesAndCommissionReport_MerchantTable__3z5bt td:first-child {

    border-left: 1px solid #e8e8e8;
}


.SalesAndCommissionReport_MerchantRow__LrFSi {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.SalesAndCommissionReport_MerchantRow__LrFSi:hover {
    background-color: #267788;
    color: #fff;

}

.SalesAndCommissionReport_MerchantRow__LrFSi:hover a {
    color: #fff;
}

.SalesAndCommissionReport_MerchantRowExpanded__3apbC {
    background: #267788;
    color: #fff;
}

.SalesAndCommissionReport_table__1069D.SalesAndCommissionReport_table-condensed__1Scrc.SalesAndCommissionReport_table-detailed__2kDW8>tbody>tr.SalesAndCommissionReport_shown__3Vr6B>td {
    background: #fdf5df;
}


.SalesAndCommissionReport_Totals__1oLfN td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.SalesAndCommissionReport_MoneyTotalLabel__1IHRT {
    float: right;

}

.SalesAndCommissionReport_FilterWrapper__2fte7 {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.SalesAndCommissionReport_FilterPeriods__3Vq-7 {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.SalesAndCommissionReport_FilterPeriods__3Vq-7 li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.SalesAndCommissionReport_FinancialsIntro__3gHOC {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.SalesAndCommissionReport_SalesBtn__2bcvz {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.GroupSalesAndCommissionReport_Container__GUpiM {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.GroupSalesAndCommissionReport_BreadcrumbWrapper__3-JZq {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.GroupSalesAndCommissionReport_Content__4rOj0 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.GroupSalesAndCommissionReport_CardBody__uu2hw {
    margin-top: 1rem;
}

.GroupSalesAndCommissionReport_Alert__3zmxQ {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.GroupSalesAndCommissionReport_Breadcrumb__1yH03 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.GroupSalesAndCommissionReport_Header__1-WLu {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.GroupSalesAndCommissionReport_ByLine__35Cep {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.GroupSalesAndCommissionReport_Activity__1qGmx {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.GroupSalesAndCommissionReport_WelcomeContainer__3yAkW {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.GroupSalesAndCommissionReport_MerchantProfile__2t25h {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.GroupSalesAndCommissionReport_TabHeader__3xazj {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.GroupSalesAndCommissionReport_PageHeader__1iU3a {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.GroupSalesAndCommissionReport_Header__1-WLu {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.GroupSalesAndCommissionReport_ExportReport__gtlJo {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.GroupSalesAndCommissionReport_ExportIconWrapper__3uRbp {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.GroupSalesAndCommissionReport_EmailIconWrapper__1KQwC {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.GroupSalesAndCommissionReport_AddUser__1OR-E {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.GroupSalesAndCommissionReport_AddUserBtn__19he2 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.GroupSalesAndCommissionReport_Tools__11bB9 {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.GroupSalesAndCommissionReport_PageFilter__12m1T {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.GroupSalesAndCommissionReport_PrintToolsWrapper__2LuDS {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.GroupSalesAndCommissionReport_FilterReportBtn__1rmQZ {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.GroupSalesAndCommissionReport_AddNewMerchantBtn__8_6-G:focus,
.GroupSalesAndCommissionReport_AddNewMerchantBtn__8_6-G:hover,
.GroupSalesAndCommissionReport_AddNewMerchantBtn__8_6-G:active {
    background-color: rgb(51, 137, 255);
}


.GroupSalesAndCommissionReport_ToolsDataExportIconWrapper__25l42 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.GroupSalesAndCommissionReport_ToolsPrintIconWrapper__3e9k9 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.GroupSalesAndCommissionReport_FilterInput__2buAN {
    margin-right: 2rem;
}

.GroupSalesAndCommissionReport_FormGroupDefault__29gOj.GroupSalesAndCommissionReport_has-error__2m0GU {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.GroupSalesAndCommissionReport_FormGroupDefault__29gOj {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.GroupSalesAndCommissionReport_FormGroupDefault__29gOj label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.GroupSalesAndCommissionReport_FormGroup__33nsl label:not(.GroupSalesAndCommissionReport_error__2hd_s) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.GroupSalesAndCommissionReport_FormControl__nKpxH {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.GroupSalesAndCommissionReport_MerchantTableWrapper__3SzoE {
    margin-top: 3rem;
    white-space: nowrap;
}

.GroupSalesAndCommissionReport_MerchantTable__2gpDI {
    width: 100%;
    border-collapse: collapse;
}

.GroupSalesAndCommissionReport_MerchantTable__2gpDI th {
    text-align: left;
}

.GroupSalesAndCommissionReport_MerchantTable__2gpDI th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.GroupSalesAndCommissionReport_MerchantTable__2gpDI td,
.GroupSalesAndCommissionReport_MerchantTable__2gpDI th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.GroupSalesAndCommissionReport_RevealMoreHeader__2ebYI {
    padding: 0;
    vertical-align: middle;
}

.GroupSalesAndCommissionReport_Row__2-QUj {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.GroupSalesAndCommissionReport_Row__2-QUj>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.GroupSalesAndCommissionReport_dataTables_paginate__3wxJU {
    float: right;
}

div.GroupSalesAndCommissionReport_dataTables_paginate__3wxJU {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.GroupSalesAndCommissionReport_dataTables_paginate__3wxJU ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.GroupSalesAndCommissionReport_dataTables_paginate__3wxJU ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.GroupSalesAndCommissionReport_dataTables_paginate__3wxJU ul>li.GroupSalesAndCommissionReport_disabled__2Yw9P a {
    opacity: .5;
}

.GroupSalesAndCommissionReport_dataTables_paginate__3wxJU ul>li.GroupSalesAndCommissionReport_disabled__2Yw9P a {
    opacity: .35;
}

.GroupSalesAndCommissionReport_dataTables_paginate__3wxJU ul>li.GroupSalesAndCommissionReport_active__3L79V>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.GroupSalesAndCommissionReport_dataTables_paginate__3wxJU ul>li.GroupSalesAndCommissionReport_next__1iV_z>a,
.GroupSalesAndCommissionReport_dataTables_paginate__3wxJU ul>li.GroupSalesAndCommissionReport_prev__19BxQ>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.GroupSalesAndCommissionReport_pg_arrow_right__ZJpNi {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.GroupSalesAndCommissionReport_pg_arrow_left__1o-jP {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.GroupSalesAndCommissionReport_pg_arrow_right__ZJpNi:before {
    content: "\E631";
}

.GroupSalesAndCommissionReport_pg_arrow_left__1o-jP:before {
    content: "\E629";
}

.GroupSalesAndCommissionReport_btn__1mgHf {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.GroupSalesAndCommissionReport_btn_primary__3senJ,
.GroupSalesAndCommissionReport_btn_primary__3senJ:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.GroupSalesAndCommissionReport_btn_cons__2Jxew {
    min-width: 120px;
}




.GroupSalesAndCommissionReport_MerchantName__1ZHtd {
    font-weight: 500;
}

.GroupSalesAndCommissionReport_Icn__2k_r_ {
    cursor: pointer;
    /* fill:#000; */
}

.GroupSalesAndCommissionReport_Icn__2k_r_:hover {
    cursor: pointer;

}



.GroupSalesAndCommissionReport_RevealMore__3M5m8 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.GroupSalesAndCommissionReport_RevealMore__3M5m8:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.GroupSalesAndCommissionReport_RevealMore__3M5m8 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.GroupSalesAndCommissionReport_RevealMoreExpanded__2E2Nt {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.GroupSalesAndCommissionReport_Expandabled__koNvt {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.GroupSalesAndCommissionReport_Expandabled__koNvt td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.GroupSalesAndCommissionReport_MerchantTable__2gpDI td:last-child {

    border-right: 1px solid #e8e8e8;
}


.GroupSalesAndCommissionReport_MerchantTable__2gpDI td:first-child {

    border-left: 1px solid #e8e8e8;
}


.GroupSalesAndCommissionReport_MerchantRow__TW96L {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.GroupSalesAndCommissionReport_MerchantRow__TW96L:hover {
    background-color: #267788;
    color: #fff;

}

.GroupSalesAndCommissionReport_MerchantRow__TW96L:hover a {
    color: #fff;
}

.GroupSalesAndCommissionReport_MerchantRowExpanded__279IN {
    background: #267788;
    color: #fff;
}

.GroupSalesAndCommissionReport_table__12Qmr.GroupSalesAndCommissionReport_table-condensed__gFWbi.GroupSalesAndCommissionReport_table-detailed__31Ye2>tbody>tr.GroupSalesAndCommissionReport_shown__3a3yD>td {
    background: #fdf5df;
}


.GroupSalesAndCommissionReport_Totals__2BBA8 td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.GroupSalesAndCommissionReport_MoneyTotalLabel__4p4IR {
    float: right;

}

.GroupSalesAndCommissionReport_FilterWrapper__1UlqM {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.GroupSalesAndCommissionReport_FilterPeriods__-QCuF {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.GroupSalesAndCommissionReport_FilterPeriods__-QCuF li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.GroupSalesAndCommissionReport_FinancialsIntro__2QOgE {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.GroupSalesAndCommissionReport_SalesBtn__2oDuI {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.MerchantContractDepositsReport_Container__2zH1R {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.MerchantContractDepositsReport_BreadcrumbWrapper__JHxWK {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.MerchantContractDepositsReport_Content__3mkdG {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.MerchantContractDepositsReport_CardBody__32YZZ {
    margin-top: 1rem;
}

.MerchantContractDepositsReport_Alert__3aryP {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.MerchantContractDepositsReport_Breadcrumb__2f2MX {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.MerchantContractDepositsReport_Header__3fLf8 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.MerchantContractDepositsReport_ByLine__23-Ou {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.MerchantContractDepositsReport_Activity__u32KD {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.MerchantContractDepositsReport_WelcomeContainer__2tzWM {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.MerchantContractDepositsReport_MerchantProfile__3x7pD {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.MerchantContractDepositsReport_TabHeader__3YslI {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.MerchantContractDepositsReport_PageHeader__3mq_B {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.MerchantContractDepositsReport_Header__3fLf8 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.MerchantContractDepositsReport_ExportReport__39-q8 {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.MerchantContractDepositsReport_ExportIconWrapper__3MIRt {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.MerchantContractDepositsReport_EmailIconWrapper__ZEfKG {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.MerchantContractDepositsReport_AddUser__2zWS0 {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.MerchantContractDepositsReport_AddUserBtn__1POis {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.MerchantContractDepositsReport_Tools__1dxq9 {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.MerchantContractDepositsReport_PageFilter__2kc_s {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.MerchantContractDepositsReport_PrintToolsWrapper__3QQ04 {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.MerchantContractDepositsReport_FilterReportBtn__2268g {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.MerchantContractDepositsReport_AddNewMerchantBtn__EvLCI:focus,
.MerchantContractDepositsReport_AddNewMerchantBtn__EvLCI:hover,
.MerchantContractDepositsReport_AddNewMerchantBtn__EvLCI:active {
    background-color: rgb(51, 137, 255);
}


.MerchantContractDepositsReport_ToolsDataExportIconWrapper__1fUsv {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.MerchantContractDepositsReport_ToolsPrintIconWrapper__3gAby {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.MerchantContractDepositsReport_FilterInput__2CMGb {
    margin-right: 2rem;
}

.MerchantContractDepositsReport_FormGroupDefault__1qFcY.MerchantContractDepositsReport_has-error__brdUT {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.MerchantContractDepositsReport_FormGroupDefault__1qFcY {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.MerchantContractDepositsReport_FormGroupDefault__1qFcY label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.MerchantContractDepositsReport_FormGroup__2O-bA label:not(.MerchantContractDepositsReport_error__3qJYx) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.MerchantContractDepositsReport_FormControl__2eTG7 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.MerchantContractDepositsReport_MerchantTableWrapper__35eD_ {
    margin-top: 3rem;
    white-space: nowrap;
}

.MerchantContractDepositsReport_MerchantTable__Yysjr {
    width: 100%;
    border-collapse: collapse;
}

.MerchantContractDepositsReport_MerchantTable__Yysjr th {
    text-align: left;
}

.MerchantContractDepositsReport_MerchantTable__Yysjr th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.MerchantContractDepositsReport_MerchantTable__Yysjr td,
.MerchantContractDepositsReport_MerchantTable__Yysjr th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.MerchantContractDepositsReport_RevealMoreHeader__3E1kD {
    padding: 0;
    vertical-align: middle;
}

.MerchantContractDepositsReport_Row__38DM3 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.MerchantContractDepositsReport_Row__38DM3>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.MerchantContractDepositsReport_dataTables_paginate__-xT6Y {
    float: right;
}

div.MerchantContractDepositsReport_dataTables_paginate__-xT6Y {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.MerchantContractDepositsReport_dataTables_paginate__-xT6Y ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.MerchantContractDepositsReport_dataTables_paginate__-xT6Y ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.MerchantContractDepositsReport_dataTables_paginate__-xT6Y ul>li.MerchantContractDepositsReport_disabled__3puYd a {
    opacity: .5;
}

.MerchantContractDepositsReport_dataTables_paginate__-xT6Y ul>li.MerchantContractDepositsReport_disabled__3puYd a {
    opacity: .35;
}

.MerchantContractDepositsReport_dataTables_paginate__-xT6Y ul>li.MerchantContractDepositsReport_active__1MrUq>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.MerchantContractDepositsReport_dataTables_paginate__-xT6Y ul>li.MerchantContractDepositsReport_next__3qqfp>a,
.MerchantContractDepositsReport_dataTables_paginate__-xT6Y ul>li.MerchantContractDepositsReport_prev__3K450>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MerchantContractDepositsReport_pg_arrow_right__1IC0_ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MerchantContractDepositsReport_pg_arrow_left__EZCy0 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MerchantContractDepositsReport_pg_arrow_right__1IC0_:before {
    content: "\E631";
}

.MerchantContractDepositsReport_pg_arrow_left__EZCy0:before {
    content: "\E629";
}

.MerchantContractDepositsReport_btn__2NcxS {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.MerchantContractDepositsReport_btn_primary__gPxSe,
.MerchantContractDepositsReport_btn_primary__gPxSe:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.MerchantContractDepositsReport_btn_cons__1tEIN {
    min-width: 120px;
}




.MerchantContractDepositsReport_MerchantName__MlYwC {
    font-weight: 500;
}

.MerchantContractDepositsReport_Icn__3ewx0 {
    cursor: pointer;
    /* fill:#000; */
}

.MerchantContractDepositsReport_Icn__3ewx0:hover {
    cursor: pointer;

}



.MerchantContractDepositsReport_RevealMore__1T7eQ {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.MerchantContractDepositsReport_RevealMore__1T7eQ:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.MerchantContractDepositsReport_RevealMore__1T7eQ div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.MerchantContractDepositsReport_RevealMoreExpanded__1f6nM {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.MerchantContractDepositsReport_Expandabled__HLHQK {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.MerchantContractDepositsReport_Expandabled__HLHQK td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.MerchantContractDepositsReport_MerchantTable__Yysjr td:last-child {

    border-right: 1px solid #e8e8e8;
}


.MerchantContractDepositsReport_MerchantTable__Yysjr td:first-child {

    border-left: 1px solid #e8e8e8;
}


.MerchantContractDepositsReport_MerchantRow__1DBMP {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.MerchantContractDepositsReport_MerchantRow__1DBMP:hover {
    background-color: #267788;
    color: #fff;

}

.MerchantContractDepositsReport_MerchantRow__1DBMP:hover a {
    color: #fff;
}

.MerchantContractDepositsReport_MerchantRowExpanded__TNmQq {
    background: #267788;
    color: #fff;
}

.MerchantContractDepositsReport_table__3i5fF.MerchantContractDepositsReport_table-condensed__3lEg-.MerchantContractDepositsReport_table-detailed__39CIs>tbody>tr.MerchantContractDepositsReport_shown__6ZBxF>td {
    background: #fdf5df;
}


.MerchantContractDepositsReport_Totals__u4ep- td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.MerchantContractDepositsReport_MoneyTotalLabel__11TjP {
    float: right;

}

.MerchantContractDepositsReport_FilterWrapper__1Y3Rd {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.MerchantContractDepositsReport_FilterPeriods__1sVeQ {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.MerchantContractDepositsReport_FilterPeriods__1sVeQ li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.MerchantContractDepositsReport_FinancialsIntro__2snCT {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.MerchantContractDepositsReport_SalesBtn__8nTX3 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.DeviceTransactionsReport_Container__1q-KS {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.DeviceTransactionsReport_BreadcrumbWrapper__2x9Lp {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.DeviceTransactionsReport_Content__1zOUU {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.DeviceTransactionsReport_CardBody__4ksPI {
    margin-top: 1rem;
}

.DeviceTransactionsReport_Alert__1iHFO {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.DeviceTransactionsReport_Breadcrumb__1RoIf {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.DeviceTransactionsReport_Header__zPSzk {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.DeviceTransactionsReport_ByLine__2vixq {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.DeviceTransactionsReport_Activity__3VF4l {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.DeviceTransactionsReport_WelcomeContainer__3_Y6H {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.DeviceTransactionsReport_MerchantProfile__1ITAh {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.DeviceTransactionsReport_TabHeader__30W7Y {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.DeviceTransactionsReport_PageHeader__CPOI- {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.DeviceTransactionsReport_Header__zPSzk {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.DeviceTransactionsReport_ExportReport__aRfxe {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.DeviceTransactionsReport_ExportIconWrapper__2HKaC {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.DeviceTransactionsReport_EmailIconWrapper__31C5R {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.DeviceTransactionsReport_AddUser__tt1ub {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.DeviceTransactionsReport_AddUserBtn__2tgA6 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.DeviceTransactionsReport_Tools__3JwgP {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.DeviceTransactionsReport_PageFilter__2yzQa {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.DeviceTransactionsReport_PrintToolsWrapper__3Yw_U {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.DeviceTransactionsReport_FilterReportBtn__2MqsW {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.DeviceTransactionsReport_AddNewMerchantBtn__1DNbw:focus,
.DeviceTransactionsReport_AddNewMerchantBtn__1DNbw:hover,
.DeviceTransactionsReport_AddNewMerchantBtn__1DNbw:active {
    background-color: rgb(51, 137, 255);
}


.DeviceTransactionsReport_ToolsDataExportIconWrapper__XTA02 {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.DeviceTransactionsReport_ToolsPrintIconWrapper__2OJKJ {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.DeviceTransactionsReport_FilterInput__1yoNl {
    margin-right: 2rem;
}

.DeviceTransactionsReport_FormGroupDefault__1YPxk.DeviceTransactionsReport_has-error__N1zb- {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.DeviceTransactionsReport_FormGroupDefault__1YPxk {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.DeviceTransactionsReport_FormGroupDefault__1YPxk label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.DeviceTransactionsReport_FormGroup__KpVxo label:not(.DeviceTransactionsReport_error__3kX8-) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.DeviceTransactionsReport_FormControl__1mpUw {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.DeviceTransactionsReport_MerchantTableWrapper__34_x- {
    margin-top: 3rem;
    white-space: nowrap;
}

.DeviceTransactionsReport_MerchantTable__1bKMP {
    width: 100%;
    border-collapse: collapse;
}

.DeviceTransactionsReport_MerchantTable__1bKMP th {
    text-align: left;
}

.DeviceTransactionsReport_MerchantTable__1bKMP th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.DeviceTransactionsReport_MerchantTable__1bKMP td,
.DeviceTransactionsReport_MerchantTable__1bKMP th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.DeviceTransactionsReport_RevealMoreHeader__3fdpH {
    padding: 0;
    vertical-align: middle;
}

.DeviceTransactionsReport_Row__1ujDq {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.DeviceTransactionsReport_Row__1ujDq>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.DeviceTransactionsReport_dataTables_paginate__36L1P {
    float: right;
}

div.DeviceTransactionsReport_dataTables_paginate__36L1P {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.DeviceTransactionsReport_dataTables_paginate__36L1P ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.DeviceTransactionsReport_dataTables_paginate__36L1P ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.DeviceTransactionsReport_dataTables_paginate__36L1P ul>li.DeviceTransactionsReport_disabled__1rVJk a {
    opacity: .5;
}

.DeviceTransactionsReport_dataTables_paginate__36L1P ul>li.DeviceTransactionsReport_disabled__1rVJk a {
    opacity: .35;
}

.DeviceTransactionsReport_dataTables_paginate__36L1P ul>li.DeviceTransactionsReport_active__2jkfr>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.DeviceTransactionsReport_dataTables_paginate__36L1P ul>li.DeviceTransactionsReport_next__2naTx>a,
.DeviceTransactionsReport_dataTables_paginate__36L1P ul>li.DeviceTransactionsReport_prev__2vQvu>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.DeviceTransactionsReport_pg_arrow_right__UUl7O {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.DeviceTransactionsReport_pg_arrow_left__2dqdF {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.DeviceTransactionsReport_pg_arrow_right__UUl7O:before {
    content: "\E631";
}

.DeviceTransactionsReport_pg_arrow_left__2dqdF:before {
    content: "\E629";
}

.DeviceTransactionsReport_btn__2Iaun {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.DeviceTransactionsReport_btn_primary__3jgak,
.DeviceTransactionsReport_btn_primary__3jgak:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.DeviceTransactionsReport_btn_cons__2kOr_ {
    min-width: 120px;
}




.DeviceTransactionsReport_MerchantName__2jLci {
    font-weight: 500;
}

.DeviceTransactionsReport_Icn__TuF_s {
    cursor: pointer;
    /* fill:#000; */
}

.DeviceTransactionsReport_Icn__TuF_s:hover {
    cursor: pointer;

}



.DeviceTransactionsReport_RevealMore__3DH3Q {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.DeviceTransactionsReport_RevealMore__3DH3Q:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.DeviceTransactionsReport_RevealMore__3DH3Q div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.DeviceTransactionsReport_RevealMoreExpanded__2J2jX {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.DeviceTransactionsReport_Expandabled__2JqP7 {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.DeviceTransactionsReport_Expandabled__2JqP7 td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.DeviceTransactionsReport_MerchantTable__1bKMP td:last-child {

    border-right: 1px solid #e8e8e8;
}


.DeviceTransactionsReport_MerchantTable__1bKMP td:first-child {

    border-left: 1px solid #e8e8e8;
}


.DeviceTransactionsReport_MerchantRow__1CXtZ {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.DeviceTransactionsReport_MerchantRow__1CXtZ:hover {
    background-color: #267788;
    color: #fff;

}

.DeviceTransactionsReport_MerchantRow__1CXtZ:hover a {
    color: #fff;
}

.DeviceTransactionsReport_MerchantRowExpanded__33LqC {
    background: #267788;
    color: #fff;
}

.DeviceTransactionsReport_table__29nGA.DeviceTransactionsReport_table-condensed__28EUZ.DeviceTransactionsReport_table-detailed__3SICL>tbody>tr.DeviceTransactionsReport_shown__5TIPu>td {
    background: #fdf5df;
}


.DeviceTransactionsReport_Totals__3OhR- td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.DeviceTransactionsReport_MoneyTotalLabel__3Xq7i {
    float: right;

}

.DeviceTransactionsReport_FilterWrapper__2buoe {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.DeviceTransactionsReport_FilterPeriods__hwNNk {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.DeviceTransactionsReport_FilterPeriods__hwNNk li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.DeviceTransactionsReport_FinancialsIntro__3SYOV {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.DeviceTransactionsReport_SalesBtn__2U_wX {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.ChoppiesSalesAndCommissionReport_Container__2AQww {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.ChoppiesSalesAndCommissionReport_BreadcrumbWrapper__cqAGb {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.ChoppiesSalesAndCommissionReport_Content__PYufp {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.ChoppiesSalesAndCommissionReport_CardBody__fZg7r {
    margin-top: 1rem;
}

.ChoppiesSalesAndCommissionReport_Alert__3awlS {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.ChoppiesSalesAndCommissionReport_Breadcrumb__14XsZ {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.ChoppiesSalesAndCommissionReport_Header__1tNa4 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.ChoppiesSalesAndCommissionReport_ByLine__4wC5P {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.ChoppiesSalesAndCommissionReport_Activity__3I1G_ {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.ChoppiesSalesAndCommissionReport_WelcomeContainer__2baai {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.ChoppiesSalesAndCommissionReport_MerchantProfile__1r2bC {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.ChoppiesSalesAndCommissionReport_TabHeader__bCc6V {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.ChoppiesSalesAndCommissionReport_PageHeader__ZYHZ5 {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.ChoppiesSalesAndCommissionReport_Header__1tNa4 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.ChoppiesSalesAndCommissionReport_ExportReport__2e3s- {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.ChoppiesSalesAndCommissionReport_ExportIconWrapper__147Vf {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.ChoppiesSalesAndCommissionReport_EmailIconWrapper__9eOGT {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.ChoppiesSalesAndCommissionReport_AddUser__1yoTu {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.ChoppiesSalesAndCommissionReport_AddUserBtn__9fpJt {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.ChoppiesSalesAndCommissionReport_Tools__3EIGB {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.ChoppiesSalesAndCommissionReport_PageFilter__261wC {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.ChoppiesSalesAndCommissionReport_PrintToolsWrapper__Y2SWI {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.ChoppiesSalesAndCommissionReport_FilterReportBtn__1qcaM {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.ChoppiesSalesAndCommissionReport_AddNewMerchantBtn__2BfnO:focus,
.ChoppiesSalesAndCommissionReport_AddNewMerchantBtn__2BfnO:hover,
.ChoppiesSalesAndCommissionReport_AddNewMerchantBtn__2BfnO:active {
    background-color: rgb(51, 137, 255);
}


.ChoppiesSalesAndCommissionReport_ToolsDataExportIconWrapper__35U-K {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.ChoppiesSalesAndCommissionReport_ToolsPrintIconWrapper__1VOeG {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.ChoppiesSalesAndCommissionReport_FilterInput__1bnyX {
    margin-right: 2rem;
}

.ChoppiesSalesAndCommissionReport_FormGroupDefault__39onK.ChoppiesSalesAndCommissionReport_has-error__3kcal {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.ChoppiesSalesAndCommissionReport_FormGroupDefault__39onK {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.ChoppiesSalesAndCommissionReport_FormGroupDefault__39onK label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.ChoppiesSalesAndCommissionReport_FormGroup__1Fr4H label:not(.ChoppiesSalesAndCommissionReport_error__2C4e0) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.ChoppiesSalesAndCommissionReport_FormControl__1tj2w {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.ChoppiesSalesAndCommissionReport_MerchantTableWrapper__2RZ4Y {
    margin-top: 3rem;
    white-space: nowrap;
}

.ChoppiesSalesAndCommissionReport_MerchantTable__GwQSa {
    width: 100%;
    border-collapse: collapse;
}

.ChoppiesSalesAndCommissionReport_MerchantTable__GwQSa th {
    text-align: left;
}

.ChoppiesSalesAndCommissionReport_MerchantTable__GwQSa th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.ChoppiesSalesAndCommissionReport_MerchantTable__GwQSa td,
.ChoppiesSalesAndCommissionReport_MerchantTable__GwQSa th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.ChoppiesSalesAndCommissionReport_RevealMoreHeader__Acqpn {
    padding: 0;
    vertical-align: middle;
}

.ChoppiesSalesAndCommissionReport_Row__3B8z4 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.ChoppiesSalesAndCommissionReport_Row__3B8z4>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.ChoppiesSalesAndCommissionReport_dataTables_paginate__9jnzw {
    float: right;
}

div.ChoppiesSalesAndCommissionReport_dataTables_paginate__9jnzw {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.ChoppiesSalesAndCommissionReport_dataTables_paginate__9jnzw ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.ChoppiesSalesAndCommissionReport_dataTables_paginate__9jnzw ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.ChoppiesSalesAndCommissionReport_dataTables_paginate__9jnzw ul>li.ChoppiesSalesAndCommissionReport_disabled__3xCBS a {
    opacity: .5;
}

.ChoppiesSalesAndCommissionReport_dataTables_paginate__9jnzw ul>li.ChoppiesSalesAndCommissionReport_disabled__3xCBS a {
    opacity: .35;
}

.ChoppiesSalesAndCommissionReport_dataTables_paginate__9jnzw ul>li.ChoppiesSalesAndCommissionReport_active__1ijpE>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.ChoppiesSalesAndCommissionReport_dataTables_paginate__9jnzw ul>li.ChoppiesSalesAndCommissionReport_next__1gaHc>a,
.ChoppiesSalesAndCommissionReport_dataTables_paginate__9jnzw ul>li.ChoppiesSalesAndCommissionReport_prev__1V2w4>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ChoppiesSalesAndCommissionReport_pg_arrow_right__2qTA3 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ChoppiesSalesAndCommissionReport_pg_arrow_left__2AFDE {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ChoppiesSalesAndCommissionReport_pg_arrow_right__2qTA3:before {
    content: "\E631";
}

.ChoppiesSalesAndCommissionReport_pg_arrow_left__2AFDE:before {
    content: "\E629";
}

.ChoppiesSalesAndCommissionReport_btn__2XYEe {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.ChoppiesSalesAndCommissionReport_btn_primary__14rh0,
.ChoppiesSalesAndCommissionReport_btn_primary__14rh0:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.ChoppiesSalesAndCommissionReport_btn_cons__3oVEk {
    min-width: 120px;
}




.ChoppiesSalesAndCommissionReport_MerchantName__6FoVP {
    font-weight: 500;
}

.ChoppiesSalesAndCommissionReport_Icn__1KJtD {
    cursor: pointer;
    /* fill:#000; */
}

.ChoppiesSalesAndCommissionReport_Icn__1KJtD:hover {
    cursor: pointer;

}



.ChoppiesSalesAndCommissionReport_RevealMore__XnHT5 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.ChoppiesSalesAndCommissionReport_RevealMore__XnHT5:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.ChoppiesSalesAndCommissionReport_RevealMore__XnHT5 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.ChoppiesSalesAndCommissionReport_RevealMoreExpanded__3l7oJ {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ChoppiesSalesAndCommissionReport_Expandabled__1Tqrj {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.ChoppiesSalesAndCommissionReport_Expandabled__1Tqrj td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.ChoppiesSalesAndCommissionReport_MerchantTable__GwQSa td:last-child {

    border-right: 1px solid #e8e8e8;
}


.ChoppiesSalesAndCommissionReport_MerchantTable__GwQSa td:first-child {

    border-left: 1px solid #e8e8e8;
}


.ChoppiesSalesAndCommissionReport_MerchantRow__337dn {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.ChoppiesSalesAndCommissionReport_MerchantRow__337dn:hover {
    background-color: #267788;
    color: #fff;

}

.ChoppiesSalesAndCommissionReport_MerchantRow__337dn:hover a {
    color: #fff;
}

.ChoppiesSalesAndCommissionReport_MerchantRowExpanded__14wwh {
    background: #267788;
    color: #fff;
}

.ChoppiesSalesAndCommissionReport_table__2lp0J.ChoppiesSalesAndCommissionReport_table-condensed__1lR3q.ChoppiesSalesAndCommissionReport_table-detailed__3Ixpg>tbody>tr.ChoppiesSalesAndCommissionReport_shown__3rd6P>td {
    background: #fdf5df;
}


.ChoppiesSalesAndCommissionReport_Totals__1KuuA td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.ChoppiesSalesAndCommissionReport_MoneyTotalLabel__3ogOl {
    float: right;

}

.ChoppiesSalesAndCommissionReport_FilterWrapper__1bp5O {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.ChoppiesSalesAndCommissionReport_FilterPeriods__3flZ7 {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.ChoppiesSalesAndCommissionReport_FilterPeriods__3flZ7 li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.ChoppiesSalesAndCommissionReport_FinancialsIntro__1kDiu {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.ChoppiesSalesAndCommissionReport_SalesBtn__WyVI7 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.FinancialsReport_Container__3nZOY {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.FinancialsReport_BreadcrumbWrapper__cCB7B {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.FinancialsReport_Content__nOp21 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.FinancialsReport_CardBody__2cWS1 {
    margin-top: 1rem;
}

.FinancialsReport_Alert__1cBrx {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.FinancialsReport_Breadcrumb__1tzwo {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.FinancialsReport_Header__2t67_ {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.FinancialsReport_ByLine__Ik0lN {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.FinancialsReport_Activity__3a_Ht {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.FinancialsReport_WelcomeContainer__3mjmG {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.FinancialsReport_MerchantProfile__1isjr {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.FinancialsReport_TabHeader__1mpZS {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.FinancialsReport_PageHeader__291PQ {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.FinancialsReport_Header__2t67_ {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.FinancialsReport_ExportReport__kKPBW {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.FinancialsReport_ExportIconWrapper__1yQwV {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.FinancialsReport_EmailIconWrapper__1vl9C {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.FinancialsReport_AddUser__54rOm {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.FinancialsReport_AddUserBtn__xNaKp {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.FinancialsReport_Tools__BA-xa {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.FinancialsReport_PageFilter__2kTDp {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.FinancialsReport_PrintToolsWrapper__FYDAF {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.FinancialsReport_FilterReportBtn__JED48 {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.FinancialsReport_AddNewMerchantBtn__2b50m:focus,
.FinancialsReport_AddNewMerchantBtn__2b50m:hover,
.FinancialsReport_AddNewMerchantBtn__2b50m:active {
    background-color: rgb(51, 137, 255);
}


.FinancialsReport_ToolsDataExportIconWrapper__2xUDF {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.FinancialsReport_ToolsPrintIconWrapper__1e58N {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.FinancialsReport_FilterInput__1W6UO {
    margin-right: 2rem;
}

.FinancialsReport_FormGroupDefault__D4rIJ.FinancialsReport_has-error__3wk_M {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.FinancialsReport_FormGroupDefault__D4rIJ {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.FinancialsReport_FormGroupDefault__D4rIJ label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.FinancialsReport_FormGroup__UYt2T label:not(.FinancialsReport_error__1Vq_X) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.FinancialsReport_FormControl__3hQRT {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.FinancialsReport_MerchantTableWrapper__Sw07n {
    margin-top: 3rem;
    white-space: nowrap;
}

.FinancialsReport_MerchantTable__HV93y {
    width: 100%;
    border-collapse: collapse;
}

.FinancialsReport_MerchantTable__HV93y th {
    text-align: left;
}

.FinancialsReport_MerchantTable__HV93y th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.FinancialsReport_MerchantTable__HV93y td,
.FinancialsReport_MerchantTable__HV93y th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.FinancialsReport_RevealMoreHeader__1tsS4 {
    padding: 0;
    vertical-align: middle;
}

.FinancialsReport_Row__BLQU5 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.FinancialsReport_Row__BLQU5>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.FinancialsReport_dataTables_paginate__tRx3i {
    float: right;
}

div.FinancialsReport_dataTables_paginate__tRx3i {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.FinancialsReport_dataTables_paginate__tRx3i ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.FinancialsReport_dataTables_paginate__tRx3i ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.FinancialsReport_dataTables_paginate__tRx3i ul>li.FinancialsReport_disabled__34Y5H a {
    opacity: .5;
}

.FinancialsReport_dataTables_paginate__tRx3i ul>li.FinancialsReport_disabled__34Y5H a {
    opacity: .35;
}

.FinancialsReport_dataTables_paginate__tRx3i ul>li.FinancialsReport_active__2nOtc>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.FinancialsReport_dataTables_paginate__tRx3i ul>li.FinancialsReport_next__2PsjH>a,
.FinancialsReport_dataTables_paginate__tRx3i ul>li.FinancialsReport_prev__2wTVA>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.FinancialsReport_pg_arrow_right__1GRlw {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.FinancialsReport_pg_arrow_left__3PGcq {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.FinancialsReport_pg_arrow_right__1GRlw:before {
    content: "\E631";
}

.FinancialsReport_pg_arrow_left__3PGcq:before {
    content: "\E629";
}

.FinancialsReport_btn__3PidG {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.FinancialsReport_btn_primary__eSgnR,
.FinancialsReport_btn_primary__eSgnR:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.FinancialsReport_btn_cons__1_30B {
    min-width: 120px;
}




.FinancialsReport_MerchantName__1q5oY {
    font-weight: 500;
}

.FinancialsReport_Icn__2b2Ri {
    cursor: pointer;
    /* fill:#000; */
}

.FinancialsReport_Icn__2b2Ri:hover {
    cursor: pointer;

}



.FinancialsReport_RevealMore__2o1W9 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.FinancialsReport_RevealMore__2o1W9:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.FinancialsReport_RevealMore__2o1W9 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.FinancialsReport_RevealMoreExpanded__zpjxY {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.FinancialsReport_Expandabled__kW-tj {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.FinancialsReport_Expandabled__kW-tj td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.FinancialsReport_MerchantTable__HV93y td:last-child {

    border-right: 1px solid #e8e8e8;
}


.FinancialsReport_MerchantTable__HV93y td:first-child {

    border-left: 1px solid #e8e8e8;
}


.FinancialsReport_MerchantRow__3nh27 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.FinancialsReport_MerchantRow__3nh27:hover {
    background-color: #267788;
    color: #fff;

}

.FinancialsReport_MerchantRow__3nh27:hover a {
    color: #fff;
}

.FinancialsReport_MerchantRowExpanded__1OTXM {
    background: #267788;
    color: #fff;
}

.FinancialsReport_table__2qiuv.FinancialsReport_table-condensed__1X1aD.FinancialsReport_table-detailed__3U1lE>tbody>tr.FinancialsReport_shown__2Qbav>td {
    background: #fdf5df;
}


.FinancialsReport_Totals__2Vm4K td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.FinancialsReport_MoneyTotalLabel__OyS-b {
    float: right;

}

.FinancialsReport_FilterWrapper__SRYtI {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.FinancialsReport_FilterPeriods__3hcgT {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.FinancialsReport_FilterPeriods__3hcgT li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.FinancialsReport_FinancialsIntro__1YcMM {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.FinancialsReport_SalesBtn__4t3gk {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.Cachet_Container__3HzH6 {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.Cachet_BreadcrumbWrapper__uKOta {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Cachet_Content__2kdv7 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Cachet_CardBody__2Idah {
    margin-top: 1rem;
}

.Cachet_Alert__o75Id {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Cachet_Breadcrumb__1edbp {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Cachet_Header__1NnU6 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.Cachet_ByLine__TeQRz {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Cachet_Activity__1iHer {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Cachet_WelcomeContainer__2D2tl {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Cachet_MerchantProfile__2ImYL {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.Cachet_TabHeader__2jF__ {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Cachet_PageHeader__1Qgk7 {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.Cachet_Header__1NnU6 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Cachet_ExportReport__rN55m {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Cachet_ExportIconWrapper__VBr22 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Cachet_EmailIconWrapper__1Aw1n {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Cachet_AddUser__21Tm0 {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Cachet_AddUserBtn__2EBHQ {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.Cachet_Tools__1K8YQ {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.Cachet_PageFilter__2ztUu {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.Cachet_PrintToolsWrapper__gEoBz {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.Cachet_FilterReportBtn__3j0xX {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.Cachet_AddNewMerchantBtn__3EPtN:focus,
.Cachet_AddNewMerchantBtn__3EPtN:hover,
.Cachet_AddNewMerchantBtn__3EPtN:active {
    background-color: rgb(51, 137, 255);
}


.Cachet_ToolsDataExportIconWrapper__2kMWE {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Cachet_ToolsPrintIconWrapper__1USnk {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Cachet_FilterInput__2Xv3G {
    margin-right: 2rem;
}

.Cachet_FormGroupDefault__1ICz-.Cachet_has-error__yVvDq {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Cachet_FormGroupDefault__1ICz- {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Cachet_FormGroupDefault__1ICz- label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Cachet_FormGroup__1Gmoi label:not(.Cachet_error__349DP) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Cachet_FormControl__Z7uV_ {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Cachet_MerchantTableWrapper__HrFOr {
    margin-top: 3rem;
    white-space: nowrap;
}

.Cachet_MerchantTable__2d79H {
    width: 100%;
    border-collapse: collapse;
}

.Cachet_MerchantTable__2d79H th {
    text-align: left;
}

.Cachet_MerchantTable__2d79H th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Cachet_MerchantTable__2d79H td,
.Cachet_MerchantTable__2d79H th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Cachet_RevealMoreHeader__3KO6X {
    padding: 0;
    vertical-align: middle;
}

.Cachet_Row__3OTC9 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Cachet_Row__3OTC9>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Cachet_dataTables_paginate__3C1o6 {
    float: right;
}

div.Cachet_dataTables_paginate__3C1o6 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Cachet_dataTables_paginate__3C1o6 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.Cachet_dataTables_paginate__3C1o6 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Cachet_dataTables_paginate__3C1o6 ul>li.Cachet_disabled__2-lRd a {
    opacity: .5;
}

.Cachet_dataTables_paginate__3C1o6 ul>li.Cachet_disabled__2-lRd a {
    opacity: .35;
}

.Cachet_dataTables_paginate__3C1o6 ul>li.Cachet_active__1Tce2>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Cachet_dataTables_paginate__3C1o6 ul>li.Cachet_next__hGnjf>a,
.Cachet_dataTables_paginate__3C1o6 ul>li.Cachet_prev__1NfYS>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Cachet_pg_arrow_right__3TXM3 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Cachet_pg_arrow_left__3Jc2g {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Cachet_pg_arrow_right__3TXM3:before {
    content: "\E631";
}

.Cachet_pg_arrow_left__3Jc2g:before {
    content: "\E629";
}

.Cachet_btn__3m_ly {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Cachet_btn_primary__1MvZa,
.Cachet_btn_primary__1MvZa:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Cachet_btn_cons__3ooB4 {
    min-width: 120px;
}




.Cachet_MerchantName__1m2yh {
    font-weight: 500;
}

.Cachet_Icn__2WaL4 {
    cursor: pointer;
    /* fill:#000; */
}

.Cachet_Icn__2WaL4:hover {
    cursor: pointer;

}



.Cachet_RevealMore__1CCy4 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Cachet_RevealMore__1CCy4:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Cachet_RevealMore__1CCy4 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Cachet_RevealMoreExpanded__WkA83 {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Cachet_Expandabled__99Ueb {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Cachet_Expandabled__99Ueb td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Cachet_MerchantTable__2d79H td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Cachet_MerchantTable__2d79H td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Cachet_MerchantRow___eqZ1 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Cachet_MerchantRow___eqZ1:hover {
    background-color: #267788;
    color: #fff;

}

.Cachet_MerchantRow___eqZ1:hover a {
    color: #fff;
}

.Cachet_MerchantRowExpanded__1NRdR {
    background: #267788;
    color: #fff;
}

.Cachet_table__ONTr4.Cachet_table-condensed__1dBtW.Cachet_table-detailed__18fKp>tbody>tr.Cachet_shown__2g_Xt>td {
    background: #fdf5df;
}


.Cachet_Totals__3RiQz td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Cachet_MoneyTotalLabel__31b79 {
    float: right;

}

.Cachet_FilterWrapper__2PNIK {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.Cachet_FilterPeriods__3wDqn {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.Cachet_FilterPeriods__3wDqn li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.Cachet_FinancialsIntro__1n9Wz {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}
.Deposits_Container__1Pn4t {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Deposits_Tools__3tXMb {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Deposits_PageFilter__cGuRu {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Deposits_ToolsWrapper__2V-wB {
    height: 6rem;
    width: 100%;

}

.Deposits_TabHeader__1wPUu {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Deposits_Header__25M47 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Deposits_ExportReport__3W7nV {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Deposits_ExportIconWrapper__17QF7 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Deposits_EmailIconWrapper__1CXaq {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Deposits_PrintToolsWrapper__gXs6h {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Deposits_AddNewMerchant__KXDfa {
    /* // font-family: Cambay; */
}

.Deposits_AddNewMerchantBtn__2e32C {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Deposits_AddNewMerchantBtn__2e32C:focus,
.Deposits_AddNewMerchantBtn__2e32C:hover,
.Deposits_AddNewMerchantBtn__2e32C:active {
    background-color: rgb(51, 137, 255);
}


.Deposits_ToolsDataExportIconWrapper__3MEXs {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Deposits_ToolsPrintIconWrapper__3pcZa {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Deposits_FilterInput__3yXTX {
    margin-right: 2rem;
}

.Deposits_FormGroup__1Qbzu {
    /* margin-bottom: 10px; */
}

.Deposits_FormGroupDefault__1DsOp.Deposits_has-error__2dEL7 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Deposits_FormGroupDefault__1DsOp {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Deposits_FormGroupDefault__1DsOp label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Deposits_FormGroup__1Qbzu label:not(.Deposits_error__2bmmG) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Deposits_FormControl__3QDbS {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Deposits_MerchantTableWrapper__2eMlU {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Deposits_TableWrapper__38cRk {
    display: flex;
}

.Deposits_MerchantTable__tmK85 {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Deposits_MerchantTable__tmK85 th {
    text-align: left;
}

.Deposits_MerchantTable__tmK85 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Deposits_MerchantTable__tmK85 td,
.Deposits_MerchantTable__tmK85 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Deposits_MerchantTable__tmK85 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Deposits_MerchantTable__tmK85 td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Deposits_RevealMoreHeader__fqHwh {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Deposits_Row__1PU4o {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Deposits_Row__1PU4o>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Deposits_dataTables_paginate__fthjl {
    float: right;
}

.Deposits_dataTables_paginate__fthjl {
    /* margin-top: 25px !important; */
}

div.Deposits_dataTables_paginate__fthjl {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Deposits_dataTables_paginate__fthjl ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Deposits_dataTables_paginate__fthjl ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Deposits_dataTables_paginate__fthjl ul>li.Deposits_disabled__2Froq a {
    opacity: .5;
}

.Deposits_dataTables_paginate__fthjl ul>li.Deposits_disabled__2Froq a {
    opacity: .35;
}

.Deposits_dataTables_paginate__fthjl ul>li.Deposits_active__3ry_R>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Deposits_dataTables_paginate__fthjl ul>li.Deposits_next__2dnWe>a,
.Deposits_dataTables_paginate__fthjl ul>li.Deposits_prev__1yZqp>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_right__1j_yY {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_left__aDILd {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_right__1j_yY:before {
    content: "\E631";
}

.Deposits_pg_arrow_left__aDILd:before {
    content: "\E629";
}

.Deposits_btn__21cNv {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Deposits_btn_primary__GzP5D,
.Deposits_btn_primary__GzP5D:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Deposits_btn_cons__1O4_C {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Deposits_MerchantRow__2cSec {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Deposits_MerchantRow__2cSec:hover {
    background-color: #267788;
    color: #fff;

}

.Deposits_MerchantRow__2cSec:hover a {
    color: #fff;
}

.Deposits_MerchantRowExpanded__HuSRb {
    background: #267788;
    color: #fff;
}

.Deposits_table__2JVua.Deposits_table-condensed__2X2Cy.Deposits_table-detailed__1J-x0>tbody>tr.Deposits_shown__2zZKO>td {
    background: #fdf5df;
}

.Deposits_EnableMerchant__1gb5- {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Deposits_EnableMerchantHeader__1O4Iv {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Deposits_EnableMerchantBody__32YwV {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}


.Deposits_MoneyTotalLabel__3PV8U {
    float: right;
    font-weight: 600;
}

.Deposits_MoneyTotal__9rMSY {
    font-weight: 600;
}
.Reversals_Container__30G9H {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Reversals_Tools__2s96j {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Reversals_PageFilter__MdyMu {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Reversals_ToolsWrapper__2D3NS {
    height: 6rem;
    width: 100%;

}

.Reversals_TabHeader__1EkKv {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Reversals_Header__2HiwH {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Reversals_ExportReport__3hY9u {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Reversals_ExportIconWrapper__3IVHQ {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Reversals_EmailIconWrapper__3SFPO {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Reversals_PrintToolsWrapper__3cGqR {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Reversals_AddNewMerchant__2i0Rh {
    /* // font-family: Cambay; */
}

.Reversals_AddNewMerchantBtn__1_PA2 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Reversals_AddNewMerchantBtn__1_PA2:focus,
.Reversals_AddNewMerchantBtn__1_PA2:hover,
.Reversals_AddNewMerchantBtn__1_PA2:active {
    background-color: rgb(51, 137, 255);
}


.Reversals_ToolsDataExportIconWrapper__GpfYS {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Reversals_ToolsPrintIconWrapper__3WiF7 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Reversals_FilterInput__11BaE {
    margin-right: 2rem;
}

.Reversals_FormGroup__3FREs {
    /* margin-bottom: 10px; */
}

.Reversals_FormGroupDefault__1yyRg.Reversals_has-error__24uWR {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Reversals_FormGroupDefault__1yyRg {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Reversals_FormGroupDefault__1yyRg label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Reversals_FormGroup__3FREs label:not(.Reversals_error__1u7oY) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Reversals_FormControl__23Gm0 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Reversals_MerchantTableWrapper__19_tn {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Reversals_TableWrapper__1OyLN {
    display: flex;
}

.Reversals_MerchantTable__1OWXQ {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Reversals_MerchantTable__1OWXQ th {
    text-align: left;
}

.Reversals_MerchantTable__1OWXQ th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Reversals_MerchantTable__1OWXQ td,
.Reversals_MerchantTable__1OWXQ th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Reversals_MerchantTable__1OWXQ td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Reversals_MerchantTable__1OWXQ td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Reversals_RevealMoreHeader__GarBZ {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Reversals_Row__UaF85 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Reversals_Row__UaF85>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Reversals_dataTables_paginate__3xl_t {
    float: right;
}

.Reversals_dataTables_paginate__3xl_t {
    /* margin-top: 25px !important; */
}

div.Reversals_dataTables_paginate__3xl_t {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Reversals_dataTables_paginate__3xl_t ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Reversals_dataTables_paginate__3xl_t ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Reversals_dataTables_paginate__3xl_t ul>li.Reversals_disabled__2RzXG a {
    opacity: .5;
}

.Reversals_dataTables_paginate__3xl_t ul>li.Reversals_disabled__2RzXG a {
    opacity: .35;
}

.Reversals_dataTables_paginate__3xl_t ul>li.Reversals_active__3lzLV>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Reversals_dataTables_paginate__3xl_t ul>li.Reversals_next__Z6yBv>a,
.Reversals_dataTables_paginate__3xl_t ul>li.Reversals_prev__1bbiK>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Reversals_pg_arrow_right__1HeJd {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Reversals_pg_arrow_left__2lo-h {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Reversals_pg_arrow_right__1HeJd:before {
    content: "\E631";
}

.Reversals_pg_arrow_left__2lo-h:before {
    content: "\E629";
}

.Reversals_btn__2-d3_ {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Reversals_btn_primary__1_TPS,
.Reversals_btn_primary__1_TPS:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Reversals_btn_cons__2XNyb {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Reversals_MerchantRow__1vKo7 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Reversals_MerchantRow__1vKo7:hover {
    background-color: #267788;
    color: #fff;

}

.Reversals_MerchantRow__1vKo7:hover a {
    color: #fff;
}

.Reversals_MerchantRowExpanded__9E-Rw {
    background: #267788;
    color: #fff;
}

.Reversals_table__2e5rC.Reversals_table-condensed__PlRYK.Reversals_table-detailed__aayL_>tbody>tr.Reversals_shown__330Lp>td {
    background: #fdf5df;
}

.Reversals_EnableMerchant__1Ajay {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Reversals_EnableMerchantHeader__16_B- {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Reversals_EnableMerchantBody__1l_RI {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}


.Reversals_MoneyTotalLabel__tLrsk {
    float: right;
    font-weight: 600;
}

.Reversals_MoneyTotal__3bj-m {
    font-weight: 600;
}
.Summary_Container__3_NN3 {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Summary_Tools__3XS7Z {
    display: flex;
    /* margin: 0 2.5rem; */
    /* padding-top: 1.4rem; */
}

.Summary_PageFilter__27rZY {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.Summary_ToolsWrapper__2dr-L {
    height: 6rem;
    width: 100%;

}

.Summary_PrintToolsWrapper__2Y06D {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Summary_AddNewMerchant__1jyAf {
    /* // font-family: Cambay; */
}

.Summary_FilterReportBtn__Rd4Jv {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Summary_AddNewMerchantBtn__32E4T:focus,
.Summary_AddNewMerchantBtn__32E4T:hover,
.Summary_AddNewMerchantBtn__32E4T:active {
    background-color: rgb(51, 137, 255);
}


.Summary_ToolsDataExportIconWrapper__ulA3h {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Summary_ToolsPrintIconWrapper__-k9Ld {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Summary_FilterInput__sRibg {
    margin-right: 2rem;
}

.Summary_FormGroup__2RGEp {
    /* margin-bottom: 10px; */
}

.Summary_FormGroupDefault__3HmhQ.Summary_has-error__3Rn18 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Summary_FormGroupDefault__3HmhQ {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Summary_FormGroupDefault__3HmhQ label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Summary_FormGroup__2RGEp label:not(.Summary_error__2oYz5) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Summary_FormControl__3cg7S {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Summary_MerchantTableWrapper__3TYA- {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Summary_TableWrapper__K2PJ9 {

    display: flex;
    flex-direction:row;

}

.Summary_BalanceSheet__1UdXa {
    width: 45%;
    margin-right: 2.5rem;
}

.Summary_VendorBalances__2HlF3 {
    width: 45%;
}

.Summary_MerchantTable__3dqfO {
    width:100%;
    border-collapse: collapse;
    border: 1px solid #e8e8e8;
}

.Summary_MerchantTable__3dqfO th {
    text-align: left;
}

.Summary_MerchantTable__3dqfO th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Summary_MerchantTable__3dqfO td,
.Summary_MerchantTable__3dqfO th {
    padding:0.35rem 1rem;
    /* border-bottom: 1px solid #e8e8e8; */
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Summary_RevealMoreHeader__3gxAd {
    padding: 0;
    vertical-align: middle;
}





.Summary_Row__LWYIq {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Summary_Row__LWYIq>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Summary_dataTables_paginate__4YuMa {
    float: right;
}

.Summary_dataTables_paginate__4YuMa {
    /* margin-top: 25px !important; */
}

div.Summary_dataTables_paginate__4YuMa {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Summary_dataTables_paginate__4YuMa ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Summary_dataTables_paginate__4YuMa ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Summary_dataTables_paginate__4YuMa ul>li.Summary_disabled__17J-q a {
    opacity: .5;
}

.Summary_dataTables_paginate__4YuMa ul>li.Summary_disabled__17J-q a {
    opacity: .35;
}

.Summary_dataTables_paginate__4YuMa ul>li.Summary_active__20O74>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Summary_dataTables_paginate__4YuMa ul>li.Summary_next__2jZFe>a,
.Summary_dataTables_paginate__4YuMa ul>li.Summary_prev__tkh36>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_right__gkDBQ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_left__2Zx1s {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Summary_pg_arrow_right__gkDBQ:before {
    content: "\E631";
}

.Summary_pg_arrow_left__2Zx1s:before {
    content: "\E629";
}

.Summary_btn__3sSya {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Summary_btn_primary__h-ZHz,
.Summary_btn_primary__h-ZHz:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Summary_btn_cons__asaJs {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Summary_MerchantName__1D9Dr {
    font-weight: 500;
}

.Summary_Icn__2Jes4 {
    cursor: pointer;
    /* fill:#000; */
}

.Summary_Icn__2Jes4:hover {
    cursor: pointer;

}



.Summary_RevealMore__3HYEZ {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Summary_RevealMore__3HYEZ:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Summary_RevealMore__3HYEZ div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Summary_RevealMoreExpanded__rJ4SJ {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Summary_Expandabled__y3_5J {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Summary_Expandabled__y3_5J td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Summary_MerchantTable__3dqfO td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Summary_MerchantTable__3dqfO td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Summary_MerchantRow__2Na4- {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Summary_MerchantRow__2Na4-:hover {
    background-color: #267788;
    color: #fff;

}

.Summary_MerchantRow__2Na4-:hover a {
    color: #fff;
}

.Summary_MerchantRowExpanded__i72po {
    background: #267788;
    color: #fff;
}

.Summary_table__3iiJ8.Summary_table-condensed__1F2AI.Summary_table-detailed__1lFgA>tbody>tr.Summary_shown__ODNPy>td {
    background: #fdf5df;
}


.Summary_Totals__2BT4W td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Summary_MoneyTotalLabel__Dfo2V {
    float: right;

}
.Commissions_Container__1nnXU {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Commissions_Tools__1PLii {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Commissions_PageFilter__3R5p0 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Commissions_ToolsWrapper__2gbC1 {
    height: 6rem;
    width: 100%;

}

.Commissions_TabHeader__VXmEQ {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Commissions_Header__2EyI9 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Commissions_ExportReport__1QZBY {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Commissions_ExportIconWrapper__gmcF4 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Commissions_EmailIconWrapper__1a-66 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Commissions_PrintToolsWrapper__1MGM4 {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Commissions_AddNewMerchant__NVrdR {
    /* // font-family: Cambay; */
}

.Commissions_AddNewMerchantBtn__3wkOj {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Commissions_AddNewMerchantBtn__3wkOj:focus,
.Commissions_AddNewMerchantBtn__3wkOj:hover,
.Commissions_AddNewMerchantBtn__3wkOj:active {
    background-color: rgb(51, 137, 255);
}


.Commissions_ToolsDataExportIconWrapper__3nslL {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Commissions_ToolsPrintIconWrapper__1JwJH {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Commissions_FilterInput__OT3XU {
    margin-right: 2rem;
}

.Commissions_FormGroup__3goyl {
    /* margin-bottom: 10px; */
}

.Commissions_FormGroupDefault__mPogS.Commissions_has-error__3PZcE {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Commissions_FormGroupDefault__mPogS {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Commissions_FormGroupDefault__mPogS label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Commissions_FormGroup__3goyl label:not(.Commissions_error__2X_fP) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Commissions_FormControl__3hgma {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Commissions_MerchantTableWrapper__GEi_m {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Commissions_TableWrapper__1N0Da {
    display: flex;
}

.Commissions_MerchantTable__2cqGV {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Commissions_MerchantTable__2cqGV th {
    text-align: left;
}

.Commissions_MerchantTable__2cqGV th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Commissions_MerchantTable__2cqGV td,
.Commissions_MerchantTable__2cqGV th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Commissions_MerchantTable__2cqGV td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Commissions_MerchantTable__2cqGV td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Commissions_RevealMoreHeader__3YUVg {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Commissions_Row__2OOJt {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Commissions_Row__2OOJt>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Commissions_dataTables_paginate__3ifmH {
    float: right;
}

.Commissions_dataTables_paginate__3ifmH {
    /* margin-top: 25px !important; */
}

div.Commissions_dataTables_paginate__3ifmH {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Commissions_dataTables_paginate__3ifmH ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Commissions_dataTables_paginate__3ifmH ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Commissions_dataTables_paginate__3ifmH ul>li.Commissions_disabled__3gUj3 a {
    opacity: .5;
}

.Commissions_dataTables_paginate__3ifmH ul>li.Commissions_disabled__3gUj3 a {
    opacity: .35;
}

.Commissions_dataTables_paginate__3ifmH ul>li.Commissions_active__3zHUU>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Commissions_dataTables_paginate__3ifmH ul>li.Commissions_next__2hl5U>a,
.Commissions_dataTables_paginate__3ifmH ul>li.Commissions_prev__4OWFC>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commissions_pg_arrow_right__1WNKF {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commissions_pg_arrow_left__3Pws1 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Commissions_pg_arrow_right__1WNKF:before {
    content: "\E631";
}

.Commissions_pg_arrow_left__3Pws1:before {
    content: "\E629";
}

.Commissions_btn__2p57s {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Commissions_btn_primary__2-gkk,
.Commissions_btn_primary__2-gkk:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Commissions_btn_cons__2fwzi {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Commissions_MerchantRow__2iz17 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Commissions_MerchantRow__2iz17:hover {
    background-color: #267788;
    color: #fff;

}

.Commissions_MerchantRow__2iz17:hover a {
    color: #fff;
}

.Commissions_MerchantRowExpanded__5B-Hh {
    background: #267788;
    color: #fff;
}

.Commissions_table__BumLI.Commissions_table-condensed__2ELkp.Commissions_table-detailed__1R48H>tbody>tr.Commissions_shown__1JsLA>td {
    background: #fdf5df;
}

.Commissions_EnableMerchant__3IMfO {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Commissions_EnableMerchantHeader__38VJ6 {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Commissions_EnableMerchantBody__kfUhh {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}


.Commissions_MoneyTotalLabel__3u2SV {
    /* float: right; */
    font-weight: 600;
}

.Commissions_MoneyTotal__o1vCz {
    font-weight: 600;
}
.Withdrawals_Container__2Wow0 {
    background-color: #fff;
    margin: 2.4rem;
    margin-bottom: 2.4rem;
    /* flex-grow: 1; */
    border: 1px solid #f1f3f4;
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Withdrawals_Tools__2YW1W {
    display: flex;
    margin: 0 2.5rem;
    padding-top: 1.4rem;
}

.Withdrawals_PageFilter__PRMWe {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Withdrawals_ToolsWrapper__2ysqV {
    height: 6rem;
    width: 100%;

}

.Withdrawals_TabHeader__1qsvw {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Withdrawals_Header__1c2ry {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Withdrawals_ExportReport__2sJ9s {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Withdrawals_ExportIconWrapper__8QVvp {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Withdrawals_EmailIconWrapper__Y70O6 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Withdrawals_PrintToolsWrapper__20BNe {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Withdrawals_AddNewMerchant__iQ61S {
    /* // font-family: Cambay; */
}

.Withdrawals_AddNewMerchantBtn__P2HaD {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Withdrawals_AddNewMerchantBtn__P2HaD:focus,
.Withdrawals_AddNewMerchantBtn__P2HaD:hover,
.Withdrawals_AddNewMerchantBtn__P2HaD:active {
    background-color: rgb(51, 137, 255);
}


.Withdrawals_ToolsDataExportIconWrapper__1-RgO {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Withdrawals_ToolsPrintIconWrapper__2IpPN {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Withdrawals_FilterInput__3a5VK {
    margin-right: 2rem;
}

.Withdrawals_FormGroup__3Aqyb {
    /* margin-bottom: 10px; */
}

.Withdrawals_FormGroupDefault__1lD4B.Withdrawals_has-error__2epa4 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Withdrawals_FormGroupDefault__1lD4B {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Withdrawals_FormGroupDefault__1lD4B label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Withdrawals_FormGroup__3Aqyb label:not(.Withdrawals_error__2zyAT) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Withdrawals_FormControl__1_RqI {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Withdrawals_MerchantTableWrapper__16bBB {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Withdrawals_TableWrapper__1gmGP {
    display: flex;
}

.Withdrawals_MerchantTable__3LxRT {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Withdrawals_MerchantTable__3LxRT th {
    text-align: left;
}

.Withdrawals_MerchantTable__3LxRT th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Withdrawals_MerchantTable__3LxRT td,
.Withdrawals_MerchantTable__3LxRT th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Withdrawals_MerchantTable__3LxRT td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Withdrawals_MerchantTable__3LxRT td:first-child {

    border-left: 1px solid #e8e8e8;
}

.Withdrawals_RevealMoreHeader__2BQZ5 {
    padding: 0;
    vertical-align: middle;
}



/* table .expandable {
    text-align: left;
    background: #e8e8e8;
    border-bottom: 5px solid #38003c;
    display: none;
    z-index: 500;
} */



.Withdrawals_Row__yGX8c {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem 0;
}

.Withdrawals_Row__yGX8c>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Withdrawals_dataTables_paginate__2jYy5 {
    float: right;
}

.Withdrawals_dataTables_paginate__2jYy5 {
    /* margin-top: 25px !important; */
}

div.Withdrawals_dataTables_paginate__2jYy5 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Withdrawals_dataTables_paginate__2jYy5 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Withdrawals_dataTables_paginate__2jYy5 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Withdrawals_dataTables_paginate__2jYy5 ul>li.Withdrawals_disabled__3OFpA a {
    opacity: .5;
}

.Withdrawals_dataTables_paginate__2jYy5 ul>li.Withdrawals_disabled__3OFpA a {
    opacity: .35;
}

.Withdrawals_dataTables_paginate__2jYy5 ul>li.Withdrawals_active__ldvLZ>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Withdrawals_dataTables_paginate__2jYy5 ul>li.Withdrawals_next__2lw4s>a,
.Withdrawals_dataTables_paginate__2jYy5 ul>li.Withdrawals_prev__xYqlX>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Withdrawals_pg_arrow_right__3hYcZ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Withdrawals_pg_arrow_left__2ZcPE {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Withdrawals_pg_arrow_right__3hYcZ:before {
    content: "\E631";
}

.Withdrawals_pg_arrow_left__2ZcPE:before {
    content: "\E629";
}

.Withdrawals_btn__370w8 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Withdrawals_btn_primary__h2aBr,
.Withdrawals_btn_primary__h2aBr:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Withdrawals_btn_cons__1Y8It {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */

.Withdrawals_MerchantRow__2VUcD {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Withdrawals_MerchantRow__2VUcD:hover {
    background-color: #267788;
    color: #fff;

}

.Withdrawals_MerchantRow__2VUcD:hover a {
    color: #fff;
}

.Withdrawals_MerchantRowExpanded__9a772 {
    background: #267788;
    color: #fff;
}

.Withdrawals_table__2a6Su.Withdrawals_table-condensed__2igwT.Withdrawals_table-detailed__3wQVN>tbody>tr.Withdrawals_shown__1C9YZ>td {
    background: #fdf5df;
}

.Withdrawals_EnableMerchant__175c4 {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Withdrawals_EnableMerchantHeader__3uu_t {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Withdrawals_EnableMerchantBody__1h6I6 {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.Sales_Container__14De_ {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Sales_TabHeader__1HARC {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Sales_Header__27ddj {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Sales_ExportReport__1tBKC {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Sales_ExportIconWrapper__dZf1I {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Sales_EmailIconWrapper__3KVWx {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Sales_Tools__1BQlm {
    display: flex;
    /* margin: 0 2.5rem; */
    /* padding-top: 1.4rem; */
}

.Sales_PageFilter__2lxq8 {
    /* display: flex; */
    flex: 1 1 80%;
    justify-content: flex-start;
    width: auto;
}

.Sales_ToolsWrapper__3w-Yr {
    height: 6rem;
    width: 100%;

}

.Sales_PrintToolsWrapper__2RfKr {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.Sales_AddNewMerchant__2LP6v {
    /* // font-family: Cambay; */
}

.Sales_FilterReportBtn__3E4nR {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 0.9rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
    width: 100%;
}



/* 
text-align: center;
    padding: 0 3rem;

    background: #E8E8E8;
    color: #333;

    transition: all .2s;
    position: relative;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 4rem;
    border-radius: 2px; */

.Sales_AddNewMerchantBtn__35pJM:focus,
.Sales_AddNewMerchantBtn__35pJM:hover,
.Sales_AddNewMerchantBtn__35pJM:active {
    background-color: rgb(51, 137, 255);
}


.Sales_ToolsDataExportIconWrapper__oRBUw {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Sales_ToolsPrintIconWrapper__gFVLV {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Sales_FilterInput__1yy3j {
    margin-right: 2rem;
}

.Sales_FormGroup__2V640 {
    /* margin-bottom: 10px; */
}

.Sales_FormGroupDefault__3sRM8.Sales_has-error__17QJd {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Sales_FormGroupDefault__3sRM8 {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Sales_FormGroupDefault__3sRM8 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Sales_FormGroup__2V640 label:not(.Sales_error__2bGsO) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Sales_FormControl__2C3zE {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Sales_MerchantTableWrapper__1-s6r {
    margin-top: 2.5rem;
    white-space: nowrap;
}

.Sales_TableWrapper__b0TeH {
    /* margin: 0 2.5rem; */
}

.Sales_MerchantTable__qs2dV {
    width: 100%;
    border-collapse: collapse;
}

.Sales_MerchantTable__qs2dV th {
    text-align: left;
}

.Sales_MerchantTable__qs2dV th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Sales_MerchantTable__qs2dV td,
.Sales_MerchantTable__qs2dV th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Sales_RevealMoreHeader__UKatH {
    padding: 0;
    vertical-align: middle;
}





.Sales_Row__37aSh {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Sales_Row__37aSh>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Sales_dataTables_paginate__1qgJn {
    float: right;
}

.Sales_dataTables_paginate__1qgJn {
    /* margin-top: 25px !important; */
}

div.Sales_dataTables_paginate__1qgJn {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Sales_dataTables_paginate__1qgJn ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Sales_dataTables_paginate__1qgJn ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Sales_dataTables_paginate__1qgJn ul>li.Sales_disabled__3Op_K a {
    opacity: .5;
}

.Sales_dataTables_paginate__1qgJn ul>li.Sales_disabled__3Op_K a {
    opacity: .35;
}

.Sales_dataTables_paginate__1qgJn ul>li.Sales_active__3uCTD>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Sales_dataTables_paginate__1qgJn ul>li.Sales_next__3Wl_n>a,
.Sales_dataTables_paginate__1qgJn ul>li.Sales_prev__37kBE>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Sales_pg_arrow_right__3Tb79 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Sales_pg_arrow_left__1_UKG {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Sales_pg_arrow_right__3Tb79:before {
    content: "\E631";
}

.Sales_pg_arrow_left__1_UKG:before {
    content: "\E629";
}

.Sales_btn__38Rsm {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Sales_btn_primary__cEyr7,
.Sales_btn_primary__cEyr7:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Sales_btn_cons__16wNw {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Sales_MerchantName__yhAyv {
    font-weight: 500;
}

.Sales_Icn__3oX2Q {
    cursor: pointer;
    /* fill:#000; */
}

.Sales_Icn__3oX2Q:hover {
    cursor: pointer;

}



.Sales_RevealMore__2Dtm4 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Sales_RevealMore__2Dtm4:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Sales_RevealMore__2Dtm4 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Sales_RevealMoreExpanded__l8s8s {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Sales_Expandabled__1bNtD {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Sales_Expandabled__1bNtD td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Sales_MerchantTable__qs2dV td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Sales_MerchantTable__qs2dV td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Sales_MerchantRow__3ed8v {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Sales_MerchantRow__3ed8v:hover {
    background-color: #267788;
    color: #fff;

}

.Sales_MerchantRow__3ed8v:hover a {
    color: #fff;
}

.Sales_MerchantRowExpanded__3SGc8 {
    background: #267788;
    color: #fff;
}

.Sales_table__30Q5t.Sales_table-condensed__2K2SS.Sales_table-detailed__1uHm_>tbody>tr.Sales_shown__1yqhk>td {
    background: #fdf5df;
}


.Sales_Totals__3iE_C td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Sales_MoneyTotalLabel__2RQOh {
    float: right;

}

.Sales_MoneyTotal__2BG_4 {
    font-weight: 600;
}
.transactions_Container__ie_vZ {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.transactions_BreadcrumbWrapper__3BBTa {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.transactions_Content___eyqt {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.transactions_Alert__3-Oto {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.transactions_Breadcrumb__3VQTP {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.transactions_Header__Jz1do {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.transactions_ByLine__1RO2y {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.transactions_Activity__1EjOK {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.transactions_WelcomeContainer__2hQHx {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.transactions_MerchantProfile__DiXrG {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.transactions_TabHeader__4NgtK {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.transactions_PageHeader__3Ohkj {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.transactions_AddUser__GTpME {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.transactions_AddUserBtn__3_81r {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.transactions_ReportContainer__1raTj {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.transactions_ReportTiles__21tGr {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.transactions_TileContainer__3FGNU {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.transactions_Tile__1IeMk {
    cursor: pointer;
    padding: 4px;
}

.transactions_TileInner__7roUq {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.PresetDateRangePicker_panel {
    padding: 0 22px 11px
}

.PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer
}

.PresetDateRangePicker_button:active {
    outline: 0
}

.PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
}

.SingleDatePickerInput {
    display: inline-block;
    background-color: #fff
}

.SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
}

.SingleDatePickerInput__rtl {
    direction: rtl
}

.SingleDatePickerInput__disabled {
    background-color: #f2f2f2
}

.SingleDatePickerInput__block {
    display: block
}

.SingleDatePickerInput__showClearDate {
    padding-right: 30px
}

.SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.SingleDatePickerInput_clearDate__small {
    padding: 6px
}

.SingleDatePickerInput_clearDate__hide {
    visibility: hidden
}

.SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.SingleDatePickerInput_clearDate_svg__small {
    height: 9px
}

.SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.SingleDatePicker {
    position: relative;
    display: inline-block
}

.SingleDatePicker__block {
    display: block
}

.SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.SingleDatePicker_picker__rtl {
    direction: rtl
}

.SingleDatePicker_picker__directionLeft {
    left: 0
}

.SingleDatePicker_picker__directionRight {
    right: 0
}

.SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
}

.SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_show {
    width: 33px;
    height: 26px;
    position: absolute;
    z-index: 2
}

.DayPickerKeyboardShortcuts_show::before {
    content: "";
    display: block;
    position: absolute
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topRight {
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight::before {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topLeft {
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid #008489
}

.DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: 5px
}

.DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
    text-align: left
}

.DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
}

.DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
}

.DayPickerKeyboardShortcuts_close:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
}

.CalendarDay {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center
}

.CalendarDay:active {
    outline: 0
}

.CalendarDay__defaultCursor {
    cursor: default
}

.CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff
}

.CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px solid #e4e7e7;
    color: inherit
}

.CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
}

.CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848
}

.CalendarDay__outside:hover {
    border: 0
}

.CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
}

.CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
}

.CalendarDay__selected_span {
    background: #66e2da;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #00a699;
    border: 1px double #00a699;
    color: #fff
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd
}

.CalendarDay__hovered_start_first_possible_end {
    background: #eceeee;
    border: 1px double #eceeee
}

.CalendarDay__hovered_start_blocked_min_nights {
    background: #eceeee;
    border: 1px double #e4e7e7
}

.CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    user-select: none
}

.CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
}

.CalendarMonth_verticalSpacing {
    border-collapse: separate
}

.CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial
}

.CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
}

.CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0
}

.CalendarMonthGrid__animating {
    z-index: 1
}

.CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
}

.CalendarMonthGrid__vertical {
    margin: 0 auto
}

.CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll
}

.CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
}

.CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
}

.CalendarMonthGrid_month__hidden {
    visibility: hidden
}

.DayPickerNavigation {
    position: relative;
    z-index: 2
}

.DayPickerNavigation__horizontal {
    height: 0
}

.DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
}

.DayPickerNavigation__verticalScrollableDefault {
    position: relative
}

.DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
}

.DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
}

.DayPickerNavigation_button__default:active {
    background: #f2f2f2
}

.DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:active {
    background: 0 0
}

.DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
}

.DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
}

.DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
}

.DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
}

.DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
}

.DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%
}

.DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
}

.DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848
}

.DayPickerNavigation_svg__disabled {
    fill: #f2f2f2
}

.DayPicker {
    background: #fff;
    position: relative;
    text-align: left
}

.DayPicker__horizontal {
    background: #fff
}

.DayPicker__verticalScrollable {
    height: 100%
}

.DayPicker__hidden {
    visibility: hidden
}

.DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
    border-radius: 3px
}

.DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
}

.DayPicker_portal__vertical {
    position: initial
}

.DayPicker_focusRegion {
    outline: 0
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
}

.DayPicker_weekHeaders {
    position: relative
}

.DayPicker_weekHeaders__horizontal {
    margin-left: 9px
}

.DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
}

.DayPicker_weekHeader__vertical {
    left: 50%
}

.DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
}

.DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
}

.DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
}

.DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px
}

.DayPicker_transitionContainer__horizontal {
    transition: height .2s ease-in-out
}

.DayPicker_transitionContainer__vertical {
    width: 100%
}

.DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll
}


.DateRangePickerInput {
    background-color: #fff;
    display: inline-block
}

.DateRangePickerInput__disabled {
    background: #f2f2f2
}

.DateRangePickerInput__withBorder {
    border-radius: 0;
    border: 1px solid #dbdbdb
}

.DateRangePickerInput__rtl {
    direction: rtl
}

.DateRangePickerInput__block {
    display: block
}

.DateRangePickerInput__showClearDates {
    padding-right: 30px
}

.DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
    padding: 5px;
}

.DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
}

.DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.DateRangePickerInput_clearDates__small {
    padding: 6px
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.DateRangePickerInput_clearDates__hide {
    visibility: hidden
}

.DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.DateRangePickerInput_clearDates_svg__small {
    height: 9px
}

.DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.DateRangePicker {
    position: relative;
    display: inline-block
}

.DateRangePicker__block {
    display: block
}

.DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.DateRangePicker_picker__rtl {
    direction: rtl
}

.DateRangePicker_picker__directionLeft {
    left: 0
}

.DateRangePicker_picker__directionRight {
    right: 0
}

.DateRangePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.DateRangePicker_picker__fullScreenPortal {
    background-color: #fff
}

.DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}
.Transactions_Container__1B2nL {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Transactions_Tools__2Z58y {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.Transactions_PageFilterTools__xqm0D {
    display: flex;
    justify-content: flex-start;
    flex: 1 1 auto;
    width: auto;
    margin-left: 15px;
    align-items: center;
}


.Transactions_PageFilter__1gj-U {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
  
}

.Transactions_FilterReportBtn__3if56 {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.Transactions_ToolsWrapper__1yVhS {
    height: 6rem;
    width: 100%;

}

.Transactions_PrintToolsWrapper__21PYn {
    display: flex;
    flex: 0 1 auto;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}



.Transactions_AddNewMerchantBtn__DUmUy:focus,
.Transactions_AddNewMerchantBtn__DUmUy:hover,
.Transactions_AddNewMerchantBtn__DUmUy:active {
    background-color: rgb(51, 137, 255);
}


.Transactions_ToolsDataExportIconWrapper__3J4hv {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Transactions_ToolsPrintIconWrapper__qrvuD {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Transactions_FilterInput__3Pj0Z {
    margin-right: 2rem;
}

.Transactions_FormGroup__2sr6P {
    /* margin-bottom: 10px; */
}

.Transactions_FormGroupDefault__1me1D.Transactions_has-error__nhBQw {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Transactions_FormGroupDefault__1me1D {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Transactions_FormGroupDefault__1me1D label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Transactions_FormGroup__2sr6P label:not(.Transactions_error__3Qbkz) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Transactions_FormControl__2OOqs {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Transactions_MerchantTableWrapper__8wwUA {
    margin-top: 3rem;
    white-space: nowrap;
}

.Transactions_TableWrapper__3oV7R {
    display: flex;
}

.Transactions_MerchantTable__35VOe {
    flex: 1 1 70%;
    border-collapse: collapse;
}

.Transactions_MerchantTable__35VOe th {
    text-align: left;
}

.Transactions_MerchantTable__35VOe th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Transactions_MerchantTable__35VOe td,
.Transactions_MerchantTable__35VOe th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Transactions_SummaryTable__1r7zc {
    width: 100%;
    border-collapse: collapse;
}

.Transactions_SummaryTable__1r7zc th {
    text-align: left;
}

.Transactions_SummaryTable__1r7zc th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Transactions_SummaryTable__1r7zc td,
.Transactions_SummaryTable__1r7zc th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Transactions_SummaryTable__1r7zc td:first-child {
    border-left: 1px solid #e8e8e8;

}

.Transactions_SummaryTable__1r7zc td:last-child {

    border-right: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Transactions_RevealMoreHeader__3q9Cd {
    padding: 0;
    vertical-align: middle;
}





.Transactions_Row__36JOD {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Transactions_Row__36JOD>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Transactions_dataTables_paginate__2f0Zp {
    float: right;
}

.Transactions_dataTables_paginate__2f0Zp {
    /* margin-top: 25px !important; */
}

div.Transactions_dataTables_paginate__2f0Zp {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Transactions_dataTables_paginate__2f0Zp ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Transactions_dataTables_paginate__2f0Zp ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Transactions_dataTables_paginate__2f0Zp ul>li.Transactions_disabled__V1X9I a {
    opacity: .5;
}

.Transactions_dataTables_paginate__2f0Zp ul>li.Transactions_disabled__V1X9I a {
    opacity: .35;
}

.Transactions_dataTables_paginate__2f0Zp ul>li.Transactions_active__2Vx90>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Transactions_dataTables_paginate__2f0Zp ul>li.Transactions_next__3sCno>a,
.Transactions_dataTables_paginate__2f0Zp ul>li.Transactions_prev__1yt4j>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_right__3AL80 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_left__2PzSJ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_right__3AL80:before {
    content: "\E631";
}

.Transactions_pg_arrow_left__2PzSJ:before {
    content: "\E629";
}

.Transactions_btn__1jKxz {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Transactions_btn_primary__3ybFI,
.Transactions_btn_primary__3ybFI:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Transactions_btn_cons__3EaGZ {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Transactions_MerchantName__1yZnm {
    font-weight: 500;
}

.Transactions_Icn__3VJAm {
    cursor: pointer;
    /* fill:#000; */
}

.Transactions_Icn__3VJAm:hover {
    cursor: pointer;

}



.Transactions_RevealMore__ILiho {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Transactions_RevealMore__ILiho:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Transactions_RevealMore__ILiho div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Transactions_RevealMoreExpanded__3cbuM {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Transactions_Expandabled__114gQ {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Transactions_Expandabled__114gQ td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Transactions_MerchantTable__35VOe td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Transactions_MerchantTable__35VOe td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Transactions_MerchantRow__30Iwg {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Transactions_MerchantRow__30Iwg:hover {
    background-color: #267788;
    color: #fff;

}

.Transactions_SelectedTransaction__1yDMD {
    background-color: #267788;
    color: #fff;

}

.Transactions_MerchantRow__30Iwg:hover a {
    color: #fff;
}

.Transactions_MerchantRowExpanded__1Mj-V {
    background: #267788;
    color: #fff;
}

.Transactions_table__3DBi_.Transactions_table-condensed__1fasz.Transactions_table-detailed__lqBh8>tbody>tr.Transactions_shown__IVKLQ>td {
    background: #fdf5df;
}


.Transactions_Totals__1L6wd td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Transactions_MoneyTotalLabel__7YvD0 {
    float: right;

}

.Transactions_TransactionDetail__2_zxM {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Transactions_TransactionDetailHeader__1oJGN {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Transactions_TransactionDetailBody__3C4bk {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}

.Transactions_form-group-default__2kI_g {

    
    width: auto;

    
}

form > div {
    width: auto;
}
.selectedTransaction_PageHeader__1Y41Y {
    flex: 1 1 100%;
    justify-content: flex-start;
    width: auto;
}
/* .Odd {
}

.Even {
} */

.details_MemberName__48rR3 {
    font-weight: 500;
}

.details_Icn__dQ3xR {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__dQ3xR:hover {
    cursor: pointer;

}

.details_RevealMore__3Vru3 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__3Vru3:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.details_RevealMore__3Vru3 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__Xhyc0 {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .details_Expandabled__pKUXC {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__pKUXC td {
    padding: 2rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .details_MemberRow__EBgP9 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MemberRow__EBgP9:hover {
    background-color: #267788;
    color:#fff;

    }

    .details_MemberRow__EBgP9:hover a {
        color:#fff;
        }

    .details_MemberRowExpanded__2-E7z {
        background: #267788;
        color:#fff;
    }

.details_table__gqnl2.details_table-condensed__2RW9S.details_table-detailed__yhHLL > tbody > tr.details_shown__2MKNe > td {
    background: #fdf5df;
}

.details_Container__X_19k {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.details_BreadcrumbWrapper__3WSZl {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.details_Content__1kGy2 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.details_CardBody__1F48s {
    margin-top: 1rem;
}

.details_Alert__1gQyV {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.details_Breadcrumb__3zBi0 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.details_Header__3TDWh {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.details_ByLine__MktEP {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_Activity__fH_R0 {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.details_WelcomeContainer__2R1tt {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.details_MerchantProfile__iADHw {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.details_TabHeader__3TZrY {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.details_PageHeader__3fNbD {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.details_Header__3TDWh {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.details_ExportReport__2zYq5 {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.details_ExportIconWrapper__2K1Ck {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_EmailIconWrapper__3RQZf {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_AddUser__17M8O {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.details_AddUserBtn__3xIch {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_Tools__2zVFJ {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.details_PageFilter__PzNvP {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}

.details_PrintToolsWrapper__2pi_n {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.details_FilterReportBtn__1A8b8 {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.details_AddNewMerchantBtn__2pzPp:focus,
.details_AddNewMerchantBtn__2pzPp:hover,
.details_AddNewMerchantBtn__2pzPp:active {
    background-color: rgb(51, 137, 255);
}

.details_ToolsDataExportIconWrapper__w4OcO {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.details_ToolsPrintIconWrapper__1cuUI {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.details_FilterInput__3xZoX {
    margin-right: 2rem;
}

.details_FormGroupDefault__1eps6.details_has-error__1W51g {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__1eps6 {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.details_FormGroupDefault__1eps6 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.details_FormGroup__1AFHN label:not(.details_error__CZswD) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.details_FormControl__2_DiH {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.details_MerchantTableWrapper__3FQyj {
    margin-top: 3rem;
    white-space: nowrap;
}

.details_MerchantTable__-D2yT {
    width: 100%;
    border-collapse: collapse;
}

.details_MerchantTable__-D2yT th {
    text-align: left;
}

.details_MerchantTable__-D2yT th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.details_MerchantTable__-D2yT td,
.details_MerchantTable__-D2yT th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__1N4SI {
    padding: 0;
    vertical-align: middle;
}

.details_Row__39Roc {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.details_Row__39Roc>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.details_dataTables_paginate__1pG8N {
    float: right;
}

div.details_dataTables_paginate__1pG8N {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.details_dataTables_paginate__1pG8N ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

.details_dataTables_paginate__1pG8N ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.details_dataTables_paginate__1pG8N ul>li.details_disabled__2euRJ a {
    opacity: .5;
}

.details_dataTables_paginate__1pG8N ul>li.details_disabled__2euRJ a {
    opacity: .35;
}

.details_dataTables_paginate__1pG8N ul>li.details_active__2I5yG>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.details_dataTables_paginate__1pG8N ul>li.details_next__1uE5d>a,
.details_dataTables_paginate__1pG8N ul>li.details_prev__3-wAw>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__1KqDf {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__3pcnq {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__1KqDf:before {
    content: "\E631";
}

.details_pg_arrow_left__3pcnq:before {
    content: "\E629";
}

.details_btn__lLuJS {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.details_btn_primary__aG8Xa,
.details_btn_primary__aG8Xa:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.details_btn_cons__3tT6- {
    min-width: 120px;
}

.details_MerchantName__1DrkB {
    font-weight: 500;
}

.details_Icn__3xQPD {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__3xQPD:hover {
    cursor: pointer;

}



.details_RevealMore__1ZFXq {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__1ZFXq:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.details_RevealMore__1ZFXq div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__A3-FD {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.details_Expandabled__3f2_G {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__3f2_G td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__-D2yT td:last-child {

    border-right: 1px solid #e8e8e8;
}


.details_MerchantTable__-D2yT td:first-child {

    border-left: 1px solid #e8e8e8;
}


.details_MerchantRow__2jSj_ {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MerchantRow__2jSj_:hover {
    background-color: #267788;
    color: #fff;

}

.details_MerchantRow__2jSj_:hover a {
    color: #fff;
}

.details_MerchantRowExpanded__1xUeo {
    background: #267788;
    color: #fff;
}

.details_table__31PTK.details_table-condensed__35siA.details_table-detailed__2vOPH>tbody>tr.details_shown__2QUCR>td {
    background: #fdf5df;
}


.details_Totals__25IyQ td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.details_MoneyTotalLabel__hn80D {
    float: right;

}

.details_FilterWrapper__AuhPo {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.details_FilterPeriods__1EQqH {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.details_FilterPeriods__1EQqH li {
    font-weight: 600;

    padding-left: 2rem;
    line-height: 38px;
}

.details_FinancialsIntro__p0195 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_ExpandableView__1yFQM {
    background-color: transparent;
    /* font-family: Cambay; */
}

.details_Outlets__1bbrr {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    /* margin-left: 0.6rem; */
}

.details_Outlets__1bbrr li {
    display: inline-block;
        list-style: none;
}

.details_Outlet__3ILz7 {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;}

.details_Outlet__3ILz7:hover {
color: #FFF;
    background: #5BA6E6;

}

.details_OutletName__194so {
    font-weight: 600;
}

.details_OutletLocation__1W-io {
    font-weight: 400;

}

.details_ExpandableMember__nMo69 {
    float: left;
    text-decoration: none;
}

.details_ExpandableMemberName__9YZnz {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.details_OutletListArea__2YTwc {
    width: 100%;
    margin-top: 1rem;
    background: transparent;
}



.details_MemberContractDetailArea__2f_Kx {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem 0px;
    background: rgb(255, 255, 255);
}

.details_MemberContractDetailArea__2f_Kx header {
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.details_ExpandableMemberWrapper__1CnLO {
    background: transparent;
}

.details_clearfix__1ZBsq::before,
.details_clearfix__1ZBsq::after {
    display:table;
    content: " ";
}

.details_clearfix__1ZBsq::after {
clear:both;
}


.details_FuneralSchemeWrapper__16ZH7 {
    margin: 0 2.5rem;
}

.details_AddNewMember__3_44h {
float:right;
margin-right: 8.8rem;
}

.details_AddNewMemberBtn__2GhQw {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_FuneralSchemeTable__1J2Dg  {
    width: 100%;
    border-collapse: collapse;
}

.details_FuneralSchemeTable__1J2Dg th {
    text-align: left;
}

.details_FuneralSchemeTable__1J2Dg th {
    color: #666;
    vertical-align: top;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    vertical-align: middle;
}

.details_FuneralSchemeTable__1J2Dg td,
.details_FuneralSchemeTable__1J2Dg th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_FuneralSchemeTable__1J2Dg .details_Totals__25IyQ td {   
     border-bottom: none;
     font-weight: 600;
}

.details_FuneralSchemeTable__1J2Dg .details_MoneyTotalLabel__hn80D {
    float: right;

}
.details_FuneralSchemeTable__1J2Dg .details_MoneyTotal__YZ4Yu {

}
.transactions_Container__3FDoS {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.transactions_BreadcrumbWrapper__YJhl0 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.transactions_Content__13yIq {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.transactions_Alert___uRuZ {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.transactions_Breadcrumb__2XSu3 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.transactions_Header__1irbI {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.transactions_ByLine__11cqn {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.transactions_Activity__eu94b {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.transactions_WelcomeContainer__8gjZA {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.transactions_MerchantProfile__2i5jz {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.transactions_TabHeader__SclI4 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.transactions_PageHeader__3pzzh {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.transactions_AddUser__2VYoU {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.transactions_AddUserBtn__9xl5m {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.transactions_ReportContainer__3DolM {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.transactions_ReportTiles__jdMsn {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.transactions_TileContainer__NGi6R {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.transactions_Tile__1UtBY {
    cursor: pointer;
    padding: 4px;
}

.transactions_TileInner__38We2 {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.PresetDateRangePicker_panel {
    padding: 0 22px 11px
}

.PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer
}

.PresetDateRangePicker_button:active {
    outline: 0
}

.PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
}

.SingleDatePickerInput {
    display: inline-block;
    background-color: #fff
}

.SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
}

.SingleDatePickerInput__rtl {
    direction: rtl
}

.SingleDatePickerInput__disabled {
    background-color: #f2f2f2
}

.SingleDatePickerInput__block {
    display: block
}

.SingleDatePickerInput__showClearDate {
    padding-right: 30px
}

.SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.SingleDatePickerInput_clearDate__small {
    padding: 6px
}

.SingleDatePickerInput_clearDate__hide {
    visibility: hidden
}

.SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.SingleDatePickerInput_clearDate_svg__small {
    height: 9px
}

.SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.SingleDatePicker {
    position: relative;
    display: inline-block
}

.SingleDatePicker__block {
    display: block
}

.SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.SingleDatePicker_picker__rtl {
    direction: rtl
}

.SingleDatePicker_picker__directionLeft {
    left: 0
}

.SingleDatePicker_picker__directionRight {
    right: 0
}

.SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
}

.SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_show {
    width: 33px;
    height: 26px;
    position: absolute;
    z-index: 2
}

.DayPickerKeyboardShortcuts_show::before {
    content: "";
    display: block;
    position: absolute
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topRight {
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight::before {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topLeft {
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid #008489
}

.DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: 5px
}

.DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
    text-align: left
}

.DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
}

.DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
}

.DayPickerKeyboardShortcuts_close:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
}

.CalendarDay {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center
}

.CalendarDay:active {
    outline: 0
}

.CalendarDay__defaultCursor {
    cursor: default
}

.CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff
}

.CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px solid #e4e7e7;
    color: inherit
}

.CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
}

.CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848
}

.CalendarDay__outside:hover {
    border: 0
}

.CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
}

.CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
}

.CalendarDay__selected_span {
    background: #66e2da;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #00a699;
    border: 1px double #00a699;
    color: #fff
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd
}

.CalendarDay__hovered_start_first_possible_end {
    background: #eceeee;
    border: 1px double #eceeee
}

.CalendarDay__hovered_start_blocked_min_nights {
    background: #eceeee;
    border: 1px double #e4e7e7
}

.CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    user-select: none
}

.CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
}

.CalendarMonth_verticalSpacing {
    border-collapse: separate
}

.CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial
}

.CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
}

.CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0
}

.CalendarMonthGrid__animating {
    z-index: 1
}

.CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
}

.CalendarMonthGrid__vertical {
    margin: 0 auto
}

.CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll
}

.CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
}

.CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
}

.CalendarMonthGrid_month__hidden {
    visibility: hidden
}

.DayPickerNavigation {
    position: relative;
    z-index: 2
}

.DayPickerNavigation__horizontal {
    height: 0
}

.DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
}

.DayPickerNavigation__verticalScrollableDefault {
    position: relative
}

.DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
}

.DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
}

.DayPickerNavigation_button__default:active {
    background: #f2f2f2
}

.DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:active {
    background: 0 0
}

.DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
}

.DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
}

.DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
}

.DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
}

.DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
}

.DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%
}

.DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
}

.DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848
}

.DayPickerNavigation_svg__disabled {
    fill: #f2f2f2
}

.DayPicker {
    background: #fff;
    position: relative;
    text-align: left
}

.DayPicker__horizontal {
    background: #fff
}

.DayPicker__verticalScrollable {
    height: 100%
}

.DayPicker__hidden {
    visibility: hidden
}

.DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
    border-radius: 3px
}

.DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
}

.DayPicker_portal__vertical {
    position: initial
}

.DayPicker_focusRegion {
    outline: 0
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
}

.DayPicker_weekHeaders {
    position: relative
}

.DayPicker_weekHeaders__horizontal {
    margin-left: 9px
}

.DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
}

.DayPicker_weekHeader__vertical {
    left: 50%
}

.DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
}

.DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
}

.DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
}

.DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px
}

.DayPicker_transitionContainer__horizontal {
    transition: height .2s ease-in-out
}

.DayPicker_transitionContainer__vertical {
    width: 100%
}

.DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll
}


.DateRangePickerInput {
    background-color: #fff;
    display: inline-block
}

.DateRangePickerInput__disabled {
    background: #f2f2f2
}

.DateRangePickerInput__withBorder {
    border-radius: 0;
    border: 1px solid #dbdbdb
}

.DateRangePickerInput__rtl {
    direction: rtl
}

.DateRangePickerInput__block {
    display: block
}

.DateRangePickerInput__showClearDates {
    padding-right: 30px
}

.DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
    padding: 5px;
}

.DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
}

.DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.DateRangePickerInput_clearDates__small {
    padding: 6px
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.DateRangePickerInput_clearDates__hide {
    visibility: hidden
}

.DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.DateRangePickerInput_clearDates_svg__small {
    height: 9px
}

.DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.DateRangePicker {
    position: relative;
    display: inline-block
}

.DateRangePicker__block {
    display: block
}

.DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.DateRangePicker_picker__rtl {
    direction: rtl
}

.DateRangePicker_picker__directionLeft {
    left: 0
}

.DateRangePicker_picker__directionRight {
    right: 0
}

.DateRangePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.DateRangePicker_picker__fullScreenPortal {
    background-color: #fff
}

.DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}
.Transactions_Container__lcvxt {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Transactions_Tools__U6yYI {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.Transactions_PageFilterTools__2ngXT {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    margin-left: 15px;
    align-items: center;
}


.Transactions_PageFilter__3Mwng {
    display: flex;
    flex: 1 1 10%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
  
}

.Transactions_FilterReportBtn__Z8K-C {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.Transactions_ToolsWrapper__LeHWY {
    height: 6rem;
    width: 100%;

}

.Transactions_PrintToolsWrapper__1vl9B {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}



.Transactions_AddNewMerchantBtn__2tIlD:focus,
.Transactions_AddNewMerchantBtn__2tIlD:hover,
.Transactions_AddNewMerchantBtn__2tIlD:active {
    background-color: rgb(51, 137, 255);
}


.Transactions_ToolsDataExportIconWrapper__OVmgj {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Transactions_ToolsPrintIconWrapper__21_3V {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Transactions_FilterInput__ZF7QM {
    margin-right: 2rem;
}

.Transactions_FormGroup__2BmQg {
    /* margin-bottom: 10px; */
}

.Transactions_FormGroupDefault__73bDc.Transactions_has-error__1_Vk1 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Transactions_FormGroupDefault__73bDc {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Transactions_FormGroupDefault__73bDc label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Transactions_FormGroup__2BmQg label:not(.Transactions_error__mSUzf) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Transactions_FormControl__2i1kr {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Transactions_MerchantTableWrapper__KcE2K {
    margin-top: 3rem;
    white-space: nowrap;
}

.Transactions_TableWrapper__2Iiun {
    display: flex;
}

.Transactions_MerchantTable__2fI-t {
    flex: 1 1 70%;
    border-collapse: collapse;
}

.Transactions_MerchantTable__2fI-t th {
    text-align: left;
}

.Transactions_MerchantTable__2fI-t th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Transactions_MerchantTable__2fI-t td,
.Transactions_MerchantTable__2fI-t th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Transactions_SummaryTable__2JflJ {
    width: 100%;
    border-collapse: collapse;
}

.Transactions_SummaryTable__2JflJ th {
    text-align: left;
}

.Transactions_SummaryTable__2JflJ th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Transactions_SummaryTable__2JflJ td,
.Transactions_SummaryTable__2JflJ th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Transactions_SummaryTable__2JflJ td:first-child {
    border-left: 1px solid #e8e8e8;

}

.Transactions_SummaryTable__2JflJ td:last-child {

    border-right: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Transactions_RevealMoreHeader__1NBNm {
    padding: 0;
    vertical-align: middle;
}





.Transactions_Row__1obPq {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Transactions_Row__1obPq>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Transactions_dataTables_paginate__3JMDq {
    float: right;
}

.Transactions_dataTables_paginate__3JMDq {
    /* margin-top: 25px !important; */
}

div.Transactions_dataTables_paginate__3JMDq {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Transactions_dataTables_paginate__3JMDq ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Transactions_dataTables_paginate__3JMDq ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Transactions_dataTables_paginate__3JMDq ul>li.Transactions_disabled__1MQu0 a {
    opacity: .5;
}

.Transactions_dataTables_paginate__3JMDq ul>li.Transactions_disabled__1MQu0 a {
    opacity: .35;
}

.Transactions_dataTables_paginate__3JMDq ul>li.Transactions_active__2WSn0>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Transactions_dataTables_paginate__3JMDq ul>li.Transactions_next__1o3dW>a,
.Transactions_dataTables_paginate__3JMDq ul>li.Transactions_prev__2HFVO>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_right__31Cpd {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_left__1WGqM {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_right__31Cpd:before {
    content: "\E631";
}

.Transactions_pg_arrow_left__1WGqM:before {
    content: "\E629";
}

.Transactions_btn__1g-Al {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Transactions_btn_primary__W1Uly,
.Transactions_btn_primary__W1Uly:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Transactions_btn_cons__2QFIy {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Transactions_MerchantName__2eNcE {
    font-weight: 500;
}

.Transactions_Icn__3_42Z {
    cursor: pointer;
    /* fill:#000; */
}

.Transactions_Icn__3_42Z:hover {
    cursor: pointer;

}



.Transactions_RevealMore__ujtY- {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Transactions_RevealMore__ujtY-:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Transactions_RevealMore__ujtY- div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Transactions_RevealMoreExpanded__2-phf {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Transactions_Expandabled__3gq4O {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Transactions_Expandabled__3gq4O td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Transactions_MerchantTable__2fI-t td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Transactions_MerchantTable__2fI-t td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Transactions_MerchantRow__jeo3N {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Transactions_MerchantRow__jeo3N:hover {
    background-color: #267788;
    color: #fff;

}

.Transactions_SelectedTransaction__2GH0O {
    background-color: #267788;
    color: #fff;

}

.Transactions_MerchantRow__jeo3N:hover a {
    color: #fff;
}

.Transactions_MerchantRowExpanded__TT9iG {
    background: #267788;
    color: #fff;
}

.Transactions_table__291ah.Transactions_table-condensed__2kj7E.Transactions_table-detailed__1WGoW>tbody>tr.Transactions_shown__3uiNE>td {
    background: #fdf5df;
}


.Transactions_Totals__3S8mQ td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Transactions_MoneyTotalLabel__1cQyi {
    float: right;

}

.Transactions_TransactionDetail__mB9Se {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Transactions_TransactionDetailHeader__3XqKp {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Transactions_TransactionDetailBody__2YTE8 {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.selectedTransaction_PageHeader__1I_U2 {
    flex: 1 1 100%;
    justify-content: flex-start;
    width: auto;
}
/* .Odd {
}

.Even {
} */

.details_MemberName__2vyTv {
    font-weight: 500;
}

.details_Icn__3RLNE {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__3RLNE:hover {
    cursor: pointer;

}

.details_RevealMore__2T3yR {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__2T3yR:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.details_RevealMore__2T3yR div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__3Qg_G {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .details_Expandabled__2frmy {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__2frmy td {
    padding: 2rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .details_MemberRow__3qJvf {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MemberRow__3qJvf:hover {
    background-color: #267788;
    color:#fff;

    }

    .details_MemberRow__3qJvf:hover a {
        color:#fff;
        }

    .details_MemberRowExpanded__2-wDX {
        background: #267788;
        color:#fff;
    }

.details_table__2M_zy.details_table-condensed__1ag3V.details_table-detailed__1T_9L > tbody > tr.details_shown__3X5cJ > td {
    background: #fdf5df;
}

.details_Container__3rc8a {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.details_BreadcrumbWrapper__3RJAP {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.details_Content__3Xgcn {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.details_CardBody__39E8h {
    margin-top: 1rem;
}

.details_Alert__3X5Xc {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.details_Breadcrumb__3iKFZ {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.details_Header__160hq {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.details_ByLine__3q3rj {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_Activity__20oHO {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.details_WelcomeContainer__2RkIL {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.details_MerchantProfile__1NfpF {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.details_TabHeader__OQTq9 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.details_PageHeader__1UtYl {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.details_Header__160hq {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.details_ExportReport__1LcYD {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.details_ExportIconWrapper__1Qsfo {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_EmailIconWrapper__3mPVu {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_AddUser__1DVEz {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.details_AddUserBtn__wvOE6 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_Tools__1V-hl {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.details_PageFilter__2x2vt {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}

.details_PrintToolsWrapper__3MeMj {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.details_FilterReportBtn__3ZCXX {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.details_AddNewMerchantBtn__2MRZ7:focus,
.details_AddNewMerchantBtn__2MRZ7:hover,
.details_AddNewMerchantBtn__2MRZ7:active {
    background-color: rgb(51, 137, 255);
}

.details_ToolsDataExportIconWrapper__1XG_n {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.details_ToolsPrintIconWrapper__3arL0 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.details_FilterInput__2GANV {
    margin-right: 2rem;
}

.details_FormGroupDefault__26pP2.details_has-error__13-jM {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__26pP2 {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.details_FormGroupDefault__26pP2 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.details_FormGroup__2n7u6 label:not(.details_error__2JkE-) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.details_FormControl__2zDgt {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.details_MerchantTableWrapper__1PICP {
    margin-top: 3rem;
    white-space: nowrap;
}

.details_MerchantTable__3GTuO {
    width: 100%;
    border-collapse: collapse;
}

.details_MerchantTable__3GTuO th {
    text-align: left;
}

.details_MerchantTable__3GTuO th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.details_MerchantTable__3GTuO td,
.details_MerchantTable__3GTuO th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__mA-UG {
    padding: 0;
    vertical-align: middle;
}

.details_Row__33z6n {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.details_Row__33z6n>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.details_dataTables_paginate__inD5a {
    float: right;
}

div.details_dataTables_paginate__inD5a {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.details_dataTables_paginate__inD5a ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

.details_dataTables_paginate__inD5a ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.details_dataTables_paginate__inD5a ul>li.details_disabled__OP7jq a {
    opacity: .5;
}

.details_dataTables_paginate__inD5a ul>li.details_disabled__OP7jq a {
    opacity: .35;
}

.details_dataTables_paginate__inD5a ul>li.details_active__2Gigb>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.details_dataTables_paginate__inD5a ul>li.details_next__2M6Uk>a,
.details_dataTables_paginate__inD5a ul>li.details_prev__j5bBH>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__3seRi {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__1ylA9 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__3seRi:before {
    content: "\E631";
}

.details_pg_arrow_left__1ylA9:before {
    content: "\E629";
}

.details_btn__JHhSo {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.details_btn_primary__1jjwU,
.details_btn_primary__1jjwU:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.details_btn_cons__TMFzA {
    min-width: 120px;
}

.details_MerchantName__2uHE8 {
    font-weight: 500;
}

.details_Icn__1owhp {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__1owhp:hover {
    cursor: pointer;

}



.details_RevealMore__FqTrI {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__FqTrI:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.details_RevealMore__FqTrI div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__38AN_ {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.details_Expandabled__3AY1Z {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__3AY1Z td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__3GTuO td:last-child {

    border-right: 1px solid #e8e8e8;
}


.details_MerchantTable__3GTuO td:first-child {

    border-left: 1px solid #e8e8e8;
}


.details_MerchantRow__15_q2 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MerchantRow__15_q2:hover {
    background-color: #267788;
    color: #fff;

}

.details_MerchantRow__15_q2:hover a {
    color: #fff;
}

.details_MerchantRowExpanded__1lDLD {
    background: #267788;
    color: #fff;
}

.details_table__3ps2q.details_table-condensed__1IlBc.details_table-detailed__2xi5K>tbody>tr.details_shown__3JMta>td {
    background: #fdf5df;
}


.details_Totals__1KTsS td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.details_MoneyTotalLabel__1zMUP {
    float: right;

}

.details_FilterWrapper__1C4Ga {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.details_FilterPeriods__1756n {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.details_FilterPeriods__1756n li {
    font-weight: 600;

    padding-left: 2rem;
    line-height: 38px;
}

.details_FinancialsIntro__2nEuF {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_ExpandableView__2irTY {
    background-color: transparent;
    /* font-family: Cambay; */
}

.details_Outlets__2O2d3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    /* margin-left: 0.6rem; */
}

.details_Outlets__2O2d3 li {
    display: inline-block;
        list-style: none;
}

.details_Outlet___cyQx {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;}

.details_Outlet___cyQx:hover {
color: #FFF;
    background: #5BA6E6;

}

.details_OutletName__b3FbG {
    font-weight: 600;
}

.details_OutletLocation__3ABjk {
    font-weight: 400;

}

.details_ExpandableMember__t8VxO {
    float: left;
    text-decoration: none;
}

.details_ExpandableMemberName__adA65 {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.details_OutletListArea__1gzBT {
    width: 100%;
    margin-top: 1rem;
    background: transparent;
}



.details_MemberContractDetailArea__2FXZu {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem 0px;
    background: rgb(255, 255, 255);
}

.details_MemberContractDetailArea__2FXZu header {
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.details_ExpandableMemberWrapper__8TbYY {
    background: transparent;
}

.details_clearfix__2n2nh::before,
.details_clearfix__2n2nh::after {
    display:table;
    content: " ";
}

.details_clearfix__2n2nh::after {
clear:both;
}


.details_FuneralSchemeWrapper__13A17 {
    margin: 0 2.5rem;
}

.details_AddNewMember__3XHDw {
float:right;
margin-right: 8.8rem;
}

.details_AddNewMemberBtn__2Ra63 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_FuneralSchemeTable__A1-Kc  {
    width: 100%;
    border-collapse: collapse;
}

.details_FuneralSchemeTable__A1-Kc th {
    text-align: left;
}

.details_FuneralSchemeTable__A1-Kc th {
    color: #666;
    vertical-align: top;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    vertical-align: middle;
}

.details_FuneralSchemeTable__A1-Kc td,
.details_FuneralSchemeTable__A1-Kc th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_FuneralSchemeTable__A1-Kc .details_Totals__1KTsS td {   
     border-bottom: none;
     font-weight: 600;
}

.details_FuneralSchemeTable__A1-Kc .details_MoneyTotalLabel__1zMUP {
    float: right;

}
.details_FuneralSchemeTable__A1-Kc .details_MoneyTotal__3TMnN {

}
.transactionsbydaterange_Container__1GsPb {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.transactionsbydaterange_BreadcrumbWrapper__6Zi1n {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.transactionsbydaterange_Content__3z-2w {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.transactionsbydaterange_Alert__1s7wK {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.transactionsbydaterange_Breadcrumb__2XF1a {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.transactionsbydaterange_Header__lISaq {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.transactionsbydaterange_ByLine__w9BNf {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.transactionsbydaterange_Activity__QVchf {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.transactionsbydaterange_WelcomeContainer__11Xpn {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.transactionsbydaterange_MerchantProfile__jgTM- {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.transactionsbydaterange_TabHeader__2M-n_ {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.transactionsbydaterange_PageHeader__kpv0C {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.transactionsbydaterange_AddUser__3iQDq {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.transactionsbydaterange_AddUserBtn__3SV8D {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.transactionsbydaterange_ReportContainer__3cmj6 {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.transactionsbydaterange_ReportTiles__1_UkR {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.transactionsbydaterange_TileContainer__QHQNk {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.transactionsbydaterange_Tile__2aWht {
    cursor: pointer;
    padding: 4px;
}

.transactionsbydaterange_TileInner__1xV6m {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.PresetDateRangePicker_panel {
    padding: 0 22px 11px
}

.PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer
}

.PresetDateRangePicker_button:active {
    outline: 0
}

.PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
}

.SingleDatePickerInput {
    display: inline-block;
    background-color: #fff
}

.SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
}

.SingleDatePickerInput__rtl {
    direction: rtl
}

.SingleDatePickerInput__disabled {
    background-color: #f2f2f2
}

.SingleDatePickerInput__block {
    display: block
}

.SingleDatePickerInput__showClearDate {
    padding-right: 30px
}

.SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.SingleDatePickerInput_clearDate__small {
    padding: 6px
}

.SingleDatePickerInput_clearDate__hide {
    visibility: hidden
}

.SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.SingleDatePickerInput_clearDate_svg__small {
    height: 9px
}

.SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.SingleDatePicker {
    position: relative;
    display: inline-block
}

.SingleDatePicker__block {
    display: block
}

.SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.SingleDatePicker_picker__rtl {
    direction: rtl
}

.SingleDatePicker_picker__directionLeft {
    left: 0
}

.SingleDatePicker_picker__directionRight {
    right: 0
}

.SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
}

.SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_show {
    width: 33px;
    height: 26px;
    position: absolute;
    z-index: 2
}

.DayPickerKeyboardShortcuts_show::before {
    content: "";
    display: block;
    position: absolute
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topRight {
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight::before {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topLeft {
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid #008489
}

.DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: 5px
}

.DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
    text-align: left
}

.DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
}

.DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
}

.DayPickerKeyboardShortcuts_close:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
}

.CalendarDay {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center
}

.CalendarDay:active {
    outline: 0
}

.CalendarDay__defaultCursor {
    cursor: default
}

.CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff
}

.CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px solid #e4e7e7;
    color: inherit
}

.CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
}

.CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848
}

.CalendarDay__outside:hover {
    border: 0
}

.CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
}

.CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
}

.CalendarDay__selected_span {
    background: #66e2da;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #00a699;
    border: 1px double #00a699;
    color: #fff
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd
}

.CalendarDay__hovered_start_first_possible_end {
    background: #eceeee;
    border: 1px double #eceeee
}

.CalendarDay__hovered_start_blocked_min_nights {
    background: #eceeee;
    border: 1px double #e4e7e7
}

.CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    user-select: none
}

.CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
}

.CalendarMonth_verticalSpacing {
    border-collapse: separate
}

.CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial
}

.CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
}

.CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0
}

.CalendarMonthGrid__animating {
    z-index: 1
}

.CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
}

.CalendarMonthGrid__vertical {
    margin: 0 auto
}

.CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll
}

.CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
}

.CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
}

.CalendarMonthGrid_month__hidden {
    visibility: hidden
}

.DayPickerNavigation {
    position: relative;
    z-index: 2
}

.DayPickerNavigation__horizontal {
    height: 0
}

.DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
}

.DayPickerNavigation__verticalScrollableDefault {
    position: relative
}

.DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
}

.DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
}

.DayPickerNavigation_button__default:active {
    background: #f2f2f2
}

.DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:active {
    background: 0 0
}

.DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
}

.DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
}

.DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
}

.DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
}

.DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
}

.DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%
}

.DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
}

.DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848
}

.DayPickerNavigation_svg__disabled {
    fill: #f2f2f2
}

.DayPicker {
    background: #fff;
    position: relative;
    text-align: left
}

.DayPicker__horizontal {
    background: #fff
}

.DayPicker__verticalScrollable {
    height: 100%
}

.DayPicker__hidden {
    visibility: hidden
}

.DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
    border-radius: 3px
}

.DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
}

.DayPicker_portal__vertical {
    position: initial
}

.DayPicker_focusRegion {
    outline: 0
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
}

.DayPicker_weekHeaders {
    position: relative
}

.DayPicker_weekHeaders__horizontal {
    margin-left: 9px
}

.DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
}

.DayPicker_weekHeader__vertical {
    left: 50%
}

.DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
}

.DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
}

.DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
}

.DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px
}

.DayPicker_transitionContainer__horizontal {
    transition: height .2s ease-in-out
}

.DayPicker_transitionContainer__vertical {
    width: 100%
}

.DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll
}


.DateRangePickerInput {
    background-color: #fff;
    display: inline-block
}

.DateRangePickerInput__disabled {
    background: #f2f2f2
}

.DateRangePickerInput__withBorder {
    border-radius: 0;
    border: 1px solid #dbdbdb
}

.DateRangePickerInput__rtl {
    direction: rtl
}

.DateRangePickerInput__block {
    display: block
}

.DateRangePickerInput__showClearDates {
    padding-right: 30px
}

.DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
    padding: 5px;
}

.DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
}

.DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.DateRangePickerInput_clearDates__small {
    padding: 6px
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.DateRangePickerInput_clearDates__hide {
    visibility: hidden
}

.DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.DateRangePickerInput_clearDates_svg__small {
    height: 9px
}

.DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.DateRangePicker {
    position: relative;
    display: inline-block
}

.DateRangePicker__block {
    display: block
}

.DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.DateRangePicker_picker__rtl {
    direction: rtl
}

.DateRangePicker_picker__directionLeft {
    left: 0
}

.DateRangePicker_picker__directionRight {
    right: 0
}

.DateRangePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.DateRangePicker_picker__fullScreenPortal {
    background-color: #fff
}

.DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}
.Transactions_Container__1RH6k {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Transactions_Tools__Dnu40 {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.Transactions_PageFilterTools__r8DH0 {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    margin-left: 15px;
    align-items: center;
}


.Transactions_PageFilter__3DEjd {
    display: flex;
    flex: 1 1 10%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
  
}

.Transactions_FilterReportBtn__1_Pu2 {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.8rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.Transactions_ToolsWrapper__uBffi {
    height: 6rem;
    width: 100%;

}

.Transactions_PrintToolsWrapper__3do-7 {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}



.Transactions_AddNewMerchantBtn__2Takz:focus,
.Transactions_AddNewMerchantBtn__2Takz:hover,
.Transactions_AddNewMerchantBtn__2Takz:active {
    background-color: rgb(51, 137, 255);
}


.Transactions_ToolsDataExportIconWrapper__2owHs {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Transactions_ToolsPrintIconWrapper__2jrBp {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Transactions_FilterInput__3yF4M {
    margin-right: 2rem;
}

.Transactions_FormGroup__1Rthl {
    /* margin-bottom: 10px; */
}

.Transactions_FormGroupDefault__5ywPI.Transactions_has-error__2sl6h {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Transactions_FormGroupDefault__5ywPI {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Transactions_FormGroupDefault__5ywPI label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Transactions_FormGroup__1Rthl label:not(.Transactions_error__13sSJ) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Transactions_FormControl__1Kq3n {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Transactions_MerchantTableWrapper__2BtCD {
    margin-top: 3rem;
    white-space: nowrap;
}

.Transactions_TableWrapper__17ReM {
    display: flex;
}

.Transactions_MerchantTable__iUQan {
    flex: 1 1 70%;
    border-collapse: collapse;
}

.Transactions_MerchantTable__iUQan th {
    text-align: left;
}

.Transactions_MerchantTable__iUQan th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Transactions_MerchantTable__iUQan td,
.Transactions_MerchantTable__iUQan th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Transactions_SummaryTable__NEDJg {
    width: 100%;
    border-collapse: collapse;
}

.Transactions_SummaryTable__NEDJg th {
    text-align: left;
}

.Transactions_SummaryTable__NEDJg th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Transactions_SummaryTable__NEDJg td,
.Transactions_SummaryTable__NEDJg th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Transactions_SummaryTable__NEDJg td:first-child {
    border-left: 1px solid #e8e8e8;

}

.Transactions_SummaryTable__NEDJg td:last-child {

    border-right: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Transactions_RevealMoreHeader__3Icv3 {
    padding: 0;
    vertical-align: middle;
}





.Transactions_Row__3so_8 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Transactions_Row__3so_8>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Transactions_dataTables_paginate__3xyK7 {
    float: right;
}

.Transactions_dataTables_paginate__3xyK7 {
    /* margin-top: 25px !important; */
}

div.Transactions_dataTables_paginate__3xyK7 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Transactions_dataTables_paginate__3xyK7 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Transactions_dataTables_paginate__3xyK7 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Transactions_dataTables_paginate__3xyK7 ul>li.Transactions_disabled__2oz6p a {
    opacity: .5;
}

.Transactions_dataTables_paginate__3xyK7 ul>li.Transactions_disabled__2oz6p a {
    opacity: .35;
}

.Transactions_dataTables_paginate__3xyK7 ul>li.Transactions_active__37jHI>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Transactions_dataTables_paginate__3xyK7 ul>li.Transactions_next__1pap->a,
.Transactions_dataTables_paginate__3xyK7 ul>li.Transactions_prev__hVGGy>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_right__2MKQt {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_left__1ZRC- {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_right__2MKQt:before {
    content: "\E631";
}

.Transactions_pg_arrow_left__1ZRC-:before {
    content: "\E629";
}

.Transactions_btn__2RL6a {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Transactions_btn_primary__Rplx9,
.Transactions_btn_primary__Rplx9:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Transactions_btn_cons__1BRUM {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Transactions_MerchantName__1_Vio {
    font-weight: 500;
}

.Transactions_Icn__1PUyP {
    cursor: pointer;
    /* fill:#000; */
}

.Transactions_Icn__1PUyP:hover {
    cursor: pointer;

}



.Transactions_RevealMore__3DpNc {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Transactions_RevealMore__3DpNc:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Transactions_RevealMore__3DpNc div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Transactions_RevealMoreExpanded__3rF7D {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Transactions_Expandabled__1AsJA {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Transactions_Expandabled__1AsJA td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Transactions_MerchantTable__iUQan td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Transactions_MerchantTable__iUQan td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Transactions_MerchantRow__N1E4b {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Transactions_MerchantRow__N1E4b:hover {
    background-color: #267788;
    color: #fff;

}

.Transactions_SelectedTransaction__20TBQ {
    background-color: #267788;
    color: #fff;

}

.Transactions_MerchantRow__N1E4b:hover a {
    color: #fff;
}

.Transactions_MerchantRowExpanded__2C_1m {
    background: #267788;
    color: #fff;
}

.Transactions_table__gfKXw.Transactions_table-condensed__1mBdj.Transactions_table-detailed__14llA>tbody>tr.Transactions_shown__1Vewt>td {
    background: #fdf5df;
}


.Transactions_Totals__11Nwe td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Transactions_MoneyTotalLabel__3xIXM {
    float: right;

}

.Transactions_TransactionDetail__3LNIi {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Transactions_TransactionDetailHeader__2skgb {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Transactions_TransactionDetailBody__USZsS {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.selectedTransaction_PageHeader__32kag {
    flex: 1 1 100%;
    justify-content: flex-start;
    width: auto;
}
/* .Odd {
}

.Even {
} */

.details_MemberName__1KA1j {
    font-weight: 500;
}

.details_Icn__2hxYu {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__2hxYu:hover {
    cursor: pointer;

}

.details_RevealMore__3LPKj {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__3LPKj:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.details_RevealMore__3LPKj div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__Zbw26 {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .details_Expandabled__30x78 {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__30x78 td {
    padding: 2rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .details_MemberRow__2nXAb {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MemberRow__2nXAb:hover {
    background-color: #267788;
    color:#fff;

    }

    .details_MemberRow__2nXAb:hover a {
        color:#fff;
        }

    .details_MemberRowExpanded__XkLAn {
        background: #267788;
        color:#fff;
    }

.details_table__1-b-y.details_table-condensed__N0-gb.details_table-detailed__2eWfi > tbody > tr.details_shown__Cr9lE > td {
    background: #fdf5df;
}

.details_Container__3qMdn {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.details_BreadcrumbWrapper__oWneL {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.details_Content__zFuti {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.details_CardBody__2MKPh {
    margin-top: 1rem;
}

.details_Alert__8LpcF {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.details_Breadcrumb__2MsGW {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.details_Header__246vM {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.details_ByLine__3LHmS {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_Activity__2SI6j {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.details_WelcomeContainer__3bO0B {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.details_MerchantProfile__1zPy0 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.details_TabHeader__1RBHJ {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.details_PageHeader__adiVG {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.details_Header__246vM {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.details_ExportReport__o2de6 {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.details_ExportIconWrapper__zzsXR {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_EmailIconWrapper__3s44S {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_AddUser__p_dl2 {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.details_AddUserBtn__8dpCm {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_Tools__12nOA {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.details_PageFilter__cK7nB {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}

.details_PrintToolsWrapper__fmLmW {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.details_FilterReportBtn__2rSPl {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.details_AddNewMerchantBtn__10SzD:focus,
.details_AddNewMerchantBtn__10SzD:hover,
.details_AddNewMerchantBtn__10SzD:active {
    background-color: rgb(51, 137, 255);
}

.details_ToolsDataExportIconWrapper__3nA5q {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.details_ToolsPrintIconWrapper__1RFHH {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.details_FilterInput__1H1eR {
    margin-right: 2rem;
}

.details_FormGroupDefault__1PsAZ.details_has-error__3ZzwO {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__1PsAZ {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.details_FormGroupDefault__1PsAZ label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.details_FormGroup__157eJ label:not(.details_error__cveQo) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.details_FormControl__2PUFA {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.details_MerchantTableWrapper__DhroQ {
    margin-top: 3rem;
    white-space: nowrap;
}

.details_MerchantTable__XP1-Q {
    width: 100%;
    border-collapse: collapse;
}

.details_MerchantTable__XP1-Q th {
    text-align: left;
}

.details_MerchantTable__XP1-Q th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.details_MerchantTable__XP1-Q td,
.details_MerchantTable__XP1-Q th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__31rz3 {
    padding: 0;
    vertical-align: middle;
}

.details_Row__1soy8 {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.details_Row__1soy8>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.details_dataTables_paginate__15BEe {
    float: right;
}

div.details_dataTables_paginate__15BEe {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.details_dataTables_paginate__15BEe ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

.details_dataTables_paginate__15BEe ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.details_dataTables_paginate__15BEe ul>li.details_disabled__1ywpt a {
    opacity: .5;
}

.details_dataTables_paginate__15BEe ul>li.details_disabled__1ywpt a {
    opacity: .35;
}

.details_dataTables_paginate__15BEe ul>li.details_active__yKQ-D>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.details_dataTables_paginate__15BEe ul>li.details_next__2h_6t>a,
.details_dataTables_paginate__15BEe ul>li.details_prev__eHxTo>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__3OV-J {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__1U9sx {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__3OV-J:before {
    content: "\E631";
}

.details_pg_arrow_left__1U9sx:before {
    content: "\E629";
}

.details_btn__395IF {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.details_btn_primary__spyA2,
.details_btn_primary__spyA2:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.details_btn_cons__a5dCR {
    min-width: 120px;
}

.details_MerchantName__19Tp6 {
    font-weight: 500;
}

.details_Icn__2wgup {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__2wgup:hover {
    cursor: pointer;

}



.details_RevealMore__2Djjk {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__2Djjk:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.details_RevealMore__2Djjk div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__38FgY {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.details_Expandabled__2AzCG {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__2AzCG td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__XP1-Q td:last-child {

    border-right: 1px solid #e8e8e8;
}


.details_MerchantTable__XP1-Q td:first-child {

    border-left: 1px solid #e8e8e8;
}


.details_MerchantRow__1wS1z {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MerchantRow__1wS1z:hover {
    background-color: #267788;
    color: #fff;

}

.details_MerchantRow__1wS1z:hover a {
    color: #fff;
}

.details_MerchantRowExpanded__FuafJ {
    background: #267788;
    color: #fff;
}

.details_table__35QOH.details_table-condensed__VLqVF.details_table-detailed__19Q8i>tbody>tr.details_shown__1yMmP>td {
    background: #fdf5df;
}


.details_Totals__3fG2O td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.details_MoneyTotalLabel__24dR6 {
    float: right;

}

.details_FilterWrapper__2PgzA {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.details_FilterPeriods__CFxQ2 {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.details_FilterPeriods__CFxQ2 li {
    font-weight: 600;

    padding-left: 2rem;
    line-height: 38px;
}

.details_FinancialsIntro__kTdFJ {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_ExpandableView__X2Dia {
    background-color: transparent;
    /* font-family: Cambay; */
}

.details_Outlets__1pEs_ {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    /* margin-left: 0.6rem; */
}

.details_Outlets__1pEs_ li {
    display: inline-block;
        list-style: none;
}

.details_Outlet__YBnIB {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;}

.details_Outlet__YBnIB:hover {
color: #FFF;
    background: #5BA6E6;

}

.details_OutletName__1Qb-7 {
    font-weight: 600;
}

.details_OutletLocation__19QM3 {
    font-weight: 400;

}

.details_ExpandableMember__20W0r {
    float: left;
    text-decoration: none;
}

.details_ExpandableMemberName__2nhyx {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.details_OutletListArea__1qYjA {
    width: 100%;
    margin-top: 1rem;
    background: transparent;
}



.details_MemberContractDetailArea__1C5lv {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem 0px;
    background: rgb(255, 255, 255);
}

.details_MemberContractDetailArea__1C5lv header {
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.details_ExpandableMemberWrapper__34R4O {
    background: transparent;
}

.details_clearfix__2b5dB::before,
.details_clearfix__2b5dB::after {
    display:table;
    content: " ";
}

.details_clearfix__2b5dB::after {
clear:both;
}


.details_FuneralSchemeWrapper__2CFFV {
    margin: 0 2.5rem;
}

.details_AddNewMember__2TGdp {
float:right;
margin-right: 8.8rem;
}

.details_AddNewMemberBtn__1F6ul {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_FuneralSchemeTable__hD0q2  {
    width: 100%;
    border-collapse: collapse;
}

.details_FuneralSchemeTable__hD0q2 th {
    text-align: left;
}

.details_FuneralSchemeTable__hD0q2 th {
    color: #666;
    vertical-align: top;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    vertical-align: middle;
}

.details_FuneralSchemeTable__hD0q2 td,
.details_FuneralSchemeTable__hD0q2 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_FuneralSchemeTable__hD0q2 .details_Totals__3fG2O td {   
     border-bottom: none;
     font-weight: 600;
}

.details_FuneralSchemeTable__hD0q2 .details_MoneyTotalLabel__24dR6 {
    float: right;

}
.details_FuneralSchemeTable__hD0q2 .details_MoneyTotal__39tQB {

}
.CustomerITReport_Container__1S0x4 {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.CustomerITReport_BreadcrumbWrapper__3lop_ {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.CustomerITReport_Content__3Qhq6 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.CustomerITReport_CardBody__34AOU {
    margin-top: 1rem;
}

.CustomerITReport_Alert__1UDPv {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.CustomerITReport_Breadcrumb__1GuqQ {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.CustomerITReport_Header__BcE-b {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.CustomerITReport_ByLine__3Zypr {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.CustomerITReport_Activity__1bpoQ {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.CustomerITReport_WelcomeContainer__F15TG {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.CustomerITReport_MerchantProfile__1FTGc {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.CustomerITReport_TabHeader__Fa8as {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.CustomerITReport_PageHeader__3k93F {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.CustomerITReport_Header__BcE-b {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.CustomerITReport_ExportReport__2FhV2 {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.CustomerITReport_ExportIconWrapper__2Cbu4 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.CustomerITReport_EmailIconWrapper__6vwV5 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.CustomerITReport_AddUser__D8hHf {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.CustomerITReport_AddUserBtn__1ns_e {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.CustomerITReport_Tools__36wIk {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.CustomerITReport_PageFilter__2Og-L {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.CustomerITReport_PrintToolsWrapper__1mrzP {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.CustomerITReport_FilterReportBtn__20QUM {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.CustomerITReport_AddNewMerchantBtn__3jFWR:focus,
.CustomerITReport_AddNewMerchantBtn__3jFWR:hover,
.CustomerITReport_AddNewMerchantBtn__3jFWR:active {
    background-color: rgb(51, 137, 255);
}


.CustomerITReport_ToolsDataExportIconWrapper__1_Vwc {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.CustomerITReport_ToolsPrintIconWrapper__wUEiW {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.CustomerITReport_FilterInput__1SAMm {
    margin-right: 2rem;
}

.CustomerITReport_FormGroupDefault__1_tvJ.CustomerITReport_has-error__3L_Ty {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.CustomerITReport_FormGroupDefault__1_tvJ {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.CustomerITReport_FormGroupDefault__1_tvJ label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.CustomerITReport_FormGroup__2Jp2b label:not(.CustomerITReport_error__3oBTT) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.CustomerITReport_FormControl__35wsS {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.CustomerITReport_MerchantTableWrapper__25bPj {
    margin-top: 3rem;
    white-space: nowrap;
}

.CustomerITReport_MerchantTable__3bAxE {
    width: 100%;
    border-collapse: collapse;
}

.CustomerITReport_MerchantTable__3bAxE th {
    text-align: left;
}

.CustomerITReport_MerchantTable__3bAxE th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.CustomerITReport_MerchantTable__3bAxE td,
.CustomerITReport_MerchantTable__3bAxE th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.CustomerITReport_RevealMoreHeader__3hV4H {
    padding: 0;
    vertical-align: middle;
}

.CustomerITReport_Row__1Ad-d {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.CustomerITReport_Row__1Ad-d>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.CustomerITReport_dataTables_paginate__1Ewyt {
    float: right;
}

div.CustomerITReport_dataTables_paginate__1Ewyt {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.CustomerITReport_dataTables_paginate__1Ewyt ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.CustomerITReport_dataTables_paginate__1Ewyt ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.CustomerITReport_dataTables_paginate__1Ewyt ul>li.CustomerITReport_disabled__1vST5 a {
    opacity: .5;
}

.CustomerITReport_dataTables_paginate__1Ewyt ul>li.CustomerITReport_disabled__1vST5 a {
    opacity: .35;
}

.CustomerITReport_dataTables_paginate__1Ewyt ul>li.CustomerITReport_active__1rxjP>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.CustomerITReport_dataTables_paginate__1Ewyt ul>li.CustomerITReport_next__2VXfl>a,
.CustomerITReport_dataTables_paginate__1Ewyt ul>li.CustomerITReport_prev__1GbXQ>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.CustomerITReport_pg_arrow_right__2ldY4 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.CustomerITReport_pg_arrow_left__4ybKj {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.CustomerITReport_pg_arrow_right__2ldY4:before {
    content: "\E631";
}

.CustomerITReport_pg_arrow_left__4ybKj:before {
    content: "\E629";
}

.CustomerITReport_btn__2tnXe {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.CustomerITReport_btn_primary__W6Vyc,
.CustomerITReport_btn_primary__W6Vyc:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.CustomerITReport_btn_cons__3g-PN {
    min-width: 120px;
}




.CustomerITReport_MerchantName__1Nc4H {
    font-weight: 500;
}

.CustomerITReport_Icn__3j7i7 {
    cursor: pointer;
    /* fill:#000; */
}

.CustomerITReport_Icn__3j7i7:hover {
    cursor: pointer;

}



.CustomerITReport_RevealMore__1AwgV {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.CustomerITReport_RevealMore__1AwgV:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.CustomerITReport_RevealMore__1AwgV div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.CustomerITReport_RevealMoreExpanded__3Xa2J {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.CustomerITReport_Expandabled__m21dx {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.CustomerITReport_Expandabled__m21dx td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.CustomerITReport_MerchantTable__3bAxE td:last-child {

    border-right: 1px solid #e8e8e8;
}


.CustomerITReport_MerchantTable__3bAxE td:first-child {

    border-left: 1px solid #e8e8e8;
}


.CustomerITReport_MerchantRow__31ZvE {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.CustomerITReport_MerchantRow__31ZvE:hover {
    background-color: #267788;
    color: #fff;

}

.CustomerITReport_MerchantRow__31ZvE:hover a {
    color: #fff;
}

.CustomerITReport_MerchantRowExpanded__32xqN {
    background: #267788;
    color: #fff;
}

.CustomerITReport_table__ZTwuH.CustomerITReport_table-condensed__3Ez_Z.CustomerITReport_table-detailed__2o0Tj>tbody>tr.CustomerITReport_shown__1u8ml>td {
    background: #fdf5df;
}


.CustomerITReport_Totals__1AvsC td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.CustomerITReport_MoneyTotalLabel__3TrtU {
    float: right;

}

.CustomerITReport_FilterWrapper__KcIVe {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.CustomerITReport_FilterPeriods__UpFB1 {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.CustomerITReport_FilterPeriods__UpFB1 li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.CustomerITReport_FinancialsIntro__2_T8M {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.CustomerITReport_SalesBtn__YAFd4 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.Statement_Container__2PnXk {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.Statement_BreadcrumbWrapper__115al {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.Statement_Content__25dsZ {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.Statement_CardBody__q7cMN {
    margin-top: 1rem;
}

.Statement_Alert__M30ZB {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.Statement_Breadcrumb__3mebw {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.Statement_Header__1G5D6 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.Statement_ByLine__3Met2 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Statement_Activity__2kpJh {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.Statement_WelcomeContainer__1iBzv {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.Statement_MerchantProfile__20wUs {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.Statement_TabHeader__2va-a {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.Statement_PageHeader__2MnIY {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.Statement_Header__1G5D6 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.Statement_ExportReport__29LuE {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.Statement_ExportIconWrapper__BhEgm {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Statement_EmailIconWrapper__1Bv1h {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.Statement_AddUser__3_1Vf {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.Statement_AddUserBtn__3RqO_ {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.Statement_Tools__Y7IYr {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.Statement_PageFilter__qXqL_ {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.Statement_PrintToolsWrapper__1fCaQ {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.Statement_FilterReportBtn__2iccw {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.Statement_AddNewMerchantBtn__3aXJM:focus,
.Statement_AddNewMerchantBtn__3aXJM:hover,
.Statement_AddNewMerchantBtn__3aXJM:active {
    background-color: rgb(51, 137, 255);
}


.Statement_ToolsDataExportIconWrapper__2M8DG {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Statement_ToolsPrintIconWrapper__221JI {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Statement_FilterInput__2JaV5 {
    margin-right: 2rem;
}

.Statement_FormGroupDefault__3IzDU.Statement_has-error__SHw9_ {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Statement_FormGroupDefault__3IzDU {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Statement_FormGroupDefault__3IzDU label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Statement_FormGroup__1aV10 label:not(.Statement_error__32UgQ) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Statement_FormControl__c18OV {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Statement_MerchantTableWrapper__R9qdd {
    margin-top: 3rem;
    white-space: nowrap;
}

.Statement_MerchantTable__2kM1i {
    width: 100%;
    border-collapse: collapse;
}

.Statement_MerchantTable__2kM1i th {
    text-align: left;
}

.Statement_MerchantTable__2kM1i th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Statement_MerchantTable__2kM1i td,
.Statement_MerchantTable__2kM1i th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.Statement_RevealMoreHeader__1Ee4L {
    padding: 0;
    vertical-align: middle;
}

.Statement_Row__2QEdW {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Statement_Row__2QEdW>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Statement_dataTables_paginate__1Cclg {
    float: right;
}

div.Statement_dataTables_paginate__1Cclg {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Statement_dataTables_paginate__1Cclg ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.Statement_dataTables_paginate__1Cclg ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Statement_dataTables_paginate__1Cclg ul>li.Statement_disabled__1qrnL a {
    opacity: .5;
}

.Statement_dataTables_paginate__1Cclg ul>li.Statement_disabled__1qrnL a {
    opacity: .35;
}

.Statement_dataTables_paginate__1Cclg ul>li.Statement_active__OqjV_>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Statement_dataTables_paginate__1Cclg ul>li.Statement_next__vqBAH>a,
.Statement_dataTables_paginate__1Cclg ul>li.Statement_prev__M1skb>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Statement_pg_arrow_right__22nIv {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Statement_pg_arrow_left__3OhxU {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Statement_pg_arrow_right__22nIv:before {
    content: "\E631";
}

.Statement_pg_arrow_left__3OhxU:before {
    content: "\E629";
}

.Statement_btn__2fGG3 {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Statement_btn_primary__TACro,
.Statement_btn_primary__TACro:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Statement_btn_cons__dW4j- {
    min-width: 120px;
}




.Statement_MerchantName__324eO {
    font-weight: 500;
}

.Statement_Icn__23LyJ {
    cursor: pointer;
    /* fill:#000; */
}

.Statement_Icn__23LyJ:hover {
    cursor: pointer;

}



.Statement_RevealMore__vtIRc {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Statement_RevealMore__vtIRc:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Statement_RevealMore__vtIRc div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Statement_RevealMoreExpanded__2Krip {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Statement_Expandabled__xxCbE {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Statement_Expandabled__xxCbE td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Statement_MerchantTable__2kM1i td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Statement_MerchantTable__2kM1i td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Statement_MerchantRow__OLS6f {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Statement_MerchantRow__OLS6f:hover {
    background-color: #267788;
    color: #fff;

}

.Statement_MerchantRow__OLS6f:hover a {
    color: #fff;
}

.Statement_MerchantRowExpanded__zinYr {
    background: #267788;
    color: #fff;
}

.Statement_table__1nW-J.Statement_table-condensed__3eGpB.Statement_table-detailed__3mOHa>tbody>tr.Statement_shown__2MJjd>td {
    background: #fdf5df;
}


.Statement_Totals__1_7FN td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Statement_MoneyTotalLabel__2_JuA {
    float: right;

}

.Statement_FilterWrapper__gBTOv {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.Statement_FilterPeriods__1e64h {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.Statement_FilterPeriods__1e64h li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.Statement_FinancialsIntro__2ey5h {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.Statement_SalesBtn__3vjUo {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
.wuc_Container__3-b8i {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.wuc_BreadcrumbWrapper__2FA7J {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.wuc_Content__10lr_ {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.wuc_Alert__3Qtmq {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.wuc_Breadcrumb__SswiV {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.wuc_Header__3B_PW {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.wuc_ByLine__36ghV {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.wuc_Activity__1XOpP {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.wuc_WelcomeContainer__3C5Eo {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.wuc_MerchantProfile__188Kv {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.wuc_TabHeader__1VgIT {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.wuc_PageHeader__30G8R {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.wuc_AddUser__2GELf {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.wuc_AddUserBtn__rE1i4 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.wuc_ReportContainer__3sl62 {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.wuc_ReportTiles__3cD67 {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.wuc_TileContainer__11kdt {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.wuc_Tile__3xzQQ {
    cursor: pointer;
    padding: 4px;
}

.wuc_TileInner__n3Z7l {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.PresetDateRangePicker_panel {
    padding: 0 22px 11px
}

.PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer
}

.PresetDateRangePicker_button:active {
    outline: 0
}

.PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
}

.SingleDatePickerInput {
    display: inline-block;
    background-color: #fff
}

.SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
}

.SingleDatePickerInput__rtl {
    direction: rtl
}

.SingleDatePickerInput__disabled {
    background-color: #f2f2f2
}

.SingleDatePickerInput__block {
    display: block
}

.SingleDatePickerInput__showClearDate {
    padding-right: 30px
}

.SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.SingleDatePickerInput_clearDate__small {
    padding: 6px
}

.SingleDatePickerInput_clearDate__hide {
    visibility: hidden
}

.SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.SingleDatePickerInput_clearDate_svg__small {
    height: 9px
}

.SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.SingleDatePicker {
    position: relative;
    display: inline-block
}

.SingleDatePicker__block {
    display: block
}

.SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.SingleDatePicker_picker__rtl {
    direction: rtl
}

.SingleDatePicker_picker__directionLeft {
    left: 0
}

.SingleDatePicker_picker__directionRight {
    right: 0
}

.SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
}

.SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_show {
    width: 33px;
    height: 26px;
    position: absolute;
    z-index: 2
}

.DayPickerKeyboardShortcuts_show::before {
    content: "";
    display: block;
    position: absolute
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topRight {
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight::before {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topLeft {
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid #008489
}

.DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: 5px
}

.DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
    text-align: left
}

.DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
}

.DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
}

.DayPickerKeyboardShortcuts_close:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
}

.CalendarDay {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center
}

.CalendarDay:active {
    outline: 0
}

.CalendarDay__defaultCursor {
    cursor: default
}

.CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff
}

.CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px solid #e4e7e7;
    color: inherit
}

.CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
}

.CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848
}

.CalendarDay__outside:hover {
    border: 0
}

.CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
}

.CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
}

.CalendarDay__selected_span {
    background: #66e2da;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #00a699;
    border: 1px double #00a699;
    color: #fff
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd
}

.CalendarDay__hovered_start_first_possible_end {
    background: #eceeee;
    border: 1px double #eceeee
}

.CalendarDay__hovered_start_blocked_min_nights {
    background: #eceeee;
    border: 1px double #e4e7e7
}

.CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    user-select: none
}

.CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
}

.CalendarMonth_verticalSpacing {
    border-collapse: separate
}

.CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial
}

.CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
}

.CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0
}

.CalendarMonthGrid__animating {
    z-index: 1
}

.CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
}

.CalendarMonthGrid__vertical {
    margin: 0 auto
}

.CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll
}

.CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
}

.CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
}

.CalendarMonthGrid_month__hidden {
    visibility: hidden
}

.DayPickerNavigation {
    position: relative;
    z-index: 2
}

.DayPickerNavigation__horizontal {
    height: 0
}

.DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
}

.DayPickerNavigation__verticalScrollableDefault {
    position: relative
}

.DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
}

.DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
}

.DayPickerNavigation_button__default:active {
    background: #f2f2f2
}

.DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:active {
    background: 0 0
}

.DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
}

.DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
}

.DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
}

.DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
}

.DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
}

.DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%
}

.DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
}

.DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848
}

.DayPickerNavigation_svg__disabled {
    fill: #f2f2f2
}

.DayPicker {
    background: #fff;
    position: relative;
    text-align: left
}

.DayPicker__horizontal {
    background: #fff
}

.DayPicker__verticalScrollable {
    height: 100%
}

.DayPicker__hidden {
    visibility: hidden
}

.DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
    border-radius: 3px
}

.DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
}

.DayPicker_portal__vertical {
    position: initial
}

.DayPicker_focusRegion {
    outline: 0
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
}

.DayPicker_weekHeaders {
    position: relative
}

.DayPicker_weekHeaders__horizontal {
    margin-left: 9px
}

.DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
}

.DayPicker_weekHeader__vertical {
    left: 50%
}

.DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
}

.DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
}

.DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
}

.DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px
}

.DayPicker_transitionContainer__horizontal {
    transition: height .2s ease-in-out
}

.DayPicker_transitionContainer__vertical {
    width: 100%
}

.DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll
}

.DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 130px;
    vertical-align: middle
}

.DateInput__small {
    width: 97px
}

.DateInput__block {
    width: 100%
}

.DateInput__disabled {
    background: #f2f2f2;
    color: #dbdbdb
}

.DateInput_input {
    font-weight: 200;
    font-size: 19px;
    line-height: 24px;
    color: #484848;
    background-color: #fff;
    width: 100%;
    padding: 11px 11px 9px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0
}

.DateInput_input__small {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: .2px;
    padding: 10px 8px 6px;
}

.DateInput_input__regular {
    font-weight: auto
}

.DateInput_input__readOnly {
    -webkit-user-select: none;
    user-select: none
}

.DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid #008489;
    border-left: 0
}

.DateInput_input__disabled {
    background: #f2f2f2;
    font-style: italic
}

.DateInput_screenReaderMessage {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.DateInput_fang {
    position: absolute;
    width: 20px;
    height: 10px;
    left: 22px;
    z-index: 2
}

.DateInput_fangShape {
    fill: #fff
}

.DateInput_fangStroke {
    stroke: #dbdbdb;
    fill: transparent
}

.DateRangePickerInput {
    background-color: #fff;
    display: inline-block
}

.DateRangePickerInput__disabled {
    background: #f2f2f2
}

.DateRangePickerInput__withBorder {
    border-radius: 0;
    border: 1px solid #dbdbdb
}

.DateRangePickerInput__rtl {
    direction: rtl
}

.DateRangePickerInput__block {
    display: block
}

.DateRangePickerInput__showClearDates {
    padding-right: 30px
}

.DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
    padding: 5px;
}

.DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
}

.DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.DateRangePickerInput_clearDates__small {
    padding: 6px
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.DateRangePickerInput_clearDates__hide {
    visibility: hidden
}

.DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.DateRangePickerInput_clearDates_svg__small {
    height: 9px
}

.DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.DateRangePicker {
    position: relative;
    display: inline-block
}

.DateRangePicker__block {
    display: block
}

.DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.DateRangePicker_picker__rtl {
    direction: rtl
}

.DateRangePicker_picker__directionLeft {
    left: 0
}

.DateRangePicker_picker__directionRight {
    right: 0
}

.DateRangePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.DateRangePicker_picker__fullScreenPortal {
    background-color: #fff
}

.DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}
.Transactions_Container__2R8an {
  background-color: #fff;
  /* margin: 2.4rem;
  margin-bottom: 2.4rem; */
  /* flex-grow: 1; */
  /* border-top: 1px solid #707070; */
  /* display: flex; */
  flex-direction: column;
  font-size: 1.4rem;
}

.Transactions_Tools__3MVGn {
  display: flex;
  /* margin: 0 2.5rem; */
  padding-top: 1.4rem;
}

.Transactions_PageFilterTools__2NHqt {
  display: flex;
  justify-content: flex-start;
  width: auto;
  margin-left: 15px;
  align-items: center;
}

.Transactions_PageFilter__3CMmO {
  display: flex;
  flex: 1 1 50%;
  justify-content: flex-start;
  width: auto;
  padding-left: 2rem;
}

.Transactions_FilterReportBtn__3VH7x {
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  height: 3.8rem;
  padding: 10px;
  text-decoration: none;
  border: none;
  display: block;
}

.Transactions_ToolsWrapper__3ehkF {
  height: 6rem;
  width: 100%;
}

.Transactions_PrintToolsWrapper__dAiuJ {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
}

.Transactions_AddNewMerchantBtn__1mARu:focus,
.Transactions_AddNewMerchantBtn__1mARu:hover,
.Transactions_AddNewMerchantBtn__1mARu:active {
  background-color: rgb(51, 137, 255);
}

.Transactions_ToolsDataExportIconWrapper__3IclK {
  cursor: pointer;
  /* height: 24px;
  width: 24px; */
}

.Transactions_ToolsPrintIconWrapper__vuDrH {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.Transactions_FilterInput__fJIPu {
  margin-right: 2rem;
}

.Transactions_FormGroup__2rSGW {
  /* margin-bottom: 10px; */
}

.Transactions_FormGroupDefault__34RzA.Transactions_has-error__2tcjo {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.Transactions_FormGroupDefault__34RzA {
  /* background-color: #fff;
  position: relative;
  padding-top: 7px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;

  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease; */
  /* padding: 1.4rem 2.5rem .2em .5rem; */
  line-height: 2rem;
  position: relative;
  transition: all 0.2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.Transactions_FormGroupDefault__34RzA label {
  margin: 0;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.Transactions_FormGroup__2rSGW label:not(.Transactions_error__3ltgI) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.Transactions_FormControl__2QqqE {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.Transactions_MerchantTableWrapper__Kq9-_ {
  margin-top: 3rem;
  white-space: nowrap;
}

.Transactions_TableWrapper__337d_ {
  display: flex;
}

.Transactions_MerchantTable__24Dcw {
  flex: 1 1 70%;
  width: 100%;
  border-collapse: collapse;
}

.Transactions_MerchantTable__24Dcw th {
  text-align: left;
}

.Transactions_MerchantTable__24Dcw th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.Transactions_MerchantTable__24Dcw td,
.Transactions_MerchantTable__24Dcw th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

  border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

  border-right: 1px solid #e8e8e8;
} */

.Transactions_RevealMoreHeader__1n1je {
  padding: 0;
  vertical-align: middle;
}

.Transactions_Row__1HzJy {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem;
}

.Transactions_Row__1HzJy > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.Transactions_dataTables_paginate__3ZUg4 {
  float: right;
}

.Transactions_dataTables_paginate__3ZUg4 {
  /* margin-top: 25px !important; */
}

div.Transactions_dataTables_paginate__3ZUg4 {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.Transactions_dataTables_paginate__3ZUg4 ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
  outline: 0 !important;
  text-decoration: none;
  background-color: transparent;
} */

.Transactions_dataTables_paginate__3ZUg4 ul > li > a {
  padding: 5px 10px;
  color: #575757;
  opacity: 0.35;
  transition: opacity 0.3s ease;
}

.Transactions_dataTables_paginate__3ZUg4 ul > li.Transactions_disabled__22sEv a {
  opacity: 0.5;
}

.Transactions_dataTables_paginate__3ZUg4 ul > li.Transactions_disabled__22sEv a {
  opacity: 0.35;
}

.Transactions_dataTables_paginate__3ZUg4 ul > li.Transactions_active__2ukfN > a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.Transactions_dataTables_paginate__3ZUg4 ul > li.Transactions_next__2B1cv > a,
.Transactions_dataTables_paginate__3ZUg4 ul > li.Transactions_prev__1rTIe > a {
  opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_right__3z-0w {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_left__BsPEw {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_right__3z-0w:before {
  content: "\E631";
}

.Transactions_pg_arrow_left__BsPEw:before {
  content: "\E629";
}

.Transactions_btn__2LF5I {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  /* border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px; */
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
}

.Transactions_btn_primary__1IZTX,
.Transactions_btn_primary__1IZTX:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.Transactions_btn_cons__2mBAN {
  min-width: 120px;
}

/* .MerchantTable tr.expanded + .expandable {
  display: table-row;
} */

/* .Odd {
}

.Even {
} */

.Transactions_MerchantName__1Njr_ {
  font-weight: 500;
}

.Transactions_Icn__y20lO {
  cursor: pointer;
  /* fill:#000; */
}

.Transactions_Icn__y20lO:hover {
  cursor: pointer;
}

.Transactions_RevealMore__1eNHM {
  background: #e1e1e1;
  cursor: pointer;
  width: 2.4rem;
}

.Transactions_RevealMore__1eNHM:hover {
  background: transparent;
  color: #707070;
  fill: #fff;
}

.Transactions_RevealMore__1eNHM div {
  margin: 0 auto;
  width: 2.4rem;
  height: 2.4rem;
}

.Transactions_RevealMoreExpanded__2128U {
  background: transparent;
  fill: #fff;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.Transactions_Expandabled__3dIyr {
  text-align: left;
  background: #d7effb;
  border-bottom: 1px solid rgb(56, 0, 60);
  display: table-row;
  z-index: 500;
}

.Transactions_Expandabled__3dIyr td {
  padding: 2rem 2rem 2rem 5.6rem;
  border-bottom: 1px solid #e8e8e8;
}

.Transactions_MerchantTable__24Dcw td:last-child {
  border-right: 1px solid #e8e8e8;
}

.Transactions_MerchantTable__24Dcw td:first-child {
  border-left: 1px solid #e8e8e8;
}

.Transactions_MerchantRow__3hC1Q {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.Transactions_MerchantRow__3hC1Q:hover {
  background-color: #267788;
  color: #fff;
}

.Transactions_SelectedTransaction__3x5-u {
  background-color: #267788;
  color: #fff;
}

.Transactions_MerchantRow__3hC1Q:hover a {
  color: #fff;
}

.Transactions_MerchantRowExpanded__3VSwr {
  background: #267788;
  color: #fff;
}

.Transactions_table__2E4Nj.Transactions_table-condensed__2aLSk.Transactions_table-detailed__2IqEJ > tbody > tr.Transactions_shown__V3bOX > td {
  background: #fdf5df;
}

.Transactions_Totals__2L7Mj td {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid #e8e8e8;
  font-weight: 700;
}

.Transactions_MoneyTotalLabel__2of83 {
  float: right;
}

.Transactions_TransactionDetail__2wTz5 {
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  /* background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0; */
}

.Transactions_TransactionDetailHeader__dVXeX {
  padding: 0;
  vertical-align: middle;
  height: 4rem;
  background-color: #fff;
  border: none;
}

.Transactions_TransactionDetailBody__3AYQu {
  flex: 1 1 auto;
  padding: 0;
  vertical-align: middle;
  overflow: hidden;
  padding: 2rem;
  background-color: transparent;
  border: 1px solid #e0e0e0;
}

/* .Odd {
}

.Even {
} */

.details_MemberName__HghEY {
  font-weight: 500;
}

.details_Icn__27nBG {
  cursor: pointer;
  /* fill:#000; */
}

.details_Icn__27nBG:hover {
  cursor: pointer;
}

.details_RevealMore__2hSCA {
  background: #e1e1e1;
  cursor: pointer;
  width: 2.4rem;
}

.details_RevealMore__2hSCA:hover {
  background: transparent;
  color: #707070;
  fill: #fff;
}

.details_RevealMore__2hSCA div {
  margin: 0 auto;
  width: 2.4rem;
  height: 2.4rem;
}

.details_RevealMoreExpanded__3iGtq {
  background: transparent;
  fill: #fff;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.details_Expandabled__1n_Vo {
  text-align: left;
  background: #d7effb;
  border-bottom: 1px solid rgb(56, 0, 60);
  display: table-row;
  z-index: 500;
}

.details_Expandabled__1n_Vo td {
  padding: 2rem;
  border-bottom: 1px solid #e8e8e8;
}

.details_ToolsDataExportIconWrapper__3_DUG {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.details_PrintToolsWrapper__eLIgd {
  display: flex;
  flex: 1 1 20%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
}

.details_AddUser__3CS3Q {
  display: flex;
  margin-right: 20px;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.details_AddUserBtn__3PGe8 {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 0.8rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  display: block;
}

.details_MemberRow__1UiJI {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.details_MemberRow__1UiJI:hover {
  background-color: rgb(38, 119, 136, 0.3);

  color: #fff;
}

.details_MemberRow__1UiJI:hover a {
  color: #fff;
}

.details_MemberRowExpanded__3qTPs {
  background: #267788;
  color: #fff;
}

.details_table__1_I3n.details_table-condensed__71YtL.details_table-detailed__1z6k9 > tbody > tr.details_shown__2xKg5 > td {
  background: #fdf5df;
}

.details_Container__vrq8O {
  /* background-color: #fff; */
  flex-direction: column;
  height: 100%;
  font-size: 1.4rem;
}

.details_BreadcrumbWrapper__3XI6s {
  display: flex;
  background-color: transparent;
  width: 100%;
  padding-left: 2.4rem;
  flex: 0 0 7.2rem;
  height: 7.2rem;
}

.details_Content__3xDLg {
  flex: 1 1 auto;
  display: flex;
  margin: 2.4rem;
  flex-direction: column;
}

.details_CardBody__356MK {
  margin-top: 1rem;
}

.details_ToolsDataExportIconWrapper__15wPl {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.details_Alert__1CgFE {
  padding: 18px;
  margin-bottom: 15px;
  border: 0;
  border-radius: 0;
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.details_Breadcrumb__2fXj4 {
  padding: 1.8rem 0;
  flex: 0 0 50%;
}

.details_Header__1UHMh {
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 1.5px;
  /* text-transform: uppercase; */
}

.details_ByLine__22IBW {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.details_Activity___plPm {
  background: #fff;
  flex: 0 0 36rem;
  /* width: 36rem; */
}

.details_WelcomeContainer__28LC0 {
  display: flex;
  /* margin: 0 2.4rem 0 0; */
  /* height: 17.6rem; */
}

.details_MerchantProfile__a_kN4 {
  display: flex;
  background: transparent;
  flex: 0 0 100%;
}

.details_TabHeader__3sBwC {
  /* padding: 0px 2rem; */
  margin-bottom: 1rem;
  display: flex;
}

.details_PageHeader__2ryg4 {
  flex: 1 1 auto;
  justify-content: flex-start;
  width: auto;
}

.details_Header__1UHMh {
  flex: 1 1 50%;
  justify-content: flex-start;
  width: auto;
}

.details_ExportReport__1x74D {
  flex: 1 1 50%;
  width: auto;
  margin-right: 1.4rem;
  align-items: center;
  display: flex;

  line-height: normal;
  flex: 1 1 auto;
  justify-content: flex-end;
}

.details_ExportIconWrapper__vKEuy {
  margin: 0 1.6rem;
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
  flex: 0 0 auto;
}

.details_EmailIconWrapper__3toae {
  margin: 0 1.6rem;
  vertical-align: middle;
  cursor: pointer;
  height: 24px;
  width: 24px;
  flex: 0 0 auto;
}

.details_AddUser__264yI {
  display: flex;
  margin-right: 20px;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.details_AddUserBtn__2me60 {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

.details_Tools__SFRFN {
  /* display: flex; */
  margin: 1rem 0 3.5rem;
  /* padding-top: 1.4rem; */
}

.details_PageFilter__2r4Gx {
  /* display: flex; */
  flex: 1 1 auto;
  justify-content: flex-start;
  width: auto;
  margin-left: 15px;
}

.details_PrintToolsWrapper__2PGnd {
  display: flex;
  flex: 1 1 20%;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
}

.details_FilterReportBtn__3L3sB {
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  height: 3.4rem;
  padding: 8px;
  text-decoration: none;
  border: none;
  display: block;
}

.details_AddNewMerchantBtn__EYJ0I:focus,
.details_AddNewMerchantBtn__EYJ0I:hover,
.details_AddNewMerchantBtn__EYJ0I:active {
  background-color: rgb(51, 137, 255);
}

.details_ToolsDataExportIconWrapper__15wPl {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.details_ToolsPrintIconWrapper__3mhin {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.details_FilterInput__1ghxW {
  margin-right: 2rem;
}

.details_FormGroupDefault__1zXTG.details_has-error__1CCjk {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__1zXTG {
  line-height: 2rem;
  position: relative;
  transition: all 0.2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.details_FormGroupDefault__1zXTG label {
  margin: 0;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.details_FormGroup__a-Huj label:not(.details_error__1eHPd) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.details_FormControl__2YI7U {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.details_MerchantTableWrapper__1npsH {
  margin-top: 3rem;
  white-space: nowrap;
}

.details_MerchantTable__2g-H1 {
  width: 100%;
  border-collapse: collapse;
}

.details_MerchantTable__2g-H1 th {
  text-align: left;
}

.details_MerchantTable__2g-H1 th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.details_MerchantTable__2g-H1 td,
.details_MerchantTable__2g-H1 th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__3Q4YQ {
  padding: 0;
  vertical-align: middle;
}

.details_Row__2SksD {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem;
}

.details_Row__2SksD > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.details_dataTables_paginate__1LBEH {
  float: right;
}

div.details_dataTables_paginate__1LBEH {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.details_dataTables_paginate__1LBEH ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

.details_dataTables_paginate__1LBEH ul > li > a {
  padding: 5px 10px;
  color: #575757;
  opacity: 0.35;
  transition: opacity 0.3s ease;
}

.details_dataTables_paginate__1LBEH ul > li.details_disabled__uWTUh a {
  opacity: 0.5;
}

.details_dataTables_paginate__1LBEH ul > li.details_disabled__uWTUh a {
  opacity: 0.35;
}

.details_dataTables_paginate__1LBEH ul > li.details_active__10mOp > a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.details_dataTables_paginate__1LBEH ul > li.details_next__3paJX > a,
.details_dataTables_paginate__1LBEH ul > li.details_prev__1Dyzl > a {
  opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__2GCKF {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__gkYyc {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__2GCKF:before {
  content: "\E631";
}

.details_pg_arrow_left__gkYyc:before {
  content: "\E629";
}

.details_btn__2ZRz_ {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
}

.details_btn_primary__3NWvd,
.details_btn_primary__3NWvd:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.details_btn_cons__1yx1- {
  min-width: 120px;
}

.details_MerchantName__3fBXv {
  font-weight: 500;
}

.details_Icn__eywAN {
  cursor: pointer;
  /* fill:#000; */
}

.details_Icn__eywAN:hover {
  cursor: pointer;
}

.details_RevealMore__2NLF0 {
  background: #e1e1e1;
  cursor: pointer;
  width: 2.4rem;
}

.details_RevealMore__2NLF0:hover {
  background: transparent;
  color: #707070;
  fill: #fff;
}

.details_RevealMore__2NLF0 div {
  margin: 0 auto;
  width: 2.4rem;
  height: 2.4rem;
}

.details_RevealMoreExpanded__2nO6h {
  background: transparent;
  fill: #fff;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.details_Expandabled__2ZUD1 {
  text-align: left;
  background: #d7effb;
  border-bottom: 1px solid rgb(56, 0, 60);
  display: table-row;
  z-index: 500;
}

.details_Expandabled__2ZUD1 td {
  padding: 2rem 2rem 2rem 5.6rem;
  border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__2g-H1 td:last-child {
  border-right: 1px solid #e8e8e8;
}

.details_MerchantTable__2g-H1 td:first-child {
  border-left: 1px solid #e8e8e8;
}

.details_MerchantRow__3UWIm {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.details_MerchantRow__3UWIm:hover {
  background-color: #267788;
  color: #fff;
}

.details_MerchantRow__3UWIm:hover a {
  color: #fff;
}

.details_MerchantRowExpanded__Lx_x7 {
  background: #267788;
  color: #fff;
}

.details_table__3JNjw.details_table-condensed__2YMuW.details_table-detailed__2TfUv > tbody > tr.details_shown__bmIAL > td {
  background: #fdf5df;
}

.details_Totals__3C0Qz td {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid #e8e8e8;
  font-weight: 700;
}

.details_MoneyTotalLabel__H-JR6 {
  float: right;
}

.details_FilterWrapper__2GfeU {
  display: flex;
  justify-content: flex-end;
  border-right: solid #707070;
}

.details_FilterPeriods__2OP1N {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row;
  /* height: 100%; */
  padding-left: 24px;
  color: rgb(0, 0, 0);
  justify-content: flex-end;
  margin-right: 3.2rem;
}

.details_FilterPeriods__2OP1N li {
  font-weight: 600;

  padding-left: 2rem;
  line-height: 38px;
}

.details_FinancialsIntro__uFAhi {
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

.details_ExpandableView__1Rcxs {
  background-color: transparent;
  /* font-family: Cambay; */
}

.details_Outlets__2SP5p {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* margin-left: 0.6rem; */
}

.details_Outlets__2SP5p li {
  display: inline-block;
  list-style: none;
}

.details_Outlet__1UpE0 {
  display: inline-block;
  width: 15rem;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  background: #fff;
  margin-right: 1rem;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

.details_Outlet__1UpE0:hover {
  color: #fff;
  background: #5ba6e6;
}

.details_OutletName__2huIh {
  font-weight: 600;
}

.details_OutletLocation__Htjoz {
  font-weight: 400;
}

.details_ExpandableMember__2fLrs {
  float: left;
  text-decoration: none;
}

.details_ExpandableMemberName__3FjzN {
  font-weight: 600;
  font-size: 2.2rem;
  display: inline-block;
  vertical-align: middle;
}

.details_OutletListArea__1Nr4f {
  width: 100%;
  margin-top: 1rem;
  background: transparent;
}

.details_MemberContractDetailArea__U4tSs {
  width: 100%;
  margin-top: 1rem;
  padding: 2rem 0px;
  background: rgb(255, 255, 255);
}

.details_MemberContractDetailArea__U4tSs header {
  padding: 0px 2rem;
  margin-bottom: 1rem;
}

.details_ExpandableMemberWrapper__372jc {
  background: transparent;
}

.details_clearfix__31QlA::before,
.details_clearfix__31QlA::after {
  display: table;
  content: " ";
}

.details_clearfix__31QlA::after {
  clear: both;
}

.details_FuneralSchemeWrapper__1xWay {
  margin: 0 2.5rem;
}

.details_AddNewMember__1rFt6 {
  float: right;
  margin-right: 8.8rem;
}

.details_AddNewMemberBtn__1FquP {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  vertical-align: middle;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

.details_FuneralSchemeTable__13iXi {
  width: 100%;
  border-collapse: collapse;
}

.details_FuneralSchemeTable__13iXi th {
  text-align: left;
}

.details_FuneralSchemeTable__13iXi th {
  color: #666;
  vertical-align: top;
  font-size: 1.2rem;
  font-family: Raleway;
  font-weight: 600;
  vertical-align: middle;
}

.details_FuneralSchemeTable__13iXi td,
.details_FuneralSchemeTable__13iXi th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.details_FuneralSchemeTable__13iXi .details_Totals__3C0Qz td {
  border-bottom: none;
  font-weight: 600;
}

.details_FuneralSchemeTable__13iXi .details_MoneyTotalLabel__H-JR6 {
  float: right;
}
.details_FuneralSchemeTable__13iXi .details_MoneyTotal__2VUNx {
}

.sms_Container__3xgl2 {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.sms_BreadcrumbWrapper__3IIXB {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.sms_Content___CvQn {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.sms_Alert__2wmsp {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.sms_Breadcrumb__KBtZA {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.sms_Header__BEQYP {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.sms_ByLine__2Dm5U {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.sms_Activity__GDVjw {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.sms_WelcomeContainer__A_AUQ {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.sms_MerchantProfile__1Cmvd {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.sms_TabHeader__2J_Jt {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.sms_PageHeader__bWHn- {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.sms_AddUser__253rg {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.sms_AddUserBtn__A6Y1K {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.sms_ReportContainer__1eNrs {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.sms_ReportTiles__2LPGa {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.sms_TileContainer__1NiOV {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.sms_Tile__7uoxF {
    cursor: pointer;
    padding: 4px;
}

.sms_TileInner__2owXd {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.PresetDateRangePicker_panel {
    padding: 0 22px 11px
}

.PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer
}

.PresetDateRangePicker_button:active {
    outline: 0
}

.PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
}

.SingleDatePickerInput {
    display: inline-block;
    background-color: #fff
}

.SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
}

.SingleDatePickerInput__rtl {
    direction: rtl
}

.SingleDatePickerInput__disabled {
    background-color: #f2f2f2
}

.SingleDatePickerInput__block {
    display: block
}

.SingleDatePickerInput__showClearDate {
    padding-right: 30px
}

.SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.SingleDatePickerInput_clearDate__small {
    padding: 6px
}

.SingleDatePickerInput_clearDate__hide {
    visibility: hidden
}

.SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.SingleDatePickerInput_clearDate_svg__small {
    height: 9px
}

.SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.SingleDatePicker {
    position: relative;
    display: inline-block
}

.SingleDatePicker__block {
    display: block
}

.SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.SingleDatePicker_picker__rtl {
    direction: rtl
}

.SingleDatePicker_picker__directionLeft {
    left: 0
}

.SingleDatePicker_picker__directionRight {
    right: 0
}

.SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
}

.SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_show {
    width: 33px;
    height: 26px;
    position: absolute;
    z-index: 2
}

.DayPickerKeyboardShortcuts_show::before {
    content: "";
    display: block;
    position: absolute
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topRight {
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight::before {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topLeft {
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid #008489
}

.DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: 5px
}

.DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
    text-align: left
}

.DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
}

.DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
}

.DayPickerKeyboardShortcuts_close:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
}

.CalendarDay {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center
}

.CalendarDay:active {
    outline: 0
}

.CalendarDay__defaultCursor {
    cursor: default
}

.CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff
}

.CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px solid #e4e7e7;
    color: inherit
}

.CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
}

.CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848
}

.CalendarDay__outside:hover {
    border: 0
}

.CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
}

.CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
}

.CalendarDay__selected_span {
    background: #66e2da;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #00a699;
    border: 1px double #00a699;
    color: #fff
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd
}

.CalendarDay__hovered_start_first_possible_end {
    background: #eceeee;
    border: 1px double #eceeee
}

.CalendarDay__hovered_start_blocked_min_nights {
    background: #eceeee;
    border: 1px double #e4e7e7
}

.CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    user-select: none
}

.CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
}

.CalendarMonth_verticalSpacing {
    border-collapse: separate
}

.CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial
}

.CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
}

.CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0
}

.CalendarMonthGrid__animating {
    z-index: 1
}

.CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
}

.CalendarMonthGrid__vertical {
    margin: 0 auto
}

.CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll
}

.CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
}

.CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
}

.CalendarMonthGrid_month__hidden {
    visibility: hidden
}

.DayPickerNavigation {
    position: relative;
    z-index: 2
}

.DayPickerNavigation__horizontal {
    height: 0
}

.DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
}

.DayPickerNavigation__verticalScrollableDefault {
    position: relative
}

.DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
}

.DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
}

.DayPickerNavigation_button__default:active {
    background: #f2f2f2
}

.DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:active {
    background: 0 0
}

.DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
}

.DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
}

.DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
}

.DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
}

.DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
}

.DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%
}

.DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
}

.DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848
}

.DayPickerNavigation_svg__disabled {
    fill: #f2f2f2
}

.DayPicker {
    background: #fff;
    position: relative;
    text-align: left
}

.DayPicker__horizontal {
    background: #fff
}

.DayPicker__verticalScrollable {
    height: 100%
}

.DayPicker__hidden {
    visibility: hidden
}

.DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
    border-radius: 3px
}

.DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
}

.DayPicker_portal__vertical {
    position: initial
}

.DayPicker_focusRegion {
    outline: 0
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
}

.DayPicker_weekHeaders {
    position: relative
}

.DayPicker_weekHeaders__horizontal {
    margin-left: 9px
}

.DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
}

.DayPicker_weekHeader__vertical {
    left: 50%
}

.DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
}

.DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
}

.DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
}

.DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px
}

.DayPicker_transitionContainer__horizontal {
    transition: height .2s ease-in-out
}

.DayPicker_transitionContainer__vertical {
    width: 100%
}

.DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll
}

.DateRangePickerInput {
    background-color: #fff;
    display: inline-block
}

.DateRangePickerInput__disabled {
    background: #f2f2f2
}

.DateRangePickerInput__withBorder {
    border-radius: 0;
    border: 1px solid #dbdbdb
}

.DateRangePickerInput__rtl {
    direction: rtl
}

.DateRangePickerInput__block {
    display: block
}

.DateRangePickerInput__showClearDates {
    padding-right: 30px
}

.DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
    padding: 5px;
}

.DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
}

.DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.DateRangePickerInput_clearDates__small {
    padding: 6px
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.DateRangePickerInput_clearDates__hide {
    visibility: hidden
}

.DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.DateRangePickerInput_clearDates_svg__small {
    height: 9px
}

.DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.DateRangePicker {
    position: relative;
    display: inline-block
}

.DateRangePicker__block {
    display: block
}

.DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.DateRangePicker_picker__rtl {
    direction: rtl
}

.DateRangePicker_picker__directionLeft {
    left: 0
}

.DateRangePicker_picker__directionRight {
    right: 0
}

.DateRangePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.DateRangePicker_picker__fullScreenPortal {
    background-color: #fff
}

.DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}
.transactions_Container__3Cg-T {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.transactions_Tools__3mN95 {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.transactions_PageFilterTools__3Hg2h {
    display: flex;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
    align-items: center;
}


.transactions_PageFilter__1YcNw {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
  
}

.transactions_FilterReportBtn__1Jpa9 {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.transactions_ToolsWrapper__2dmUF {
    height: 6rem;
    width: 100%;

}

.transactions_PrintToolsWrapper__cbXxF {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}



.transactions_AddNewMerchantBtn__3v4wH:focus,
.transactions_AddNewMerchantBtn__3v4wH:hover,
.transactions_AddNewMerchantBtn__3v4wH:active {
    background-color: rgb(51, 137, 255);
}


.transactions_ToolsDataExportIconWrapper__2TTRl {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.transactions_ToolsPrintIconWrapper__1jasA {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.transactions_FilterInput__1pKMj {
    margin-right: 2rem;
}

.transactions_FormGroup__1Mpxb {
    /* margin-bottom: 10px; */
}

.transactions_FormGroupDefault__1TRvE.transactions_has-error__2lh29 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.transactions_FormGroupDefault__1TRvE {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.transactions_FormGroupDefault__1TRvE label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.transactions_FormGroup__1Mpxb label:not(.transactions_error__161-X) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.transactions_FormControl__3P0-1 {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.transactions_MerchantTableWrapper__Q-fVf {
    margin-top: 3rem;
    white-space: nowrap;
}

.transactions_TableWrapper__2fBEl {
    display: flex;
}

.transactions_MerchantTable__39C0n {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.transactions_MerchantTable__39C0n th {
    text-align: left;
}

.transactions_MerchantTable__39C0n th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.transactions_MerchantTable__39C0n td,
.transactions_MerchantTable__39C0n th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.transactions_RevealMoreHeader__3mi9z {
    padding: 0;
    vertical-align: middle;
}





.transactions_Row__3A4dT {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.transactions_Row__3A4dT>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.transactions_dataTables_paginate__6FbJK {
    float: right;
}

.transactions_dataTables_paginate__6FbJK {
    /* margin-top: 25px !important; */
}

div.transactions_dataTables_paginate__6FbJK {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.transactions_dataTables_paginate__6FbJK ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.transactions_dataTables_paginate__6FbJK ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.transactions_dataTables_paginate__6FbJK ul>li.transactions_disabled__3_ejA a {
    opacity: .5;
}

.transactions_dataTables_paginate__6FbJK ul>li.transactions_disabled__3_ejA a {
    opacity: .35;
}

.transactions_dataTables_paginate__6FbJK ul>li.transactions_active__1czEd>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.transactions_dataTables_paginate__6FbJK ul>li.transactions_next__3Uyip>a,
.transactions_dataTables_paginate__6FbJK ul>li.transactions_prev__3JDWM>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.transactions_pg_arrow_right__1ZGuh {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.transactions_pg_arrow_left__iQj4Q {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.transactions_pg_arrow_right__1ZGuh:before {
    content: "\E631";
}

.transactions_pg_arrow_left__iQj4Q:before {
    content: "\E629";
}

.transactions_btn__mrfv- {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.transactions_btn_primary__183Rq,
.transactions_btn_primary__183Rq:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.transactions_btn_cons__3kQey {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.transactions_MerchantName__3RXjD {
    font-weight: 500;
}

.transactions_Icn__2CagK {
    cursor: pointer;
    /* fill:#000; */
}

.transactions_Icn__2CagK:hover {
    cursor: pointer;

}



.transactions_RevealMore__1n2b0 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.transactions_RevealMore__1n2b0:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.transactions_RevealMore__1n2b0 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.transactions_RevealMoreExpanded__143-m {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.transactions_Expandabled__JmJKr {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.transactions_Expandabled__JmJKr td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.transactions_MerchantTable__39C0n td:last-child {

    border-right: 1px solid #e8e8e8;
}


.transactions_MerchantTable__39C0n td:first-child {

    border-left: 1px solid #e8e8e8;
}


.transactions_MerchantRow__3A2YG {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.transactions_MerchantRow__3A2YG:hover {
    background-color: #267788;
    color: #fff;

}

.transactions_SelectedTransaction__2BfID {
    background-color: #267788;
    color: #fff;

}

.transactions_MerchantRow__3A2YG:hover a {
    color: #fff;
}

.transactions_MerchantRowExpanded__3UNDv {
    background: #267788;
    color: #fff;
}

.transactions_table__E3ozu.transactions_table-condensed__2RD8a.transactions_table-detailed__1YF1->tbody>tr.transactions_shown___hTH2>td {
    background: #fdf5df;
}


.transactions_Totals__jh7LU td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.transactions_MoneyTotalLabel__1nuC_ {
    float: right;

}

.transactions_TransactionDetail__3nbZA {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.transactions_TransactionDetailHeader__cyTIL {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.transactions_TransactionDetailBody__2s08Z {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
/* .Odd {
}

.Even {
} */

.details_MemberName__18YeG {
    font-weight: 500;
}

.details_Icn__CAzyY {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__CAzyY:hover {
    cursor: pointer;

}

.details_RevealMore__LUgTO {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__LUgTO:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.details_RevealMore__LUgTO div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__GBR_I {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .details_Expandabled__2IPmF {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__2IPmF td {
    padding: 2rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .details_MemberRow__1gnHI {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MemberRow__1gnHI:hover {
    background-color: #267788;
    color:#fff;

    }

    .details_MemberRow__1gnHI:hover a {
        color:#fff;
        }

    .details_MemberRowExpanded__JG8ZX {
        background: #267788;
        color:#fff;
    }

.details_table__jvif0.details_table-condensed__3pbcH.details_table-detailed__2QHuo > tbody > tr.details_shown__2RUxQ > td {
    background: #fdf5df;
}

.details_Container__djB-D {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.details_BreadcrumbWrapper__1e04q {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.details_Content__3lMi9 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.details_CardBody__1qfUd {
    margin-top: 1rem;
}

.details_Alert__2LUdr {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.details_Breadcrumb__3uC4G {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.details_Header__knsFK {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.details_ByLine__nvoof {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_Activity__1HRzA {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.details_WelcomeContainer__2vddi {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.details_MerchantProfile__1Eh0E {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.details_TabHeader__QpZgR {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.details_PageHeader__3_-2H {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.details_Header__knsFK {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.details_ExportReport__qlO5q {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.details_ExportIconWrapper__17Htb {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_EmailIconWrapper__EX-4Q {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_AddUser__3odMz {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.details_AddUserBtn__21qFz {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_Tools__2rrKA {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.details_PageFilter__25b7- {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}

.details_PrintToolsWrapper__gJAhW {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.details_FilterReportBtn__KfXB2 {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.details_AddNewMerchantBtn__IF9Or:focus,
.details_AddNewMerchantBtn__IF9Or:hover,
.details_AddNewMerchantBtn__IF9Or:active {
    background-color: rgb(51, 137, 255);
}

.details_ToolsDataExportIconWrapper__1EDeC {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.details_ToolsPrintIconWrapper__USEE2 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.details_FilterInput__CjX2e {
    margin-right: 2rem;
}

.details_FormGroupDefault__3hlF_.details_has-error__32Vfd {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__3hlF_ {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.details_FormGroupDefault__3hlF_ label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.details_FormGroup__3WaxO label:not(.details_error__11D84) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.details_FormControl__jX5gi {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.details_MerchantTableWrapper__2dOF_ {
    margin-top: 3rem;
    white-space: nowrap;
}

.details_MerchantTable__29lgJ {
    width: 100%;
    border-collapse: collapse;
}

.details_MerchantTable__29lgJ th {
    text-align: left;
}

.details_MerchantTable__29lgJ th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.details_MerchantTable__29lgJ td,
.details_MerchantTable__29lgJ th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__PDOfn {
    padding: 0;
    vertical-align: middle;
}

.details_Row__3YoKi {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.details_Row__3YoKi>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.details_dataTables_paginate__16CWw {
    float: right;
}

div.details_dataTables_paginate__16CWw {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.details_dataTables_paginate__16CWw ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

.details_dataTables_paginate__16CWw ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.details_dataTables_paginate__16CWw ul>li.details_disabled__ZcEuU a {
    opacity: .5;
}

.details_dataTables_paginate__16CWw ul>li.details_disabled__ZcEuU a {
    opacity: .35;
}

.details_dataTables_paginate__16CWw ul>li.details_active__2h5eV>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.details_dataTables_paginate__16CWw ul>li.details_next__16NZy>a,
.details_dataTables_paginate__16CWw ul>li.details_prev__2skCs>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__1Xubm {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__2VBzY {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__1Xubm:before {
    content: "\E631";
}

.details_pg_arrow_left__2VBzY:before {
    content: "\E629";
}

.details_btn__1NRdw {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.details_btn_primary__2rDm-,
.details_btn_primary__2rDm-:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.details_btn_cons__1n02P {
    min-width: 120px;
}

.details_MerchantName__1ZXrL {
    font-weight: 500;
}

.details_Icn__oACOt {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__oACOt:hover {
    cursor: pointer;

}



.details_RevealMore__dZ93m {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__dZ93m:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.details_RevealMore__dZ93m div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__2Ifw3 {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.details_Expandabled__u_J85 {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__u_J85 td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__29lgJ td:last-child {

    border-right: 1px solid #e8e8e8;
}


.details_MerchantTable__29lgJ td:first-child {

    border-left: 1px solid #e8e8e8;
}


.details_MerchantRow__1Q7vy {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MerchantRow__1Q7vy:hover {
    background-color: #267788;
    color: #fff;

}

.details_MerchantRow__1Q7vy:hover a {
    color: #fff;
}

.details_MerchantRowExpanded__3dz27 {
    background: #267788;
    color: #fff;
}

.details_table__1n5Wv.details_table-condensed__3vNcr.details_table-detailed__3crOo>tbody>tr.details_shown__29GYB>td {
    background: #fdf5df;
}


.details_Totals__3RMwP td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.details_MoneyTotalLabel__WsNu2 {
    float: right;

}

.details_FilterWrapper__2l3Wi {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.details_FilterPeriods__2auZE {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.details_FilterPeriods__2auZE li {
    font-weight: 600;

    padding-left: 2rem;
    line-height: 38px;
}

.details_FinancialsIntro__vrnvq {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_ExpandableView__8tlpU {
    background-color: transparent;
    /* font-family: Cambay; */
}

.details_Outlets__34tsR {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    /* margin-left: 0.6rem; */
}

.details_Outlets__34tsR li {
    display: inline-block;
        list-style: none;
}

.details_Outlet__3B7_K {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;}

.details_Outlet__3B7_K:hover {
color: #FFF;
    background: #5BA6E6;

}

.details_OutletName__3IQnW {
    font-weight: 600;
}

.details_OutletLocation__21Swu {
    font-weight: 400;

}

.details_ExpandableMember__3Ogir {
    float: left;
    text-decoration: none;
}

.details_ExpandableMemberName__MJEAL {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.details_OutletListArea__1hz6c {
    width: 100%;
    margin-top: 1rem;
    background: transparent;
}



.details_MemberContractDetailArea__3Bc-c {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem 0px;
    background: rgb(255, 255, 255);
}

.details_MemberContractDetailArea__3Bc-c header {
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.details_ExpandableMemberWrapper__1n5Te {
    background: transparent;
}

.details_clearfix__AhS22::before,
.details_clearfix__AhS22::after {
    display:table;
    content: " ";
}

.details_clearfix__AhS22::after {
clear:both;
}


.details_FuneralSchemeWrapper__2nKv- {
    margin: 0 2.5rem;
}

.details_AddNewMember__1ki4T {
float:right;
margin-right: 8.8rem;
}

.details_AddNewMemberBtn__2S9gx {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_FuneralSchemeTable___9H7_  {
    width: 100%;
    border-collapse: collapse;
}

.details_FuneralSchemeTable___9H7_ th {
    text-align: left;
}

.details_FuneralSchemeTable___9H7_ th {
    color: #666;
    vertical-align: top;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    vertical-align: middle;
}

.details_FuneralSchemeTable___9H7_ td,
.details_FuneralSchemeTable___9H7_ th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_FuneralSchemeTable___9H7_ .details_Totals__3RMwP td {   
     border-bottom: none;
     font-weight: 600;
}

.details_FuneralSchemeTable___9H7_ .details_MoneyTotalLabel__WsNu2 {
    float: right;

}
.details_FuneralSchemeTable___9H7_ .details_MoneyTotal__2tMnm {

}
.competitions_Container__31FUx {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.competitions_BreadcrumbWrapper__3OKji {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.competitions_Content__2zd5w {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.competitions_Alert__3PtX4 {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.competitions_Breadcrumb__2fJtX {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.competitions_Header__2EYsd {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.competitions_ByLine__2QWdn {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.competitions_Activity__2_-rC {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.competitions_WelcomeContainer__36jBy {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.competitions_MerchantProfile__3dZE9 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.competitions_TabHeader__21tmR {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.competitions_PageHeader__1ODR7 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.competitions_AddUser__2T0Hy {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.competitions_AddUserBtn__2ZS8U {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.competitions_ReportContainer__3waBn {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.competitions_ReportTiles__1JItH {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.competitions_TileContainer__1YzKK {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.competitions_Tile__1ebMp {
    cursor: pointer;
    padding: 4px;
}

.competitions_TileInner__2cGuG {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.PresetDateRangePicker_panel {
    padding: 0 22px 11px
}

.PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer
}

.PresetDateRangePicker_button:active {
    outline: 0
}

.PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
}

.SingleDatePickerInput {
    display: inline-block;
    background-color: #fff
}

.SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
}

.SingleDatePickerInput__rtl {
    direction: rtl
}

.SingleDatePickerInput__disabled {
    background-color: #f2f2f2
}

.SingleDatePickerInput__block {
    display: block
}

.SingleDatePickerInput__showClearDate {
    padding-right: 30px
}

.SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.SingleDatePickerInput_clearDate__small {
    padding: 6px
}

.SingleDatePickerInput_clearDate__hide {
    visibility: hidden
}

.SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.SingleDatePickerInput_clearDate_svg__small {
    height: 9px
}

.SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.SingleDatePicker {
    position: relative;
    display: inline-block
}

.SingleDatePicker__block {
    display: block
}

.SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.SingleDatePicker_picker__rtl {
    direction: rtl
}

.SingleDatePicker_picker__directionLeft {
    left: 0
}

.SingleDatePicker_picker__directionRight {
    right: 0
}

.SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
}

.SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_show {
    width: 33px;
    height: 26px;
    position: absolute;
    z-index: 2
}

.DayPickerKeyboardShortcuts_show::before {
    content: "";
    display: block;
    position: absolute
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topRight {
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight::before {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topLeft {
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid #008489
}

.DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: 5px
}

.DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
    text-align: left
}

.DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
}

.DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
}

.DayPickerKeyboardShortcuts_close:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
}

.CalendarDay {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center
}

.CalendarDay:active {
    outline: 0
}

.CalendarDay__defaultCursor {
    cursor: default
}

.CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff
}

.CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px solid #e4e7e7;
    color: inherit
}

.CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
}

.CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848
}

.CalendarDay__outside:hover {
    border: 0
}

.CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
}

.CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
}

.CalendarDay__selected_span {
    background: #66e2da;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #00a699;
    border: 1px double #00a699;
    color: #fff
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd
}

.CalendarDay__hovered_start_first_possible_end {
    background: #eceeee;
    border: 1px double #eceeee
}

.CalendarDay__hovered_start_blocked_min_nights {
    background: #eceeee;
    border: 1px double #e4e7e7
}

.CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    user-select: none
}

.CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
}

.CalendarMonth_verticalSpacing {
    border-collapse: separate
}

.CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial
}

.CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
}

.CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0
}

.CalendarMonthGrid__animating {
    z-index: 1
}

.CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
}

.CalendarMonthGrid__vertical {
    margin: 0 auto
}

.CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll
}

.CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
}

.CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
}

.CalendarMonthGrid_month__hidden {
    visibility: hidden
}

.DayPickerNavigation {
    position: relative;
    z-index: 2
}

.DayPickerNavigation__horizontal {
    height: 0
}

.DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
}

.DayPickerNavigation__verticalScrollableDefault {
    position: relative
}

.DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
}

.DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
}

.DayPickerNavigation_button__default:active {
    background: #f2f2f2
}

.DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:active {
    background: 0 0
}

.DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
}

.DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
}

.DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
}

.DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
}

.DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
}

.DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%
}

.DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
}

.DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848
}

.DayPickerNavigation_svg__disabled {
    fill: #f2f2f2
}

.DayPicker {
    background: #fff;
    position: relative;
    text-align: left
}

.DayPicker__horizontal {
    background: #fff
}

.DayPicker__verticalScrollable {
    height: 100%
}

.DayPicker__hidden {
    visibility: hidden
}

.DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
    border-radius: 3px
}

.DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
}

.DayPicker_portal__vertical {
    position: initial
}

.DayPicker_focusRegion {
    outline: 0
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
}

.DayPicker_weekHeaders {
    position: relative
}

.DayPicker_weekHeaders__horizontal {
    margin-left: 9px
}

.DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
}

.DayPicker_weekHeader__vertical {
    left: 50%
}

.DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
}

.DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
}

.DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
}

.DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px
}

.DayPicker_transitionContainer__horizontal {
    transition: height .2s ease-in-out
}

.DayPicker_transitionContainer__vertical {
    width: 100%
}

.DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll
}






.DateRangePickerInput {
    background-color: #fff;
    display: inline-block
}

.DateRangePickerInput__disabled {
    background: #f2f2f2
}

.DateRangePickerInput__withBorder {
    border-radius: 0;
    border: 1px solid #dbdbdb
}

.DateRangePickerInput__rtl {
    direction: rtl
}

.DateRangePickerInput__block {
    display: block
}

.DateRangePickerInput__showClearDates {
    padding-right: 30px
}

.DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
    padding: 5px;
}

.DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
}

.DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.DateRangePickerInput_clearDates__small {
    padding: 6px
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.DateRangePickerInput_clearDates__hide {
    visibility: hidden
}

.DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.DateRangePickerInput_clearDates_svg__small {
    height: 9px
}

.DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.DateRangePicker {
    position: relative;
    display: inline-block
}

.DateRangePicker__block {
    display: block
}

.DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
}

.DateRangePicker_picker__rtl {
    direction: rtl
}

.DateRangePicker_picker__directionLeft {
    left: 0
}

.DateRangePicker_picker__directionRight {
    right: 0
}

.DateRangePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.DateRangePicker_picker__fullScreenPortal {
    background-color: #fff
}

.DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}
.Transactions_Container__2ADJO {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.Transactions_Tools__IG4eJ {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.Transactions_PageFilterTools__1H6VI {
    display: flex;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
    align-items: center;
}


.Transactions_PageFilter__1lOOj {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
  
}

.Transactions_FilterReportBtn__ys19M {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.Transactions_ToolsWrapper__2PntL {
    height: 6rem;
    width: 100%;

}

.Transactions_PrintToolsWrapper__2gujb {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}



.Transactions_AddNewMerchantBtn__66yb2:focus,
.Transactions_AddNewMerchantBtn__66yb2:hover,
.Transactions_AddNewMerchantBtn__66yb2:active {
    background-color: rgb(51, 137, 255);
}


.Transactions_ToolsDataExportIconWrapper__2bLo_ {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.Transactions_ToolsPrintIconWrapper__3nLib {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.Transactions_FilterInput__QbvcM {
    margin-right: 2rem;
}

.Transactions_FormGroup__1HZEP {
    /* margin-bottom: 10px; */
}

.Transactions_FormGroupDefault__2lS1-.Transactions_has-error__3h88k {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.Transactions_FormGroupDefault__2lS1- {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.Transactions_FormGroupDefault__2lS1- label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.Transactions_FormGroup__1HZEP label:not(.Transactions_error__3D95k) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.Transactions_FormControl___ccxT {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.Transactions_MerchantTableWrapper__2Pi2I {
    margin-top: 3rem;
    white-space: nowrap;
}

.Transactions_TableWrapper__1zeFF {
    display: flex;
}

.Transactions_MerchantTable__3lQVv {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.Transactions_MerchantTable__3lQVv th {
    text-align: left;
}

.Transactions_MerchantTable__3lQVv th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.Transactions_MerchantTable__3lQVv td,
.Transactions_MerchantTable__3lQVv th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.Transactions_RevealMoreHeader__1PE3E {
    padding: 0;
    vertical-align: middle;
}





.Transactions_Row__16VlI {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.Transactions_Row__16VlI>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.Transactions_dataTables_paginate__IItNm {
    float: right;
}

.Transactions_dataTables_paginate__IItNm {
    /* margin-top: 25px !important; */
}

div.Transactions_dataTables_paginate__IItNm {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.Transactions_dataTables_paginate__IItNm ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.Transactions_dataTables_paginate__IItNm ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.Transactions_dataTables_paginate__IItNm ul>li.Transactions_disabled__2PvL1 a {
    opacity: .5;
}

.Transactions_dataTables_paginate__IItNm ul>li.Transactions_disabled__2PvL1 a {
    opacity: .35;
}

.Transactions_dataTables_paginate__IItNm ul>li.Transactions_active__1jvaI>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.Transactions_dataTables_paginate__IItNm ul>li.Transactions_next__3tzfR>a,
.Transactions_dataTables_paginate__IItNm ul>li.Transactions_prev__2xbae>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_right__dhp1_ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_left__2B8hJ {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Transactions_pg_arrow_right__dhp1_:before {
    content: "\E631";
}

.Transactions_pg_arrow_left__2B8hJ:before {
    content: "\E629";
}

.Transactions_btn__2oGaX {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.Transactions_btn_primary__2tU24,
.Transactions_btn_primary__2tU24:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.Transactions_btn_cons__1g3Q_ {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.Transactions_MerchantName__2oSta {
    font-weight: 500;
}

.Transactions_Icn__39Pv_ {
    cursor: pointer;
    /* fill:#000; */
}

.Transactions_Icn__39Pv_:hover {
    cursor: pointer;

}



.Transactions_RevealMore__2Yb0W {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.Transactions_RevealMore__2Yb0W:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.Transactions_RevealMore__2Yb0W div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.Transactions_RevealMoreExpanded__2wGtU {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Transactions_Expandabled__uN6Fq {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.Transactions_Expandabled__uN6Fq td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.Transactions_MerchantTable__3lQVv td:last-child {

    border-right: 1px solid #e8e8e8;
}


.Transactions_MerchantTable__3lQVv td:first-child {

    border-left: 1px solid #e8e8e8;
}


.Transactions_MerchantRow__1Cl-0 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.Transactions_MerchantRow__1Cl-0:hover {
    background-color: #267788;
    color: #fff;

}

.Transactions_SelectedTransaction__3SGFz {
    background-color: #267788;
    color: #fff;

}

.Transactions_MerchantRow__1Cl-0:hover a {
    color: #fff;
}

.Transactions_MerchantRowExpanded__2e_rD {
    background: #267788;
    color: #fff;
}

.Transactions_table__3Iv2x.Transactions_table-condensed__1HORP.Transactions_table-detailed__1EjoI>tbody>tr.Transactions_shown__3WFkD>td {
    background: #fdf5df;
}


.Transactions_Totals__15gj5 td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.Transactions_MoneyTotalLabel__3CyAk {
    float: right;

}

.Transactions_TransactionDetail__1fyxh {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.Transactions_TransactionDetailHeader__I19px {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.Transactions_TransactionDetailBody__368km {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
/* .Odd {
}

.Even {
} */

.details_MemberName__2qPEl {
    font-weight: 500;
}

.details_Icn__2kdlm {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__2kdlm:hover {
    cursor: pointer;

}

.details_RevealMore__gbUqp {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__gbUqp:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.details_RevealMore__gbUqp div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__1O9Vb {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .details_Expandabled__3lFZ1 {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__3lFZ1 td {
    padding: 2rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .details_MemberRow__3ItR4 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MemberRow__3ItR4:hover {
    background-color: #267788;
    color:#fff;

    }

    .details_MemberRow__3ItR4:hover a {
        color:#fff;
        }

    .details_MemberRowExpanded__fMd2X {
        background: #267788;
        color:#fff;
    }

.details_table__W8Ak-.details_table-condensed__22na1.details_table-detailed__4BnR3 > tbody > tr.details_shown__1U9V- > td {
    background: #fdf5df;
}

.details_Container__3v2V1 {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.details_BreadcrumbWrapper__3ANtV {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.details_Content__uQMVz {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.details_CardBody__3g--f {
    margin-top: 1rem;
}

.details_Alert__1tOH2 {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.details_Breadcrumb__2fznL {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.details_Header__1i7xw {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.details_ByLine__jBW0P {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_Activity__2I3bb {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.details_WelcomeContainer__2ASTQ {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.details_MerchantProfile__mJ1g5 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.details_TabHeader__1EMX1 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.details_PageHeader__3UzVo {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.details_Header__1i7xw {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.details_ExportReport__3qDBx {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.details_ExportIconWrapper__1V0yW {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_EmailIconWrapper__3hRwu {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_AddUser__3Mr2J {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.details_AddUserBtn__1PZm1 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_Tools__3gegV {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.details_PageFilter__-puqJ {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}

.details_PrintToolsWrapper__3LeMw {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.details_FilterReportBtn__EVTd6 {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.details_AddNewMerchantBtn__3Mhuz:focus,
.details_AddNewMerchantBtn__3Mhuz:hover,
.details_AddNewMerchantBtn__3Mhuz:active {
    background-color: rgb(51, 137, 255);
}

.details_ToolsDataExportIconWrapper__20hIL {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.details_ToolsPrintIconWrapper__6KTZR {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.details_FilterInput__2l1HP {
    margin-right: 2rem;
}

.details_FormGroupDefault__2uKGT.details_has-error__2qodC {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__2uKGT {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.details_FormGroupDefault__2uKGT label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.details_FormGroup__1IEGe label:not(.details_error__2YosN) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.details_FormControl__1btHF {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.details_MerchantTableWrapper__2V_ds {
    margin-top: 3rem;
    white-space: nowrap;
}

.details_MerchantTable__1QYYy {
    width: 100%;
    border-collapse: collapse;
}

.details_MerchantTable__1QYYy th {
    text-align: left;
}

.details_MerchantTable__1QYYy th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.details_MerchantTable__1QYYy td,
.details_MerchantTable__1QYYy th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__1vYqY {
    padding: 0;
    vertical-align: middle;
}

.details_Row__3z_nj {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.details_Row__3z_nj>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.details_dataTables_paginate__1TLSN {
    float: right;
}

div.details_dataTables_paginate__1TLSN {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.details_dataTables_paginate__1TLSN ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

.details_dataTables_paginate__1TLSN ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.details_dataTables_paginate__1TLSN ul>li.details_disabled__2Mqmb a {
    opacity: .5;
}

.details_dataTables_paginate__1TLSN ul>li.details_disabled__2Mqmb a {
    opacity: .35;
}

.details_dataTables_paginate__1TLSN ul>li.details_active__2ig3H>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.details_dataTables_paginate__1TLSN ul>li.details_next__1xKY7>a,
.details_dataTables_paginate__1TLSN ul>li.details_prev__AFYb->a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__1vLAh {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__3W-e5 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__1vLAh:before {
    content: "\E631";
}

.details_pg_arrow_left__3W-e5:before {
    content: "\E629";
}

.details_btn__3pQc- {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.details_btn_primary__ddSI5,
.details_btn_primary__ddSI5:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.details_btn_cons__2Q96a {
    min-width: 120px;
}

.details_MerchantName__2PE3y {
    font-weight: 500;
}

.details_Icn__ypIaj {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__ypIaj:hover {
    cursor: pointer;

}



.details_RevealMore__iGcUz {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__iGcUz:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.details_RevealMore__iGcUz div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__yQI9Z {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.details_Expandabled__3wrOE {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__3wrOE td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__1QYYy td:last-child {

    border-right: 1px solid #e8e8e8;
}


.details_MerchantTable__1QYYy td:first-child {

    border-left: 1px solid #e8e8e8;
}


.details_MerchantRow__2mWKL {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MerchantRow__2mWKL:hover {
    background-color: #267788;
    color: #fff;

}

.details_MerchantRow__2mWKL:hover a {
    color: #fff;
}

.details_MerchantRowExpanded__3ey5L {
    background: #267788;
    color: #fff;
}

.details_table__i2i0-.details_table-condensed__hQbs-.details_table-detailed__jU5Fi>tbody>tr.details_shown__1sVMs>td {
    background: #fdf5df;
}


.details_Totals__1eeuD td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.details_MoneyTotalLabel__l1xGL {
    float: right;

}

.details_FilterWrapper__1PO0e {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.details_FilterPeriods__2RoEo {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.details_FilterPeriods__2RoEo li {
    font-weight: 600;

    padding-left: 2rem;
    line-height: 38px;
}

.details_FinancialsIntro__34Sja {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_ExpandableView__1TMvS {
    background-color: transparent;
    /* font-family: Cambay; */
}

.details_Outlets__Wm_P1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    /* margin-left: 0.6rem; */
}

.details_Outlets__Wm_P1 li {
    display: inline-block;
        list-style: none;
}

.details_Outlet__ytLGU {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;}

.details_Outlet__ytLGU:hover {
color: #FFF;
    background: #5BA6E6;

}

.details_OutletName__1cbm2 {
    font-weight: 600;
}

.details_OutletLocation__3z9r4 {
    font-weight: 400;

}

.details_ExpandableMember__3XIlM {
    float: left;
    text-decoration: none;
}

.details_ExpandableMemberName__31cj1 {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.details_OutletListArea__3uWds {
    width: 100%;
    margin-top: 1rem;
    background: transparent;
}



.details_MemberContractDetailArea__rOMsr {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem 0px;
    background: rgb(255, 255, 255);
}

.details_MemberContractDetailArea__rOMsr header {
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.details_ExpandableMemberWrapper__QPPwQ {
    background: transparent;
}

.details_clearfix__oGrOf::before,
.details_clearfix__oGrOf::after {
    display:table;
    content: " ";
}

.details_clearfix__oGrOf::after {
clear:both;
}


.details_FuneralSchemeWrapper__2M9JJ {
    margin: 0 2.5rem;
}

.details_AddNewMember__2HuuR {
float:right;
margin-right: 8.8rem;
}

.details_AddNewMemberBtn__2Caye {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_FuneralSchemeTable__1aElW  {
    width: 100%;
    border-collapse: collapse;
}

.details_FuneralSchemeTable__1aElW th {
    text-align: left;
}

.details_FuneralSchemeTable__1aElW th {
    color: #666;
    vertical-align: top;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    vertical-align: middle;
}

.details_FuneralSchemeTable__1aElW td,
.details_FuneralSchemeTable__1aElW th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_FuneralSchemeTable__1aElW .details_Totals__1eeuD td {   
     border-bottom: none;
     font-weight: 600;
}

.details_FuneralSchemeTable__1aElW .details_MoneyTotalLabel__l1xGL {
    float: right;

}
.details_FuneralSchemeTable__1aElW .details_MoneyTotal__mpHW8 {

}
.deposits_Container__glF40 {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.deposits_BreadcrumbWrapper__174Ua {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.deposits_Content__1ecAX {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.deposits_Alert__3UUZ7 {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.deposits_Breadcrumb__TFjOg {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.deposits_Header__1nx-a {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.deposits_ByLine__3UWnj {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.deposits_Activity__3YZsi {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.deposits_WelcomeContainer__378sY {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.deposits_MerchantProfile__1lszz {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.deposits_TabHeader__1QT_L {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.deposits_PageHeader__2abwU {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.deposits_AddUser__1PxHD {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.deposits_AddUserBtn__1AooX {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.deposits_ReportContainer__2FRLZ {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.deposits_ReportTiles__2dKkV {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.deposits_TileContainer__KA7mF {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.deposits_Tile__A79Zk {
    cursor: pointer;
    padding: 4px;
}

.deposits_TileInner__3nwUV {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.PresetDateRangePicker_panel {
  padding: 0 22px 11px
}

.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer
}

.PresetDateRangePicker_button:active {
  outline: 0
}

.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699
}

.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff
}

.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}

.SingleDatePickerInput__rtl {
  direction: rtl
}

.SingleDatePickerInput__disabled {
  background-color: #f2f2f2
}

.SingleDatePickerInput__block {
  display: block
}

.SingleDatePickerInput__showClearDate {
  padding-right: 30px
}

.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%
}

.SingleDatePickerInput_clearDate__small {
  padding: 6px
}

.SingleDatePickerInput_clearDate__hide {
  visibility: hidden
}

.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}

.SingleDatePickerInput_clearDate_svg__small {
  height: 9px
}

.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}

.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}

.SingleDatePicker {
  position: relative;
  display: inline-block
}

.SingleDatePicker__block {
  display: block
}

.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}

.SingleDatePicker_picker__rtl {
  direction: rtl
}

.SingleDatePicker_picker__directionLeft {
  left: 0
}

.SingleDatePicker_picker__directionRight {
  right: 0
}

.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, .3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff
}

.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
  text-decoration: none
}

.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}

.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px
}

.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0
}

.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2
}

.DayPickerKeyboardShortcuts_show::before {
  content: "";
  display: block;
  position: absolute
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0
}

.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0
}

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #008489
}

.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px
}

.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left
}

.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0
}

.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px
}

.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2
}

.DayPickerKeyboardShortcuts_close:active {
  outline: 0
}

.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a
}

.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center
}

.CalendarDay:active {
  outline: 0
}

.CalendarDay__defaultCursor {
  cursor: default
}

.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff
}

.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit
}

.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit
}

.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848
}

.CalendarDay__outside:hover {
  border: 0
}

.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd
}

.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848
}

.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px double #33dacd;
  color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px double #33dacd;
  color: #fff
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px double #00a699;
  color: #fff
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px double #80e8e0;
  color: #007a87
}

.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px double #80e8e0;
  color: #007a87
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd
}

.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  border: 1px double #eceeee
}

.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border: 1px double #e4e7e7
}

.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  user-select: none
}

.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0
}

.CalendarMonth_verticalSpacing {
  border-collapse: separate
}

.CalendarMonth_caption {
  color: #484848;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial
}

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px
}

.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0
}

.CalendarMonthGrid__animating {
  z-index: 1
}

.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px
}

.CalendarMonthGrid__vertical {
  margin: 0 auto
}

.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll
}

.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%
}

.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none
}

.CalendarMonthGrid_month__hidden {
  visibility: hidden
}

.DayPickerNavigation {
  position: relative;
  z-index: 2
}

.DayPickerNavigation__horizontal {
  height: 0
}

.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0
}

.DayPickerNavigation__verticalScrollableDefault {
  position: relative
}

.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0
}

.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4
}

.DayPickerNavigation_button__default:active {
  background: #f2f2f2
}

.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:active {
  background: 0 0
}

.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: .78;
  border-radius: 3px;
  padding: 6px 9px
}

.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px
}

.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px
}

.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%
}

.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0
}

.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%
}

.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block
}

.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848
}

.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2
}

.DayPicker {
  background: #fff;
  position: relative;
  text-align: left
}

.DayPicker__horizontal {
  background: #fff
}

.DayPicker__verticalScrollable {
  height: 100%
}

.DayPicker__hidden {
  visibility: hidden
}

.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
  border-radius: 3px
}

.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%
}

.DayPicker_portal__vertical {
  position: initial
}

.DayPicker_focusRegion {
  outline: 0
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top
}

.DayPicker_weekHeaders {
  position: relative
}

.DayPicker_weekHeaders__horizontal {
  margin-left: 9px
}

.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left
}

.DayPicker_weekHeader__vertical {
  left: 50%
}

.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center
}

.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px
}

.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center
}

.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px
}

.DayPicker_transitionContainer__horizontal {
  transition: height .2s ease-in-out
}

.DayPicker_transitionContainer__vertical {
  width: 100%
}

.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll
}

.DateRangePickerInput {
  background-color: #fff;
  display: inline-block
}

.DateRangePickerInput__disabled {
  background: #f2f2f2
}

.DateRangePickerInput__withBorder {
  border-radius: 0;
  border: 1px solid #dbdbdb
}

.DateRangePickerInput__rtl {
  direction: rtl
}

.DateRangePickerInput__block {
  display: block
}

.DateRangePickerInput__showClearDates {
  padding-right: 30px
}

.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848;
  padding: 5px;
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px
}

.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.DateRangePickerInput_clearDates__small {
  padding: 6px
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%
}

.DateRangePickerInput_clearDates__hide {
  visibility: hidden
}

.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}

.DateRangePickerInput_clearDates_svg__small {
  height: 9px
}

.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}

.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}

.DateRangePicker {
  position: relative;
  display: inline-block
}

.DateRangePicker__block {
  display: block
}

.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}

.DateRangePicker_picker__rtl {
  direction: rtl
}

.DateRangePicker_picker__directionLeft {
  left: 0
}

.DateRangePicker_picker__directionRight {
  right: 0
}

.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, .3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff
}

.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
  text-decoration: none
}

.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.transactions_Container__1IZ88 {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.transactions_Tools__2D2yV {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.transactions_PageFilterTools__O9wkX {
    display: flex;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
    align-items: center;
}


.transactions_PageFilter__1Yjak {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
  
}

.transactions_FilterReportBtn__34igl {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.transactions_ToolsWrapper__27MVU {
    height: 6rem;
    width: 100%;

}

.transactions_PrintToolsWrapper__2NLVe {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}



.transactions_AddNewMerchantBtn__1EyFB:focus,
.transactions_AddNewMerchantBtn__1EyFB:hover,
.transactions_AddNewMerchantBtn__1EyFB:active {
    background-color: rgb(51, 137, 255);
}


.transactions_ToolsDataExportIconWrapper__2d4XY {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.transactions_ToolsPrintIconWrapper__1bovL {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.transactions_FilterInput__MM69y {
    margin-right: 2rem;
}

.transactions_FormGroup__1HlKm {
    /* margin-bottom: 10px; */
}

.transactions_FormGroupDefault__1wWfl.transactions_has-error__1b_Hc {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.transactions_FormGroupDefault__1wWfl {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.transactions_FormGroupDefault__1wWfl label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.transactions_FormGroup__1HlKm label:not(.transactions_error__3p8E8) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.transactions_FormControl__27A9s {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.transactions_MerchantTableWrapper__2VksO {
    margin-top: 3rem;
    white-space: nowrap;
}

.transactions_TableWrapper__2cnMh {
    display: flex;
}

.transactions_MerchantTable__30kBB {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.transactions_MerchantTable__30kBB th {
    text-align: left;
}

.transactions_MerchantTable__30kBB th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.transactions_MerchantTable__30kBB td,
.transactions_MerchantTable__30kBB th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.transactions_RevealMoreHeader__3auxa {
    padding: 0;
    vertical-align: middle;
}





.transactions_Row__3EtOA {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.transactions_Row__3EtOA>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.transactions_dataTables_paginate__2FdYZ {
    float: right;
}

.transactions_dataTables_paginate__2FdYZ {
    /* margin-top: 25px !important; */
}

div.transactions_dataTables_paginate__2FdYZ {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.transactions_dataTables_paginate__2FdYZ ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.transactions_dataTables_paginate__2FdYZ ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.transactions_dataTables_paginate__2FdYZ ul>li.transactions_disabled__1Ac0a a {
    opacity: .5;
}

.transactions_dataTables_paginate__2FdYZ ul>li.transactions_disabled__1Ac0a a {
    opacity: .35;
}

.transactions_dataTables_paginate__2FdYZ ul>li.transactions_active__33w_Q>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.transactions_dataTables_paginate__2FdYZ ul>li.transactions_next__2pEoV>a,
.transactions_dataTables_paginate__2FdYZ ul>li.transactions_prev__2Z7B9>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.transactions_pg_arrow_right__35CDO {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.transactions_pg_arrow_left__3NZEs {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.transactions_pg_arrow_right__35CDO:before {
    content: "\E631";
}

.transactions_pg_arrow_left__3NZEs:before {
    content: "\E629";
}

.transactions_btn__5LWhV {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.transactions_btn_primary__3S0wE,
.transactions_btn_primary__3S0wE:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.transactions_btn_cons__2JyFM {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.transactions_MerchantName__EDVxN {
    font-weight: 500;
}

.transactions_Icn__317KT {
    cursor: pointer;
    /* fill:#000; */
}

.transactions_Icn__317KT:hover {
    cursor: pointer;

}



.transactions_RevealMore__2mTuZ {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.transactions_RevealMore__2mTuZ:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.transactions_RevealMore__2mTuZ div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.transactions_RevealMoreExpanded__14jo5 {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.transactions_Expandabled__3mD4F {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.transactions_Expandabled__3mD4F td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.transactions_MerchantTable__30kBB td:last-child {

    border-right: 1px solid #e8e8e8;
}


.transactions_MerchantTable__30kBB td:first-child {

    border-left: 1px solid #e8e8e8;
}


.transactions_MerchantRow__Awp_1 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.transactions_MerchantRow__Awp_1:hover {
    background-color: #267788;
    color: #fff;

}

.transactions_SelectedTransaction__1RhiW {
    background-color: #267788;
    color: #fff;

}

.transactions_MerchantRow__Awp_1:hover a {
    color: #fff;
}

.transactions_MerchantRowExpanded__2d6C0 {
    background: #267788;
    color: #fff;
}

.transactions_table__1DcsE.transactions_table-condensed__okvgT.transactions_table-detailed__1Z8dB>tbody>tr.transactions_shown__Oh0MP>td {
    background: #fdf5df;
}


.transactions_Totals__1j1CY td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.transactions_MoneyTotalLabel__QFSDP {
    float: right;

}

.transactions_TransactionDetail__1rY_u {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.transactions_TransactionDetailHeader__dPKJi {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.transactions_TransactionDetailBody__Sgzrx {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
/* .Odd {
}

.Even {
} */

.details_MemberName__1EMEN {
    font-weight: 500;
}

.details_Icn__2SViV {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__2SViV:hover {
    cursor: pointer;

}

.details_RevealMore__3haUi {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__3haUi:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.details_RevealMore__3haUi div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__3rZ3i {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .details_Expandabled__2Q9I6 {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__2Q9I6 td {
    padding: 2rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .details_MemberRow__n8tFb {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MemberRow__n8tFb:hover {
    background-color: #267788;
    color:#fff;

    }

    .details_MemberRow__n8tFb:hover a {
        color:#fff;
        }

    .details_MemberRowExpanded__3LUue {
        background: #267788;
        color:#fff;
    }

.details_table__1NFOE.details_table-condensed__2cOED.details_table-detailed__1Pmq2 > tbody > tr.details_shown__w6cfg > td {
    background: #fdf5df;
}

.details_Container__LNSRJ {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.details_BreadcrumbWrapper__1FcB0 {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.details_Content__G69tT {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.details_CardBody__vmteQ {
    margin-top: 1rem;
}

.details_Alert__2rMsr {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.details_Breadcrumb__2R-v- {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.details_Header__1-sMh {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.details_ByLine__1vLZb {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_Activity__3DzU5 {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.details_WelcomeContainer__89zDx {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.details_MerchantProfile__H29pz {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.details_TabHeader__2CqFx {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.details_PageHeader__1u5Fp {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.details_Header__1-sMh {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.details_ExportReport__AK2lM {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.details_ExportIconWrapper__K6Z1m {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_EmailIconWrapper__2mUAR {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_AddUser__3GSll {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.details_AddUserBtn__1utOR {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_Tools__1x_3d {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.details_PageFilter__Tkoy3 {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}

.details_PrintToolsWrapper__17Apw {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.details_FilterReportBtn__mEoRV {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.details_AddNewMerchantBtn__3X786:focus,
.details_AddNewMerchantBtn__3X786:hover,
.details_AddNewMerchantBtn__3X786:active {
    background-color: rgb(51, 137, 255);
}

.details_ToolsDataExportIconWrapper__KwO-a {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.details_ToolsPrintIconWrapper__B3bNv {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.details_FilterInput__UHiK5 {
    margin-right: 2rem;
}

.details_FormGroupDefault__1H0tC.details_has-error__3OEvS {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__1H0tC {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.details_FormGroupDefault__1H0tC label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.details_FormGroup__2AAQA label:not(.details_error__1kgwW) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.details_FormControl__1guRC {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.details_MerchantTableWrapper__36leM {
    margin-top: 3rem;
    white-space: nowrap;
}

.details_MerchantTable__1q8Ob {
    width: 100%;
    border-collapse: collapse;
}

.details_MerchantTable__1q8Ob th {
    text-align: left;
}

.details_MerchantTable__1q8Ob th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.details_MerchantTable__1q8Ob td,
.details_MerchantTable__1q8Ob th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__2O6hR {
    padding: 0;
    vertical-align: middle;
}

.details_Row__3YNBz {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.details_Row__3YNBz>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.details_dataTables_paginate__3TC9O {
    float: right;
}

div.details_dataTables_paginate__3TC9O {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.details_dataTables_paginate__3TC9O ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

.details_dataTables_paginate__3TC9O ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.details_dataTables_paginate__3TC9O ul>li.details_disabled__3lUbo a {
    opacity: .5;
}

.details_dataTables_paginate__3TC9O ul>li.details_disabled__3lUbo a {
    opacity: .35;
}

.details_dataTables_paginate__3TC9O ul>li.details_active__2SFMl>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.details_dataTables_paginate__3TC9O ul>li.details_next__9Ei5q>a,
.details_dataTables_paginate__3TC9O ul>li.details_prev__1Bra3>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__1GwNS {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__2HWBT {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__1GwNS:before {
    content: "\E631";
}

.details_pg_arrow_left__2HWBT:before {
    content: "\E629";
}

.details_btn__54e_H {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.details_btn_primary__c67zO,
.details_btn_primary__c67zO:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.details_btn_cons__3lUa2 {
    min-width: 120px;
}

.details_MerchantName__31boE {
    font-weight: 500;
}

.details_Icn__VxJx- {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__VxJx-:hover {
    cursor: pointer;

}



.details_RevealMore__3VuR_ {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__3VuR_:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.details_RevealMore__3VuR_ div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__1L-en {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.details_Expandabled__2E5Be {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__2E5Be td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__1q8Ob td:last-child {

    border-right: 1px solid #e8e8e8;
}


.details_MerchantTable__1q8Ob td:first-child {

    border-left: 1px solid #e8e8e8;
}


.details_MerchantRow__1kTKD {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MerchantRow__1kTKD:hover {
    background-color: #267788;
    color: #fff;

}

.details_MerchantRow__1kTKD:hover a {
    color: #fff;
}

.details_MerchantRowExpanded__YWLF1 {
    background: #267788;
    color: #fff;
}

.details_table__3wpK9.details_table-condensed__2QJHN.details_table-detailed__axnv->tbody>tr.details_shown__3QtAz>td {
    background: #fdf5df;
}


.details_Totals__3Ly-T td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.details_MoneyTotalLabel__1SnDw {
    float: right;

}

.details_FilterWrapper__33UBT {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.details_FilterPeriods__2X2bv {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.details_FilterPeriods__2X2bv li {
    font-weight: 600;

    padding-left: 2rem;
    line-height: 38px;
}

.details_FinancialsIntro__30Xxh {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_ExpandableView__1Gkwr {
    background-color: transparent;
    /* font-family: Cambay; */
}

.details_Outlets__1MbBo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    /* margin-left: 0.6rem; */
}

.details_Outlets__1MbBo li {
    display: inline-block;
        list-style: none;
}

.details_Outlet__GNuW0 {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;}

.details_Outlet__GNuW0:hover {
color: #FFF;
    background: #5BA6E6;

}

.details_OutletName__287fT {
    font-weight: 600;
}

.details_OutletLocation__1iukR {
    font-weight: 400;

}

.details_ExpandableMember__3sCTq {
    float: left;
    text-decoration: none;
}

.details_ExpandableMemberName__2uefN {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.details_OutletListArea__17B7_ {
    width: 100%;
    margin-top: 1rem;
    background: transparent;
}



.details_MemberContractDetailArea__3cVo- {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem 0px;
    background: rgb(255, 255, 255);
}

.details_MemberContractDetailArea__3cVo- header {
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.details_ExpandableMemberWrapper__2aKB1 {
    background: transparent;
}

.details_clearfix__1-4dB::before,
.details_clearfix__1-4dB::after {
    display:table;
    content: " ";
}

.details_clearfix__1-4dB::after {
clear:both;
}


.details_FuneralSchemeWrapper__3EsJg {
    margin: 0 2.5rem;
}

.details_AddNewMember__3ABVu {
float:right;
margin-right: 8.8rem;
}

.details_AddNewMemberBtn__3moQm {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.details_FuneralSchemeTable__1S2DX  {
    width: 100%;
    border-collapse: collapse;
}

.details_FuneralSchemeTable__1S2DX th {
    text-align: left;
}

.details_FuneralSchemeTable__1S2DX th {
    color: #666;
    vertical-align: top;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    vertical-align: middle;
}

.details_FuneralSchemeTable__1S2DX td,
.details_FuneralSchemeTable__1S2DX th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_FuneralSchemeTable__1S2DX .details_Totals__3Ly-T td {   
     border-bottom: none;
     font-weight: 600;
}

.details_FuneralSchemeTable__1S2DX .details_MoneyTotalLabel__1SnDw {
    float: right;

}
.details_FuneralSchemeTable__1S2DX .details_MoneyTotal__2e5ur {

}
.Deposits_Container__3Wq0u {
  background-color: #fff;
  margin: 2.4rem;
  margin-bottom: 2.4rem;
  /* flex-grow: 1; */
  border: 1px solid #f1f3f4;
  /* display: flex; */
  flex-direction: column;
  font-size: 1.4rem;
}

.Deposits_Tools__142SY {
  display: flex;
  margin: 0 2.5rem;
  padding-top: 1.4rem;
}
.Deposits_Dropzone__32PSN {
  border: 1px solid rgba(100, 100, 100, 1);
  border-radius: 1px;
  background: linear-gradient(
    0deg,
    rgb(231, 231, 231) 68%,
    rgb(231, 231, 231) 68%
  );
  background-blend-mode: normal;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.09);
}

.Deposits_CenteredDiv__2oAH0 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
}

.Deposits_PageFilter__2RIBL {
  display: flex;
  flex: 1 1 50%;
  justify-content: flex-start;
  width: auto;
}

.Deposits_ToolsWrapper__3pKKi {
  height: 6rem;
  width: 100%;
}

.Deposits_PrintToolsWrapper__2l2jr {
  display: flex;
  flex: 1 1 50%;
  justify-content: flex-end;
  align-items: center;
}

.Deposits_AddNewMerchant__3tlhS {
  /* // font-family: Cambay; */
}

.Deposits_AddNewMerchantBtn__1HdNl {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.05rem;
  vertical-align: middle;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 108, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 1rem;
  padding-left: 17px;
  padding-right: 17px;
  text-decoration: none;
  border: none;
  display: block;
}

/* 
  text-align: center;
      padding: 0 3rem;
  
      background: #E8E8E8;
      color: #333;
  
      transition: all .2s;
      position: relative;
      font-size: 1.2rem;
      cursor: pointer;
      line-height: 4rem;
      border-radius: 2px; */

.Deposits_AddNewMerchantBtn__1HdNl:focus,
.Deposits_AddNewMerchantBtn__1HdNl:hover,
.Deposits_AddNewMerchantBtn__1HdNl:active {
  background-color: rgb(51, 137, 255);
}

.Deposits_ToolsDataExportIconWrapper__1PkfT {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.Deposits_ToolsPrintIconWrapper__1Lzfd {
  display: inline-block;
  margin: 0 3rem;
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.Deposits_FilterInput__2p_9E {
  margin-right: 2rem;
}

.Deposits_FormGroup__fC8gk {
  /* margin-bottom: 10px; */
}

.Deposits_FormGroupDefault__39_g9.Deposits_has-error__2ME5J {
  background-color: rgba(255, 0, 0, 0.05);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.Deposits_FormGroupDefault__39_g9 {
  /* background-color: #fff;
      position: relative;
      padding-top: 7px;
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 4px;
  
      -webkit-transition: background-color 0.2s ease;
      transition: background-color 0.2s ease; */
  /* padding: 1.4rem 2.5rem .2em .5rem; */
  line-height: 2rem;
  position: relative;
  transition: all 0.2s;
  z-index: 5;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e8e8e8;
  color: #e90052;
  min-height: 4rem;
}

label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.Deposits_FormGroupDefault__39_g9 label {
  margin: 0;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease;
}

.Deposits_FormGroup__fC8gk label:not(.Deposits_error__3TzA_) {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.Deposits_FormControl__2KB6D {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  box-shadow: none;
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.Deposits_MerchantTableWrapper__2KWXi {
  margin-top: 2.5rem;
  white-space: nowrap;
}

/* .TableWrapper {
      display: flex;
  } */

.Deposits_MerchantTable__2r-fC {
  flex: 1 1 70%;
  width: 100%;
  border-collapse: collapse;
}

.Deposits_MerchantTable__2r-fC th {
  text-align: left;
}

.Deposits_MerchantTable__2r-fC th {
  color: #666;
  vertical-align: top;
  font-size: 1.4rem;
  font-family: Roboto;
  font-weight: 500;
  vertical-align: middle;
}

.Deposits_MerchantTable__2r-fC td,
.Deposits_MerchantTable__2r-fC th {
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.Deposits_MerchantTable__2r-fC td:last-child {
  border-right: 1px solid #e8e8e8;
}

.Deposits_MerchantTable__2r-fC td:first-child {
  border-left: 1px solid #e8e8e8;
}

.Deposits_RevealMoreHeader__N4QmF {
  padding: 0;
  vertical-align: middle;
}

/* table .expandable {
      text-align: left;
      background: #e8e8e8;
      border-bottom: 5px solid #38003c;
      display: none;
      z-index: 500;
  } */

.Deposits_Row__1DqK2 {
  display: flex;
  flex-wrap: wrap;
  margin: 2.5rem 0;
}

.Deposits_Row__1DqK2 > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.Deposits_dataTables_paginate__3togO {
  float: right;
}

.Deposits_dataTables_paginate__3togO {
  /* margin-top: 25px !important; */
}

div.Deposits_dataTables_paginate__3togO {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

.Deposits_dataTables_paginate__3togO ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px;
  line-height: 24px;
}

/* a,
  a:focus,
  a:hover,
  a:active {
      outline: 0 !important;
      text-decoration: none;
      background-color: transparent;
  } */

.Deposits_dataTables_paginate__3togO ul > li > a {
  padding: 5px 10px;
  color: #575757;
  opacity: 0.35;
  transition: opacity 0.3s ease;
}

.Deposits_dataTables_paginate__3togO ul > li.Deposits_disabled__2udVD a {
  opacity: 0.5;
}

.Deposits_dataTables_paginate__3togO ul > li.Deposits_disabled__2udVD a {
  opacity: 0.35;
}

.Deposits_dataTables_paginate__3togO ul > li.Deposits_active__1m1Ll > a {
  font-weight: 700;
  color: #575757;
  /* opacity: 1; */
}

.Deposits_dataTables_paginate__3togO ul > li.Deposits_next__1bnnN > a,
.Deposits_dataTables_paginate__3togO ul > li.Deposits_prev__2uf-K > a {
  opacity: 1;
}

[class^="pg_"],
[class*=" pg_"] {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_right__1g8Si {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_left__9QnxL {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Deposits_pg_arrow_right__1g8Si:before {
  content: "\E631";
}

.Deposits_pg_arrow_left__9QnxL:before {
  content: "\E629";
}

.Deposits_btn__1gMej {
  font-family: Cambay, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  /* border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px; */
  background-image: none !important;
  color: #626262;
  background-color: #ffffff;
  text-shadow: none;
  box-shadow: none;
  line-height: 25px;
  padding: 5px 12px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s, background-color 0.1s linear 0s,
    opacity 0.2s linear 0s !important;
}

.Deposits_btn_primary__15v59,
.Deposits_btn_primary__15v59:focus {
  color: #ffffff;
  background-color: #6d5cae;
  border-color: #6d5cae;
}

.Deposits_btn_cons__3-mtj {
  min-width: 120px;
}

/* .MerchantTable tr.expanded + .expandable {
      display: table-row;
  } */

.Deposits_MerchantRow__2oUIN {
  background: #f8f8f8;
  color: rgb(0, 0, 0);
}

.Deposits_MerchantRow__2oUIN:hover {
  background-color: #267788;
  color: #fff;
}

.Deposits_MerchantRow__2oUIN:hover a {
  color: #fff;
}

.Deposits_MerchantRowExpanded__1pauQ {
  background: #267788;
  color: #fff;
}

.Deposits_table__mLpad.Deposits_table-condensed__3Dua6.Deposits_table-detailed__3kZkq > tbody > tr.Deposits_shown__3SsmT > td {
  background: #fdf5df;
}

.Deposits_SelectedDevice__3B-p_ {
  background-color: #267788;
  color: #fff;
}

.Deposits_DeviceDetail__3_ERy {
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  /* background-color: #f5f5f5;
      border-bottom: 1px solid #e0e0e0; */
}

.recons_Container__3wXUO {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.recons_BreadcrumbWrapper__1SG1t {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.recons_Content__2jEbh {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.recons_Alert__3YtIX {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.recons_Breadcrumb__1rpa8 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.recons_Header__2fdDc {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.recons_ByLine__3XQ_G {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.recons_Activity__2bH6G {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.recons_WelcomeContainer__3hgdW {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.recons_MerchantProfile__2gBG8 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.recons_TabHeader__1X65n {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.recons_PageHeader__3oKtg {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.recons_AddUser__2_FZt {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.recons_AddUserBtn__3sFtg {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.recons_ReportContainer__3hShx {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.recons_ReportTiles__28x_e {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.recons_TileContainer__51RhG {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.recons_Tile__2jO9t {
    cursor: pointer;
    padding: 4px;
}

.recons_TileInner__2xXaL {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.PresetDateRangePicker_panel {
    padding: 0 22px 11px
}

.PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer
}

.PresetDateRangePicker_button:active {
    outline: 0
}

.PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
}

.SingleDatePickerInput {
    display: inline-block;
    background-color: #fff
}

.SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
}

.SingleDatePickerInput__rtl {
    direction: rtl
}

.SingleDatePickerInput__disabled {
    background-color: #f2f2f2
}

.SingleDatePickerInput__block {
    display: block
}

.SingleDatePickerInput__showClearDate {
    padding-right: 30px
}

.SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.SingleDatePickerInput_clearDate__small {
    padding: 6px
}

.SingleDatePickerInput_clearDate__hide {
    visibility: hidden
}

.SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.SingleDatePickerInput_clearDate_svg__small {
    height: 9px
}

.SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.SingleDatePicker {
    position: relative;
    display: inline-block
}

.SingleDatePicker__block {
    display: block
}

.SingleDatePicker_picker {
    z-index: 5;
    background-color: #fff;
    position: absolute
}

.SingleDatePicker_picker__rtl {
    direction: rtl
}

.SingleDatePicker_picker__directionLeft {
    left: 0
}

.SingleDatePicker_picker__directionRight {
    right: 0
}

.SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
}

.SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_show {
    width: 33px;
    height: 26px;
    position: absolute;
    z-index: 2
}

.DayPickerKeyboardShortcuts_show::before {
    content: "";
    display: block;
    position: absolute
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topRight {
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight::before {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
}

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid #008489
}

.DayPickerKeyboardShortcuts_show__topLeft {
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid #008489
}

.DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: 5px
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: 5px
}

.DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
    text-align: left
}

.DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
}

.DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
}

.DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
}

.DayPickerKeyboardShortcuts_close:active {
    outline: 0
}

.DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
}

.CalendarDay {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    z-index: 10;

}

.CalendarDay:active {
    outline: 0
}

.CalendarDay__defaultCursor {
    cursor: default
}

.CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff;
    z-index: 10;

}

.CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px solid #e4e7e7;
    color: inherit
}

.CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
}

.CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848;
    z-index: 10;

}

.CalendarDay__outside:hover {
    border: 0
}

.CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
}

.CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
}

.CalendarDay__selected_span {
    background: #66e2da;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px double #33dacd;
    color: #fff
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #00a699;
    border: 1px double #00a699;
    color: #fff
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 1px double #80e8e0;
    color: #007a87
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd
}

.CalendarDay__hovered_start_first_possible_end {
    background: #eceeee;
    border: 1px double #eceeee
}

.CalendarDay__hovered_start_blocked_min_nights {
    background: #eceeee;
    border: 1px double #e4e7e7
}

.CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    user-select: none
}

.CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
}

.CalendarMonth_verticalSpacing {
    border-collapse: separate
}

.CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial
}

.CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
}

.CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 1
}

.CalendarMonthGrid__animating {
    z-index: 1
}

.CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
}

.CalendarMonthGrid__vertical {
    margin: 0 auto
}

.CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll
}

.CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%;
    z-index: 10;

}

.CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    z-index: 10;

}

.CalendarMonthGrid_month__hidden {
    visibility: hidden
}

.DayPickerNavigation {
    position: relative;
    z-index: 2
}

.DayPickerNavigation__horizontal {
    height: 0
}

.DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
}

.DayPickerNavigation__verticalScrollableDefault {
    position: relative
}

.DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
}

.DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
}

.DayPickerNavigation_button__default:active {
    background: #f2f2f2
}

.DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
    border: 1px solid #f2f2f2
}

.DayPickerNavigation_button__disabled:active {
    background: 0 0
}

.DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
}

.DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
}

.DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
}

.DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%
}

.DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
}

.DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%
}

.DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
}

.DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848
}

.DayPickerNavigation_svg__disabled {
    fill: #f2f2f2
}

.DayPicker {
    background: #fff;
    position: relative;
    text-align: left
}

.DayPicker__horizontal {
    background: #fff
}

.DayPicker__verticalScrollable {
    height: 100%
}

.DayPicker__hidden {
    visibility: hidden
}

.DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .07);
    border-radius: 3px
}

.DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
}

.DayPicker_portal__vertical {
    position: initial
}

.DayPicker_focusRegion {
    outline: 0;
    z-index: 10;

}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top;
    z-index: 10;

}

.DayPicker_weekHeaders {
    position: relative
}

.DayPicker_weekHeaders__horizontal {
    margin-left: 9px
}

.DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
}

.DayPicker_weekHeader__vertical {
    left: 50%
}

.DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
}

.DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
}

.DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
}

.DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px
}

.DayPicker_transitionContainer__horizontal {
    transition: height .2s ease-in-out
}

.DayPicker_transitionContainer__vertical {
    width: 100%
}

.DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll;
    z-index: 10;

}

.DateRangePickerInput {
    background-color: #fff;
    display: inline-block;
    z-index: 10;

}

.DateRangePickerInput__disabled {
    background: #f2f2f2
}

.DateRangePickerInput__withBorder {
    border-radius: 0;
    border: 1px solid #dbdbdb
}

.DateRangePickerInput__rtl {
    direction: rtl
}

.DateRangePickerInput__block {
    display: block
}

.DateRangePickerInput__showClearDates {
    padding-right: 30px
}

.DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
    padding: 5px;
}

.DateRangePickerInput_arrow_svg {

    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
}

.DateRangePickerInput_clearDates {
    z-index: 1;
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.DateRangePickerInput_clearDates__small {
    padding: 6px
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
}

.DateRangePickerInput_clearDates__hide {
    z-index: 1;
    visibility: hidden
}

.DateRangePickerInput_clearDates_svg {
    z-index: 1;
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
}

.DateRangePickerInput_clearDates_svg__small {
    z-index: 1;
    height: 9px
}

.DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
}

.DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
}

.DateRangePicker {
    z-index: 10;
    position: relative;
    display: inline-block
}

.DateRangePicker__block {
    z-index: 10;
    display: block
}

.DateRangePicker_picker {
    z-index: 10;
    background-color: #fff;
    position: absolute
}

.DateRangePicker_picker__rtl {
    z-index: 10;
    direction: rtl
}

.DateRangePicker_picker__directionLeft {
    left: 0;
    z-index: 10;

}

.DateRangePicker_picker__directionRight {
    right: 0;
    z-index: 10;

}

.DateRangePicker_picker__portal {
    z-index: 10;
    background-color: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
}

.DateRangePicker_picker__fullScreenPortal {
    background-color: #fff;
    z-index: 10;
    
}

.DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none
}

.DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
}

/* class="DateRangePicker_picker DateRangePicker_picker_1 DateRangePicker_picker__directionLeft DateRangePicker_picker__directionLeft_2" */
.transactions_Container__19Bnt {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.transactions_Tools__2YR-z {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.transactions_PageFilterTools__24D2j {
    display: flex;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
    align-items: center;
}


.transactions_PageFilter__1kTKA {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
  
}

.transactions_FilterReportBtn__zTBK2 {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.transactions_ToolsWrapper__356R_ {
    height: 6rem;
    width: 100%;

}

.transactions_PrintToolsWrapper__2aZ_K {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}



.transactions_AddNewMerchantBtn__1d4-t:focus,
.transactions_AddNewMerchantBtn__1d4-t:hover,
.transactions_AddNewMerchantBtn__1d4-t:active {
    background-color: rgb(51, 137, 255);
}


.transactions_ToolsDataExportIconWrapper__37qqx {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.transactions_ToolsPrintIconWrapper__2vdhl {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.transactions_FilterInput__ulRGF {
    margin-right: 2rem;
    z-index: 5;

}

.transactions_FormGroup__1MrLx {
    /* margin-bottom: 10px; */
    z-index: 5;

}

.transactions_FormGroupDefault__bHUlX.transactions_has-error__22kmb {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.transactions_FormGroupDefault__bHUlX {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
    z-index: 5;

}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.transactions_FormGroupDefault__bHUlX label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
    z-index: 5;

}

.transactions_FormGroup__1MrLx label:not(.transactions_error__3Li0t) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.transactions_FormControl__3ybDk {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
    z-index: 5;

}

.transactions_MerchantTableWrapper__2EMHW {
    margin-top: 3rem;
    white-space: nowrap;
}

.transactions_TableWrapper__1ze_n {
    display: flex;
}

.transactions_MerchantTable__jXfRE {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.transactions_MerchantTable__jXfRE th {
    text-align: left;
}

.transactions_MerchantTable__jXfRE th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.transactions_MerchantTable__jXfRE td,
.transactions_MerchantTable__jXfRE th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.transactions_RevealMoreHeader__1hB-4 {
    padding: 0;
    vertical-align: middle;
}





.transactions_Row__2bzW_ {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.transactions_Row__2bzW_>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.transactions_dataTables_paginate__UAJrj {
    float: right;
}

.transactions_dataTables_paginate__UAJrj {
    /* margin-top: 25px !important; */
}

div.transactions_dataTables_paginate__UAJrj {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.transactions_dataTables_paginate__UAJrj ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.transactions_dataTables_paginate__UAJrj ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.transactions_dataTables_paginate__UAJrj ul>li.transactions_disabled__3Zng9 a {
    opacity: .5;
}

.transactions_dataTables_paginate__UAJrj ul>li.transactions_disabled__3Zng9 a {
    opacity: .35;
}

.transactions_dataTables_paginate__UAJrj ul>li.transactions_active__3iCI7>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.transactions_dataTables_paginate__UAJrj ul>li.transactions_next__1a4j_>a,
.transactions_dataTables_paginate__UAJrj ul>li.transactions_prev__15Uv8>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.transactions_pg_arrow_right__3OfqO {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.transactions_pg_arrow_left__YV_kP {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.transactions_pg_arrow_right__3OfqO:before {
    content: "\E631";
}

.transactions_pg_arrow_left__YV_kP:before {
    content: "\E629";
}

.transactions_btn__1xYgh {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.transactions_btn_primary__2nCou,
.transactions_btn_primary__2nCou:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.transactions_btn_cons__2ZHat {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.transactions_MerchantName__2ZAMQ {
    font-weight: 500;
}

.transactions_Icn__3c5RJ {
    cursor: pointer;
    /* fill:#000; */
}

.transactions_Icn__3c5RJ:hover {
    cursor: pointer;

}



.transactions_RevealMore__2p4bP {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.transactions_RevealMore__2p4bP:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.transactions_RevealMore__2p4bP div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.transactions_RevealMoreExpanded__3tAgO {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.transactions_Expandabled__2fStL {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.transactions_Expandabled__2fStL td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.transactions_MerchantTable__jXfRE td:last-child {

    border-right: 1px solid #e8e8e8;
}


.transactions_MerchantTable__jXfRE td:first-child {

    border-left: 1px solid #e8e8e8;
}


.transactions_MerchantRow__1PxTb {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.transactions_MerchantRow__1PxTb:hover {
    background-color: #267788;
    color: #fff;

}

.transactions_SelectedTransaction__33op0 {
    background-color: #267788;
    color: #fff;

}

.transactions_MerchantRow__1PxTb:hover a {
    color: #fff;
}

.transactions_MerchantRowExpanded__2semi {
    background: #267788;
    color: #fff;
}

.transactions_table__1yYSm.transactions_table-condensed__2Ffu9.transactions_table-detailed__3stvs>tbody>tr.transactions_shown__24E5v>td {
    background: #fdf5df;
}


.transactions_Totals__D-9FS td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.transactions_MoneyTotalLabel__2gjCj {
    float: right;

}

.transactions_TransactionDetail__25XcH {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.transactions_TransactionDetailHeader__1o6i5 {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.transactions_TransactionDetailBody__11liV {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
/* .Odd {
}

.Even {
} */

.details_MemberName__2k82G {
    font-weight: 500;
}

.details_Icn__9ayfZ {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__9ayfZ:hover {
    cursor: pointer;

}

.details_RevealMore__1DbB1 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__1DbB1:hover {
    background: transparent;
    color:#707070;
    fill:#fff;
    }

.details_RevealMore__1DbB1 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__1LlAi {
    background: transparent;
    fill:#fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

 .details_Expandabled__2lzUU {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__2lzUU td {
    padding: 2rem ;
    border-bottom: 1px solid #e8e8e8;
}




 .details_MemberRow__bKXkQ {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MemberRow__bKXkQ:hover {
    background-color: #267788;
    color:#fff;

    }

    .details_MemberRow__bKXkQ:hover a {
        color:#fff;
        }

    .details_MemberRowExpanded__p2uka {
        background: #267788;
        color:#fff;
    }

.details_table__3kdMy.details_table-condensed__2j5Gg.details_table-detailed__fPg3_ > tbody > tr.details_shown__38HPU > td {
    background: #fdf5df;
}

.triggers_Container__27rsh {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.triggers_BreadcrumbWrapper__1KmsY {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.triggers_Content__tSqZf {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.triggers_Alert__x6XK0 {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.triggers_Breadcrumb__3oPM5 {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.triggers_Header__1OGv9 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.triggers_ByLine__NGUm9 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.triggers_Activity__3b4Uk {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.triggers_WelcomeContainer__1QStX {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.triggers_MerchantProfile__187UK {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.triggers_TabHeader__1GB6R {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.triggers_PageHeader__1N6_A {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.triggers_AddUser__2mqAV {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.triggers_AddUserBtn__2nrE5 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.triggers_ReportContainer__3n71t {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.triggers_ReportTiles__oLa48 {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.triggers_TileContainer__1UhfZ {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.triggers_Tile__1idnP {
    cursor: pointer;
    padding: 4px;
}

.triggers_TileInner__1mWWz {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.triggers_Container__ucJGL {
    background-color: #fff;
    /* margin: 2.4rem;
    margin-bottom: 2.4rem; */
    /* flex-grow: 1; */
    /* border-top: 1px solid #707070; */
    /* display: flex; */
    flex-direction: column;
    font-size: 1.4rem;
}

.triggers_Tools__3Hh-4 {
    display: flex;
    /* margin: 0 2.5rem; */
    padding-top: 1.4rem;
}



.triggers_PageFilterTools__34nFT {
    display: flex;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
    align-items: center;
}


.triggers_PageFilter__1Y8Fl {
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
    padding-left: 2rem;
  
}

.triggers_FilterReportBtn__2fRuv {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 10px;
    text-decoration: none;
    border: none;
    display: block;

}

.triggers_ToolsWrapper__2S4NJ {
    height: 6rem;
    width: 100%;

}

.triggers_PrintToolsWrapper__g7QgE {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}



.triggers_AddNewMerchantBtn__3kkWC:focus,
.triggers_AddNewMerchantBtn__3kkWC:hover,
.triggers_AddNewMerchantBtn__3kkWC:active {
    background-color: rgb(51, 137, 255);
}


.triggers_ToolsDataExportIconWrapper__bcPdP {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.triggers_ToolsPrintIconWrapper__aOz48 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.triggers_FilterInput__3KrUL {
    margin-right: 2rem;
}

.triggers_FormGroup__2UR0x {
    /* margin-bottom: 10px; */
}

.triggers_FormGroupDefault__3rA1J.triggers_has-error__20esV {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.triggers_FormGroupDefault__3rA1J {
    /* background-color: #fff;
    position: relative;
    padding-top: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;

    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease; */
    /* padding: 1.4rem 2.5rem .2em .5rem; */
    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.triggers_FormGroupDefault__3rA1J label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.triggers_FormGroup__2UR0x label:not(.triggers_error__3a7L7) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.triggers_FormControl__39KlE {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.triggers_MerchantTableWrapper__nhGio {
    margin-top: 3rem;
    white-space: nowrap;
}

.triggers_TableWrapper__3HPR4 {
    display: flex;
}

.triggers_MerchantTable__3AYxZ {
    flex: 1 1 70%;
    width: 100%;
    border-collapse: collapse;
}

.triggers_MerchantTable__3AYxZ th {
    text-align: left;
}

.triggers_MerchantTable__3AYxZ th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.triggers_MerchantTable__3AYxZ td,
.triggers_MerchantTable__3AYxZ th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

/* .MerchantTable td:first-child {

    border-left: 1px solid #e8e8e8;
}
.MerchantTable td:last-child {

    border-right: 1px solid #e8e8e8;
} */

.triggers_RevealMoreHeader__RpIPc {
    padding: 0;
    vertical-align: middle;
}





.triggers_Row__1jInv {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.triggers_Row__1jInv>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.triggers_dataTables_paginate__3zJpH {
    float: right;
}

.triggers_dataTables_paginate__3zJpH {
    /* margin-top: 25px !important; */
}

div.triggers_dataTables_paginate__3zJpH {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.triggers_dataTables_paginate__3zJpH ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

/* a,
a:focus,
a:hover,
a:active {
    outline: 0 !important;
    text-decoration: none;
    background-color: transparent;
} */

.triggers_dataTables_paginate__3zJpH ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.triggers_dataTables_paginate__3zJpH ul>li.triggers_disabled__23vAb a {
    opacity: .5;
}

.triggers_dataTables_paginate__3zJpH ul>li.triggers_disabled__23vAb a {
    opacity: .35;
}

.triggers_dataTables_paginate__3zJpH ul>li.triggers_active__1uUP7>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.triggers_dataTables_paginate__3zJpH ul>li.triggers_next__GulYf>a,
.triggers_dataTables_paginate__3zJpH ul>li.triggers_prev__12Yqe>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.triggers_pg_arrow_right__3w7Vi {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.triggers_pg_arrow_left__20f55 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.triggers_pg_arrow_right__3w7Vi:before {
    content: "\E631";
}

.triggers_pg_arrow_left__20f55:before {
    content: "\E629";
}

.triggers_btn__2JSzc {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    /* border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px; */
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.triggers_btn_primary__18n_s,
.triggers_btn_primary__18n_s:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.triggers_btn_cons__zj2v6 {
    min-width: 120px;
}


/* .MerchantTable tr.expanded + .expandable {
    display: table-row;
} */


/* .Odd {
}

.Even {
} */

.triggers_MerchantName__3Ifi- {
    font-weight: 500;
}

.triggers_Icn__1LM3a {
    cursor: pointer;
    /* fill:#000; */
}

.triggers_Icn__1LM3a:hover {
    cursor: pointer;

}



.triggers_RevealMore__3m9hr {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.triggers_RevealMore__3m9hr:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.triggers_RevealMore__3m9hr div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.triggers_RevealMoreExpanded__2HI8b {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.triggers_Expandabled__xvN8i {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.triggers_Expandabled__xvN8i td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.triggers_MerchantTable__3AYxZ td:last-child {

    border-right: 1px solid #e8e8e8;
}


.triggers_MerchantTable__3AYxZ td:first-child {

    border-left: 1px solid #e8e8e8;
}


.triggers_MerchantRow__1DnY5 {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.triggers_MerchantRow__1DnY5:hover {
    background-color: #267788;
    color: #fff;

}

.triggers_SelectedTransaction__2Pfd4 {
    background-color: #267788;
    color: #fff;

}

.triggers_MerchantRow__1DnY5:hover a {
    color: #fff;
}

.triggers_MerchantRowExpanded__2tUxF {
    background: #267788;
    color: #fff;
}

.triggers_table__2hS7B.triggers_table-condensed__175Vb.triggers_table-detailed__IkD2k>tbody>tr.triggers_shown__1SJnj>td {
    background: #fdf5df;
}


.triggers_Totals__2ltEs td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.triggers_MoneyTotalLabel__3TkPT {
    float: right;

}

.triggers_TransactionDetail__2y8Hp {
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    /* background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0; */

}

.triggers_TransactionDetailHeader__2mNI9 {

    padding: 0;
    vertical-align: middle;
    height: 4rem;
    background-color: #fff;
    border: none;

}

.triggers_TransactionDetailBody__2x9Oc {
    flex: 1 1 auto;
    padding: 0;
    vertical-align: middle;
    overflow: hidden;
    padding: 2rem;
    background-color: transparent;
    border: 1px solid #e0e0e0;
}
.pettycash_Container__X0KnJ {
    /* display: flex; */
    flex-direction: column;
    /* justify-content: flex-start; */
    height: 100%;
}

.pettycash_BreadcrumbWrapper__2zD8q {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.pettycash_Content__3048j {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.pettycash_Alert__3MQ0V {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.pettycash_Breadcrumb__1MB_D {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.pettycash_Header__3xt25 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.pettycash_ByLine__3CK7- {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.pettycash_Activity__2jHUL {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.pettycash_WelcomeContainer__2o6GJ {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.pettycash_MerchantProfile__1psS4 {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}



.pettycash_TabHeader__1i2dB {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}


.pettycash_PageHeader__17vx2 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.pettycash_AddUser__2YNIO {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.pettycash_AddUserBtn__2lt0b {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}


.pettycash_ReportContainer__3aHpI {
    margin: 10px 0;
    text-align: -webkit-center;
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

.pettycash_ReportTiles__u9WA5 {
    display: flex;
    flex-wrap: wrap;
    font-size: 0;
    /* justify-content: center; */
    max-width: 100%;
    opacity: 1;
    position: static;
    text-align: -webkit-auto;
    transition: opacity 300ms;
    -webkit-user-select: none;
            user-select: none;
}

.pettycash_TileContainer__3jYRn {
    border-radius: 4px;
    box-sizing: border-box;
    height: 11.2rem;
    margin-bottom: var(--md-tile-margin);
    opacity: 1;
    position: relative;
    transition-property: background, background-color, border-color, box-shadow, opacity, -webkit-filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter;
    transition-property: background, background-color, border-color, box-shadow, opacity, filter, -webkit-filter;
    width: 11.2rem;
}

.pettycash_Tile__P4WGQ {
    cursor: pointer;
    padding: 4px;
}

.pettycash_TileInner__2oPYK {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    /* z-index: -1; */
}
.transactions_Container__1Wx83 {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.transactions_BreadcrumbWrapper__tFXWy {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.transactions_Content__OsX7d {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.transactions_CardBody__2rvLc {
    margin-top: 1rem;
}

.transactions_Alert__Gxd5c {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.transactions_Breadcrumb__20-u_ {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.transactions_Header__8lBWK {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.transactions_ByLine__2_oN5 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.transactions_Activity__2FYYI {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.transactions_WelcomeContainer__3RWRX {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.transactions_MerchantProfile__2kaap {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.transactions_TabHeader__1Jfpo {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.transactions_PageHeader__2lXav {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.transactions_Header__8lBWK {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.transactions_ExportReport__21NRx {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.transactions_ExportIconWrapper__3eFfV {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.transactions_EmailIconWrapper__2YiBQ {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.transactions_AddUser__3eDrS {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.transactions_AddUserBtn__2KM-F {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.transactions_Tools__1oJHS {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.transactions_PageFilter__29ROD {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.transactions_PrintToolsWrapper__3p-dF {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.transactions_FilterReportBtn__3VYeM {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.transactions_AddNewMerchantBtn__1QU6l:focus,
.transactions_AddNewMerchantBtn__1QU6l:hover,
.transactions_AddNewMerchantBtn__1QU6l:active {
    background-color: rgb(51, 137, 255);
}


.transactions_ToolsDataExportIconWrapper__ylbQh {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.transactions_ToolsPrintIconWrapper__32RC6 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.transactions_FilterInput__10I0y {
    margin-right: 2rem;
}

.transactions_FormGroupDefault__3eQz7.transactions_has-error__1_Cab {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.transactions_FormGroupDefault__3eQz7 {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.transactions_FormGroupDefault__3eQz7 label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.transactions_FormGroup__3wqAC label:not(.transactions_error__3pCWK) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.transactions_FormControl__3Clpv {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.transactions_MerchantTableWrapper__RUjCA {
    margin-top: 3rem;
    white-space: nowrap;
}

.transactions_MerchantTable__1f5G0 {
    width: 100%;
    border-collapse: collapse;
}

.transactions_MerchantTable__1f5G0 th {
    text-align: left;
}

.transactions_MerchantTable__1f5G0 th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.transactions_MerchantTable__1f5G0 td,
.transactions_MerchantTable__1f5G0 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.transactions_RevealMoreHeader__3cdvb {
    padding: 0;
    vertical-align: middle;
}

.transactions_Row__3I3qM {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.transactions_Row__3I3qM>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.transactions_dataTables_paginate__2ankg {
    float: right;
}

div.transactions_dataTables_paginate__2ankg {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.transactions_dataTables_paginate__2ankg ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.transactions_dataTables_paginate__2ankg ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.transactions_dataTables_paginate__2ankg ul>li.transactions_disabled__2WXMp a {
    opacity: .5;
}

.transactions_dataTables_paginate__2ankg ul>li.transactions_disabled__2WXMp a {
    opacity: .35;
}

.transactions_dataTables_paginate__2ankg ul>li.transactions_active__FpREy>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.transactions_dataTables_paginate__2ankg ul>li.transactions_next__1dhsV>a,
.transactions_dataTables_paginate__2ankg ul>li.transactions_prev__25wAa>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.transactions_pg_arrow_right__2FfcK {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.transactions_pg_arrow_left__1zMX9 {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.transactions_pg_arrow_right__2FfcK:before {
    content: "\E631";
}

.transactions_pg_arrow_left__1zMX9:before {
    content: "\E629";
}

.transactions_btn__kdE3J {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.transactions_btn_primary__ZQ3mj,
.transactions_btn_primary__ZQ3mj:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.transactions_btn_cons__h2elS {
    min-width: 120px;
}




.transactions_MerchantName__GXsIc {
    font-weight: 500;
}

.transactions_Icn__2toLW {
    cursor: pointer;
    /* fill:#000; */
}

.transactions_Icn__2toLW:hover {
    cursor: pointer;

}



.transactions_RevealMore__3KLae {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.transactions_RevealMore__3KLae:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.transactions_RevealMore__3KLae div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.transactions_RevealMoreExpanded__2sHf3 {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.transactions_Expandabled__3STk_ {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.transactions_Expandabled__3STk_ td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.transactions_MerchantTable__1f5G0 td:last-child {

    border-right: 1px solid #e8e8e8;
}


.transactions_MerchantTable__1f5G0 td:first-child {

    border-left: 1px solid #e8e8e8;
}


.transactions_MerchantRow__O20eC {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.transactions_MerchantRow__O20eC:hover {
    background-color: #267788;
    color: #fff;

}

.transactions_MerchantRow__O20eC:hover a {
    color: #fff;
}

.transactions_MerchantRowExpanded__2_KOU {
    background: #267788;
    color: #fff;
}

.transactions_table__2SO1X.transactions_table-condensed__3m8FE.transactions_table-detailed__2ppAr>tbody>tr.transactions_shown__2oGFF>td {
    background: #fdf5df;
}


.transactions_Totals__1EbeO td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.transactions_MoneyTotalLabel__3yoJL {
    float: right;

}

.transactions_FilterWrapper__1GxXC {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.transactions_FilterPeriods__rB4Tb {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.transactions_FilterPeriods__rB4Tb li {
    font-weight: 600;

    padding-left: 2rem;
    line-height: 38px;
}

.transactions_FinancialsIntro__3Ahy- {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}
.details_Container__39CO_ {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.details_BreadcrumbWrapper__3WVdh {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.details_Content__2gzdj {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.details_CardBody__2oqvw {
    margin-top: 1rem;
}

.details_Alert__1IGqt {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.details_Breadcrumb__3z32n {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.details_Header__3WrE9 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.details_ByLine__38Ngq {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.details_Activity__2SCzF {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.details_WelcomeContainer__1RP7I {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.details_MerchantProfile__15PCG {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.details_TabHeader__3URQ1 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.details_PageHeader__1sb5r {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.details_Header__3WrE9 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.details_ExportReport__3W0Et {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.details_ExportIconWrapper__1M0-3 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_EmailIconWrapper__2ZwR0 {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.details_AddUser__Gi29q {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.details_AddUserBtn__22HvT {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.details_Tools__1cOLY {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.details_PageFilter__2xE2s {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.details_PrintToolsWrapper__1M-sj {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.details_FilterReportBtn__2RQLv {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.details_AddNewMerchantBtn__gVHhe:focus,
.details_AddNewMerchantBtn__gVHhe:hover,
.details_AddNewMerchantBtn__gVHhe:active {
    background-color: rgb(51, 137, 255);
}


.details_ToolsDataExportIconWrapper__qLN2I {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.details_ToolsPrintIconWrapper__15jt2 {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.details_FilterInput__38bng {
    margin-right: 2rem;
}

.details_FormGroupDefault__1ysyk.details_has-error__1fuU6 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.details_FormGroupDefault__1ysyk {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.details_FormGroupDefault__1ysyk label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.details_FormGroup__3mIQ_ label:not(.details_error__2Mdfs) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.details_FormControl__1KHnW {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.details_MerchantTableWrapper__nzt6g {
    margin-top: 3rem;
    white-space: nowrap;
}

.details_MerchantTable__3oRtW {
    width: 100%;
    border-collapse: collapse;
}

.details_MerchantTable__3oRtW th {
    text-align: left;
}

.details_MerchantTable__3oRtW th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.details_MerchantTable__3oRtW td,
.details_MerchantTable__3oRtW th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_RevealMoreHeader__2HWSK {
    padding: 0;
    vertical-align: middle;
}

.details_Row__2vX2a {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.details_Row__2vX2a>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.details_dataTables_paginate__2yb4C {
    float: right;
}

div.details_dataTables_paginate__2yb4C {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.details_dataTables_paginate__2yb4C ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.details_dataTables_paginate__2yb4C ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.details_dataTables_paginate__2yb4C ul>li.details_disabled__1Az3g a {
    opacity: .5;
}

.details_dataTables_paginate__2yb4C ul>li.details_disabled__1Az3g a {
    opacity: .35;
}

.details_dataTables_paginate__2yb4C ul>li.details_active__33LZg>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.details_dataTables_paginate__2yb4C ul>li.details_next__gvPWY>a,
.details_dataTables_paginate__2yb4C ul>li.details_prev__16yx8>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__9D1ay {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_left__1-3YI {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.details_pg_arrow_right__9D1ay:before {
    content: "\E631";
}

.details_pg_arrow_left__1-3YI:before {
    content: "\E629";
}

.details_btn__2oP7- {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.details_btn_primary__1gFfH,
.details_btn_primary__1gFfH:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.details_btn_cons__drnm4 {
    min-width: 120px;
}




.details_MerchantName__1J2H9 {
    font-weight: 500;
}

.details_Icn__3_zRK {
    cursor: pointer;
    /* fill:#000; */
}

.details_Icn__3_zRK:hover {
    cursor: pointer;

}



.details_RevealMore__t2w61 {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.details_RevealMore__t2w61:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.details_RevealMore__t2w61 div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.details_RevealMoreExpanded__2hJs8 {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.details_Expandabled__1T_HR {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.details_Expandabled__1T_HR td {
    padding: 2rem;
    border-bottom: 1px solid #e8e8e8;
}

.details_MerchantTable__3oRtW td:last-child {

    border-right: 1px solid #e8e8e8;
}


.details_MerchantTable__3oRtW td:first-child {

    border-left: 1px solid #e8e8e8;
}


.details_MerchantRow__3PNsY {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.details_MerchantRow__3PNsY:hover {
    background-color: #267788;
    color: #fff;

}

.details_MerchantRow__3PNsY:hover a {
    color: #fff;
}

.details_MerchantRowExpanded__m3vkL {
    background: #267788;
    color: #fff;
}

.details_table__2ooDh.details_table-condensed__1EssK.details_table-detailed__Wtb9X>tbody>tr.details_shown__jQfs->td {
    background: #fdf5df;
}


.details_Totals__2X1L4 td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.details_MoneyTotalLabel__1Grdw {
    float: right;

}

.details_FilterWrapper__drv0b {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.details_FilterPeriods__6cgW_ {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.details_FilterPeriods__6cgW_ li {
    font-weight: 600;

    padding-left: 2rem;
    line-height: 38px;
}

.details_FinancialsIntro__18Wrv {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}
.detail_Container__3yXHH {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.detail_BreadcrumbWrapper__1_1Hz {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.detail_Content__3MibA {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.detail_CardBody__3qHax {
    margin-top: 1rem;
}

.detail_Alert__vsuCd {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.detail_Breadcrumb__3Z2_F {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.detail_Header__rPTKh {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.detail_ByLine__3wz-u {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.detail_Activity__1mHg9 {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.detail_WelcomeContainer__1xBvz {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.detail_MerchantProfile__2wnBz {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.detail_TabHeader__YDRq8 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.detail_PageHeader__xhcRE {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.detail_Header__rPTKh {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.detail_ExportReport__2fQ3g {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.detail_ExportIconWrapper__2P4PT {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.detail_EmailIconWrapper__2F-AJ {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.detail_AddUser__3A-i5 {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.detail_AddUserBtn__1lyDU {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.detail_Tools__17Yon {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.detail_PageFilter__3Lj-I {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}

.detail_PrintToolsWrapper__2L3GN {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}

.detail_FilterReportBtn__318HP {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.detail_AddNewMerchantBtn__2ghJh:focus,
.detail_AddNewMerchantBtn__2ghJh:hover,
.detail_AddNewMerchantBtn__2ghJh:active {
    background-color: rgb(51, 137, 255);
}

.detail_ToolsDataExportIconWrapper__1i9KV {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.detail_ToolsPrintIconWrapper__2ZNic {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.detail_FilterInput__39ym0 {
    margin-right: 2rem;
}

.detail_FormGroupDefault__ZUmYC.detail_has-error__1g3_2 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.detail_FormGroupDefault__ZUmYC {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.detail_FormGroupDefault__ZUmYC label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.detail_FormGroup__3BR2u label:not(.detail_error__88hDq) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.detail_FormControl__VaDD_ {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.detail_MerchantTableWrapper__3NKbE {
    margin-top: 3rem;
    white-space: nowrap;
}

.detail_MerchantTable__3yVOk {
    width: 100%;
    border-collapse: collapse;
}

.detail_MerchantTable__3yVOk th {
    text-align: left;
}

.detail_MerchantTable__3yVOk th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.detail_MerchantTable__3yVOk td,
.detail_MerchantTable__3yVOk th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.detail_RevealMoreHeader__1Lv2Y {
    padding: 0;
    vertical-align: middle;
}

.detail_Row__Lho9E {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.detail_Row__Lho9E>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.detail_dataTables_paginate__kHqz0 {
    float: right;
}

div.detail_dataTables_paginate__kHqz0 {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.detail_dataTables_paginate__kHqz0 ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}

.detail_dataTables_paginate__kHqz0 ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.detail_dataTables_paginate__kHqz0 ul>li.detail_disabled__3JGEm a {
    opacity: .5;
}

.detail_dataTables_paginate__kHqz0 ul>li.detail_disabled__3JGEm a {
    opacity: .35;
}

.detail_dataTables_paginate__kHqz0 ul>li.detail_active__ZdGX1>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.detail_dataTables_paginate__kHqz0 ul>li.detail_next__kbEpG>a,
.detail_dataTables_paginate__kHqz0 ul>li.detail_prev__3P6xf>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.detail_pg_arrow_right__2KSxC {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.detail_pg_arrow_left__1EecW {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.detail_pg_arrow_right__2KSxC:before {
    content: "\E631";
}

.detail_pg_arrow_left__1EecW:before {
    content: "\E629";
}

.detail_btn__TGmja {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.detail_btn_primary__NwG01,
.detail_btn_primary__NwG01:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.detail_btn_cons__ndMkS {
    min-width: 120px;
}

.detail_MerchantName__3rQPa {
    font-weight: 500;
}

.detail_Icn__2mzWc {
    cursor: pointer;
    /* fill:#000; */
}

.detail_Icn__2mzWc:hover {
    cursor: pointer;

}



.detail_RevealMore__3Nm7X {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.detail_RevealMore__3Nm7X:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.detail_RevealMore__3Nm7X div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.detail_RevealMoreExpanded__3kjLL {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.detail_Expandabled__3TMjr {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.detail_Expandabled__3TMjr td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.detail_MerchantTable__3yVOk td:last-child {

    border-right: 1px solid #e8e8e8;
}


.detail_MerchantTable__3yVOk td:first-child {

    border-left: 1px solid #e8e8e8;
}


.detail_MerchantRow__3Kh3Y {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.detail_MerchantRow__3Kh3Y:hover {
    background-color: #267788;
    color: #fff;

}

.detail_MerchantRow__3Kh3Y:hover a {
    color: #fff;
}

.detail_MerchantRowExpanded___ta5G {
    background: #267788;
    color: #fff;
}

.detail_table__2yETB.detail_table-condensed__gA8XF.detail_table-detailed__1tAvt>tbody>tr.detail_shown__1ONRM>td {
    background: #fdf5df;
}


.detail_Totals__lpNLB td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.detail_MoneyTotalLabel__21kBr {
    float: right;

}

.detail_FilterWrapper__1Noqj {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.detail_FilterPeriods__3FlqC {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.detail_FilterPeriods__3FlqC li {
    font-weight: 600;

    padding-left: 2rem;
    line-height: 38px;
}

.detail_FinancialsIntro__ywByp {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.detail_ExpandableView__S1cA8 {
    background-color: transparent;
    /* font-family: Cambay; */
}

.detail_Outlets__1V31V {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
    /* margin-left: 0.6rem; */
}

.detail_Outlets__1V31V li {
    display: inline-block;
        list-style: none;
}

.detail_Outlet__3fMBS {
    display: inline-block;
    width: 15rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #FFF;
    margin-right: 1rem;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;}

.detail_Outlet__3fMBS:hover {
color: #FFF;
    background: #5BA6E6;

}

.detail_OutletName__3sqer {
    font-weight: 600;
}

.detail_OutletLocation__G8-VE {
    font-weight: 400;

}

.detail_ExpandableMember__3W0cS {
    float: left;
    text-decoration: none;
}

.detail_ExpandableMemberName__LShNz {
    font-weight: 600;
    font-size: 2.2rem;
    display: inline-block;
    vertical-align: middle;
}

.detail_OutletListArea__3ng0S {
    width: 100%;
    margin-top: 1rem;
    background: transparent;
}



.detail_MemberContractDetailArea__29ryU {
    width: 100%;
    margin-top: 1rem;
    padding: 2rem 0px;
    background: rgb(255, 255, 255);
}

.detail_MemberContractDetailArea__29ryU header {
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.detail_ExpandableMemberWrapper__1ySat {
    background: transparent;
}

.detail_clearfix__bEB7Z::before,
.detail_clearfix__bEB7Z::after {
    display:table;
    content: " ";
}

.detail_clearfix__bEB7Z::after {
clear:both;
}


.detail_FuneralSchemeWrapper__29xiw {
    margin: 0 2.5rem;
}

.detail_AddNewMember__3Z1j9 {
float:right;
margin-right: 8.8rem;
}

.detail_AddNewMemberBtn__37fo7 {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    vertical-align: middle;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}

.detail_FuneralSchemeTable__3RXm1  {
    width: 100%;
    border-collapse: collapse;
}

.detail_FuneralSchemeTable__3RXm1 th {
    text-align: left;
}

.detail_FuneralSchemeTable__3RXm1 th {
    color: #666;
    vertical-align: top;
    font-size: 1.2rem;
    font-family: Raleway;
    font-weight: 600;
    vertical-align: middle;
}

.detail_FuneralSchemeTable__3RXm1 td,
.detail_FuneralSchemeTable__3RXm1 th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.detail_FuneralSchemeTable__3RXm1 .detail_Totals__lpNLB td {   
     border-bottom: none;
     font-weight: 600;
}

.detail_FuneralSchemeTable__3RXm1 .detail_MoneyTotalLabel__21kBr {
    float: right;

}
.detail_FuneralSchemeTable__3RXm1 .detail_MoneyTotal__YkZlB {

}
.MerchantActivityReport_Container__2gbmi {
    /* background-color: #fff; */
    flex-direction: column;
    height: 100%;
    font-size: 1.4rem;
}

.MerchantActivityReport_BreadcrumbWrapper__2wCOe {
    display: flex;
    background-color: transparent;
    width: 100%;
    padding-left: 2.4rem;
    flex: 0 0 7.2rem;
    height: 7.2rem;
}

.MerchantActivityReport_Content__1P-r2 {
    flex: 1 1 auto;
    display: flex;
    margin: 2.4rem;
    flex-direction: column;
}

.MerchantActivityReport_CardBody__3Yw67 {
    margin-top: 1rem;
}

.MerchantActivityReport_Alert__fyCH3 {
    padding: 18px;
    margin-bottom: 15px;
    border: 0;
    border-radius: 0;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.MerchantActivityReport_Breadcrumb__2f3DT {
    padding: 1.8rem 0;
    flex: 0 0 50%;
}

.MerchantActivityReport_Header__2_234 {
    font-weight: 600;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}

.MerchantActivityReport_ByLine__DXoez {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.MerchantActivityReport_Activity__2Li8L {
    background: #FFF;
    flex: 0 0 36rem;
    /* width: 36rem; */
}

.MerchantActivityReport_WelcomeContainer__2Xxu2 {
    display: flex;
    /* margin: 0 2.4rem 0 0; */
    /* height: 17.6rem; */
}

.MerchantActivityReport_MerchantProfile__1_NvQ {
    display: flex;
    background: transparent;
    flex: 0 0 100%;
}

.MerchantActivityReport_TabHeader__3HOi0 {
    /* padding: 0px 2rem; */
    margin-bottom: 1rem;
    display: flex;
}

.MerchantActivityReport_PageHeader__2SMHW {
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
}

.MerchantActivityReport_Header__2_234 {
    flex: 1 1 50%;
    justify-content: flex-start;
    width: auto;
}

.MerchantActivityReport_ExportReport__25aBh {
    flex: 1 1 50%;
    width: auto;
    margin-right: 1.4rem;
    align-items: center;
    display: flex;

    line-height: normal;
    flex: 1 1 auto;
    justify-content: flex-end;
}

.MerchantActivityReport_ExportIconWrapper__1OO0F {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.MerchantActivityReport_EmailIconWrapper__1I8Qy {
    margin: 0 1.6rem;
    vertical-align: middle;
    cursor: pointer;
    height: 24px;
    width: 24px;
    flex: 0 0 auto;
}

.MerchantActivityReport_AddUser__1k63N {
    display: flex;
    margin-right: 20px;
    flex: 1 1 50%;
    justify-content: flex-end;
    align-items: center;
}

.MerchantActivityReport_AddUserBtn__2BwOS {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    padding: 1rem;
    padding-left: 17px;
    padding-right: 17px;
    text-decoration: none;
    border: none;
    display: block;
}



.MerchantActivityReport_Tools__2EsO5 {
    /* display: flex; */
    margin: 1rem 0 3.5rem;
    /* padding-top: 1.4rem; */
}

.MerchantActivityReport_PageFilter__H-qyq {
    /* display: flex; */
    flex: 1 1 auto;
    justify-content: flex-start;
    width: auto;
    margin-left: 15px;
}


.MerchantActivityReport_PrintToolsWrapper__dOdhi {
    display: flex;
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
}


.MerchantActivityReport_FilterReportBtn__1bD8h {

    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 108, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
    height: 3.4rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    display: block;

}

.MerchantActivityReport_AddNewMerchantBtn__30cUG:focus,
.MerchantActivityReport_AddNewMerchantBtn__30cUG:hover,
.MerchantActivityReport_AddNewMerchantBtn__30cUG:active {
    background-color: rgb(51, 137, 255);
}


.MerchantActivityReport_ToolsDataExportIconWrapper__2jJwU {
    cursor: pointer;
    height: 24px;
    width: 24px;
}

.MerchantActivityReport_ToolsPrintIconWrapper__1APEM {
    display: inline-block;
    margin: 0 3rem;
    cursor: pointer;
    height: 24px;
    width: 24px;

}

.MerchantActivityReport_FilterInput__dCROb {
    margin-right: 2rem;
}

.MerchantActivityReport_FormGroupDefault__1OmTM.MerchantActivityReport_has-error__f3Pb0 {
    background-color: rgba(255, 0, 0, 0.05);
    border: 2px solid rgba(255, 0, 0, 0.5);
}

.MerchantActivityReport_FormGroupDefault__1OmTM {

    line-height: 2rem;
    position: relative;
    transition: all .2s;
    z-index: 5;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #e8e8e8;
    color: #e90052;
    min-height: 4rem;
}

label {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.MerchantActivityReport_FormGroupDefault__1OmTM label {
    margin: 0;
    display: block;
    opacity: 1;
    transition: opacity 0.2s ease;
}

.MerchantActivityReport_FormGroup__qaR_3 label:not(.MerchantActivityReport_error__1sS6q) {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
}

.MerchantActivityReport_FormControl__Kub9S {
    display: block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    box-shadow: none;
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none;
}

.MerchantActivityReport_MerchantTableWrapper__lwuOZ {
    margin-top: 3rem;
    white-space: nowrap;
}

.MerchantActivityReport_MerchantTable__3Khvl {
    width: 100%;
    border-collapse: collapse;
}

.MerchantActivityReport_MerchantTable__3Khvl th {
    text-align: left;
}

.MerchantActivityReport_MerchantTable__3Khvl th {
    color: #666;
    vertical-align: top;
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 500;
    vertical-align: middle;
}

.MerchantActivityReport_MerchantTable__3Khvl td,
.MerchantActivityReport_MerchantTable__3Khvl th {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;
}

.MerchantActivityReport_RevealMoreHeader__382Og {
    padding: 0;
    vertical-align: middle;
}

.MerchantActivityReport_Row__Q8pUd {
    display: flex;
    flex-wrap: wrap;
    margin: 2.5rem;
}

.MerchantActivityReport_Row__Q8pUd>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

.MerchantActivityReport_dataTables_paginate__2wo1T {
    float: right;
}

div.MerchantActivityReport_dataTables_paginate__2wo1T {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.MerchantActivityReport_dataTables_paginate__2wo1T ul>li {
    display: inline-block;
    padding-left: 0;
    font-size: 11px;
    line-height: 24px;
}



.MerchantActivityReport_dataTables_paginate__2wo1T ul>li>a {
    padding: 5px 10px;
    color: #575757;
    opacity: .35;
    transition: opacity .3s ease;
}

.MerchantActivityReport_dataTables_paginate__2wo1T ul>li.MerchantActivityReport_disabled__2V5Me a {
    opacity: .5;
}

.MerchantActivityReport_dataTables_paginate__2wo1T ul>li.MerchantActivityReport_disabled__2V5Me a {
    opacity: .35;
}

.MerchantActivityReport_dataTables_paginate__2wo1T ul>li.MerchantActivityReport_active__1cbDb>a {
    font-weight: 700;
    color: #575757;
    /* opacity: 1; */
}

.MerchantActivityReport_dataTables_paginate__2wo1T ul>li.MerchantActivityReport_next__3LL8x>a,
.MerchantActivityReport_dataTables_paginate__2wo1T ul>li.MerchantActivityReport_prev__peg6x>a {
    opacity: 1;
}

[class^=pg_],
[class*=" pg_"] {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MerchantActivityReport_pg_arrow_right__1juvE {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MerchantActivityReport_pg_arrow_left__fXoXd {
    display: inline-block;
    font: normal normal normal 14px/1 pages-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.MerchantActivityReport_pg_arrow_right__1juvE:before {
    content: "\E631";
}

.MerchantActivityReport_pg_arrow_left__fXoXd:before {
    content: "\E629";
}

.MerchantActivityReport_btn__1S2wt {
    font-family: Cambay, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.01em;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern"1;
    -moz-font-feature-settings: "kern"1;
    margin-bottom: 0;
    border: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none !important;
    color: #626262;
    background-color: #ffffff;
    text-shadow: none;
    box-shadow: none;
    line-height: 25px;
    padding: 5px 12px;
    padding-left: 17px;
    padding-right: 17px;
    position: relative;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.2s linear 0s !important;
}

.MerchantActivityReport_btn_primary__1p_-V,
.MerchantActivityReport_btn_primary__1p_-V:focus {
    color: #ffffff;
    background-color: #6d5cae;
    border-color: #6d5cae;
}

.MerchantActivityReport_btn_cons__3fXGx {
    min-width: 120px;
}




.MerchantActivityReport_MerchantName__IsQL7 {
    font-weight: 500;
}

.MerchantActivityReport_Icn__ueDNj {
    cursor: pointer;
    /* fill:#000; */
}

.MerchantActivityReport_Icn__ueDNj:hover {
    cursor: pointer;

}



.MerchantActivityReport_RevealMore__KWA6j {
    background: #e1e1e1;
    cursor: pointer;
    width: 2.4rem;
}

.MerchantActivityReport_RevealMore__KWA6j:hover {
    background: transparent;
    color: #707070;
    fill: #fff;
}

.MerchantActivityReport_RevealMore__KWA6j div {
    margin: 0 auto;
    width: 2.4rem;
    height: 2.4rem;
}

.MerchantActivityReport_RevealMoreExpanded__24GJA {
    background: transparent;
    fill: #fff;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.MerchantActivityReport_Expandabled__Vz2JX {
    text-align: left;
    background: #d7effb;
    border-bottom: 1px solid rgb(56, 0, 60);
    display: table-row;
    z-index: 500;
}

.MerchantActivityReport_Expandabled__Vz2JX td {
    padding: 2rem 2rem 2rem 5.6rem;
    border-bottom: 1px solid #e8e8e8;
}

.MerchantActivityReport_MerchantTable__3Khvl td:last-child {

    border-right: 1px solid #e8e8e8;
}


.MerchantActivityReport_MerchantTable__3Khvl td:first-child {

    border-left: 1px solid #e8e8e8;
}


.MerchantActivityReport_MerchantRow__1C2KE {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
}

.MerchantActivityReport_MerchantRow__1C2KE:hover {
    background-color: #267788;
    color: #fff;

}

.MerchantActivityReport_MerchantRow__1C2KE:hover a {
    color: #fff;
}

.MerchantActivityReport_MerchantRowExpanded__1JoF- {
    background: #267788;
    color: #fff;
}

.MerchantActivityReport_table__ekQJS.MerchantActivityReport_table-condensed__1eDwG.MerchantActivityReport_table-detailed__1aWwW>tbody>tr.MerchantActivityReport_shown__zm-JI>td {
    background: #fdf5df;
}


.MerchantActivityReport_Totals__3PuSs td {
    background: #f8f8f8;
    color: rgb(0, 0, 0);
    border-bottom: 1px solid #e8e8e8;
    font-weight: 700;
}

.MerchantActivityReport_MoneyTotalLabel__1OzuO {
    float: right;

}

.MerchantActivityReport_FilterWrapper__1WKCf {
    display: flex;
    justify-content: flex-end;
    border-right: solid #707070;

}

.MerchantActivityReport_FilterPeriods__2eynR {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row;
    /* height: 100%; */
    padding-left: 24px;
    color: rgb(0, 0, 0);
    justify-content: flex-end;
    margin-right: 3.2rem;
}

.MerchantActivityReport_FilterPeriods__2eynR li {
    font-weight: 600;
    padding-left: 2rem;
    line-height: 38px;
}

.MerchantActivityReport_FinancialsIntro__3Ltz0 {
    font-weight: 300;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.MerchantActivityReport_SalesBtn__2pxNR {
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.05rem;
    cursor: pointer;
    color: #2c2c2c;
    background-color: transparent;
    padding: 0.75rem;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    border:  1px solid #e5e5e5;
    /* display: block; */
}
